<template>
 <v-card>
                          <h5
                            class="text-h5 grey lighten-2 text-center"
                            style="text-align:center; padding:10px;"
                          >
                            Selecciona el tipo de vacante
                          </h5>

                          <v-container>
                            <v-row>
                              <v-col>
                                <v-card
                                height="350px"
                                  style="border-radius:40px;"
                                  class="primary darken-3"
                                  dark
                                >
                                  <h4
                                    class="text-h4 text-center"
                                    style="text-align:center; padding:10px;"
                                  >
                                    <span style="color:#2afed6">Vacante</span> Profesionista
                                  </h4>

                                  <p style="margin-top:5px;padding:5px;">
                                    Vacantes que solicitan:
                                    <br /><br />
                                    -Preparación o experiencia profesional.
                                    <br />
                                    <br />
                                    -Requieren alguna formación académica
                                    (Técnicos, licenciaturas, ingenierías,
                                    maestrías)
                                  </p>

                                  <v-card-actions>
                                    <v-container>
                                      <div class="text-center">
                                        <v-btn
                                          style="background-color:#2afed6;color:black;border:1px solid #2afed6;"
                                          class="ml-2 mt-5"
                                          outlined
                                          rounded
                                          large
                                          @click="nuevaVacanteProfGratuita()"
                                        >
                                          Crear Nueva
                                        </v-btn>
                                      </div>
                                    </v-container>
                                  </v-card-actions>
                                </v-card>
                              </v-col>
                              <v-col>
                                <v-card
                                height="350px"
                                  style="border-radius:40px;"
                                  class="primary darken-3"
                                  dark
                                >
                                  <h4
                                    class="text-h4 text-center"
                                    style="text-align:center; padding:10px;"
                                  >
                                   <span style="color:#2afed6">Vacante </span> Operativa
                                  </h4>

                                  <p style="margin-top:5px;padding:5px;">
                                    Vacantes que solicitan: <br /><br />
                                    -Operadores de producción , Limpieza,
                                    Choferes, Ayudantes Generales, <br />
                                    Vigilantes, Cocinas, Cajas, etc. <br />
                                    <br />
                                    -No requieren alguna formación académica en
                                    especial.
                                  </p>

                                  <v-card-actions>
                                    <v-container>
                                      <div class="text-center">
                                        <v-btn
                                           style="background-color:#2afed6;color:black;border:1px solid #2afed6;"
                                          class="ml-2 mt-5"
                                          outlined
                                          rounded
                                          large
                                          @click="
                                            NuevaVacanteOperativaGratuita()
                                          "
                                        >
                                          Crear Nueva
                                        </v-btn>
                                      </div>
                                    </v-container>
                                  </v-card-actions>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-container>
                              <div class="text-center">
                                <v-btn
                                  x-large
                                  style="border-radius:20px;color:white;border-color:white;border:1px solid;"
                                  color="primary darken-2"
                                  @click="dialogNueva = false"
                                >
                                  Salir
                                </v-btn>
                              </div>
                            </v-container>
                          </v-card-actions>
                        </v-card>

</template>


<script src="https://badge.dimensions.ai/badge.js"></script>
<script>
var moment = require("moment");
moment.locale("es-mx");
import axios from "axios";
import { mapMutations, mapState, mapActions } from "vuex";


export default {
  data() {
    return {
   
      calle: "",
      numero: "",
      colonia: "",
      miseleccion: "",
      token:
        "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
    };
  },
  beforeMount() {
    this.$store.dispatch("autoLogin");
   
  },

  computed: {
    ...mapState(["id", "miempresa", "elestado", "laciudad"]),
  },
  methods:{
        nuevaVacanteProfGratuita() {
      this.$router.push({ name: "NuevaVacante" });
    },
    NuevaVacanteOperativaGratuita() {
      this.$router.push({ name: "NuevaVacanteOperativa" });
    },
    Salir() {
      this.$router.push({ name: "PerfilEmpresas" });
    },
  }
  }

</script>
