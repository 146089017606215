<template>
  <v-stepper v-model="e6" vertical>
    <v-stepper-step :complete="e6 > 1" step="1">
      Selecciona tu Compra
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center">
            <h3>Selecciona el  Paquete que desees adquirir</h3>
            <br>
          </v-col>
          <v-col>
          
              <v-card class="mx-auto" color="grey darken-4" width="220" height="310">
              
                  
             
                <v-card-text class="pt-6" style="position: relative;">
                  <v-btn
                    absolute
                    color="primary"
                    class="white--text"
                    fab
                    large
                    right
                    top
                    @click="seleccionaSencillo()"
                  >
                    <v-icon style="color:black;">mdi-cart</v-icon>
                  </v-btn>
                  <div style="color:#FFC000" class=" text-h6 mb-2">
                    $ 500.00 mas I.V.A
                  </div>
                  <br>
                  <h3
                    class="text-h4 text-center font-weight-light primary--text mb-2"
                  >
                    Postulación Preferencial
                  </h3>
                  <br>
                  <div style="color:#FFC000" class=" text-h6 mb-2 text-center">
                     500 Créditos
                  </div>
                </v-card-text>
              </v-card>
          
          </v-col>
          

        </v-row>
      </v-container>

    </v-stepper-content>

    <v-stepper-step :complete="e6 > 2" step="2">
      Elige la forma de pago
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card color="grey lighten-1" class="mb-12" height="400px">
        <v-card>
          <v-toolbar flat color="primary" dark> </v-toolbar>
          <v-row>
            <v-col cols="6">
              <div style="padding:20px;margin-top:20px;">
                <div class="text-center">
                  <v-radio-group
                    class="small-radio"
                    @change="verificatipoPublicacion"
                    v-model="tipoVacante"
                  >
                    <v-radio
                      selected
                      x-large
                      label="TARJETA DÉBITO / CRÉDITO"
                    ></v-radio>

                   
                  </v-radio-group>
                </div>
                <br />
              </div>
            </v-col>
            <v-col cols="6">
              <div
                v-if="confirmapublicacion == 1"
                style="padding:20px,margin-left:-10px"
              >
                <h3 style="margin-top:40px; " class="text-center">
                  Información
                </h3>
                <p>
                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;"
                    >•</span
                  >
                  Aceptamos las siguientes tarjetas<br />

                  <span
                    style="font-size:23px; color:#2afed6;margin-bottom:1px;margin-left:20px"
                    >•</span
                  >
                  Visa <br />
                  <span
                    style="font-size:23px; color:#2afed6;margin-bottom:1px; margin-left:20px"
                    >•</span
                  >
                  Mastercard <br />
                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;"
                    >•</span
                  >
                  Una vez aprobada la tarjeta, tus créditos serán cargados
                  automáticamente a tu cuenta.<br />
                </p>
              </div>
              <div
                v-if="confirmapublicacion == 2"
                style="padding:20px,margin-left:-10px; margin-top:15px"
              >
                <h3 class="text-center">
                  Información
                </h3>
                <p>
                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;"
                    >•</span
                  >
                  Recibimos transferencias de todos los bancos.<br />

                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;"
                    >•</span
                  >
                  Una vez reflejado el pago en nuestro sistema, tus créditos
                  serán abonados a tu cuenta. <br />
                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;"
                    >•</span
                  >
                  Los créditos serán abonados en un lapso no mayor a 24 horas en
                  días hábiles. <br />
                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;"
                    >•</span
                  >
                  Se enviara un correo de notificación, cuando los créditos
                  hayan sido abonados al usuario.
                  <br />
                </p>
              </div>
              <div
                v-if="confirmapublicacion == 3"
                style="padding:20px,margin-left:-10px; margin-top:15px"
              >
                <h3 class="text-center">
                  Información
                </h3>
                <p style="padding:10px;">
                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;"
                    >•</span
                  >
                  Una vez recibida la orden de compra, tus créditos serán
                  abonados a tu cuenta.
                  <br />

                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;"
                    >•</span
                  >
                  Se enviara un correo de notificación, cuando los créditos
                  hayan sido abonados al usuario <br />
                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;"
                    >•</span
                  >
                  Si requieres mayor información porfavor contacte a uno de
                  nuestros asesores al (614) 606-52-78 o a ventas@vacanta.mx
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
      <div class="text-center">
        <v-btn v-if="confirmapublicacion == 1" color="primary" style="color:black;" @click="e6 = 3">
          Continuar
        </v-btn>
        <v-btn v-else-if="confirmapublicacion == 2" color="primary" style="color:black;" @click="e6 = 3">
          Continuar
        </v-btn>
        <router-link  v-else to="/perfilEmpresas">
         <v-btn color="primary" style="color:black;" >
          Continuar
        </v-btn>
        </router-link>
        <v-btn style="margin-left:7px;" dark @click="e6 = 1">
          Atras
        </v-btn>
      </div>
    </v-stepper-content>

    <v-stepper-step :complete="e6 > 3" step="3">
      Realiza el pago
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-card color="grey lighten-1" class="mb-12" >
        <div
          ref="form"
          v-if="confirmapublicacion == 1"
        >
          <div class="text-center" style="padding:20px;">
            <h3>
              Da click en “Ok”, llena y revisa que la información este correcta.
              Al terminar da click en “Pay now” para confirmar la compra.
            </h3>
            <br />

            <v-btn
             
              color="primary"
              style="color:black"
              @click="enviarCompra()"
            >
              Ok
            </v-btn>
            <v-btn style="margin-left:10px;" dark @click="atraste()">
              Atras
            </v-btn>
          </div>
          <form
            action="/compraExitosa"
            class="paymentWidgets"
            data-brands="VISA MASTER AMEX"
          ></form>
        </div>
        <div v-if="confirmapublicacion == 2" style="padding:20px;">
          <div class="text-center" style="padding:20px;">
            <h3>
              Da click en el boton de "OK" para generar la referencia
            </h3>
            <br />

            <v-btn
             
              color="primary"
              style="color:black"
              @click="crearReferencia()"
            >
              Ok
            </v-btn>
            <v-btn style="margin-left:10px;" dark @click="e6 = 2">
              Atras
            </v-btn>
          </div>
          <h3 class="text-center">
            Realiza la transferencia con los siguientes datos y da click en
            “Continuar” para salir.
          </h3>
          <br />
          <v-container>
            <v-row >
              <v-col cols="2" class="pc"
                ><p style="font-size:20px;">
                  Nombre:
                </p>

                <p style="font-size:20px;">
                  Banco:
                </p>

                <p style="font-size:20px;">
                  CLABE:
                </p>

                <p style="font-size:20px;">
                  Referencia:
                </p>
                <p style="font-size:20px;">
                  Monto:
                </p>
              </v-col>
              <v-col cols="8"  class="pc"
                ><p
                  style="background-color:#2afed6;text-align:center;font-size:20px;"
                >
                  OPERADORA ROCHEN DE MEXICO S.A DE C.V
                </p>
                <p
                  style="background-color:#2afed6;text-align:center;font-size:20px;"
                >
                  Intercam
                </p>

                <p
                  style="background-color:#2afed6;text-align:center;font-size:20px;"
                >
                  136150259832200135
                </p>
                <p
                  style="background-color:#2afed6;text-align:center;font-size:20px;"
                >
                  {{nuevaReferencia}}
                </p>
                <p
                  style="background-color:#2afed6;text-align:center;font-size:20px;"
                >
                  {{this.cantidad.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}}
                </p>
              </v-col>
            
              <v-flex sm="6" md="8" class="cel"
              
                ><p style="font-size:20px;">
                  Nombre:
                </p>
                
                <p
                  style="background-color:#2afed6;text-align:center;font-size:20px;"
                >
                  OPERADORA ROCHEN DE MEXICO S.A DE C.V
                </p>
                <p style="font-size:20px;">
                  Banco:
                </p>
                <p
                  style="background-color:#2afed6;text-align:center;font-size:20px;"
                >
                  Intercam
                </p>
                
                <p style="font-size:20px;">
                  CLABE:
                </p>

                <p
                  style="background-color:#2afed6;text-align:center;font-size:20px;"
                >
                  136150259832200135
                </p>
                <p style="font-size:20px;">
                  Referencia:
                </p>
                <p
                  style="background-color:#2afed6;text-align:center;font-size:20px;"
                >
                  {{nuevaReferencia}}
                </p>
                <p style="font-size:20px;">
                  Monto:
                </p>
                <p
                  style="background-color:#2afed6;text-align:center;font-size:20px;"
                >
                  {{this.cantidad.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}}
                </p>
              </v-flex>
              
            </v-row>
          </v-container>
          <br />

          <div class="text-center">
            <v-btn
              style="margin-left:10px;color:black"
              color="primary"
              @click="ConfirmarTransferencia()"
            >
              Continuar
            </v-btn>
            <v-btn style="margin-left:10px;" dark @click="e6 = 2">
              Atras
            </v-btn>
          </div>
        </div>
        
      </v-card>
    </v-stepper-content>
  </v-stepper>
</template>
  <script src="https://badge.dimensions.ai/badge.js"></script>
<script>
import axios from "axios";
export default {
  data() {
    return {
      e6: 1,
      paquetes: [],
      tipoVacante: null,
      confirmapublicacion: null,
      num_empleados: null,
      nuevaReferencia: 0,
    };
  },
  mounted() {
    
     this.$store.dispatch("autoLogin");
    
    axios
      .get("/catalogos/paquetescreditosempresa")
      .then((response) => {
        this.paquetes = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post("/empresas/perfilempresa", {
        empresa: this.$store.state.id.empresa,
      })
      .then((response) => {
        this.num_empleados = response.data[0].num_empleados;
      })

      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    atraste(){
      this.$router.go(this.$router.currentRoute)
      
      
    },
    crearReferencia(){
      axios
      .post("/creditos/reftransfer", {
        importe: this.cantidad,
        empresa:this.$store.state.id.empresa,
        creditos: this.cantidad
      })
      .then((response) => {
        this.nuevaReferencia = response.data[0].referencia;
      })

      .catch((error) => {
        console.log(error);
      });
    },
    
    seleccionaElite(){
      this.cantidad = 1300;
      this.e6 = 2;
      console.log(this.cantidad)
    },
     seleccionaSencillo(){
      this.cantidad = 800;
      this.e6 = 2;
      console.log(this.cantidad)
    },
     seleccionaCombinado(){
      this.cantidad = 2400;
      this.e6 = 2;
      console.log(this.cantidad)
    },
     seleccionaAbierto(){
      this.cantidad = 5000;
      this.e6 = 2;
      console.log(this.cantidad)
    },

    ConfirmarTransferencia() {
      this.$router.push({ name: "PerfilEmpresas" });
    },
    verificatipoPublicacion() {
      if (this.tipoVacante == 0) {
        this.confirmapublicacion = 1;
      }
      if (this.tipoVacante == 1) {
        this.confirmapublicacion = 2;
      }
      if (this.tipoVacante == 2) {
        this.confirmapublicacion = 3;
      }
    },
    enviarCompra() {
      console.log("cantidad:", this.cantidad);
      
      axios
        .post("/creditos/candidatosolicitud", { cantidad: this.cantidad })
        .then((response) => {
          console.log(response.data.id);
          this.$loadScript(
            "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=" +
              response.data.id
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  /* todo lo de cel */
.pc{
  display:none;
}

}
@media only screen and (min-width: 868px) {

  .cel {
    display: none;
  }
}

@media only screen and (max-width: 768px) {

  .pc {
    display: none;
  }
}
</style>
