<template>
  
    <v-autocomplete
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      hide-details
      item-text="institucion"
       clearable
      item-value="symbol"
      label="Institución"
      color="primary"
      outlined
      :click="setInstitucion(model)"
     autocomplete="nope"
      return-object
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Busca tu 
            <strong>Institución</strong>
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:item="{ item }">
        <v-list-item-avatar
          color="#14a6a6"
          class="text-h5 font-weight-light white--text"
        >
          {{ item.institucion.charAt(0) }}
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.institucion"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
      </template>
</template>
<script>

 import { mapMutations, mapState } from 'vuex'
  export default {
    data: () => ({
      isLoading: false,
      items: [],
      model: null,
      search: null,
      tab: null,
    }),
     computed:{
      ...mapState (['lainstitucion','folioinstitucion'])
    },
     methods:{
       ...mapMutations (['setInstitucion']),
      
     },


    watch: {
      model (val) {
        if (val != null) this.tab = 0
        else this.tab = null
      },
      search (val) {
        // Items have already been loaded
        if (this.items.length > 0) return

        this.isLoading = true

        // Lazily load input items
        fetch('http://tisdcsecondary.ddns.net:8000/api/catalogos/centestu')
          .then(res => res.clone().json())
          .then(res => {
            this.items = res
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },

    },
  }
</script>