<template>
<div>
  <v-container style="margin-bottom:40px;">
      <div class="text-center" >
         <router-link :to="{ name: 'NuevoServicio' }">
         <v-btn
  color="primary" style="color:black"
  >Publicar Servicio</v-btn>
         </router-link>
   
        

      </div>
      <br>



<br>
  
    
<!--   <v-row>
    <v-col
      cols="12"
      sm="12"
    >
    <div class="text-center">
       <v-date-picker
        v-model="dates"
        range
      ></v-date-picker>
    
      <v-text-field
        v-model="dateRangeText"
        label="Rango de Fechas"
        prepend-icon="mdi-calendar"
        readonly
      ></v-text-field>

      <v-btn @click="buscarFecha()">
        Buscar
      </v-btn>


    </div>
     
      
    </v-col>
  </v-row> -->
 <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        outlined
        hide-details
      
        color="primary"
      ></v-text-field>
 <br>
   
<v-data-table
    :headers="headers"
    :items="item"
    :items-per-page="5"
    filterable 
     :search="search"
     @click:row="handleClick"
     dark
     
  >
 

  <template v-slot:item.fcrea="{ item }">
      <p>{{moment(item.fcrea).format("LL")}}</p>
     
      
    </template>
    
  <template v-slot:item.ffin="{ item }">
      <p>{{moment(item.ffin).format("LL")}}</p>
     
      
    </template>

<template v-slot:item.activa="{ item }">
      <v-icon
        
        class="mr-2"
        style="background-color:#2afed6; border-radius:25px; color: black;"
        v-if="item.activa"
      >
        mdi-check
      </v-icon>
      <v-icon
        
        class="mr-2"
        style="background-color:red; border-radius:25px; color: black;"
        v-else
      >
        mdi-close
      </v-icon>
     
      
    </template>

     <template v-slot:item.cancelada="{ item }">
      <v-icon
        
        class="mr-2"
        style="background-color:#2afed6; border-radius:25px; color: black;"
        v-if="item.cancelada"
      >
        mdi-check
      </v-icon>
      <v-icon
        
        class="mr-2"
        style="background-color:red; border-radius:25px; color: black;"
        v-else
      >
        mdi-window-close
      </v-icon>
     
      
    </template>
    <template v-slot:item.desactivada="{ item }">
      <v-button
        name="boton"
        class="mr-2"
        style="padding:10px;background-color:red; border-radius:25px; color: black; "
        v-if="item.activa"
      >Desactivar
      <v-icon color="black">
        mdi-power
      </v-icon>
       
      </v-button>
      
     
      
    </template>
   

 
 
  </v-data-table>
  
  <div>

  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >

      <v-card>
        <v-card-title class="text-h6">
        ¿Estas seguro que deseas desactivar el anuncio?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
             @click="cancelar()"
          >
            Si
          </v-btn>
          <v-btn
            color="green darken-1"
            text
                       @click="dialog = false"

          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  </div>

</v-container >
</div>
   
</template>

<script>
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

var moment = require("moment");
moment.locale("es-mx");
import axios from 'axios'
import { mapState,mapMutations } from "vuex";
  export default {
    data () {
      return {
        dialog: false,
             moment: moment,
        dates: ['2021-03-01', '2023-12-30'],
         search: '',
         paginacion: 3000,
        headers: [
          { text: 'Creada', value: 'fcrea' , dataType: "Date" },
          { text: 'Vencimiento', value: 'ffin' },
          { text: 'Anuncio', value: 'desc_corta' },
          { text: 'Activa', value: 'activa' },
          { text: 'Desactivada', value: 'cancelada' },
          { text: 'Desactivar', value: 'desactivada' },
        ],
        item: [ ],
      }
    },
     computed: {
    ...mapState(["id"]),
     dateRangeText () {
        return this.dates.join(' ~ ')
      },
  },
    mounted(){
      this.$store.dispatch("autoLogin")
                axios.post('/servicios/buscaservicioEmpresa',{
                    empresa: this.$store.state.id.servicio,
                    pagina: 1,
                    paginacion: 1500  
                }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
            .then(response=>{
                this.item = response.data
               console.log(response.data)
                     })
            .catch(error =>{
                console.log(error);
            
        })
        
    },
    methods:{
      cancelar(){
        axios
        .post(
          "/servicios/cancelaservicio",
          { folio:  this.$store.state.VacanteFolio, empresa: this.$store.state.id.id}, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   }
        )
        .then((response) => {
          console.log(response.data);
          this.$router.go(this.$router.currentRoute);
        })
        .catch((error) => {
          console.log(error);
        }); 
      },
      ...mapMutations(["setVacante"]),
      handleClick(row){
        console.log(row)
        this.setVacante(row.folio)
        this.dialog =true;
        
        if(row.activa && row.cubierta==false && row.cancelada==false){
         this.setVacante(row.folio)
            this.$router.push({name: 'Profesionistas'});
        }
        

      },
      buscarFecha(){
         axios.post('/vacantes/vacantesempresa/',{
                    empresa: this.$store.state.id.empresa,
                    buscar: "%VACIA%",
                    cubierta: null,
                    activa: null,
                    desde: this.dates[0],
                    hasta: this.dates[1],
                    paginacion: this.paginacion,
                    pagina: 1
                })
            .then(response=>{
                this.item = response.data
                     })
            .catch(error =>{
                console.log(error);
            
        })
      }

    }
    
  }
</script>

<style>
.activa{
  color:aqua;
}
@media only screen and (min-width: 800px) {
  /* todo lo de pc */
   #botonCreditos {
    margin-left: 30px;
  }
}

@media (min-width: 700px) and (orientation: landscape){
 .boton {
    margin-top: 240px;
  }
}
@media (min-width: 700px) and (orientation: portrait){
 .boton {
    margin-top: 340px;
  }
  .botonCreditos{
    margin-top: -10px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 768px) {
  /* todo lo de cel */
  #botonCreditos {
    margin-top: 20px;
    margin-left:5px;
  }

}

</style>