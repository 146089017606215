<template>

<div>
  <v-container id="pc">
      <div class="text-center" v-if="this.creditos>=1">
         
   
        
          <router-link :to="{ name: 'NuevaVacanteOperativa' }">
         <v-btn
  color="primary" style="color:black; margin-left:20px;"
  >Nueva Vacante Operativa ELITE</v-btn>
         </router-link>

      </div>
      <div class="text-center" v-else>
           
   
        
          <router-link :to="{ name: 'ComprarCreditos' }">
         <v-btn
  color="primary" style="color:black; margin-left:20px;"
  >Nueva Vacante Operativa</v-btn>
         </router-link>

      </div>
      <br>
<div class="text-center" id="pc">
        
          <v-tooltip bottom  id="pc">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="grey"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-help-circle
        </v-icon>
      </template>
      <span style="text-align:center;">Vacantes que solicitan: <br>
            -Operadores de producción , Limpieza,
                                    Choferes, Ayudantes Generales, <br />
                                    Vigilantes, Cocinas, Cajas, etc. <br />
                                    <br />
                                    -No requieren alguna formación académica en
                                    especial.
      </span>
    </v-tooltip>
</div>


<br>
  </v-container>
    <v-container id="cel">
      <div class="text-center" v-if="this.creditos>=1">
         
   
        
          <router-link :to="{ name: 'NuevaVacanteOperativa' }">
         <v-btn
  color="primary" style="color:black; margin-left:20px;"
  >Nueva Vacante Operativa ELITE</v-btn>
         </router-link>

      </div>
      <div class="text-center" v-else>
           
   
        
          <router-link :to="{ name: 'ComprarCreditos' }">
         <v-btn
  color="primary" style="color:black; margin-left:20px;"
  >Nueva Vacante Operativa</v-btn>
         </router-link>

      </div>
       <v-tooltip bottom  id="pc">

      <span style="text-align:center;">Vacantes que solicitan: <br>
          -Operadores de producción , Limpieza,
                                    Choferes, Ayudantes Generales, <br />
                                    Vigilantes, Cocinas, Cajas, etc. <br />
                                    <br />
                                    -No requieren alguna formación académica en
                                    especial.
      </span>
    </v-tooltip>



<br>
  </v-container>
<!--   <v-row>
    <v-col
      cols="12"
      sm="12"
    >
    <div class="text-center">
       <v-date-picker
        v-model="dates"
        range
      ></v-date-picker>
    
      <v-text-field
        v-model="dateRangeText"
        label="Rango de Fechas"
        prepend-icon="mdi-calendar"
        readonly
      ></v-text-field>

      <v-btn @click="buscarFecha()">
        Buscar
      </v-btn>


    </div>
     
      
    </v-col>
  </v-row> -->
 <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        outlined
        hide-details
      
        color="primary"
      ></v-text-field>
 <br>
   
<v-data-table
    :headers="headers"
    :items="item"
    :items-per-page="5"
    filterable 
     :search="search"
     @click:row="handleClick"
     dark
     
  >
 
  <template v-slot:item.fechacreacion="{ item }">
      <p>{{moment(item.fechacreacion).format("LL")}}</p>
     
      
    </template>
    <template v-slot:item.fechafinal="{ item }">
      <p>{{moment(item.fechafinal).format("LL")}}</p>
     
      
    </template>
    
     <template v-slot:item.posicion="{ item }">
      <p>{{item.posicion.toUpperCase()}}</p>
     
      
    </template>
    <template style="margin-top:13px;" v-slot:item.sueldo_raninf="{ item }">
      <p>{{item.sueldo_raninf.toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD',
})}}</p>
    </template>
    <template style="margin-top:13px;" v-slot:item.sueldo_ransup="{ item }">
      <p>{{item.sueldo_ransup.toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD',
})}}</p>
    </template>
<template v-slot:item.activa="{ item }">
      <v-icon
        
        class="mr-2"
        style="background-color:#2afed6; border-radius:25px; color: black;"
        v-if="item.activa"
      >
        mdi-check
      </v-icon>
      <v-icon
        
        class="mr-2"
        style="background-color:red; border-radius:25px; color: black;"
        v-else
      >
        mdi-close
      </v-icon>
     
      
    </template>

     <template v-slot:item.cancelada="{ item }">
      <v-icon
        
        class="mr-2"
        style="background-color:#2afed6; border-radius:25px; color: black;"
        v-if="item.cancelada"
      >
        mdi-check
      </v-icon>
      <v-icon
        
        class="mr-2"
        style="background-color:red; border-radius:25px; color: black;"
        v-else
      >
        mdi-window-close
      </v-icon>
     
      
    </template>
        <template v-slot:item.desactivada="{ item }">
      <v-button
        
        class="mr-2"
        style="padding:10px;background-color:red; border-radius:25px; color: black; "
        v-if="item.activa"
      >Desactivar
      <v-icon color="black">
        mdi-power
      </v-icon>
       
      </v-button>
      
     
      
    </template>

 
 
  </v-data-table>
</div>
   
</template>

<script>
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
var moment = require("moment");
moment.locale("es-mx");
import axios from 'axios'
import { mapState,mapMutations } from "vuex";
  export default {
    data () {
      return {
             moment: moment,
             creditos: 0,
        dates: ['2021-03-01', '2023-12-30'],
         search: '',
         paginacion: 3000,
        headers: [
          { text: 'Creada', value: 'fechacreacion' , dataType: "Date" },
          { text: 'Vencimiento', value: 'fechafinal' },
          { text: 'Posición', value: 'posicion' },

          { text: 'Ciudad', value: 'ciudad' },
          { text: 'Activa', value: 'activa' },
                { text: 'Desactivada', value: 'cancelada' },
             { text: 'Desactivar', value: 'desactivada' },
        ],
        item: [ ],
      }
    },
     computed: {
    ...mapState(["id"]),
     dateRangeText () {
        return this.dates.join(' ~ ')
      },
  },
    mounted(){
      
      this.$store.dispatch("autoLogin")
      axios
          .get("/creditos/creditosempresa/" + this.$store.state.id.empresa, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.creditos = response.data.creditos;
          })
          .catch((error) => {
            console.log(error);
          });
                axios.post('/vacantes/vacantesOperativoempresa/',{
                    empresa: this.$store.state.id.empresa,
                    buscar: "%VACIA%",
                    cubierta: null,
                    activa: null,
                    desde: this.dates[0],
                    hasta: this.dates[1],
                    paginacion: this.paginacion,
                    pagina: 1
                }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
            .then(response=>{
                this.item = response.data
                     })
            .catch(error =>{
                console.log(error);
            
        })
        
    },
    methods:{
      ...mapMutations(["setVacante"]),
      handleClick(row){
        
        if(row.activa && row.cancelada==false){
         this.setVacante(row.folio)
            this.$router.push({name: 'verVacanteOperativo'});
        }
        

      },
      buscarFecha(){
         axios.post('/vacantes/vacantesempresa/',{
                    empresa: this.$store.state.id.empresa,
                    buscar: "%VACIA%",
                    cubierta: null,
                    activa: null,
                    desde: this.dates[0],
                    hasta: this.dates[1],
                    paginacion: this.paginacion,
                    pagina: 1
                })
            .then(response=>{
                this.item = response.data
                     })
            .catch(error =>{
                console.log(error);
            
        })
      }

    }
    
  }
</script>

<style>
.activa{
  color:aqua;
}
@media only screen and (min-width: 800px) {
  /* todo lo de pc */
   #botonCreditos {
    margin-left: 30px;
  }
}

@media (min-width: 700px) and (orientation: landscape){
 .boton {
    margin-top: 240px;
  }
}
@media (min-width: 700px) and (orientation: portrait){
 .boton {
    margin-top: 340px;
  }
  .botonCreditos{
    margin-top: -10px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 768px) {
  /* todo lo de cel */
  #botonCreditos {
    margin-top: 20px;
    margin-left:5px;
  }

}

</style>