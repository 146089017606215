<template>
  <div>
    <v-autocomplete
      outlined
      v-model="mievento"
      :click="setNivelOperativo(mievento)"
      label="Nivel Educativo"
      clearable
      :items="niveles"
      item-text="nivel"
      item-value="nivel"
      autocomplete="nope"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";
export default {
  data: () => ({
    especialidadSeleccionada: {}, //<-- el seleccionado estará aquí
    niveles: [], // <-- La lista de especialidades
    mievento: "",  
  }),
  computed: {
    ...mapState(["nivel"]),
  },
 
      mounted() {
    
            axios
            .get("/catalogos/niveleducativoop")
            .then((response) => {
              this.niveles = response.data;
            })
            .catch((error) => {
              console.log(error);
            });
          
  },
  methods: {
    ...mapMutations(["setNivelOperativo"]),
  },
};
</script>

<style></style>
