var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"hide-details":"","clearable":"","hide-selected":"","item-text":"sector","item-value":"symbol","label":"Sector","color":"primary","outlined":"","click":_vm.setSector(_vm.model),"return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){_vm.searchInput=''}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Busca tu "),_c('strong',[_vm._v("Sector")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"black--text",attrs:{"input-value":selected,"color":"primary"}},'v-chip',attr,false),on),[_c('span',{domProps:{"textContent":_vm._s(item.sector)}})])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"#14a6a6"}},[_vm._v(" "+_vm._s(item.sector.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.sector)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.symbol)}})],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }