var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"id":"pc"}},[(this.creditos>0)?_c('div',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{ name: 'NuevaVacante' }}},[_c('v-btn',{staticStyle:{"color":"black"},attrs:{"color":"primary"}},[_vm._v("Nueva Vacante Profesionista ELITE")])],1)],1):_c('div',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{ name: 'ComprarCreditos' }}},[_c('v-btn',{staticStyle:{"color":"black"},attrs:{"color":"primary"}},[_vm._v("Nueva Vacante Profesionista")])],1)],1),_c('br'),_c('div',{staticClass:"text-center",attrs:{"id":"pc"}},[_c('v-tooltip',{attrs:{"bottom":"","id":"pc"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-left":"20px"},attrs:{"color":"grey","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle ")])]}}])},[_c('span',{staticStyle:{"text-align":"center"}},[_vm._v("Vacantes que solicitan: "),_c('br'),_vm._v(" -Preparación o experiencia profesional. "),_c('br'),_vm._v(" -Requieren alguna formación académica (Técnicos, licenciaturas, ingenierías, maestrías) ")])])],1),_c('br')]),_c('v-container',{attrs:{"id":"cel"}},[(this.creditos>0)?_c('div',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{ name: 'NuevaVacante' }}},[_c('v-btn',{staticStyle:{"color":"black"},attrs:{"color":"primary"}},[_vm._v("Nueva Vacante Profesionista ELITE")])],1)],1):_c('div',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{ name: 'ComprarCreditos' }}},[_c('v-btn',{staticStyle:{"color":"black"},attrs:{"color":"primary"}},[_vm._v("Nueva Vacante Profesionista")])],1)],1),_c('v-tooltip',{attrs:{"bottom":"","id":"pc"}},[_c('span',{staticStyle:{"text-align":"center"}},[_vm._v("Vacantes que solicitan: "),_c('br'),_vm._v(" -Preparación o experiencia profesional. "),_c('br'),_vm._v(" -Requieren alguna formación académica (Técnicos, licenciaturas, ingenierías, maestrías) ")])]),_c('br')],1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","outlined":"","hide-details":"","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.item,"items-per-page":5,"filterable":"","search":_vm.search,"dark":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.fechacreacion",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.moment(item.fechacreacion).format("LL")))])]}},{key:"item.fechatermino",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.moment(item.fechatermino).format("LL")))])]}},{key:"item.posicion",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.posicion.toUpperCase()))])]}},{key:"item.activa",fn:function(ref){
var item = ref.item;
return [(item.activa)?_c('v-icon',{staticClass:"mr-2",staticStyle:{"background-color":"#2afed6","border-radius":"25px","color":"black"}},[_vm._v(" mdi-check ")]):_c('v-icon',{staticClass:"mr-2",staticStyle:{"background-color":"red","border-radius":"25px","color":"black"}},[_vm._v(" mdi-close ")])]}},{key:"item.cancelada",fn:function(ref){
var item = ref.item;
return [(item.cancelada)?_c('v-icon',{staticClass:"mr-2",staticStyle:{"background-color":"#2afed6","border-radius":"25px","color":"black"}},[_vm._v(" mdi-check ")]):_c('v-icon',{staticClass:"mr-2",staticStyle:{"background-color":"red","border-radius":"25px","color":"black"}},[_vm._v(" mdi-window-close ")])]}},{key:"item.desactivada",fn:function(ref){
var item = ref.item;
return [(item.activa)?_c('v-button',{staticClass:"mr-2",staticStyle:{"padding":"10px","background-color":"red","border-radius":"25px","color":"black"}},[_vm._v("Desactivar "),_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-power ")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }