<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step @click="cambiar()" :complete="e1 > 1" step="1">
        Datos Personales
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step @click="cambiar2()" :complete="e1 > 2" step="2">
        Idiomas
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step @click="cambiar3()" step="3" :complete="e1 > 3">
        Formación Académica
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step @click="cambiar4()" step="4" :complete="e1 > 4">
        Experiencia Profesional
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step @click="cambiar5()" step="5" :complete="e1 > 5">
        Cursos
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step @click="cambiar6()" step="6" :complete="e1 > 6">
        Áreas
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step @click="cambiar7()" step="7" :complete="e1 > 7">
        Habilidades
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-form ref="form">
        <v-stepper-content step="1">
          <v-card class="mb-12 " color="#e0e0e0">
            <div style="padding:20px">
              <h1 class="text-center">Datos Personales</h1>

              <br />
              <v-alert v-if="errors.length">
                <b>Porfavor corrige los siguientes errores:</b>
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="nombre"
                    outlined
                    label="Nombre(s)"
                    required
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="apellido"
                    outlined
                    label="Apellidos"
                    required
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div>
                    <v-autocomplete
                      outlined
                      v-model="evento"
                      :click="setState(evento)"
                      label="Estado"
                      clearable
                      :items="estados"
                      item-text="estado"
                      item-value="value"
                      autocomplete="nope"
                    ></v-autocomplete>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div>
                    <v-autocomplete
                      outlined
                      v-model="cd"
                      label="Ciudades"
                      :click="setCiudad(cd)"
                      clearable
                      :items="ciudades"
                      item-text="municipio"
                      item-value="value"
                      autocomplete="nope"
                    ></v-autocomplete>
                  </div>
                </v-col>
                <v-flex md4 xs6 xm6>
                  <v-col>
                    <v-text-field
                      v-model="codigopostal"
                      outlined
                      label="C.P"
                      v-mask="maskara"
                      required
                    ></v-text-field>
                  </v-col>
                </v-flex>
                <v-flex md4 xs6 xm6>
                  <v-col>
                    <v-text-field
                      v-model="telefono"
                      v-mask="mask"
                      outlined
                      label="Teléfono"
                      required
                    ></v-text-field>
                  </v-col>
                </v-flex>
                <v-flex md4 xs6 xm6>
                  <v-col>
                    <v-select
                      :items="sexos"
                      v-model="sexo"
                      label="Sexo"
                      outlined
                    ></v-select>
                  </v-col>
                </v-flex>
                <v-flex md4 xs6 xm6>
                  <v-col>
                    <v-checkbox
                      v-model="reubicarme"
                      :label="`Estoy dispuesto a reubicarme`"
                    ></v-checkbox>
                    <br />
                  </v-col>
                </v-flex>

                <v-flex md4 xs6 xm6>
                  <v-col>
                    <vuetify-money
                      v-model="rangoinf"
                      label="Sueldo Mínimo Requerido (MXN)"
                      v-bind:options="options"
                      outlined
                      required
                    />
                  </v-col>
                </v-flex>
                <v-flex md4 xs6 xm6>
                  <v-col>
                    <vuetify-money
                      v-model="rangosup"
                      label="Sueldo Deseado (MXN)"
                      v-bind:options="options"
                      outlined
                      required
                    />
                  </v-col>
                </v-flex>

                <v-col cols="12">
                  <!-- <v-text-field
                  v-model="fecha"
                  outlined
                  label="Fecha"
                  required
                  hint="Año-Mes-Dia"
                ></v-text-field> -->
                  <v-row justify="center">
                    <div>
                      <div class="subheading" style="text-align:center">
                        <strong>Selecciona tu fecha de nacimiento</strong>
                      </div>

                      <v-date-picker v-model="fecha" disabled></v-date-picker>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <div class="text-center">
            <v-btn color="primary" @click="datosGenerales()">
              Continuar
            </v-btn>
            <router-link :to="{ name: 'perfil' }">
              <v-btn text>
                Regresar
              </v-btn>
            </router-link>
          </div>
        </v-stepper-content>
      </v-form>

      <v-stepper-content step="2">
        <v-card class="mb-12" color="#e0e0e0">
          <div class="" style="padding:20px;">
            <h1 class="text-center">Idiomas</h1>
            <br />
            <v-row style="margin-bottom:18px;">
              <v-flex md5 xs6>
                <v-col>
                  <Idiomas></Idiomas>
                </v-col>
              </v-flex>

              <v-flex md5 xs6>
                <v-col>
                  <v-select
                    v-model="select"
                    :items="items"
                    outlined
                    label="Select"
                    item-text="nivel"
                    item-value="nivel"
                    persistent-hint
                    single-line
                    return-object
                  ></v-select>
                </v-col>
              </v-flex>
              <v-col cols="2">
                <div class="text-center">
                  <v-btn
                    v-on:click="agregarIdioma()"
                    color="primary"
                    style="color:black;"
                  >
                    Agregar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row style="margin-top:18px;">
              <v-col cols="12">
                <p style="text-align:center;">
                  <strong>Idiomas Agregados</strong>
                </p>
              </v-col>

              <div
                v-for="(idioma, index) of idiomas"
                :key="idioma.idioma"
              
              >
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      v-model="idioma.idioma"
                      outlined
                      disabled
                      label="Nivel "
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="5">
                    <v-select
                    
                      style="margin-left:-20px;"
                      v-model="idioma.nivel"
                      :items="items"
                      outlined
                      label="Selecciona tu nivel"
                      item-text="nivel"
                      item-value="nivel"
                      persistent-hint
                      single-line
                      disabled
                    ></v-select>
                  </v-col>
                  <v-col cols="1" id="cel">
                    <v-btn
                      color="primary"
                      v-on:click="deleteEvent(index)"
                      style="margin-left:-30px;margin-top:5px;color:black;"
                    >
                      X</v-btn
                    >
                  </v-col>
                  <v-col cols="2" id="pc">
                    <v-btn
                      color="primary"
                      v-on:click="deleteEvent(index)"
                      style="margin-top:5px;color:black;"
                    >
                      Eliminar</v-btn
                    >
                  </v-col>
                </v-row>

                <v-divider> </v-divider>
                <br />
              </div>
            </v-row>
          </div>
        </v-card>
        <div class="text-center">
          <v-btn color="primary" @click="prueba()">
            Continuar
          </v-btn>

          <v-btn @click="e1 = 1" text>
            Regresar
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="mb-12" color="#e0e0e0">
          <v-form
            @submit="agregarEducacion()"
            action="https://vuejs.org/"
            method="post"
            v-model="valid"
            lazy-validation
          >
            <div class="" style="padding:20px;">
              <h1 class="text-center"><strong>Formación Académica</strong></h1>
              <br />
              <v-alert v-if="errores.length">
                <b>Porfavor corrige los siguientes errores:</b>
                <ul>
                  <li v-for="error in errores" :key="error">{{ error }}</li>
                </ul>
              </v-alert>

              <v-row>
                <v-flex xs6 md4>
                  <v-col>
                    <div>
                      <v-autocomplete
                        outlined
                        v-model="mievento"
                        :click="setNivelEducativo(mievento)"
                        label="Nivel Educativo"
                        clearable
                        :items="niveles"
                        item-text="nivel"
                        item-value="nivel"
                        autocomplete="nope"
                      ></v-autocomplete>
                    </div>
                  </v-col>
                </v-flex>
                <v-flex xs6 md4>
                  <v-col>
                    <v-text-field
                      v-model="mititulo"
                      outlined
                      label="Titulo"
                      required
                    ></v-text-field>
                  </v-col>
                </v-flex>
                <v-flex xs6 md4>
                  <v-col>
                    <v-text-field
                      v-model="nuevaInstitucion"
                      outlined
                      label="Institución "
                    ></v-text-field>
                  </v-col>
                </v-flex>
                <v-flex xs6 md3>
                  <v-col>
                    <v-text-field
                      v-model="año_inicio"
                      outlined
                      label="Año de Inicio "
                     

                      :min="1935"
                      :max="2021"
                      
                    ></v-text-field>
                  </v-col>
                </v-flex>
                <v-flex xs6 md3>
                  <v-col>
                    <v-text-field
                      v-model="año_final"
                      outlined
                      label="Año de Fin "

                    
                    ></v-text-field>
                  </v-col>
                </v-flex>
                <v-flex xs6 md3>
                  <v-col>
                    <v-checkbox
                      v-model="estudioactual"
                      :label="'Estudio Actual'"
                    ></v-checkbox>
                  </v-col>
                </v-flex>
                <v-col cols="2" id="pc">
                  <div>
                    <v-btn
                     
                      @click="agregarEducacion2()"
                      color="primary"
                      style="color:black;"
                      >Agregar</v-btn
                    >
                  </div>
                </v-col>
                <v-col cols="12" id="cel">
                  <div>
                    <v-btn
                      @click="agregarEducacion2()"
                      color="primary"
                      style="color:black;"
                      >Agregar</v-btn
                    >
                  </div>
                </v-col>

                <br />
              </v-row>
            </div>
          </v-form>
          <v-divider></v-divider>
          <v-row>
            <br />
            <v-col cols="12" style="margin-top:20px;">
              <p style="text-align:center;">
                <strong>Estudios Agregados</strong>
              </p>
            </v-col>
          </v-row>
          <div
            v-for="(escuela, index) of escuelas"
            :key="escuela.f_institucion"
            class="text-center"
          >
            <v-row>
              <v-flex style="margin-left:10px" md2 xs6>
                <v-col>
                  <v-text-field
                    v-model="escuela.titulo"
                    outlined
                    disabled
                    label="Titulo Recibido"
                    required
                  ></v-text-field>
                </v-col>
              </v-flex>
              
              <v-flex style="margin-left:7px" md2 xs4>
                <v-col>
                  <v-text-field
                    v-model="escuela.institucion"
                    outlined
                    disabled
                    label="Institucion"
                    required
                  ></v-text-field>
                </v-col>
              </v-flex>
              <v-flex style="margin-left:7px" md2 xs4>
                <v-col>
                  <v-text-field
                    v-model="escuela.ano_inicio"
                    outlined
                    disabled
                    label="Año de Inicio"
                    
                    required
                  ></v-text-field>
                </v-col>
              </v-flex>
              <v-flex style="margin-left:7px" md2 xs4>
                <v-col>
                  <v-text-field
                    v-model="escuela.ano_final"
                    outlined
                    disabled
                    label="Año de Fin"
                    
                    required
                  ></v-text-field>
                </v-col>
              </v-flex>
              <v-flex md2 xs3>
                <v-col>
                  <v-checkbox
                    v-model="escuela.actual"
                    :label="'Estudio Actual'"
                    
                  ></v-checkbox>
                </v-col>
              </v-flex>
              <v-col cols="1" id="cel">
                <v-btn
                  color="primary"
                  v-on:click="borrarEscuela(index)"
                  style="margin-top:13px;color:black;margin-left:25px;"
                >
                  X</v-btn
                >
              </v-col>

              <v-col cols="1" id="pc">
                <v-btn
                  color="primary"
                  v-on:click="borrarEscuela(index)"
                  style="margin-top:13px;color:black;"
                >
                  Borrar</v-btn
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <br />
          </div>
        </v-card>
        <div class="text-center">
          <v-btn color="primary" @click="agregarmieducacion()">
            Continuar
          </v-btn>

          <v-btn @click="e1 = 2" text>
            Regresar
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="4">
        <v-card class="mb-12" color="#e0e0e0">
            <v-form
            method="post"
            v-model="valida"
            lazy-validation
          >
          <div class="" style="padding:20px;">
            <h1 class="text-center">
              <strong>Experiencia Profesional</strong>
            </h1>
            <br />
            <v-alert v-if="erroros.length">
                <b>Porfavor corrige los siguientes errores:</b>
                <ul>
                  <li v-for="error in erroros" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
            <v-row style="margin-bottom:18px;">
              <v-col cols="6">
                <v-text-field
                  v-model="nuevoPuesto"
                  outlined
                  label="Puesto"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="nuevaEmpresa"
                  outlined
                  label="Empresa "
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-textarea
                      v-bind="attrs"
                      v-on="on"
                      v-model="descripcionPuesto"
                      outlined
                      label="Actividades Realizadas "
                    ></v-textarea>
                  </template>
                  <span
                    >Describa brevemente las actividades realizadas en ese
                    puesto</span
                  >
                </v-tooltip>
              </v-col>

              <v-flex md4 xs6>
                <v-col>
                  <v-text-field
                    v-model="añoEntrada"
                    outlined
                    label="Año de Inicio "
                    required
                  

                  ></v-text-field>
                </v-col>
              </v-flex>
              <v-flex md4 xs6>
                <v-col>
                  <v-text-field
                    v-model="añoSalida"
                    outlined
                    label="Año de Fin "
                    
                    
                  ></v-text-field>
                </v-col>
              </v-flex>
              <v-flex md2 xs6>
                <v-col>
                  <v-checkbox
                    v-model="empleoAct"
                    :label="`Empleo Actual`"
                  ></v-checkbox>
                </v-col>
              </v-flex>
              <v-flex md2 xs6>
                <v-col>
                  <v-btn
                 
                    v-on:click="agregarTrabajo()"
                    color="primary"
                    style="color:black;"
                  >
                    Agregar
                  </v-btn>
                </v-col>
              </v-flex>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" style="margin-top:20px;">
                <p style="text-align:center;">
                  <strong>Trabajos Agregados</strong>
                </p>
                <div
                  v-for="(trabajo, index) of trabajos"
                  :key="trabajo.puesto"
                  class="text-center"
                >
                  <v-row>
                    <v-flex style="margin-left:10px" md2 xs6>
                      <v-col>
                        <v-text-field
                          v-model="trabajo.puesto"
                          outlined
                          disabled
                          label="Puesto"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-flex>
                    <v-flex md2 xs5>
                      <v-col>
                        <v-text-field
                          v-model="trabajo.empresa"
                          outlined
                          disabled
                          label="Empresa "
                          required
                        ></v-text-field>
                      </v-col>
                    </v-flex>
                    <v-flex style="margin-left:7px" md2 xs4>
                      <v-col>
                        <v-text-field
                          v-model="trabajo.inicio"
                          outlined
                          disabled
                          label="Año Ingreso"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-flex>
                    <v-flex md2 xs4>
                      <v-col>
                        <v-text-field
                          v-model="trabajo.final"
                          outlined
                          label="Año Termino "
                          required
                        ></v-text-field>
                      </v-col>
                    </v-flex>
                    <v-flex md2 xs3>
                      <v-col>
                        <v-checkbox
                          v-model="trabajo.actual"
                          :label="`Empleo Actual`"
                        ></v-checkbox>
                      </v-col>
                    </v-flex>

                    <v-col cols="12" id="cel">
                      <v-btn
                        color="primary"
                        v-on:click="borrarTrabajo(index)"
                        style="color:black;margin-bottom:25px;margin-top:-15px"
                      >
                        Borrar</v-btn
                      >
                    </v-col>

                    <v-col cols="1" id="pc">
                      <v-btn
                        color="primary"
                        v-on:click="borrarTrabajo(index)"
                        style="margin-top:13px;color:black;"
                      >
                        Borrar</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <br />
                </div>
              </v-col>
            </v-row>
          </div>
            </v-form>
        </v-card>
        <div class="text-center">
          <v-btn color="primary" @click="agregarmitrabajo()">
            Continuar
          </v-btn>

          <v-btn text @click="e1 = 3">
            Regresar
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="5">
        <v-card class="mb-12" color="#e0e0e0">
          <div class="" style="padding:20px;">
            <h1 class="text-center">Cursos</h1>
            <br />
            <v-row style="margin-bottom:18px;">
              <v-col cols="6">
                <v-text-field
                  v-model="nuevoCurso"
                  outlined
                  label="Curso"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="nuevaDescripcion"
                  outlined
                  label="Descripción Breve"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="text-center">
                  <v-btn
                    style="color:black"
                    v-on:click="agregarCurso()"
                    color="primary"
                  >
                    Agregar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" style="margin-top:20px;">
                <p style="text-align:center;">
                  <strong>Cursos Agregados</strong>
                </p>
                <div class="text-center">
                  <table class="table text-center">
                    <tbody>
                      <tr
                        v-for="(curso, index) of cursos"
                        :key="curso.descripcion"
                        style="padding:5px;"
                      >
                        <td style="padding:5px;">
                          <v-text-field
                            v-model="curso.curso"
                            outlined
                            disabled
                            label="Curso"
                            required
                          ></v-text-field>
                        </td>
                        <td style="padding:5px;">
                          <v-text-field
                            v-model="curso.descripcion"
                            outlined
                            disabled
                            label="Descripción Breve "
                            required
                          ></v-text-field>
                        </td>

                        <v-btn
                          color="primary"
                          v-on:click="borrarCurso(index)"
                          style="margin-top:13px;color:black;"
                        >
                          Borrar</v-btn
                        >
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <div class="text-center">
          <v-btn color="primary" @click="agregarmiscursos()">
            Continuar
          </v-btn>

          <v-btn text @click="e1 = 4">
            Regresar
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="6">
        <v-card class="mb-12" color="#e0e0e0">
          <div class="" style="padding:20px;">
            <h1 class="text-center">Áreas</h1>
            <br />
            <h4 class="text-center">
              Agrega el área y la subárea en donde mas tengas experiencia.<br />
              Puedes agregar como máximo 5
            </h4>
            <br />
            <v-row style="margin-bottom:18px;">
              <v-col cols="6">
                <v-autocomplete
                  outlined
                  return-object
                  v-model="event"
                  :click="setEspecialidad(event)"
                  clearable
                  :items="misespecialidades"
                  label="Área"
                  color="primary"
                  item-text="especialidad"
                  item-value="value"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                  outlined
                  return-object
                  v-model="miivent"
                  :click="setsubEspecialidad(miivent)"
                  label="Subárea"
                  clearable
                  :items="subespecialidades"
                  item-text="sub"
                  item-value="value"
                  name="nombre"
                  id="nombre"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <div class="text-center">
                  <v-btn
                    v-on:click="agregarEspecialidad()"
                    color="primary"
                    style="color:black;"
                  >
                    Agregar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row style="margin-top:18px">
              <v-col cols="12" style="margin-top:20px;">
                <p style="text-align:center;">
                  <strong>Áreas y Subáreas Agregadas</strong>
                </p>
                <div class="text-center">
                  <table class="text-center">
                    <tbody>
                      <tr
                        v-for="(especialidad, index) of especialidades"
                        :key="especialidad.folio"
                        style="padding:5px;"
                      >
                        <v-col cols="6"> </v-col>
                        <td style="padding:5px;">
                          <v-text-field
                            v-model="especialidad.especialidad"
                            outlined
                            disabled
                            label="Área"
                            required
                          ></v-text-field>
                        </td>
                        <td style="padding:5px;">
                          <v-text-field
                            v-model="especialidad.sub"
                            outlined
                            label="Subárea "
                            required
                            disabled
                          ></v-text-field>
                        </td>

                        <v-btn
                          color="primary"
                          v-on:click="borrarEspecialidad(index)"
                          style="margin-top:13px;color:black;"
                        >
                          Borrar</v-btn
                        >
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <div class="text-center" v-if="this.especialidades.length < 6">
          <v-btn color="primary" @click="agregarmisespecialidades()">
            Continuar
          </v-btn>

          <v-btn @click="e1 = 5" text>
            Regresar
          </v-btn>
        </div>
        <div class="text-center" v-else>
          <v-btn color="primary" style="color:black;">
            Has seleccionado mas de 5 especialidades
          </v-btn>
          <v-btn @click="e1 = 5" text>
            Regresar
          </v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-content step="7">
        <v-card class="mb-12" color="#e0e0e0">
          <div class="" style="padding:20px;">
            <h1 class="text-center">Habilidades Interpersonales</h1>
            <br />
            <v-row style="margin-bottom:18px">
              <v-col cols="6">
                <Habilidad></Habilidad>
              </v-col>

              <v-col cols="6">
                <v-btn
                  @click="agregarHabilidad()"
                  color="primary"
                  style="color:black;"
                >
                  Agregar
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row style="margin-top:18px">
              <v-col cols="12" style="margin-top:20px;">
                <p style="text-align:center;">
                  <strong
                    >Agrega entre 1 y 3 habilidades que te caracterizan
                    mejor</strong
                  >
                </p>
                <div class="text-center">
                  <table class="text-center">
                    <tbody>
                      <tr
                        v-for="(habilidad, index) of habilidades"
                        :key="habilidad.folio"
                        style="padding:5px;"
                      >
                        <v-col cols="6"> </v-col>
                        <td style="padding:5px;">
                          <v-text-field
                            v-model="habilidad.habilidad"
                            outlined
                            disabled
                            label="Habilidad"
                            required
                          ></v-text-field>
                        </td>

                        <v-btn
                          color="primary"
                          v-on:click="borrarHabilidad(index)"
                          style="margin-top:13px;color:black;"
                        >
                          Borrar</v-btn
                        >
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <div class="text-center">
          <v-btn
            v-if="habilidades.length >= 1 && habilidades.length < 4"
            color="primary"
            @click="agregarmishabilidades()"
          >
            Continuar
          </v-btn>
          <v-btn v-else style="color:black;" color="primary">
            Es necesario que agregues entre 1 y 3 habilidades
          </v-btn>

          <v-btn @click="e1 = 6" text>
            Regresar
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
var moment = require("moment");
import Habilidad from "./Buscador/Habilidad.vue";
import Niveles from "./Buscador/Niveles.vue";
import Puesto from "./Buscador/Puesto.vue";
import rangosalarial from "./Buscador/rangoSalarial.vue";
import Idiomas from "./Buscador/Idiomas.vue";
import Institucion from "./Buscador/Institucion.vue";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      valid: false,
      valida: false,
      errors: [],
      errores: [],
      erroros: [],
      pruebacd: null,
      phone: null,
      mask: "(###) ###-##-##",
      maskara: "#####",
      descripcionPuesto: null,
      miivent: null,
      nuevaInstitucion: null,
      options: {
        locale: "ja-JP",
        prefix: "$",
        suffix: "",
        length: 10,
        precision: 2,
      },
      select: { nivel: "Básico" },
      items: [
        { nivel: " Básico " },
        { nivel: "Intermedio" },
        { nivel: "Avanzado" },
      ],
      searchResult: null,
      searchString: "",
      selected: "",
      menu: false,
      modal: false,
      menu2: false,
      moment: moment,
      e1: 1,
      rules: [
      
        (v) => v >= 1950 || "El año mínimo no puede ser menor de: 1950",
        (v) => v <= 2022 || "El año máximo no puede ser mayor de: 2022",
      ],
      rules2: [
        (v) => v >= 1950 || "El año mínimo no puede ser menor de: 1950",
        (v) => v <= 2022 || "El año máximo no puede ser mayor de: 2022",
      ],
      nombre: "",
      codigopostal: "",
      telefono: "",
      correo: "",
      nivel: "",
      sexo: "",
      IdiomasUnicos: [],
      min: 0,
      max: 100000,
      range: [10000, 30000],
      apellido: "",
      reubicarme: false,
      picker: null,
      idiomas: [],
      escuelas: [],
      minuevaespecialidad: "",
      trabajos: [],
      cursos: [],
      especialidades: [],
      misespecialidades: [],
      missubespecialidades: [],
      habilidades: [],
      sexos: ["H", "M"],
      usuario: null,
      fecha: "",
      rangoinf: null,
      rangosup: null,
      minivel: null,
      mititulo: null,
      año_inicio: null,
      año_final: null,
      event: "",
      subespecialidades: [],
      nuevoPuesto: null,
      nuevaEmpresa: null,
      añoEntrada: null,
      añoSalida: null,
      nuevoCurso: null,
      nuevaDescripcion: null,
      estudioactual: false,
      empleoAct: null,
      nuevoNivel: null,
      edo: null,
      cd: null,
      pais: null,
      estados: [],
      evento: null,
      ciudades: [],
      mievent: null,
      mievento: null,
      niveles: [],
    token: "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c" 

    };
  },
  watch: {
    nuevaespecialidad: function(value) {
      axios
        .get("/catalogos/subespe/" + this.nuevaespecialidad.folio, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
        .then((response) => {
          this.subespecialidades = response.data;
          console.log("Prueba:" ,response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    elestado: function(value) {
      axios
        .get("/catalogos/ciudades/" + this.elestado, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
        .then((response) => {
          this.ciudades = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    axios
      .get("/catalogos/niveleducativo", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.niveles = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/catalogos/estados", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.estados = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/catalogos/especialidades", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.misespecialidades = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  beforeCreate() {
    this.$store.dispatch("autoLogin");
    axios
      .get("/candidatos/" + this.$store.state.id.id, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
      .then((response) => {
        this.usuario = response.data;
        this.escuelas = response.data.educacion;
        this.idiomas = response.data.idiomas;
        this.trabajos = response.data.exp_profesional;
        this.cursos = response.data.cursos;
        this.especialidades = response.data.especialidades;
        this.habilidades = response.data.habilidades;
        this.fecha = response.data.nac2;
        this.nombre = response.data.nombre;
        this.apellido = response.data.apellido;
        this.rangoinf = response.data.OP_salario;
        this.rangosup = response.data.op_salario_hi;
        this.telefono = response.data.celular;
        this.sexo = response.data.sexo;
        this.reubicarme = response.data.reubicarme;
        this.codigopostal = response.data.cp;
        this.correo = response.data.correo;
        this.edo = response.data.estado;
        this.cd = response.data.ciudad;
        this.pais = response.data.pais;
        this.fecha = moment(this.fecha).format("YYYY-MM-DD");

      })
      .catch((error) => {
        console.log(error);
      });
  },
  beforeUpdate() {
    if (this.edo && this.cd && this.fecha) {
      this.evento = this.edo;
      this.picker = this.fecha;
    }
  },

  computed: {
    ...mapState([
      "elestado",
      "laciudad",
      "elPais",
      "id",
      "elidioma",
      "lainstitucion",
      "folioinstitucion",
      "nuevaespecialidad",
      "nuevasubespecialidad",
      "nuevahabilidad",
      "nivelPrueba",
      "nivelEducativo",
    ]),
  },
  components: {
    rangosalarial,
    Puesto,
    Niveles,
    Habilidad,
    Idiomas,
    Institucion,
  },
  methods: {
    ...mapMutations([
      "setEspecialidad",
      "setsubEspecialidad",
      "setState",
      "setCiudad",
      "setNivelEducativo",
    ]),

    deleteEvent: function(index) {
      this.idiomas.splice(index, 1);
    },
    borrarEscuela: function(index) {
      this.escuelas.splice(index, 1);
    },
    borrarTrabajo: function(index) {
      this.trabajos.splice(index, 1);
    },
    borrarCurso: function(index) {
      this.cursos.splice(index, 1);
    },
    borrarEspecialidad: function(index) {
      this.especialidades.splice(index, 1);
    },
    borrarHabilidad: function(index) {
      this.habilidades.splice(index, 1);
    },
    logueado() {
      return this.$store.state.token;
    },
    cambiar() {
      this.e1 = 1;
    },
    cambiar2() {
      this.e1 = 2;
    },

    cambiar3() {
      this.e1 = 3;
    },
    cambiar4() {
      this.e1 = 4;
    },
    cambiar5() {
      this.e1 = 5;
    },
    cambiar6() {
      this.e1 = 6;
    },
    cambiar7() {
      this.e1 = 7;
    },

    prueba2() {
      for (var escuela in this.escuelas) {
        console.log(
          "Escuela: ",
          this.escuelas[escuela].institucion,
          "Nivel : ",
          this.escuelas[escuela].nivel,
          "Año de Ingreso: ",
          this.escuelas[escuela].ano_inicio,
          this.escuelas[escuela].ano_final
        );
      }
    },

    datosGenerales() {
      this.errors = [];
      if (this.rangosup < this.rangoinf)
        this.errors.push("El sueldo minimo es mayor al inferior");

      if (
        this.rangosup > this.rangoinf &&
        this.nombre &&
        this.apellido &&
        this.sexo &&
        this.rangoinf &&
        this.rangosup &&
        this.fecha &&
        this.evento &&
        this.cd &&
        this.codigopostal &&
        this.telefono
      ) {
        axios
          .put("/candidatos/actualizaGenerales/", {
            folio: this.$store.state.id.id,
            nombre: this.nombre,
            apellido: this.apellido,
            sexo: this.sexo,
            nacimiento: this.fecha,
            pais: "Mexico",
            estado: this.evento,
            ciudad: this.cd,
            cp: this.codigopostal,
            reubicarme: this.reubicarme,
            celular: this.telefono,
            correo: null,
            salario_min: this.rangoinf,
            salario_max: this.rangosup,
          }, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
          .then((response) => {
            this.e1 = 2;
            console.log(response.data)
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (!this.nombre) this.errors.push("Nombre es requerido.");
        if (!this.apellido) this.errors.push("Área es requerida.");
        if (!this.sexo) this.errors.push("Sexo es requerido.");
        if (!this.rangoinf) this.errors.push("Sueldo mínimo es requerido.");
        if (!this.rangosup) this.errors.push("Sueldo aspirado es requerido.");
        if (!this.fecha) this.errors.push("Fecha es requerido.");
        if (!this.evento) this.errors.push("Estado es requerido.");
        if (!this.cd) this.errors.push("Ciudad es requerido.");
        if (!this.codigopostal) this.errors.push("Codigo Postal es requerido.");
        if (!this.telefono) this.errors.push("Teléfono es requerido.");
      }
    },

    agregarmiIdioma() {
      for (var idioma in this.idiomas) {
        axios
          .put("/candidatos/actualizaIdiomas/", {
            folio: this.$store.state.id.id,
            correo:null,
            idiomas: [
              {
                idioma: this.idiomas[idioma].idioma,
                nivel: this.idiomas[idioma].nivel,
              },
            ],
          }, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
          .then((response) => {
            console.log(response.data);
            this.e1 = 3;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    prueba() {
      let idioma = this.idiomas;
      console.log(idioma);
      axios
        .put("/candidatos/actualizaIdiomas/", {
          folio: this.$store.state.id.id,
          correo:null,
          idiomas: idioma,
        }, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
        .then((response) => {
          console.log(response.data);
          this.e1 = 3;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    agregarmieducacion() {
      let escuela = this.escuelas;
      console.log(escuela);
      axios
        .put("/candidatos/actualizaEducacion/", {
          folio: this.$store.state.id.id,
          correo:null,
          educacion: escuela,
        }, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
        .then((response) => {
          console.log(response.data);
          this.e1 = 4;
        })
        .catch((error) => {
          console.log(error);
          this.e1 = 4;
        });
    },
    agregarmitrabajo() {
      
      let trabajo = this.trabajos;
      console.log(trabajo);
      axios
        .put("/candidatos/actualizaExpPro", {
          folio: this.$store.state.id.id,
           correo:null,
          exp_profesional: trabajo,
        }, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
        .then((response) => {
          console.log(response.data);
          this.e1 = 5;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    agregarmiscursos() {
      let curso = this.cursos;
      console.log(curso);
      axios
        .put("/candidatos/actualizaCursos", {
          folio: this.$store.state.id.id,
           correo:null,
          cursos: curso,
        }, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
        .then((response) => {
          console.log(response.data);
          this.e1 = 6;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    agregarmishabilidades() {
      let habilidad = this.habilidades;
      console.log(habilidad);
      axios
        .put("/candidatos/actualizaHabilidades", {
          folio: this.$store.state.id.id,
           correo:null,
          habilidades: habilidad,
        }, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
        .then((response) => {
          console.log(response.data);
          this.$router.push({ name: "perfil" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    agregarmisespecialidades() {
      let especialidad = this.especialidades;
      console.log(especialidad);
      axios
        .put("/candidatos/actualizaEspecialidades", {
          folio: this.$store.state.id.id,
           correo:null,
          especialidades: especialidad,
        }, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
        .then((response) => {
          console.log(response.data);
          this.e1 = 7;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    agregarIdioma() {
      if (this.elidioma) {
        this.idiomas.push({
          idioma: this.elidioma,
          nivel: this.select.nivel,
        });
        this.select = "";
        this.idiomas = [
          ...this.idiomas
            .reduce((map, obj) => map.set(obj.idioma, obj), new Map())
            .values(),
        ];
      }
    },
    agregarEducacion2() {
      this.errores = [];
       if(this.año_final==null){
          this.año_final=new Date().getFullYear();
        }
      if (
        this.año_inicio &&
        this.año_final &&
        this.mievento &&
        this.mititulo &&
        this.nuevaInstitucion &&
        this.año_inicio &&
        this.año_final
      ) {
        this.escuelas.push({
          candidato: this.$store.state.id.id,
          nivel: this.mievento,
          titulo: this.mititulo,
          institucion: this.nuevaInstitucion,
          ano_inicio: this.año_inicio,
          ano_final: this.año_final,
          actual: this.estudioactual,
        });
        console.log(this.escuelas);
        this.mievento = "";
        this.mititulo = "";
        this.nuevaInstitucion = "";
        this.año_inicio = "";
        this.año_final = "";
        this.valid = true;
      } else {
        if (!this.mievento) this.errores.push("Nivel Educativo es requerido.");
        if (!this.mititulo) this.errores.push("Titulo es requerido.");
        if (!this.nuevaInstitucion)
          this.errores.push("Institución es requerido.");
        if (!this.año_inicio) this.errores.push("Año de Inicio es requerido.");
      }
    },

    agregarTrabajo() {
      this.erroros = [];
      if(this.añoSalida==null){
          this.añoSalida=new Date().getFullYear();
        }
        
       if(this.empleoAct==null){
          this.empleoAct=false;
        }
      if(this.nuevaEmpresa && this.nuevoPuesto && this.añoEntrada && this.añoSalida){
         this.trabajos.push({
         
        candidato: this.$store.state.id.id,
        empresa: this.nuevaEmpresa,
        puesto: this.nuevoPuesto,
        inicio: this.añoEntrada,
        final: this.añoSalida,
        actual: this.empleoAct,
        descripcion: this.descripcionPuesto,
      });
      this.nuevaEmpresa = "";
      this.nuevoPuesto = "";
      this.añoEntrada = "";
      this.añoSalida = "";
      this.empleoAct = "";
      this.descripcionPuesto = "";

      } else {
              if (!this.nuevaEmpresa) this.erroros.push("Empresa es requerida.");
        if (!this.nuevoPuesto) this.erroros.push("Puesto es requerido.");
        if (!this.añoEntrada)
          this.erroros.push("Año de entrada es requerido.");

      }
     
    },
    agregarCurso() {
      this.cursos.push({
        candidato: this.$store.state.id.id,
        curso: this.nuevoCurso,
        descripcion: this.nuevaDescripcion,
      });
      this.nuevoCurso = "";
      this.nuevaDescripcion = "";
    },
    agregarEspecialidad() {
      this.especialidades.push({
        f_especialidad: this.event.folio,
        f_sub: this.miivent.folio,
        especialidad: this.event.especialidad,
        sub: this.miivent.sub,
      });
      this.event = "";
      this.miivent = "";
      console.log(this.especialidades);
    },
    agregarHabilidad() {
      console.log(this.habilidades);
      console.log(this.nuevahabilidad);
      this.habilidades.push({
        foliohab: this.nuevahabilidad.folio,
        habilidad: this.nuevahabilidad.habilidad,
      });
      this.habilidades = [
        ...this.habilidades
          .reduce((map, obj) => map.set(obj.habilidad, obj), new Map())
          .values(),
      ];
    },
  },
};
</script>

<style>
@media only screen and (min-width: 868px) {
  .mitarjeta {
    margin-top: -75px;
    height: 160px;
  }
  .micontenedor {
    margin-top: 210px;
  }
  #cel {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .micontenedor {
    margin-top: 260px;
  }
  .mitarjeta {
    margin-top: -65px;
    height: 220px;
  }
  #pc {
    display: none;
  }
}
@media (min-width: 700px) and (orientation: landscape) {
}
@media (min-width: 700px) and (orientation: portrait) {
  #cel {
    display: none;
  }
}
</style>
