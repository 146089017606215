<template>
  <v-container fluid>
    <v-row>
     
      <v-col cols="12" sm="12" md="9" style="margin-top:-18px;">
        <v-card color="grey lighten-4" flat dark tile>
          <v-toolbar flat style="border-radius:20px;">
            <router-link
              style="text-decoration:none"
              :to="{ name: 'Profesionistas' }"
            >
              <v-btn text large href="" style="color:white;padding:6px;"
                ><v-icon >mdi-arrow-left-circle</v-icon>  Regresar</v-btn
              >
            </router-link>

            <v-spacer></v-spacer>
<!--             
            <v-btn
            id="pc"
            text
            target="_blank"
              style="text-decoration:none"
              :href="
                '//www.facebook.com/sharer.php?u=' +
                  'https://tisdc.net' +
                  this.$route.path
              "
              class="btn"
              ><v-icon style="color:#0572e6;">mdi-facebook</v-icon></v-btn
            > -->

            <v-btn text large v-if="webShareApiSupported" @click="shareViaWebShare()"
              ><v-icon>mdi-share-variant</v-icon> Compartir</v-btn
            >
          </v-toolbar>
        </v-card>
        <v-card
          class="tarjeta"
          v-for="vacante in vacantes"
          :key="vacante.folio"
          style="padding:0px !important; border-radius:20px; margin-top:8px;"
        >

          <div v-if="vacante.tipo_publicacion==1">
            <v-container fluid class="headerblanco" >
            <v-row>
              <!--cel -->

              <v-col cols="5" class="cel" v-if="vacante.anonimo == false">
                <div v-if="vacante.logo" class="imagen">
                  <v-img
                    class="miimagen img-fluid"
                    :src="vacante.logo"
                    alt=""
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="12" class="cel" v-if="vacante.anonimo == true">
                <div class="text-center">
                  <p
                    class="vacantePosicion"
                    style="color:black; text-align:center; "
                  >
                    {{ vacante.posicion }}
                  </p>
                  <span
                    v-if="vacante.anonimo == true"
                    style="color:#FFC000;font-size:14px;text-align:center;"
                  >
                    <strong>Empresa Lider </strong></span
                  >
                </div>
              </v-col>
              <v-col cols="7" id="cel">
                <div v-if="vacante.anonimo == false" class="text-center">
                  <p
                    class="vacantePosicion"
                    style="color:black; text-align:center; "
                  >
                    {{ vacante.posicion }}
                  </p>

                  <p
                    v-if="vacante.anonimo == false"
                    style="color: black;
text-align: center;
"
                  >
                    {{ vacante.empresa }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" id="cel" style="margin-top:-20px;">
                <div v-if="vacante.sueldo_raninf > 0">
                  <p
                    class="vacanteRango"
                    style="background-color:white; padding:10px; border-radius:12px; text-align:center;"
                  >
                    <strong>
                      Rango de Sueldo
                      <span v-if="vacante.base_sueldo == 1">Semanal</span>
                      <span v-else-if="vacante.base_sueldo == 0"> Mensual</span
                      ><br />
                      {{
                        vacante.sueldo_raninf.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}

                      -
                      {{
                        vacante.sueldo_ransup.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </strong>
                  </p>
                </div>

               
              </v-col>
              <v-col cols="12" class="cel" style="margin-top:-20px;">
                <div class="text-center">
                  <p class="text-center" style="color:black;font-size:18px;">
                    <strong> Da click para contactarte</strong>
                  </p>
                  <div v-if="vacante.telefono">
                    <v-btn
                      @click="
                        estadisticaCel(vacante.folio_emp, vacante.folio)
                      "
                      color="#A8B8CD"
                      style="background-color:#A8B8CD;color:white;font-size:14px;margin-top:-20px;border-radius:17px;"
                      target="_blank"
                      v-if="vacante.telefono"
                      :href="'tel:' + vacante.telefono"
                      ><v-icon style="padding:12px;margin-left:-7px;">
                        mdi-phone
                      </v-icon>
                      Llamar
                    </v-btn>
                  </div>

                  <div v-if="vacante.whatsapp">
                    <v-btn
                      color="#8CDB6B"
                      @click="estadisticaW(vacante.folio_emp, vacante.folio)"
                      v-if="vacante.whatsapp"
                      style="color:white;font-size:14px;border-radius:17px;"
                      target="_blank"
                      :href="
                        'https://api.whatsapp.com/send/?phone=52' +
                          vacante.whatsapp +
                          '&text=¡Hola!%20vi%20la%20vacante%20' +
                          vacante.posicion +
                          '%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20más%20información'
                      "
                    >
                      <v-icon style="padding:12px;margin-left:-7px;">
                        mdi-whatsapp
                      </v-icon>
                      Enviar WhatsApp
                    </v-btn>
                  </div>

                  <div>
                    <v-btn
                      @click="
                        estadisticaCorreo(vacante.folio_emp, vacante.folio)
                      "
                      v-if="vacante.correo"
                      style="color:white;font-size:14px; margin-top:4px; border-radius:17px;"
                      target="_blank"
                      :href="
                        'mailto:' +
                          vacante.correo +
                          '?Subject=CV%20enviado%20desde%20VACANTA' +
                          '&body=Escribe%20aqui%20el%20nombre%20del%20puesto%20para%20el%20que%20estas%20enviando%20tu%20Cv'
                      "
                      color="#EFCC63"
                    >
                      <v-icon style="padding:12px;margin-left:-7px;">
                        mdi-email
                      </v-icon>
                      Enviar CV
                    </v-btn>
                  </div>
                  <br />

                  
                </div>
              </v-col>

              <!-- PC -->

              <v-col cols="2" class="pc" v-if="vacante.anonimo == false">
                <div v-if="vacante.logo" class="imagen">
                  <v-img
                    class="miimagen img-fluid"
                    :src="vacante.logo"
                    alt=""
                  ></v-img>
                </div>
              </v-col>

              <v-col cols="6" class="text-center pc" v-if="vacante.anonimo">
                <p
                  class="vacantePosicion"
                  style="color:black; text-align:center; "
                >
                  {{ vacante.posicion }}
                </p>

                <h3 style="color:black;" class="vacanteEmpresa text-center">
                   {{ vacante.empresa}}
                </h3>
              </v-col>
              <v-col cols="6" class="pc" v-if="vacante.anonimo">
                <div v-if="vacante.sueldo_raninf > 0">
                  <p
                    class="vacanteRango"
                    style="background-color:white; padding:10px; border-radius:12px; text-align:center;"
                  >
                    <strong>
                      Rango de Sueldo
                      <span v-if="vacante.base_sueldo == 1">Semanal</span>
                      <span v-else-if="vacante.base_sueldo == 0"> Mensual</span
                      ><br />
                      {{
                        vacante.sueldo_raninf.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}

                      -
                      {{
                        vacante.sueldo_ransup.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </strong>
                  </p>
                </div>

              
              </v-col>
              <v-col
                cols="6"
                class="text-center pc"
                v-if="vacante.anonimo == false"
              >
                <p
                  class="vacantePosicion"
                  style="color:black; text-align:center; "
                >
                  {{ vacante.posicion }}
                </p>

                <h3 style="color:black;" class="vacanteEmpresa text-center">
                  {{ vacante.empresa }}
                </h3>
              </v-col>
              <v-col cols="4" class="pc" v-if="vacante.anonimo == false">
                <div v-if="vacante.sueldo_raninf > 0">
                  <p
                    class="vacanteRango"
                    style="background-color:white; padding:10px; border-radius:12px; text-align:center;"
                  >
                    <strong>
                      Rango de Sueldo
                      <span v-if="vacante.base_sueldo == 1">Semanal</span>
                      <span v-else-if="vacante.base_sueldo == 0"> Mensual</span
                      ><br />
                      {{
                        vacante.sueldo_raninf.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}

                      -
                      {{
                        vacante.sueldo_ransup.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </strong>
                  </p>
                </div>
               
              </v-col>
            </v-row>
             <v-row style="margin-top:30px;">
                  <v-col cols="4" class="pc">
                  <div class="text-center"  v-if="vacante.telefono">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialogT"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                       
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              prueba(
                                vacante.folio_emp,
                                vacante.folio,
                                vacante
                              )
                            "
                            
                            style="background-color:#A8B8CD;color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                            target="_blank"
                            v-if="vacante.telefono"
                            ><v-icon style="padding:12px;margin-left:-7px;">
                              mdi-phone
                            </v-icon>
                            Llamar
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            Teléfono
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ objeto }}</v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialogT = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>

                </v-col>
                <v-col cols="4" class="pc">
                   <div class="text-center" v-if="vacante.whatsapp">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialogoW"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              prueba2(
                                vacante.folioempresa,
                                vacante.folio,
                                vacante
                              )
                            "
                            color="#8CDB6B"
                            style="color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                            target="_blank"
                          >
                            <v-icon style="padding:12px;margin-left:-7px;">
                              mdi-whatsapp
                            </v-icon>
                            Enviar WhatsApp
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            WhatsApp
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ objeto }}
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialogoW = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                </v-col>
                <v-col cols="4" class="pc">
                      <div class="text-center">

                    <v-dialog
                      :retain-focus="false"
                      v-model="dialogoC"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                    
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            prueba3(
                              vacante.folio_emp,
                              vacante.folio,
                              vacante
                            )
                          "
                          color="#EFCC63"
                          style="color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                          target="_blank"
                        >
                          <v-icon style="padding:12px;margin-left:-7px;">
                            mdi-email
                          </v-icon>
                          Enviar CV
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Correo
                        </v-card-title>
                        <v-card-text
                          style="font-size:18px; text-align:center;"
                          >{{ objeto }}</v-card-text
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            style="color:black;"
                            @click="dialogoC = false"
                          >
                            Salir
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                      </div>
                    
                    


                </v-col>
                </v-row>
          </v-container>
          <v-container fluid style="background-color:#EFEFEF;">
            <v-row>
              

              <!--  <v-col cols="12" class="text-center">
                  <div v-if="logueado">
                    <br />
                    <v-btn
                      v-if="id.empresa"
                      style="color:black;"
                      color="#2afed6"
                    >
                      Cuenta Empresa
                    </v-btn>

                    <v-btn
                      v-if="vacante.aplico"
                      style="color:black;"
                      color="#2afed6"
                      >Ya Aplicaste <v-icon>mdi-account-check</v-icon>
                    </v-btn>
                    <div v-else>
                      <v-btn
                        style="color:black;"
                        color="secondary"
                        v-on:click="irVacante(vacante.folio)"
                        >DA CLICK PARA VER LOS DETALLES<v-icon
                          >mdi-account-check</v-icon
                        >
                      </v-btn>
                    </div>
                  </div>
                  <div v-else>
                    <v-btn
                      style="color:black;  width:300px;height:80px; font-size:22px"
                      color="secondary"
                      v-on:click="irVacante(vacante.folio)"
                      >DA CLICK PARA <br />VER LOS DETALLES
                      <v-icon>mdi-account-check</v-icon>
                    </v-btn>
                  </div>
                </v-col> -->
            </v-row>

            <!-- <-- aqui esta el proble -->
            <v-row>
              <v-flex xs12 sm12 md6 class="columna3" >
                <br />
                <h2 style="color:black" class="text-center">
                  Descripción y Requisitos 
                </h2>
                <br />

                <v-textarea
                  light
                  style="color:white !important;"
                  color="white"
                  auto-grow
                  :key="autoGrowHack"
                  outlined
                  v-model="vacante.descripcion"
                  name="tbox"
                  rows="5"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm12 md5 class="columna4" >
                <br />
                <h2 style="color:black"  class="text-center">
                  Nivel Educativo Requerido
                </h2>
                <br />
                <div class="text-center">
                  <v-textarea
                    light
                    color="white"
                    outlined
                    v-model="vacante.nivel_educativo"
                    name="tbox"
                    cols="30"
                    rows="2"
                  ></v-textarea>
                </div>
                <v-row style="margin-top:-20px;">
                  <v-col cols="6">
                    <h4
                      style="border:1px solid black; font-size:13px;color:black; border-radius:6px; width:fit-content;padding:5px;"
                    >
                      Jornada:
                      <em style="color:black">{{ vacante.tipo_jornada }}</em>
                    </h4>

                    <br />
                  </v-col>
                  <v-col cols="6">
                    <h4
                      style="border:1px solid black; font-size:13px;color:black; border-radius:6px; width:fit-content;padding:5px;"
                    >
                      Modalidad:
                      <em style="color:black">{{ vacante.tipo_area }}</em>
                    </h4>
                  </v-col>
                  <v-col cols="12" style="margin-top:-19px;">
                    <h4
                      style="border:1px solid black;text-align:center; font-size:13px;color:black; border-radius:6px; width:fit-content;padding:5px;margin-left:0px;"
                    >
                      Contratación:
                      <em style="color:black">{{
                        vacante.tipo_contratacion
                      }}</em>
                    </h4>
                  </v-col>
                </v-row>
              </v-flex>
            </v-row>
            <!-- <-- aqui esta el problecel-->

            <v-row>
              <v-col md="4" class="text-center">
                <p style="color:black">
                  <strong> Lugar</strong>
                </p>
                <p style="color:black; border-radius:6px; ">
                  <strong>
                    {{ vacante.ciudad }} ,
                    {{ vacante.estado }}
                  </strong>
                </p>
              </v-col>
              <v-col md="4" xs="6" class="text-center">
                <p style="color:black"><strong> Fecha de publicación</strong></p>
                <p style="color:black; border-radius:6px;">
                  <strong>
                    {{ moment(vacante.fechacreacion).format("LL") }}
                  </strong>
                </p>
              </v-col>
              <v-col md="4" xs="12" class="text-center">
                <p style="color:black"><strong> Área </strong></p>
                <p style="color:black; border-radius:6px;">
                  <strong> {{ vacante.sectordesc }}</strong>
                </p>
              </v-col>
            </v-row>
          </v-container>
          </div>

        <!-- Aqui esta el otro -->
          <div v-else-if="vacante.tipo_publicacion==2">
            <v-container fluid class="headernegro">
            <v-row>
              <!--cel -->

              <v-col cols="5" class="cel" v-if="vacante.anonimo == false">
                <div v-if="vacante.logo" class="imagen">
                  <v-img
                    class="miimagen img-fluid"
                    :src="vacante.logo"
                    alt=""
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="12" class="cel" v-if="vacante.anonimo == true">
                <div class="text-center">
                  <p
                    class="vacantePosicion"
                    style="color:white; text-align:center; "
                  >
                    {{ vacante.posicion }}
                  </p>
                  <span
                    v-if="vacante.anonimo == true"
                    style="color:#FFC000;font-size:14px;text-align:center;"
                  >
                    <strong>Empresa Lider </strong></span
                  >
                </div>
              </v-col>
              <v-col cols="7" id="cel">
                <div v-if="vacante.anonimo == false" class="text-center">
                  <p
                    class="vacantePosicion"
                    style="color:white; text-align:center; "
                  >
                    {{ vacante.posicion }}
                  </p>

                  <p
                    v-if="vacante.anonimo == false"
                    style="color: rgb(255, 192, 0);
text-align: center;
"
                  >
                    {{ vacante.empresa }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" id="cel" style="margin-top:-20px;">
                <div v-if="vacante.sueldo_raninf > 0">
                  <p
                    class="vacanteRango"
                    style="background-color:#2afed6; padding:10px; border-radius:12px; text-align:center;"
                  >
                    <strong>
                      Rango de Sueldo
                      <span v-if="vacante.base_sueldo == 1">Semanal</span>
                      <span v-else-if="vacante.base_sueldo == 0"> Mensual</span
                      ><br />
                      {{
                        vacante.sueldo_raninf.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}

                      -
                      {{
                        vacante.sueldo_ransup.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </strong>
                  </p>
                </div>

              </v-col>
              <v-col cols="12" class="cel" style="margin-top:-20px;">
                <div class="text-center">
                  <p class="text-center" style="color:white;font-size:18px;">
                    <strong> Da click para contactarte</strong>
                  </p>
                  <div v-if="vacante.telefono">
                    <v-btn
                      @click="
                        estadisticaCel(vacante.folio_emp, vacante.folio)
                      "
                      color="#004AAD"
                      style="background-color:#004AAD;color:white;font-size:14px;margin-top:-20px;border-radius:17px;"
                      target="_blank"
                      v-if="vacante.telefono"
                      :href="'tel:' + vacante.telefono"
                      ><v-icon style="padding:12px;margin-left:-7px;">
                        mdi-phone
                      </v-icon>
                      Llamar
                    </v-btn>
                  </div>

                  <div v-if="vacante.whatsapp">
                    <v-btn
                      color="#20B038"
                      @click="estadisticaW(vacante.folio_emp, vacante.folio)"
                      v-if="vacante.whatsapp"
                      style="color:white;font-size:14px;border-radius:17px;"
                      target="_blank"
                      :href="
                        'https://api.whatsapp.com/send/?phone=52' +
                          vacante.whatsapp +
                          '&text=¡Hola!%20vi%20la%20vacante%20' +
                          vacante.posicion +
                          '%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20más%20información'
                      "
                    >
                      <v-icon style="padding:12px;margin-left:-7px;">
                        mdi-whatsapp
                      </v-icon>
                      Enviar WhatsApp
                    </v-btn>
                  </div>

                  <div>
                    <v-btn
                      @click="
                        estadisticaCorreo(vacante.folio_emp, vacante.folio)
                      "
                      v-if="vacante.correo"
                      style="color:white;font-size:14px; margin-top:4px; border-radius:17px;"
                      target="_blank"
                      :href="
                        'mailto:' +
                          vacante.correo +
                          '?Subject=CV%20enviado%20desde%20VACANTA' +
                          '&body=Escribe%20aqui%20el%20nombre%20del%20puesto%20para%20el%20que%20estas%20enviando%20tu%20Cv'
                      "
                      color="#FFC000"
                    >
                      <v-icon style="padding:12px;margin-left:-7px;">
                        mdi-email
                      </v-icon>
                      Enviar CV
                    </v-btn>
                  </div>
                  <br />

                  
                </div>
              </v-col>

              <!-- PC -->

              <v-col cols="2" class="pc" v-if="vacante.anonimo == false">
                <div v-if="vacante.logo" class="imagen">
                  <v-img
                    class="miimagen img-fluid"
                    :src="vacante.logo"
                    alt=""
                  ></v-img>
                </div>
              </v-col>

              <v-col cols="6" class="text-center pc" v-if="vacante.anonimo">
                <p
                  class="vacantePosicion"
                  style="color:white; text-align:center; "
                >
                  {{ vacante.posicion }}
                </p>

                <h3 style="color:#FFC000;" class="vacanteEmpresa text-center">
                   {{ vacante.empresa }}
                </h3>
              </v-col>
              <v-col cols="6" class="pc" v-if="vacante.anonimo">
                <div v-if="vacante.sueldo_raninf > 0">
                  <p
                    class="vacanteRango"
                    style="background-color:#2afed6; padding:10px; border-radius:12px; text-align:center;"
                  >
                    <strong>
                      Rango de Sueldo
                      <span v-if="vacante.base_sueldo == 1">Semanal</span>
                      <span v-else-if="vacante.base_sueldo == 0"> Mensual</span
                      ><br />
                      {{
                        vacante.sueldo_raninf.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}

                      -
                      {{
                        vacante.sueldo_ransup.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </strong>
                  </p>
                </div>

            
              </v-col>
              <v-col
                cols="6"
                class="text-center pc"
                v-if="vacante.anonimo == false"
              >
                <p
                  class="vacantePosicion"
                  style="color:white; text-align:center; "
                >
                  {{ vacante.posicion }}
                </p>

                <h3 style="color:#FFC000;" class="vacanteEmpresa text-center">
                  {{ vacante.empresa}}
                </h3>
              </v-col>
              <v-col cols="4" class="pc" v-if="vacante.anonimo == false">
                <div v-if="vacante.sueldo_raninf > 0">
                  <p
                    class="vacanteRango"
                    style="background-color:#2afed6; padding:10px; border-radius:12px; text-align:center;"
                  >
                    <strong>
                      Rango de Sueldo
                      <span v-if="vacante.base_sueldo == 1">Semanal</span>
                      <span v-else-if="vacante.base_sueldo == 0"> Mensual</span
                      ><br />
                      {{
                        vacante.sueldo_raninf.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}

                      -
                      {{
                        vacante.sueldo_ransup.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </strong>
                  </p>
                </div>
               
              </v-col>
            </v-row>
             <v-row style="margin-top:30px;">
                  <v-col cols="4" class="pc">
                  <div class="text-center"  v-if="vacante.telefono">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialogT"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              prueba(
                                vacante.folio_emp,
                                vacante.folio,
                                vacante
                              )
                            "
                            color="#004AAD"
                            style="background-color:#004AAD;color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                            target="_blank"
                            v-if="vacante.telefono"
                            ><v-icon style="padding:12px;margin-left:-7px;">
                              mdi-phone
                            </v-icon>
                            Llamar
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            Teléfono
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ objeto }}</v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialogT = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>

                </v-col>
                <v-col cols="4" class="pc">
                  <div class="text-center" v-if="vacante.whatsapp">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialogoW"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                         
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              prueba2(
                                vacante.folio_emp,
                                vacante.folio,
                                vacante
                              )
                            "
                            color="#20B038"
                            style="color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                            target="_blank"
                          >
                            <v-icon style="padding:12px;margin-left:-7px;">
                              mdi-whatsapp
                            </v-icon>
                            Enviar WhatsApp
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            WhatsApp
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ objeto }}
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialogoW = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                </v-col>
                <v-col cols="4" class="pc">
                      <div class="text-center">

                    <v-dialog
                      :retain-focus="false"
                      v-model="dialogoC"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                      
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            prueba3(
                              vacante.folio_emp,
                              vacante.folio,
                              vacante
                            )
                          "
                          color="#FFC000"
                          style="color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                          target="_blank"
                        >
                          <v-icon style="padding:12px;margin-left:-7px;">
                            mdi-email
                          </v-icon>
                          Enviar CV
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Correo
                        </v-card-title>
                        <v-card-text
                          style="font-size:18px; text-align:center;"
                          >{{ objeto }}</v-card-text
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            style="color:black;"
                            @click="dialogoC = false"
                          >
                            Salir
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                      </div>
                    
                    


                </v-col>
                </v-row>
          </v-container>
          <v-container fluid style="background-color:rgb(68 84 106);">
            <v-row>
           

              <!--  <v-col cols="12" class="text-center">
                  <div v-if="logueado">
                    <br />
                    <v-btn
                      v-if="id.empresa"
                      style="color:black;"
                      color="#2afed6"
                    >
                      Cuenta Empresa
                    </v-btn>

                    <v-btn
                      v-if="vacante.aplico"
                      style="color:black;"
                      color="#2afed6"
                      >Ya Aplicaste <v-icon>mdi-account-check</v-icon>
                    </v-btn>
                    <div v-else>
                      <v-btn
                        style="color:black;"
                        color="secondary"
                        v-on:click="irVacante(vacante.folio)"
                        >DA CLICK PARA VER LOS DETALLES<v-icon
                          >mdi-account-check</v-icon
                        >
                      </v-btn>
                    </div>
                  </div>
                  <div v-else>
                    <v-btn
                      style="color:black;  width:300px;height:80px; font-size:22px"
                      color="secondary"
                      v-on:click="irVacante(vacante.folio)"
                      >DA CLICK PARA <br />VER LOS DETALLES
                      <v-icon>mdi-account-check</v-icon>
                    </v-btn>
                  </div>
                </v-col> -->
            </v-row>

            <!-- <-- aqui esta el proble -->
            <v-row>
              <v-flex xs12 sm12 md6 class="columna3" >
                <br />
                <h2 style="color:white"  class="text-center">
                  Descripción y Requisitos 
                </h2>
                <br />

                <v-textarea
                  light
                  style="color:black !important;"
                  color="white"
                  auto-grow
                  :key="autoGrowHack"
                  background-color="white"
                  outlined
                  v-model="vacante.descripcion"
                  name="tbox"
 
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm12 md5 class="columna4" >
                <br />
                <h2  style="color:white"  class="text-center">
                  Nivel Educativo Requerido
                </h2>
                <br />
                <div class="text-center">
                  <v-textarea
                    light
                    color="black"
                    background-color="white"
                    outlined
                    v-model="vacante.nivel_educativo"
                    name="tbox"
                    cols="30"
                    rows="2"
                  ></v-textarea>
                </div>
                <v-row style="margin-top:-20px;">
                  <v-col cols="6">
                    <h4
                      style="background-color:black; font-size:13px;color:white; border-radius:6px; width:fit-content;padding:5px;"
                    >
                      Jornada:
                      <em style="color:#2afed6">{{ vacante.tipo_jornada }}</em>
                    </h4>

                    <br />
                  </v-col>
                  <v-col cols="6">
                    <h4
                      style="background-color:black; font-size:13px;color:white; border-radius:6px; width:fit-content;padding:5px;"
                    >
                      Modalidad:
                      <em style="color:#2afed6">{{ vacante.tipo_area }}</em>
                    </h4>
                  </v-col>
                  <v-col cols="12" style="margin-top:-19px;">
                    <h4
                      style="background-color:black;text-align:center; font-size:13px;color:white; border-radius:6px; width:fit-content;padding:5px;margin-left:0px;"
                    >
                      Contratación:
                      <em style="color:#2afed6">{{
                        vacante.tipo_contratacion
                      }}</em>
                    </h4>
                  </v-col>
                </v-row>
              </v-flex>
            </v-row>
            <!-- <-- aqui esta el problecel-->

            <v-row>
              <v-col md="4" class="text-center">
                <p style="color:#2afed6">
                  <strong> Lugar</strong>
                </p>
                <p style="color:white; border-radius:6px; ">
                  <strong>
                    {{ vacante.ciudad }} ,
                    {{ vacante.estado }}
                  </strong>
                </p>
              </v-col>
              <v-col md="4" xs="6" class="text-center">
                <p style="color:#2afed6"><strong> Fecha de publicación</strong></p>
                <p style="color:white; border-radius:6px;">
                  <strong>
                    {{ moment(vacante.fechacreacion).format("LL") }}
                  </strong>
                </p>
              </v-col>
              <v-col md="4" xs="12" class="text-center">
                <p style="color:#2afed6"><strong> Área </strong></p>
                <p style="color:white; border-radius:6px;">
                  <strong> {{ vacante.sectordesc }}</strong>
                </p>
              </v-col>
            </v-row>
          </v-container>
          </div>
          
        </v-card>
      </v-col>

      <v-col
        v-for="imagen of portrait"
        :key="imagen.folio"
        class="columna1"
        sm="12"
        xs="12"
        md="3"
        style="margin-top:60px;"
        id="pc"
      >
        <a :href="imagen.url" target="_blank" >
          <v-img
            style="border-radius:10px; "
            :src="imagen.imagen"
            :href="imagen.url"
          >
          </v-img>
        </a>
      </v-col>
      
      <v-col
      id="cel"
        v-for="imagen of portrait"
        :key="imagen.folio"
        class="columna1"
        sm="12"
        xs="12"
        md="3"
        style="margin-top:-10px;"
      >
        <a :href="imagen.url" target="_blank" >
          <v-img
            style="border-radius:10px; "
            :src="imagen.imagen"
            :href="imagen.url"
          >
          </v-img>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const hoy = moment();
import { mapState, mapMutations } from "vuex";
import moment from "moment";
import axios from "axios";

export default {
  name: "Vacante",
  data() {
    return {
      autoGrowHack: false,
      nuevoFolio: null,
      correo: "",
      portrait: [],
      password: "",
      errorM: null,
      emailRules: [
        (v) => !!v || "E-mail es necesario",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail no valido",
      ],
      passwordRules: [(v) => v.length >= 3 || "Minimo 6 Caracteres"],
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,

      vacantes: [],
      moment: moment,
      posts: [],
      min: 0,
      max: 45000,
      range: [5000, 25000],
      header: [],
      token:
        "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
      dialog: false,
      dialogWhats: false,
      dialogoVacante: false,
      dialogT: false,
      dialogoW: false,
      dialogoC: false,
      footer: [],
      portrait: [],
      nombre: "",
      objeto: null,
      page: 1,
      totalPaginas: null,
      textoVacante: "",
      nivelVacante: "",
      vacanteJornada: "",
      vacanteModalidad: "",
      vacanteContratacion: "",
      nombreEmpresa: null,
      folioEmpresa: null,
      folioVacante: null,
    };
  },
  computed: {
    webShareApiSupported() {
      return navigator.share;
    },
    ...mapState(["VacanteFolio"]),
    logueado() {
      return this.$store.state.token;
    },
  },
  created() {
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "3",
      })
      .then((response) => {
        this.portrait = response.data;
      })
      .catch((error) => {
        console.log(error);
      }),
      this.$store.dispatch("autoLogin");
  },

  mounted() {
    console.log(this.VacanteFolio);
    this.nuevoFolio = this.$route.params.folio;
    axios
      .get("/vacantes/buscavacantefolio/" + this.nuevoFolio, {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      })
      .then((response) => {
        this.vacantes = response.data;
        this.nuevoTitulo = response.data[0].posicion;
        this.nombreEmpresa = response.data[0].empresa;
         this.folioEmpresa = response.data[0].folemp;
         this.folioVacante = response.data[0].folio
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    prueba(a, b, c) {
      console.log(a, b, c);
      this.objeto = c.telefono;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: c.folio_emp,
            folio: b,
            donde: "2",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prueba2(a, b, c) {
      console.log(a, b, c);
      this.dialogW = true;
      this.objeto = c.whatsapp;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: c.folio_emp,
            folio: b,
            donde: "3",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
       estadisticaW(emrpresa, folio) {
      console.log("1", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: emrpresa,
            folio: folio,
            donde: "3",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaCel(emrpresa, folio) {
      console.log("1", emrpresa, folio, "2");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: emrpresa,
            folio: folio,
            donde: "2",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
       estadisticaCorreo(emrpresa, folio) {
      console.log("1", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: emrpresa,
            folio: folio,
            donde: "1",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prueba3(a, b, c) {
      console.log(a, b, c);
      this.dialogoC = true;
      this.objeto = c.correo;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: c.folio_emp,
            folio: b,
            donde: "1",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    shareViaWebShare() {
      this.nombreEmpresa = this.nombreEmpresa;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: this.folioEmpresa,
            folio: this.folioVacante,
            donde: "7",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      navigator.share({
        title:
          "Vacante en la empresa " +
          this.nombreEmpresa +
          ", con el puesto: " +
          this.nuevoTitulo,
        text:
          "Vacante en la empresa " +
          this.nombreEmpresa +
          ", con el puesto: " +
          this.nuevoTitulo,
        url: "https://www.vacanta.mx" + this.$route.path,
      });
    },
    aplicaVacanteGratuito() {
      console.log(
        "candidato:",
        this.$store.state.id.id,
        "vacante:",
        this.VacanteFolio,
        "via:",
        2
      );
      axios
        .post(
          "/candidatos/aplicavacante",
          {
            candidato: this.$store.state.id.id,
            vacante: this.VacanteFolio,
            via: 2,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .then((data) => {
          this.$router.push({ name: "postulado" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapMutations(["setCorreo"]),
    ingresar() {
      this.setCorreo(this.correo);
      axios
        .post("auth/signin", { correo: this.correo, password: this.password })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          this.$store.dispatch("guardarToken", data.token);
          this.dialog = false;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.errorM = "Las credenciales son incorrectas";
          } else if (error.response.status == 400) {
            this.errorM = "El usuario no existe favor de registrarse";
          } else {
            this.errorM = "Ocurrio un error con el servidor";
          }
        });
    },
  },
};
</script>

<style scoped>
#autocompletado2 {
  margin-top: 12px;
}

@media only screen and (min-width: 868px) {
  /* Esta es la pc */
  .headernegro {
    height: 100% !important;
    background-color: rgb(68 84 106);;
  }
    .headerblanco {
    height: 100% !important;
    background-color: #EFEFEF
  }
  .vacantePosicion {
    font-size: 34px;
  }
  #cel {
    display: none;
  }
  .vacanteRango {
    font-size: 17px;
  }
  .miimagen {
    border-radius: 40px;
  }
  .imagen {
    border: 4px solid white;
    width: 120px;
    height: 120px;
    border-radius: 40px;
    display: flex;
    margin-left: 20px;
    background-color: #ddd;
  }
  .columna3 {
    margin-left: 25px;
  }
  .columna4 {
    margin-left: 25px;
  }
  .columna2 {
    padding: 15px;
    border-radius: 15px;
  }
  .tarjeta {
    padding: 15px;
    margin-top: 20px;
    border-radius: 15px;
    background-color: rgb(68 84 106);;
  }
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 20px;
    font-size: 15px;
    padding: 10px;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 768px) {
  /* Celular*/

  .vacantePosicion {
    font-size: 20px !important;
  }

  .imagen {
    border: 4px solid white;
    width: 100px;
    height: 100px;
    border-radius: 40px;
    display: flex;
    margin-left: 20px;
    background-color: #ddd;
  }
  .headernegro {
    height: 100% !important;
    background-color: rgb(68 84 106);;
  }
    .headerblanco {
    height: 100% !important;
    background-color: #EFEFEF;
  }
  #pc {
    display: none;
  }
}
/* 
@media only screen and (max-width: 768px) {
  /* Este es el celular 
  
 

  
  
  .columna1 {
    margin-bottom: 30px;
  }
  .tarjeta {
    background-color: #ddd;
    margin-bottom: 70px;
    margin-top: 10px;
  }
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  .botonPostularme {
    width: 110px;
    font-size: 10px;
    margin-left: -25px !important;
  }
  .v-btn__content {
    width: 20px !important;
    font-size: 8px !important;
  }
  .headernegro {
    height: 200px;
    background-color: black;
  }
  #milogo {
    width: 140px !important;
  }
  .social-buttons {
    display: none;
  }
  .boton-tr {
    display: none;
  }
  .logo {
    display: none;
  }
  .texto-logo {
    font-size: 20px;
  }
  .usuario {
    margin-left: -120px;
  }
  .autocompletado-estado {
    display: none;
  }
  .autocompletado-tipo {
    display: none;
  }
  .autocompletado-puesto {
    width: 74px;
  }
  ::v-deep .autocompletado-puesto .v-label {
    font-size: 11.5px;
  }
  ::v-deep .autocompletado-puesto .v-icon {
    font-size: 14px;
    margin-left: 26px;
  }
  ::v-deep .autocompletado-ciudad .v-label {
    font-size: 13px;
  }
  ::v-deep .v-input__slot {
    width: 80px !important;
    margin-left: -7px;
  }
  ::v-deep .autocompletado-ciudad .v-icon {
    font-size: 14px;
    margin-left: 26px;
  }
  .autocompletado-ciudad .v-label {
    font-size: 12px;
    margin-left: -20px;
  }
  .autocompletado-ciudad {
    width: 70px !important;
  }
  .boton-buscar {
    width: 86px;
  }
}

 */
@media (min-width: 700px) and (orientation: portrait) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
   .headerblanco {
    height: 100% !important;
    background-color: #EFEFEF; 
  }
}

@media (min-width: 700px) and (orientation: landscape) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
}
</style>
