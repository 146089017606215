<template>
  <v-autocomplete
      outlined
      v-model="model"
        :click="setHabilidad(model)"
      label="Habilidades Interpersonales"
      clearable
      :items="items"
       item-text="habilidad"
      item-value="value"
      autocomplete="nope"
      color="primary"
      return-object
    ></v-autocomplete>
  

</template>
<script>
import axios from 'axios'
 import { mapMutations, mapState } from 'vuex'
  export default {
    data: () => ({
      isLoading: false,
      items: [],
      model: null,
      search: null,
      tab: null,
      idioma: null,
          token: "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c" 

    }),
     computed:{
      ...mapState (['nuevahabilidad'])
    },
     methods:{
       ...mapMutations (['setHabilidad'])
     },
     mounted(){
       axios
      .get("/catalogos/habilidades", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.items = response.data;
        
      })
      .catch((error) => {
        console.log(error);
      });
     
     },

  }
</script>