<template>
<v-layout style="margin-top:40px; " align-center justify-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
        <v-card style="background-color:#e0e0e0">
            <v-toolbar color="primary">
                <v-container>
                     <v-toolbar-title  class="text-center">      
                   <h3>Olvide mi contraseña</h3>
                </v-toolbar-title>

                </v-container>
               
            </v-toolbar>
            <v-card-text>
                 <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                          >
                <v-alert v-if="errors.length">
              <b>Porfavor corrige los siguientes errores:</b>
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </v-alert>
                <v-text-field :rules="rulesEmail" v-model="correo" autofocus color="primary" outlined label="Email" required>
                </v-text-field>
                <v-container>
                    <v-row>
                        <v-col>
        
                         <router-link :to="{name: 'loginEmpresas'}">
                              <a href="" >Regresar</a>
                         </router-link>
                        </v-col>
                
                        
                    </v-row>
                </v-container>
                
          <v-flex class="red--text" v-if="errorM">
            {{ errorM }}
          </v-flex>
                 </v-form>
           
            </v-card-text>

               
            <div class="text-center">
                   <v-card-actions class="px-3 pb-3">
                <v-flex text-cs-right>          
                    <v-btn :disabled="!valid" @click="cambiar()"  color="#2AFED6">Enviar</v-btn>
                </v-flex>
            </v-card-actions>

            </div>
          
        </v-card>
        
        
    </v-flex> 


</v-layout>
    
</template>

<script>
import axios from 'axios';
export default {
    data (){
        return{
             valid: true ,
            correo:'',
           password:'',
           errorM:null,
           errors: [],
            rulesEmail: [
        (v) => !!v || "E-mail es necesario",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail no valido",
      ],
        }
    },
    methods:{
        cambiar(){
            if(this.correo) {
            console.log(this.correo)
            axios.post('/empresas/olvide-acceso', {correo: this.correo,origen:1 })
            .then(response=>{
            console.log(response.data)
                     })
            .then(data =>{
                this.$router.push({name: 'RecuperaContrasena'});
            })
            .catch(error =>{
                console.log(error);
                this.errorM=null;
                if(error.response.status==401){
                    this.errorM='Las credenciales son incorrectas';
                }else if(error.response.status==400){
                    this.errorM='El usuario no existe favor de registrarse'
                }else{
                    this.errorM='No se encontro el usuario'
                }
        });
    } else{
        this.errors = [];
      if (!this.correo) this.errors.push("Correo es requerido.");

    }
        }
    }
}
</script>
<style scoped>
    .v-application a{
        color: black !important ;
    }
</style>