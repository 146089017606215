<template>
  <v-container fluid>
    <v-tabs  
      show-arrows dark background-color="grey darken-1" >
      <router-link style="text-decoration: none;" :to="{ name: 'PerfilEmpresas' }">
        <v-btn style="margin-top:5px;margin-left:5px;">Perfil </v-btn>
      </router-link>
      
        
 

      <router-link style="text-decoration: none;" :to="{ name: 'EmpresasPerfilUsuario' }">
        <v-btn style="margin-left:15px;margin-top:5px;">Contacto </v-btn>
      </router-link>
       <router-link v-if="this.creditos>=1" style="text-decoration: none;" :to="{ name: 'TipoVacante' }">
        <v-btn style="margin-left:15px;margin-top:5px;">Publicar nueva vacante </v-btn>
      </router-link>
      <router-link v-else style="text-decoration: none;" :to="{ name: 'ComprarCreditos' }">
        <v-btn style="margin-left:15px;margin-top:5px;">Publicar nueva vacante </v-btn>
      </router-link>

       <router-link style="text-decoration: none;" :to="{ name: 'ComprarCreditos' }">
        <v-btn style="margin-left:15px;margin-top:5px;">Comprar</v-btn>
      </router-link>


    </v-tabs>
    <br />
    <v-alert v-if="this.celular == null" type="warning">
      Es importante tener tu perfil actualizado y lleno para tener mas
      posibilidades de obtener una vacante.</v-alert
    >
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-toolbar dark prominent height="100px" class="hola">
            <v-container>
              <v-row>
                <v-col>
                  <div class="imagen">
                    <v-img class="miimagen" :src="this.foto" alt=""></v-img>
                  </div>
                </v-col>
                <v-col>
                  <h2 class="nombre">{{ this.nombre }} </h2>

                </v-col>
              </v-row>
            </v-container>
            <v-spacer></v-spacer>
            <div class="espaciador"></div>
          </v-toolbar>
          <v-container fluid style="margin-top:-12px">
            <v-row>
              <v-col>
                <div class="gris" style="margin-top:-20px;">
                  <br />
                  <strong>
                  <p class="estado"  style="font-size:18px;">
                    
                    <v-icon>mdi-map-marker</v-icon> {{ this.evento }} ,
                    {{ this.mievento }}
                   
                  </p>
                  
                  <p class="trabajando"   style="font-size:18px;">
                    
                    <v-icon>mdi-briefcase</v-icon> RFC: {{ this.rfc }}
                     
                  </p>
                  <div style="font-size:18px;">

                
                 
                  <p class="trabajando"   >
                   <v-icon id="pc">mdi-web</v-icon>
                     {{ this.web }}
                  </p>
                    </div>
                  <p class="trabajando"   style="font-size:18px;">
                    <v-icon>mdi-circle</v-icon> CP: {{ this.cp }}
                  </p>
 </strong>
                  <br />
                </div>
              </v-col>
            </v-row>
          </v-container>

          <br />

          <v-row>
            <v-col>
              <div class="text-center">
                <v-dialog width="500" color="#2afed6">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#FFC000"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      style="color:black;"
                    >
                    
                      Adjuntar logo
                      
                      <v-icon style="margin-left:10px">mdi-camera</v-icon>
                       
                    </v-btn>
                  </template>

                  <v-card>
                    <v-alert type="primary" v-if="avisoFoto">
                      {{ this.avisoFoto }}
                    </v-alert>
                    <v-alert type="warning" v-if="errorM">
                      {{ this.errorM }}
                    </v-alert>
                    <div style="padding:10px;">
                      <v-card-title class="text-h5 grey lighten-2">
                        Adjunta tu logotipo
                      </v-card-title>
                      <br />
                      <p style="margin-bottom:10px;">El tamaño de la imagen debe ser menor de 1 mb y tener formato Jpg, jpeg, o png.</p>
                      <br />
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Escoge tu Fotografía"
                        label="Fotografía"
                        v-model="foto"
                        outlined
                        :key="prueba"
                      ></v-file-input>
                    </div>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <router-link :to="{ name: 'EmpresasPerfilUsuario' }">
                      <v-btn
                        color="primary"
                        
                        class="text-center"
                      >
                        Salir
                      </v-btn>
                      </router-link>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        
                        @click="subirFoto()"
                        class="text-center"
                      >
                        Adjuntar
                      </v-btn>
                      
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              
            </v-col>
           
            <v-col>
              <div class="text-center">
                <v-dialog
                v-model="holas"
                 width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary" style="color:black;" dark v-bind="attrs" v-on="on">
                      Editar Datos
                       <v-icon >mdi-pencil</v-icon>
                    </v-btn>
                   
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 primary darken-1">
                      Datos Empresa
                    </v-card-title>
                    <v-container>
                       <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                          >

                       <v-row>
                        
                        <v-col cols="12">
                          <v-text-field
                            label="Nombre Comercial o Razon Social"
                            hide-details="auto"
                            v-model="nombre"
                            outlined
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            
                          <v-autocomplete
                          disabled
                            outlined
                            v-model="evento"
                            :click="setState(evento)"
                            label="Estado"
                            clearable
                            :items="estados"
                            item-text="estado"
                            item-value="value"
                            autocomplete="nope"
                            color="primary"
                          ></v-autocomplete>
                       
                           
                           </v-col>
                           <v-col cols="6">
                             <v-autocomplete
                             disabled
      outlined
      v-model="mievento"
      :click="setCiudad(mievento)"
      label="Ciudad"
      clearable
      :items="ciudades"
      item-text="municipio"
      item-value="ciudad"
      autocomplete="nope"
      color="primary"
    ></v-autocomplete>
                             
                        </v-col>
                        <v-col cols="12">
                             <v-text-field
                            label="Correo"
                            hide-details="auto"
                            v-model="correo"
                            outlined
                            disabled
                           
                          ></v-text-field>
                             
                        </v-col>
                      </v-row>
                      
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            label="R.F.C."
                            hide-details="auto"
                            v-model="rfc"
                            outlined
                            disabled
                           
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                           <v-text-field
                            label="C.P."
                            hide-details="auto"
                            v-model="cp"
                            outlined
                            :rules="rulesCP"
                            v-mask="mask"
                          ></v-text-field>
                           </v-col>
                      </v-row>
                       <v-row>
                        <v-col cols="6">
                          
                          <v-select 
                          outlined
                          :items="items"
                          placeholder="Numero de Empleados"
                          item-text="descripcion"
                          item-value="folio"
                          v-model="num_empleados"
                          >

                          </v-select>
                        </v-col>
                        <v-col cols="6">
                           <v-text-field
                            label="Web"
                            hide-details="auto"
                            v-model="web"
                            outlined
                          ></v-text-field>
                           </v-col>
                      </v-row>
          </v-form>            
                    </v-container>
                   

                    <v-divider></v-divider>

                    <v-card-actions>
                       <v-container>
                      <div class="text-center">
                          <v-btn
                            
                            color="primary darken-1"
                           @click="holas = false"
                          >
                            Cancelar
                          </v-btn>
                        </div>
                      </v-container>
                      <v-spacer></v-spacer>
                      <v-container>
                        <div class="text-center">
            <v-btn 
            :disabled="!valid"
            color="primary darken-1" @click="actualizoDatos()">
                                    Guardar
                                  </v-btn>
                        </div>
                      </v-container>
                     
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
            <v-col >
              <div class="text-center">
               
               
                  <v-btn @click="eliminaLogo()" color="secondary" style="color:black;"
                    >Eliminar Logo
                    <v-icon style="margin-left:15px"
                      >mdi-trash-can</v-icon
                    ></v-btn
                  >
               
                <br />
              
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2'
var moment = require("moment");
moment.locale("es-mx");
import axios from "axios";
import { mapMutations, mapState } from "vuex";

const hoy = moment();
let foto;

export default {
  name: "PerfilEmpresas",
  data() {
    return {
      mask: "#####",
      valid: true ,
      mievento: null,
      estados: [], 
      holas: false,
    evento: "",
      moment: moment,
      vacanteFolio: null,
      usuario: null,
      file: null,
      avisoFoto: "",
      errorM: "",
      avisoVitae: "",
      errorVitae: "",
      foto: "",
      nombre: "",
      prueba: 0,
      apellido: "",
      items: [],
      puesto: "",
      estado: "",
      ciudad: "",
      activo: "",
      salarioMayor: "",
      salarioMenor: "",
      celular: "",
      nacimiento: "",
      correo: "",
      curriculum: null,
      cp: "",
      num_empleados: null,
      web: null,
      creditos: null,
      rfc: null,
      token: "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
      razonsocial: null,
      ciudades: [], // <-- La lista de especialidades
    mievent: null, 
    rulesCP: [
        (v) => v.length==5 || "El C.P. debe ser de 5 digitos",
  
      ],
    rulesNum: [
        (v) => v.length<=10 || "El Máximo son 10 digitos",
  
      ],
    };
  },

  beforeMount() {
    this.$store.dispatch("autoLogin");
    axios
      .post("/empresas/perfilempresa", {
        empresa: this.$store.state.id.empresa, 
      }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
      .then((response) => {
        //
        this.cp = response.data[0].cp;
        this.foto = response.data[0].logo;
        this.nombre = response.data[0].nom_comercial;
        this.num_empleados = response.data[0].num_empleados;
        this.razonsocial = response.data[0].razonsocial;
        this.rfc = response.data[0].rfc;
        this.web = response.data[0].web;
        this.evento= response.data[0].estado;
        this.mievento = response.data[0].ciudad;
        this.correo = response.data[0].correo
 
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
     axios
          .get("/creditos/creditosempresa/" + this.$store.state.id.empresa, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.creditos = response.data.creditos;
          })
          .catch((error) => {
            console.log(error);
          });
    axios
      .get("/catalogos/estados", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.estados = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get("/catalogos/numempleados", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.items = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
     ...mapState(["id", "miempresa","elestado","laciudad"]),
    logueado() {
      return this.$store.state.token;
    },
  },
  watch: {
          elestado:function(value){
            axios
            .get("/catalogos/ciudades/" + this.elestado, { headers: {
      Authorization: 'Bearer ' + this.token}
   } )
            .then((response) => {
              this.ciudades = response.data;
            })
            .catch((error) => {
          
            });
          }
  },
  methods: {

      eliminaLogo(){


Swal.fire({
 title: '¿Estas seguro de borrarlo?',
  text: "¡No podras revertir esta acción!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#FFC000',
  cancelButtonColor: '#FFC000',
  confirmButtonText: '¡Si, Eliminar!',
  cancelButtonText: '¡No, cancelar!',
  reverseButtons: true
}).then((result) => {
  if (result.isConfirmed) {
    axios
        .post("/empresas/quitalogo", {
           id: this.$store.state.id.empresa,
        }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
        .then((response) => {
          console.log(response.data)
           this.$router.push({ name: "TipoDeVacante" });
        })
        
        .catch((error) => {
          console.log(error);
      
        });
    swalWithBootstrapButtons.fire(
      'Deleted!',
      'Your file has been deleted.',
      'success'
    )
  } else if (
    /* Read more about handling dismissals below */
    result.dismiss === Swal.DismissReason.cancel
  ) {
    swalWithBootstrapButtons.fire(
      'Cancelled',
      'Your imaginary file is safe :)',
      'error'
    )
  }
})



    
    },
     ...mapMutations(['setState','setCiudad']),
    subirFoto() {
      
      const fd = new FormData();
      fd.append("logo", this.foto);
      console.log(fd, this.$store.state.id.empresa)
      axios
        .put(
          "/empresas/actualizalogo/" +
            this.$store.state.id.empresa,
          fd,  { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   }
        )
        .then((response) => {
          console.log(response.data);
          
        })
        .then((data) => {
          this.avisoFoto =
            "La foto se ha actualizado con exito al ingresar de nuevo podras verla";
            this.$router.go(this.$router.currentRoute)
        })
        .catch((error) => {
          this.errorM =
            "La foto que tratas de subir es muy pesada o no cuenta con extension: jpg, png o jpeg";
          console.log(error);
        });
      
    },
    rerender(){
      this.prueba++
    },
    actualizoDatos(){


         axios
        .put(
          "/empresas/actualizaempresa" , { 
            folio: this.$store.state.id.empresa,
            nom_comercial: this.nombre,
            num_empleados: this.num_empleados,
            rfc: this.rfc,
            razonsocial: this.razonsocial,
            web: this.web,
            sector: this.sector,
            giro: this.giro,
            cp: this.cp,
            estado: this.evento,
            ciudad: this.mievento
            }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   }   )
        .then((response) => {
          console.log(response.data);
        })
        .then((data) => {
          this.avisoFoto =
            "La foto se ha actualizado con exito al ingresar de nuevo podras verla";
         this.$router.go(this.$router.currentRoute)
        })
        .catch((error) => {
          this.errorM =
            "La foto que tratas de subir es muy pesada o no cuenta con extension: jpg, png o jpeg";
          console.log(error);
        });   
 
    } 
  },
};
</script>

<style scoped>
.tarjeta-postulaciones {
  padding: 25px;
  border-radius: 15px;
  background-color: #2afed6;
}
.tarjeta-trabajos {
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey;
}
.tarjeta-trabajos {
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey;
}
.hola {
  border-radius: 25px;
}
.nombre {
  color: white;
  margin-left: -196px;
  margin-top: 16px;
  font-size: 20px;
}
#espacio {
  display: none;
}
.miimagen {
  border-radius: 40px;
}
.imagen {
  border: 4px solid white;
  width: 120px;
  height: 120px;
  margin-top: 11px;
  border-radius: 40px;
  display: flex;
  margin-left: 120px;
  background-color: #ddd;
}
.gris {
  background-color: #2afed6;
  width: 100%;
  height: 200px;
  border-radius: 25px;
}
.estado {
  margin-left: 390px;
}
.percepcion {
  margin-top: -5px;
  margin-left: 395px;
}
.trabajando {
  margin-top: -5px;
  margin-left: 395px;
}
.idiomas {
  margin-top: -25px;
  margin-left: 395px;
}
@media only screen and (max-width: 768px) {
  .imagen {
    border: 4px solid white;
    width: 100px;
    height: 100px;
    margin-top: 20px;
    border-radius: 40px;
    display: flex;
    margin-left: -0px;
    background-color: #ddd;
  }
  .nombre {
    color: white;
    margin-left: -30px;
    font-size: 24px;
    text-align: center;
    margin-top: 0px;
  }
  .titulo {
    margin-top: 10px;
    margin-left: -39px;
    text-align: center;
    font-size: 15px;
  }
  .estado {
    margin-left: 50px;
    margin-bottom: 10px;
  }

  .espacio {
    display: initial;
  }
  .percepcion {
    margin-left: 50px;
  }
  .trabajando {
    margin-left: 50px;
    margin-top: 0px;
  }
  .idiomas {
    margin-left: 50px;
    margin-top: -30px;
  }
  .gris {
    background-color: #2afed6;
    
    height: 230px;
    border-radius: 25px;
  }
}
@media only screen and (max-width: 768px) {
  /* todo lo de cel */
.imagen{
  border: 4px solid white;
width: 100px;
height: 100px;
margin-top: -6px;
border-radius: 40px;
display: flex;
margin-left: -6px;
background-color: #ddd;
}

}
</style>
