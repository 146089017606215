<template>
  <v-container>
      <v-row>
          <v-col  style="background-color:rgb(208 206 206);margin-top:0px; text-align:center;" >
              <h2 style="margin-top:0px;text-align:center;">El recibo ha sido generado, una vez realizada la transferencia favor de enviar el comprobante de pago a <br> <a style="color:blue">facturacion@vacanta.mx</a> <br> Se activaran la(s) vacante(s) Elite en cuanto sea refleado el pago. </h2>
              <br>
                 <router-link style="text-decoration: none;" :to="{name: 'PerfilEmpresas'}">
                    
                    <v-btn x-large class="primary" href="" style="text-align:center; color:black;">Salir</v-btn>
                    </router-link>

                    <br>
                    <br>
                    <br>

          </v-col>
      </v-row>
  </v-container>
</template>


<style>

</style>
<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
     
      token:
        "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
    };
  },
  watch: {

  },
  mounted() {
    this.$store.dispatch("autoLogin");

  },

  computed: {
    ...mapState([
      "elestado",
     
    ]),
  },


};
</script>