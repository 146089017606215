import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    id: null,
    elestado: null,
    elPais:null,
    laciudad: null,
    puesto: null,
    tipoEmpleo: '',
    rangoInf: 6000,
    rangoSup: null,
    fechaNac: Date,
    UrlHeader: null,
    elidioma: null,
    lainstitucion: null,
    nuevaespecialidad: null,
    nuevogiro:null,
    nuevasubespecialidad: null,
    nuevahabilidad: null,
    VacanteFolio: null,
    correo: null, 
    elservicio: null,
    miempresa: null,
    nuevosector: null,
    nivelPrueba:null,
    lahabilidad: null,
    nuevoSexo: null,
    respuesta: null,
    nivelEducativo: null,
    misvacantes: 0,
    nivelOperativo: null,
    nuevaPagina: 1,
    nuevaPagina2: 1,
    ciudadBuscador: null,
    Empresa:null,
    compracreditos: null,
    vacantesOperativas: 0,
    totalVacantes: 0,
    servicios:null,
    totalServicios:null,
    elprecio:0,
    precio:null,
    minuevaArea:null,
    minuevaEmpresa:null,
    minuevaArea2:null,
    minuevaEmpresa2:null
  },
  mutations: {
    setCiudadBuscador(state,value){
      state.ciudadBuscador = value
    },
    setPrecio(state,value){
      state.precio = value
    },
    setnuevaArea(state,value){
      state.minuevaArea = value
    },
    setnuevaEmp(state,value){
      state.minuevaEmpresa = value
    },
    setnuevaArea2(state,value){
      state.minuevaArea2 = value
    },
    setnuevaEmp2(state,value){
      state.minuevaEmpresa2 = value
    },
    setServicio(state,value){
      state.elservicio = value
    },
    setEmpresa(state,value){
      state.Empresa = value
    },
    setCompraCreditos(state,value){
      state.compracreditos = value
    },
    
    setPaginacion(state,pagina){
      state.nuevaPagina = pagina
    },
    setPaginacion2(state,pagina){
      state.nuevaPagina2 = pagina
    },
    setNivelOperativo(state,nivel){
      state.nivelOperativo = nivel
    },
    setHabilidad(state,habilidad){
      state.nuevahabilidad =habilidad 
    },
    setNivel(state,nivel){
      state.nivelPrueba =nivel 
    },
    setVacantes(state,vacante){
      state.misvacantes =vacante 
    },
    setVacantesOperativas(state,vacante){
      state.vacantesOperativas =vacante 
    },
    setTotalVacantesOperativas(state,vacante){
      state.totalVacantes =vacante 
    },
    setServicios(state,servicio){
      state.servicios =servicio 
    },
    setTotalServicios(state,servicio){
      state.totalServicios =servicio 
    },
    setNivelEducativo(state,nivel){
      state.nivelEducativo =nivel 
    },
    setRespuesta(state,respuesta){
      state.respuesta =respuesta 
    },
    setSexo(state,sexo){
      if(sexo=='Indistinto'){
        state.nuevoSexo = null
      }else{
        state.nuevoSexo = sexo
      }
      
    },
    setHabilidades(state,habilidad){
      state.lahabilidad= habilidad
    },
    setEmpresa(state,empresa){
      state.miempresa= empresa
    },
    setUrl(state,url){
      state.UrlHeader =url 
    },
    setEspecialidad(state,espe){
      state.nuevaespecialidad =espe 
    },
    setSector(state,sect){
      state.nuevosector =sect 
    },
    setVacante(state,Folio){
      state.VacanteFolio = Folio
    },
    setsubEspecialidad(state,subespe){
      state.nuevasubespecialidad =subespe    
    },
    setGiro(state,giro){
      state.nuevogiro =giro    
    },
    setPuesto(state,puesto){
      state.puesto =puesto 
    },
    setPais(state,pais){
      state.elPais =pais 
    },
    setEdad(state,fecha){
      state.fechaNac =fecha 
    },
    setrangoInf(state,rango){
      state.rangoInf =rango 
    },
    setrangoSup(state,rangoS){
      state.rangoSup =rangoS 
    },
    setState(state,estado){
      state.elestado =estado 
    },
    setInstitucion(state,institucion){
      state.lainstitucion=institucion
    },
    setCiudad(state,ciudad){
      state.laciudad =ciudad 
    },
    setIdioma(state,idioma){
      state.elidioma =idioma 
    },
    setToken(state,token){
      state.token=token
    },
    setId(state,id){
      state.id=id
    },
    setCorreo(state,correo){
      state.correo=correo
    },
    setElPrecio(state,varia){
      state.elprecio=varia
    }
  },
  actions: {
    guardarToken({commit}, token){
     
      commit("setToken", token)
      commit("setId", decode(token))
      localStorage.setItem("token", token)
    
     
      
    },
    irVacante({commit},Folio){
      console.log(Folio)
      commit("setVacante", Folio)
      router.push({name: 'vacante/' + Folio });
    },
    guardarEmpresa({commit},empresa){
      commit("setEmpresa",empresa)
    
    },
    autoLogin({commit}){
    
      let token = localStorage.getItem("token");
      if(token) {
        commit("setToken", token) 
        commit("setId",decode(token))
      }
    },
    salir({commit}){
      commit("setToken", null);
      commit("setId", null);
      localStorage.removeItem("token");
      router.push({name: 'Home'});
    }
  },
  modules: {
  }
})
