<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-card color="grey darken-4" dark class="mitarjeta" fluid>
        <v-toolbar>
          <v-container fluid class="micontenedor">
            <v-row>
              <v-flex class="subir" xs12 sm12 md12 lg12 xl12>
                <v-col>
                  <v-autocomplete
                    outlined
                    v-model="modelito"
                    :click="buscar()"
                    label="Buscar por Área"
                    clearable
                    :items="servicios"
                    item-text="area"
                    item-value="value"
                    autocomplete="nope"
                    color="primary"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-flex>
            </v-row>
          </v-container>
        </v-toolbar>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
import Especialidad from "../components/Buscador/Especialidad.vue";
import CiudadBuscador from "../components/Buscador/CiudadBuscador.vue";
import Empresa from "../components/Buscador/Empresa.vue";
import rangoSalarial from "../components/Buscador/rangoSalarial.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "Buscador",
  data: () => ({
    dialog: false,
    radioGroup: 1,
    items: [],
    servicios: [],
    modelito: "",
    model: "",
    estados: [], // <-- La lista de especialidades
    evento: "",
    busqueda: "",
    objetos: [],
    values: ["Sistemas", "Contabilidad"],
    value: null,
    salario: null,
    jornada: "Cualquiera",
    token:
      "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
  }),
  components: {
    CiudadBuscador,
    Empresa,
    rangoSalarial,
    Especialidad,
  },
  computed: {
    ...mapState([
      "nuevaespecialidad",
      "elestado",
      "ciudadBuscador",
      "puesto",
      "rangoInf",
      "rangoSup",
      "Urlheader",
      "VacanteFolio",
      "miempresa",
      "puesto",
      "vacantes",
      "nuevaPagina",
      "id",
      "Empresa",
      "elservicio",
      "totalServicios"
    ]),
    logueado() {
      return this.$store.state.token;
    },
  },
  watch: {
    nuevaPagina: function(value) {
      axios
        .post(
          "/servicios/buscaservicio",
          {
            edo: null,
            cd: null,
            paginacion: 15,
            pagina: this.nuevaPagina,
            espe: this.modelito.folio,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          this.setServicios(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    modelito: function(value) {
      if (this.modelito == null) {
        this.modelito = "";

        axios
          .post(
            "/servicios/buscaservicio",
            {
              edo: null,
              cd: null,
              paginacion: 1500,
              pagina: 1,
              espe: null,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            this.setTotalServicios(response.data);
          })
          .catch((error) => {
            console.log(error);
          });

        axios
          .post(
            "/servicios/buscaservicio",
            {
              edo: null,
              cd: null,
              paginacion: 15,
              pagina: 1,
              espe: null,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            this.setServicios(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  methods: {
    buscar() {
      axios
        .post(
          "/servicios/buscaservicio",
          {
            edo: null,
            cd: null,
            paginacion: 15,
            pagina: 1,
            espe: this.modelito.folio,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          this.setServicios(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .post(
          "/servicios/buscaservicio",
          {
            edo: null,
            cd: null,
            paginacion: 1500,
            pagina: 1,
            espe: this.modelito.folio,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          this.setTotalServicios(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapMutations([
      "setServicio",
      "setVacantesOperativas",
      "setEspecialidad",
      "setEmpresa",
      "setTotalVacantesOperativas",
      "setServicios",
      "setTotalServicios"
    ]),
  },
  mounted() {
    axios
      .get("/catalogos/areas_servicio", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.servicios = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/catalogos/especialidades", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.objetos = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/catalogos/empresas", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.estados = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
@media only screen and (min-width: 868px) {
  /* Esta es la pc */
  .mitarjeta {
    margin-top: -85px;
    height: 175px;
  }
  .micontenedor {
    margin-top: 225px;
  }
  #cel {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  /* Este es el celular */
  .micontenedor {
    margin-top: 220px;
  }
  .mitarjeta {
    margin-top: -65px;
    height: 160px;
  }
  #cel {
    margin-left: -8px;
  }
  .subir {
    margin-top: -30px;
  }
  #pc {
    display: none;
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  /* Cel acostado */
  .mitarjeta {
    margin-top: -85px;
    height: 175px;
  }

  .micontenedor {
    margin-top: 225px;
  }
  #cel {
    display: none;
  }
}
@media (min-width: 700px) and (orientation: portrait) {
  /* Tablet parado */
  .mitarjeta {
    margin-top: -85px;
    height: 175px;
  }

  .micontenedor {
    margin-top: 225px;
  }
  #cel {
    display: none;
  }
}
</style>
