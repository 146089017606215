<template>
  <div>
    <v-autocomplete
      outlined
      v-model="mievent"
      :click="setCiudad(mievent)"
      label="Ciudad "
      clearable
      :items="ciudades"
      item-text="municipio"
      item-value="ciudad"
      autocomplete="nope"
      color="primary"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";
export default {
  data: () => ({
    ciudades: [], // <-- La lista de especialidades
    mievent: null,
    token: "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c" 

  }),
  computed: {
    ...mapState(["elestado"]),
  },
 
      watch: {

          elestado:function(value){
            axios
            .get("/catalogos/ciudades/" + this.elestado, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
            .then((response) => {
              this.ciudades = response.data;
            })
            .catch((error) => {
            });
          }
  },
  methods: {
    ...mapMutations(["setCiudad"]),
  },
};
</script>

<style></style>
