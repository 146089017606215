<template>

  <v-container>
    <v-row>
      
      <v-col cols="12" style="margin-top:30px; text-align:center;">
        <h2 style="text-align:center;">
          ¿Quieres estar en los primeros lugares de la lista de postulados a
          esta vacante?
        </h2>
      </v-col>
      <v-col>
        <v-card
          :loading="loading"
          class="mx-auto my-8"
          max-width="874"
          color="#ddc"
        >
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

        
          <div>
            <v-card-title>Postulacion Preferencial</v-card-title>
          </div>

          

          <v-divider class="my-2"></v-divider>

          <v-card-title>¿Que contiene esta opción?</v-card-title>

          <v-card-text class="text-subtitle-1">
            • Costo de {{creditos }} Créditos<br>
            • El perfil del candidato se quedara en las primeras posiciones del
            listado de todos los postulados a la vacante. Esto permitirá ser
            visto por los reclutadores con mayor facilidad.
            <br />
            
          </v-card-text>

          <div class="text-center">
            <v-card-actions class="px-3 pb-3">
              <v-flex text-cs-right>
                <v-btn
                  x-large
                  @click="postulacionPreferencial()"
                  style="color:black;"
                  color="secondary"
                  >Postulación Preferencial</v-btn
                >
              </v-flex>
            </v-card-actions>
            <br />
          </div>
          <v-card-text class="text-subtitle-3">
            Nota: El postularse de manera preferencial, no garantiza el asistir a una entrevista, ni tampoco
              obtener el trabajo. Esa decisión es plenamente de la empresa.
            <br />
            
          </v-card-text>
          
        </v-card>
        <br>
        <div class="text-center">
          <router-link style="text-decoration: none;" :to="{ name: 'postulado' }">
                  <v-btn class="text-center" x-large
                  @click="aplicaVacanteGratuito()"
                  style="color:black;" color="primary"
                    >Postulación Normal</v-btn
                  >
                </router-link>

        </div>
        
      </v-col>
   
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data: () => ({
    loading: false,
    selection: 1,
    creditos: 62,
    totalPreferencial: 100.00
  }),

  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
    aplicaVacanteGratuito() {
      console.log("candidato:" , this.$store.state.id.id ,  "vacante:" , this.VacanteFolio, "via:", 2)
       axios.post("/candidatos/aplicavacante" , {candidato: this.$store.state.id.id, vacante: this.VacanteFolio, via: 2} )
      .then(response => {
           console.log(response.data)
        })
               .then(data =>{
                this.$router.push({name: 'postulado'});
            })
        .catch(error=>{
            console.log(error);
        })

    },
    postulacionPreferencial(){
      this.$router.push({name: 'prueba'});
      
       
            /*  axios.post("candidatos/aplicavacante" , {cantidad: this.totalPreferencial} )
      .then(response => {
           console.log(response.data)
        })
               .then(data =>{
               
            })
        .catch(error=>{
            console.log(error);
        }) */

      
    }
  },
  computed: {
    ...mapState(["VacanteFolio"]),
    logueado() {
      return this.$store.state.token;
    },
  },
};
</script>

<style></style>
