<template>
  <v-container fluid>
  <v-tabs  
      show-arrows dark background-color="grey darken-1" >
      <router-link style="text-decoration: none;" :to="{ name: 'PerfilEmpresas' }">
        <v-btn style="margin-top:5px;margin-left:5px;">Perfil </v-btn>
      </router-link>
      
        
 

      <router-link style="text-decoration: none;" :to="{ name: 'EmpresasPerfilUsuario' }">
        <v-btn style="margin-left:15px;margin-top:5px;">Contacto </v-btn>
      </router-link>
       <router-link v-if="this.creditos>1" style="text-decoration: none;" :to="{ name: 'TipoVacante' }">
        <v-btn style="margin-left:15px;margin-top:5px;">Publicar nueva vacante </v-btn>
      </router-link>
      <router-link v-else style="text-decoration: none;" :to="{ name: 'ComprarCreditos' }">
        <v-btn style="margin-left:15px;margin-top:5px;">Publicar nueva vacante </v-btn>
      </router-link>

       <router-link style="text-decoration: none;" :to="{ name: 'ComprarCreditos' }">
        <v-btn style="margin-left:15px;margin-top:5px;">Comprar</v-btn>
      </router-link>


    </v-tabs>
    <br />
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-toolbar dark prominent height="120px" class="hola">
            <v-container>
              <v-row>
                <v-col class="text-center">
                  <h2 class="nombre">{{ this.nombre }} {{ this.apellido }}</h2>
                </v-col>
              </v-row>
            </v-container>
            <v-spacer></v-spacer>
            <div class="espaciador"></div>
          </v-toolbar>
          <v-container fluid style="margin-top:-12px">
            <v-row>
              <v-col>
                <div class="gris">
                  <br />
                  <strong>

                 
                  <p class="estado" style="font-size:22px;">
                    <v-icon>mdi-briefcase</v-icon> {{ this.miempresa }}
                  </p>
                  <p class="estado"  style="font-size:22px;"> 
                    <v-icon>mdi-map-marker</v-icon> {{ this.estado }} ,
                    {{ this.ciudad }}
                  </p>
 </strong>
                  <br />
                </div>
              </v-col>
            </v-row>
          </v-container>

          <br />

          <v-row>
            <v-col>
              <div class="text-center">
                <v-dialog width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      style="color:black;"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Editar Datos
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 primary darken-1 ">
                      Datos Contacto Empresa
                    </v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            label="Nombre"

                            v-model="nombre"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Apellido"

                            v-model="apellido"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-container>
                        <div class="text-center">
                          <v-btn
                            color="primary darken-1"
                            @click="actualizoDatos()"
                          >
                            Guardar
                          </v-btn>
                        </div>
                      </v-container>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "Vacante",
  data() {
    return {
      nombreUsuario:"",
      apellidoUsuario:"",
      nombre: "",
      apellido: "",
      estado: "",
      ciudad: "",
      empresa: null,
      miempresa: null,
    };
  },

  beforeMount() {

   
    axios
      .post("/empresas/perfilempresa", {
        empresa: this.$store.state.id.empresa, 
      }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
      .then((respuesta) => {
        //
        this.miempresa = respuesta.data[0].nom_comercial;
            this.ciudad = respuesta.data[0].ciudad;
            this.estado = respuesta.data[0].estado;
       console.log(respuesta.data)
 
      })
      .catch((error) => {
        console.log(error);
      });
        this.$store.dispatch("autoLogin");

    axios
      .post("/empresas/perfilusuario", {
        idemp: this.$store.state.id.empresa,
      }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   } )
      .then((response) => {
        console.log(response.data)
        this.foto = response.data[0].foto;
        this.nombre = response.data[0].nombre;
        this.apellido = response.data[0].apellido;
        this.empresa = response.data[0].empresa;
     
      })
      .catch((error) => {
        console.log(error);
      });
  },
  

  computed: {
          ...mapState (['correo'])
,
    logueado() {
      return this.$store.state.token;
    },
  },
  methods: {
    actualizoDatos() {
      console.log("Nombre: " , this.nombre , "apellido: ", this.apellido, "Correo: " , this.correo, this.$store.state.correo)
      axios
        .put(
          "/empresas/actualizausuario",
          {
            id: this.$store.state.id.empresa,
            nombre: this.nombre,
            apellido: this.apellido,
            password: "%%VACIO%%"
                  }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   } 
        )
        .then((response) => {
          console.log(response.data);
           this.$router.go(this.$router.currentRoute)
        })
        .catch((error) => {

          console.log(error);
        }); 
    },
    prueba(){
      console.log("prueba")
    }
  },
};
</script>

<style scoped>
.tarjeta-postulaciones {
  padding: 25px;
  border-radius: 15px;
  background-color: #2afed6;
}
.tarjeta-trabajos {
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey;
}
.tarjeta-trabajos {
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey;
}
.hola {
  border-radius: 25px;
}
.nombre {
  color: white;
  margin-left: 36px;
  margin-top: 26px;
  font-size: 30px;
}
#espacio {
  display: none;
}
.miimagen {
  border-radius: 40px;
}
.imagen {
  border: 4px solid white;
  width: 120px;
  height: 120px;
  margin-top: 11px;
  border-radius: 40px;
  display: flex;
  margin-left: 120px;
  background-color: #ddd;
}
.gris {
  background-color: #2afed6;
  width: 100%;
  height: 120px;
  border-radius: 25px;
}
.estado {
  margin-left: 390px;
}
.percepcion {
  margin-top: -5px;
  margin-left: 395px;
}
.trabajando {
  margin-top: -5px;
  margin-left: 395px;
}
.idiomas {
  margin-top: -25px;
  margin-left: 395px;
}
@media only screen and (max-width: 768px) {
  .imagen {
    border: 4px solid white;
    width: 100px;
    height: 100px;
    margin-top: 20px;
    border-radius: 40px;
    display: flex;
    margin-left: -0px;
    background-color: #ddd;
  }
  .nombre {
    color: white;
    margin-left: -30px;
    font-size: 24px;
    text-align: center;
    margin-top: 0px;
  }
  .titulo {
    margin-top: 10px;
    margin-left: -39px;
    text-align: center;
    font-size: 15px;
  }
  .estado {
    margin-left: 50px;
    margin-bottom: 10px;
  }

  .espacio {
    display: initial;
  }
  .percepcion {
    margin-left: 50px;
  }
  .trabajando {
    margin-left: 50px;
    margin-top: 0px;
  }
  .idiomas {
    margin-left: 50px;
    margin-top: -30px;
  }
  .gris {
    background-color: #2afed6;
  width: 100%;
    width: 100%;
    height: 170px;
    border-radius: 25px;
  }
}
</style>
