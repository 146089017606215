<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step @click="cambiar()" :complete="e1 > 1" step="1">
        Requisitos
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step @click="cambiar2()" :complete="e1 > 2" step="2">
        Requisitos
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step @click="cambiar3()" :complete="e1 > 3" step="3">
        Confirmación
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step @click="cambiar4()" step="4">
        Finalizado
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-stepper-content step="1">
          <v-card class="mb-12 " color="#e0e0e0">
            <div style="padding:20px">
              <h3 class="text-center">Vacante Profesionista</h3>

              <p style="text-align:center;">
                Llena los requisitos de la vacante y da click en continuar
              </p>
              <br />
              <v-alert
                icon="mdi-alert"
                color="primary"
                style="position:fixed; z-index:999; margin-top:-200px;"
                v-if="errors.length"
              >
                <b>Por favor llena los siguientes campos:</b>
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
              <v-row>
          
                <v-col  class="col-6 col-md-4">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        
                        v-model="posicion"
                        outlined
                        label="Posición"
                        required
                        :rules="reglasPosicion"
                      ></v-text-field>
                    </template>
                    <span>Especifica el puesto que buscas...</span>
                  </v-tooltip>
                  <div></div>
                </v-col>
                

                <v-col class="col-6 col-md-4">
                  <v-text-field
                   v-mask="mask"
                    v-model="whatsap"
                    outlined
                    label="WhatsApp"
                    required
                  ></v-text-field>
                </v-col>
                
                <v-col md4 xs12>
                  <v-text-field
                    v-mask="mask"
                    v-model="telefono"
                    outlined
                    label="Teléfono"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-textarea
                        v-bind="attrs"
                        v-on="on"
                        v-model="descripcion_detallada"
                        outlined
                        label="Descripción y Requisitos"
                        required
                      >
                      </v-textarea>
                    </template>
                    <span
                      >Se recomienda que agregues el texto en forma de lista,<br />
                      especificando las labores a realizar en la jornada <br />
                      insertando un (*) o (-) al inicio de cada punto
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="6">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-radio-group
                        mandatory
                        @change="verificaAnonima()"
                        v-model="checkbox"
                      >
                        <v-radio
                          label="Publicar Nombre de mi Empresa"
                        ></v-radio>
                        <v-radio
                          label="Publicación Anónima"
                          on-icon="mdi-incognito"
                          v-on="on"
                        ></v-radio>
                      </v-radio-group>
                    </template>
                    <span style="text-align:center;" class="text-center"
                      >Al seleccionar publicación anónima su publicación no
                      mostrara <br />
                      el nombre de la empresa sustituyendola por "Empresa lider"
                      <br />
                      
                    </span>
                  </v-tooltip>
                </v-col>

                <v-col cols="6" class="pc">
                   
                 <h3 style="text-align:center;">
                    <strong
                      >Selecciona si deseas mostrar sueldo en tu vacante</strong
                    >
                  </h3>
                  <v-radio-group
                    v-model="SueldoBase"
                    mandatory
                    style="text-align:center;margin-left:;"
d
                    class="text-center"
                  >
                    <v-radio label="Si mostrar sueldo"></v-radio>
                    <v-radio label="No mostrar"></v-radio>
                  </v-radio-group>
                  <h3  v-if="SueldoBase==0" style="text-align:center">
                    Rango de sueldo de la vacante (Mensual o Semanal)
                  </h3> 

                  <br />
                  <v-row v-if="SueldoBase==0">
                    <v-col>
                      <div>
                        <vuetify-money
                          v-model="sueldo_minimo"
                          label="Mínimo"
                          v-bind:options="options"
                          outlined
                        />
                      </div>
                    </v-col>
                    <v-col class="text-center">
                      <div>
                        <vuetify-money
                          v-model="sueldo_superior"
                          label="Máximo"
                          v-bind:options="options"
                          outlined
                        />
                      </div>
                    </v-col>
                  </v-row>

                  <h3  v-if="SueldoBase==0" style="text-align:center;">
                    <strong
                      >Selecciona si el rango de sueldo es Mensual o
                      Semanal</strong
                    >
                  </h3>
                  <v-radio-group  v-if="SueldoBase==0"
                    v-model="baseSalarial"
                    mandatory
                    style="text-align:center;margin-left:240px;"
                    @change="verificarbase"
                    class="text-center"
                  >
                    <v-radio label="Mensual"></v-radio>
                    <v-radio label="Semanal"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" class="cel">
                  <v-container>
                    <v-row>
                      <h3 style="text-align:center">
                        Rango de sueldo de la vacante (Mensual o Semanal)
                      </h3>

                      <br />

                      <v-col cols="6">
                        <div>
                          <vuetify-money
                            v-model="sueldo_minimo"
                            label="Mínimo"
                            v-bind:options="options"
                            outlined
                          />
                        </div>
                      </v-col>
                      <v-col cols="6" class="text-center">
                        <div>
                          <vuetify-money
                            v-model="sueldo_superior"
                            label="Máximo"
                            v-bind:options="options"
                            outlined
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>

                  <h3 style="text-align:center;">
                    <strong
                      >Selecciona si el rango de sueldo es Mensual o
                      Semanal</strong
                    >
                  </h3>
                  <v-radio-group
                    v-model="baseSalarial"
                    mandatory
                    style="margin-left:140px"
                    @change="verificarbase"
                    class="large"
                  >
                    <v-radio label="Mensual"></v-radio>
                    <v-radio label="Semanal"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-row id="pc">
                  <v-col cols="4">
                    <p style="text-align:center;">
                      <strong>Tipo de Contratación</strong>
                    </p>
                    <v-radio-group
                      v-model="contratacion"
                      mandatory
                      style="margin-left:10px"
                      @change="verificarcontratacion"
                      class="large"
                    >
                      <v-radio label="Indeterminado"></v-radio>
                      <v-radio label="Temporal"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="4">
                    <p style="text-align:center;">
                      <strong>Tipo de Jornada</strong>
                    </p>
                    <v-radio-group
                      v-model="jornada"
                      mandatory
                      style="margin-left:10px"
                      @change="verificarjornada"
                    >
                      <v-radio label="Completa"></v-radio>
                      <v-radio label="Medio Tiempo"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="4">
                    <p style="text-align:center;">
                      <strong>Tipo de Modalidad</strong>
                    </p>
                    <v-radio-group
                      v-model="area"
                      mandatory
                      style="margin-left:10px"
                      @change="verificararea"
                    >
                      <v-radio label="Presencial"></v-radio>
                      <v-radio label="Híbrida"></v-radio>
                      <v-radio label="Home Office"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row id="cel">
                  <v-col cols="6">
                    <p style="text-align:center; font-size:13px;">
                      <strong>Tipo de Contratación</strong>
                    </p>
                    <v-radio-group
                      v-model="contratacion"
                      mandatory
                      style="font-size:12px;"
                      @change="verificarcontratacion"
                    >
                      <v-radio
                        style="font-size:12px !important;"
                        label="Indeterminado"
                      ></v-radio>
                      <v-radio
                        style="font-size:12px;"
                        label="Temporal"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="6">
                    <p style="text-align:center; font-size:13px;">
                      <strong>Tipo de Jornada</strong>
                    </p>
                    <v-radio-group
                      v-model="jornada"
                      mandatory
                      style="margin-left:10px"
                      @change="verificarjornada"
                    >
                      <v-radio label="Completa"></v-radio>
                      <v-radio label="Medio Tiempo"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <p style="text-align:center; font-size:13px;">
                      <strong>Tipo de Modalidad</strong>
                    </p>
                    <v-radio-group
                      v-model="area"
                      mandatory
                      style="margin-left:10px"
                      @change="verificararea"
                    >
                      <v-radio label="Presencial"></v-radio>
                      <v-radio label="Híbrida"></v-radio>
                      <v-radio label="Home Office"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-row>
            </div>
          </v-card>

          <div class="text-center">
            <v-btn :disabled="!valid" @click="validacion1()" color="primary">
              Continuar
            </v-btn>

            <v-btn @click="salirVacantes()" style="margin-left:5px;" dark>
              Anterior
            </v-btn>
          </div>
        </v-stepper-content>
      </v-form>
      <v-form ref="form" v-model="valida" lazy-validation>
        <v-stepper-content step="2">
          <v-card class="mb-12 " color="#e0e0e0">
            <div style="padding:20px">
              <h3 class="text-center">Requisitos</h3>

              <p style="text-align:center;">
                Llena los requisitos de la vacante y da click en continuar
              </p>
              <br />
              <v-alert
                icon="mdi-alert"
                color="primary"
                style="position:fixed; z-index:999; margin-top:-200px;"
                v-if="errores.length"
              >
                <b>Por favor llena los siguientes campos:</b>
                <ul>
                  <li v-for="error in errores" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
              <v-row>
                <v-col cols="6">
                  <Estado></Estado>
                </v-col>
                <v-col cols="6">
                  <Ciudad></Ciudad>
                </v-col>

                <v-flex md6 xs12>
                  <v-col>
                    <nivelEducativo></nivelEducativo>
                  </v-col>
                </v-flex>

                <v-flex md6 xs12 style="margin-top:11px;">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-autocomplete
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        v-model="model"
                        :click="setEspecialidad(model)"
                        label="Área"
                        clearable
                        :items="items"
                        item-text="especialidad"
                        item-value="especialidad"
                        autocomplete="nope"
                        color="primary"
                        return-object
                      ></v-autocomplete>
                    </template>
                    <span>Selecciona al área al que pertenece el puesto</span>
                  </v-tooltip>
                </v-flex>
              </v-row>
              <v-row>
                <!-- <v-col cols="8">
                <HabilidadesAdministrativo></HabilidadesAdministrativo>
              </v-col>
              <v-col cols="1">
                <v-btn @click="agregarHabilidad()" color="primary" style="color:black;">
               +
              </v-btn> 
              </v-col>-->

                <!-- <v-col cols="12">
                <h3>Habilidades</h3>
                <ul>
                  <li
                    v-for="habilidad of habilidades"
                    :key="habilidad.folio"
                  >
                    {{ habilidad.habilidad }}
                  </li>
                </ul>
              </v-col> -->
              </v-row>
            </div>
          </v-card>

          <div class="text-center">
            <v-btn @click="validacion2()" color="primary">
              Continuar
            </v-btn>

            <v-btn @click="e1 = 1" style="margin-left:5px;" dark>
              Anterior
            </v-btn>
          </div>
        </v-stepper-content>
      </v-form>
      <v-stepper-content step="3">
        <v-card class="mb-12 " color="#e0e0e0">
          <v-row>
            <v-col cols="12">
              <div style="padding-top:10px">
                <h2 class="text-center">Confirmación</h2>
                <v-divider></v-divider>
                <br />
                <p style="text-align:center;font-size:22px;">
                  La vacante esta apunto de ser publicada, verifica la
                  información antes de confirmar
                </p>
                <p style="text-align:center;color: red;font-size:22px;">
                  Una vez publicada esta vacante ya no podrás hacer correciones
                </p>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-card class="tarjeta" style="background-color:#EFEFEF">
                <v-container
                  fluid
                  style="background-color:#EFEFEF; ;
                  "
                  class="headernegro"
                >
                  <v-row id="pc">
                    <v-col cols="3" class="pc text-center" v-if="checkbox == 0">
                      <div class="imagen">
                        <v-img
                          class="miimagen"
                          :src="this.logos"
                          alt=""
                        ></v-img>
                      </div>
                    </v-col>
                    <v-col cols="4" class="text-center" v-if="checkbox == 0">
                      <p
                        class="vacantePosicion"
                        style="color:black; text-align:center;"
                      >
                        {{ this.posicion }}
                      </p>

                      <h5
                        v-if="checkbox == 0"
                        style="color:black; text-align:center; "
                      >
                        
                        {{ this.empresaNombre }}
                      </h5>

                      <h3
                        v-if="checkbox == 1"
                        style="color:black; text-align:center; "
                      >
                        
                        Empresa Lider
                      </h3>
                    </v-col>

                    <v-col cols="4" class="pc" v-if="checkbox == 0">
                      <p
                        class="vacanteRango"
                        style="background-color:white; padding:10px;text-align:center; border-radius:12px;"
                      >
                        <strong>
                          Rango de Sueldo
                          <span v-if="baseSalarial == 0"> Mensual</span>
                          <span v-else-if="baseSalarial == 1"> Semanal</span
                          ><br />

                          ${{ this.sueldo_minimo }} - ${{
                            this.sueldo_superior
                          }}
                        </strong>
                      </p>

                      
                    </v-col>
                    <v-col cols="6" class="text-center" v-if="checkbox == 1">
                      <p
                        class="vacantePosicion"
                        style="color:black; text-align:center;"
                      >
                        {{ this.posicion }}
                      </p>

                      <h5
                        v-if="checkbox == 0"
                        style="color:black; text-align:center; "
                      >
                        
                        {{ this.empresaNombre }}
                      </h5>

                      <h3
                        v-if="checkbox == 1"
                        style="color:black; text-align:center; "
                      >
                        
                        Empresa Lider
                      </h3>
                    </v-col>

                    <v-col cols="6" class="pc" v-if="checkbox == 1">
                      <p
                        class="vacanteRango"
                        style="border:1px solid black; padding:10px; border-radius:12px; text-align:center;"
                      >
                        <strong>
                          Rango de Sueldo

                          <br />

                          ${{ this.sueldo_minimo }} - ${{
                            this.sueldo_superior
                          }}
                        </strong>
                      </p>


                    
                    </v-col>
                   
                    <v-row id="pc">

                    <!-- se agrega este renglon -->
                    <v-col cols="4">
                      <div class="text-center" v-if="this.telefono">
                        
                          <a
                     
                     
                      style="background-color:#A8B8CD;color:white;font-size:22px;margin-top:0px;border-radius:17px;"
                      target="_blank"
                        v-if="this.telefono"
                      :href="'tel:' + this.telefono"
                      ><v-icon style="padding:12px;margin-left:-7px;">
                        mdi-phone
                      </v-icon>
                      {{this.telefono}}
                    </a>
                        </div></v-col>
                        <v-col cols="4">
                           <div class="text-center"  v-if="this.whatsap">
                          
                           <a
                       
                      v-if="this.whatsap"
                      style="background-color:#8CDB6B;color:white;font-size:22px;border-radius:17px;"
                      target="_blank"
                      :href="
                        'https://api.whatsapp.com/send/?phone=52' +
                          this.whatsap +
                          '&text=¡Hola!%20vi%20la%20vacante%20' +
                          this.posicion +
                          '%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20más%20información'
                      "
                    >
                      
                                                  <v-icon >mdi-whatsapp</v-icon>

                    
                     {{this.whatsap}}
                    </a>
                        </div>
                        </v-col>
                        <v-col cols="4">
                          <div class="text-center">

                            
<a
                   
                      v-if="this.correo"
                      style="background-color:#EFCC63;color:white;font-size:22px; margin-top:0px; border-radius:10px;"
                      target="_blank"
                      :href="
                        'mailto:' +
                          this.correo +
                          '?Subject=CV%20enviado%20desde%20VACANTA' +
                          '&body=Escribe%20aqui%20el%20nombre%20del%20puesto%20para%20el%20que%20estas%20enviando%20tu%20Cv'
                      "
                      color=""
                    >
                      <v-icon style="padding:12px;margin-left:-7px;">
                        mdi-email
                      </v-icon>
                     {{this.correo}}
                    </a>
                          </div>
                          
                        </v-col>


                  </v-row>
                  </v-row>

                  <v-row id="cel">
                    <v-col cols="4" class="text-center" v-if="checkbox == 0">
                      <div class="imagen">
                        <v-img
                          class="miimagen"
                          :src="this.logos"
                          alt=""
                        ></v-img>
                      </div>
                    </v-col>

                    <v-col cols="8">
                      <div v-if="checkbox == 0" class="text-center">
                        <p
                          
                          style="color:black; text-align:center; "
                        >
                          {{ this.posicion }}
                        </p>

                        <p
                          v-if="checkbox == 0"
                          style="color: black;
text-align: center;
"
                        >
                          {{ this.empresaNombre }}
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="12" class="cel" v-if="checkbox == 1">
                      <div class="text-center">
                        <p
                          class="vacantePosicion"
                          style="color:black; text-align:center; "
                        >
                          {{ this.posicion }}
                        </p>
                        <p
                          v-if="checkbox == 1"
                          style="color:#FFC000;margin-left:-16px; "
                        >
                          <v-icon>mdi-office-building</v-icon>
                          Empresa Lider
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="12" id="cel">
                      <p
                        class="vacanteRango"
                        style="background-color:white; padding:10px;text-align:center; border-radius:12px;"
                      >
                        <strong>
                          Rango de Sueldo
                          <span v-if="baseSalarial == 0"> Mensual</span>
                          <span v-else-if="baseSalarial == 1"> Semanal</span
                          ><br />

                          ${{ this.sueldo_minimo }} - ${{
                            this.sueldo_superior
                          }}
                        </strong>
                      </p>
                     
                    </v-col>
                    <v-col cols="12" class="cel" style="margin-top:-28px;">
                      <div class="text-center">
                        <div v-if="this.telefono">
                          <v-icon                             >mdi-phone</v-icon
                          >
                          <a
                      style="background-color:#A8B8CD;color:white;font-size:16px;margin-top:0px;border-radius:17px;"
                            target="_blank"
                            v-if="this.telefono"
                            :href="'tel:' + this.telefono"
                            >{{ this.telefono }}</a
                          >
                        </div>

                        <div v-if="this.whatsap">
                          <v-icon >mdi-whatsapp</v-icon>
                          <a
                            v-if="this.whatsap"
                      style="background-color:#8CDB6B;color:white;font-size:16px;border-radius:17px;"
                            target="_blank"
                            :href="
                              'https://api.whatsapp.com/send?phone=52' +
                                this.whatsap +
                                '&text=¡Hola!%20vi%20la%20vacante%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20mas%20información.'
                            "
                            >{{ this.whatsap }}</a
                          >
                        </div>

                        <div v-if="this.checkbox == 0">
                          <v-icon>mdi-email</v-icon>
                          <a
                            v-if="this.correo"
                      style="background-color:#EFCC63;color:white;font-size:16px; margin-top:0px; border-radius:10px;"
                            target="_blank"
                            :href="
                              'mailto:' +
                                this.correo +
                                '?Subject=CV%20enviado%20desde%20VACANTA'
                            "
                            >{{ this.correo }}</a
                          >
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                  <v-divider></v-divider>
                <v-container>
                  <v-row>
                    <v-flex xs12 sm12 md4 class="columna3">
                      <br />
                      <h2 class="text-center">
                        Descripción y Requisitos
                      </h2>
                      <br />

                      <v-textarea
                        light
                        
                        background-color="white"
                        outlined
                        v-model="descripcion_detallada"
                        name="tbox"
                        rows="5"
                      ></v-textarea>
                    </v-flex>

                    <v-flex xs12 sm12 md5 class="columna4">
                      <br />
                      <h2 class="text-center">
                        Nivel Educativo Requerido
                      </h2>
                      <br />
                      <div class="text-center">
                        <v-textarea
                          light
                        background-color="white"
                          outlined
                          v-model="nivelPrueba"
                          name="tbox"
                          cols="30"
                          rows="2"
                        ></v-textarea>
                      </div>
                      <v-row style="margin-top:-20px;">
                        <v-col cols="6">
                          <h4
                            style="border:1px solid black; font-size:14px;color:black; border-radius:6px; width:fit-content;padding:5px;"
                          >
                            Jornada:
                            <em style="color:black">
                              {{ this.confirmajornada }}</em
                            >
                          </h4>

                          <br />
                        </v-col>
                        <v-col cols="6">
                          <h4
                            style="border:1px solid black; font-size:14px;color:black; border-radius:6px; width:fit-content;padding:5px;"
                          >
                            Modalidad:
                            <em style="color:black">{{
                              this.confirmaarea
                            }}</em>
                          </h4>
                        </v-col>
                        <v-col cols="12" style="margin-top:-19px;">
                          <h4
                            style="border:1px solid black;text-align:center; font-size:14px;color:black; border-radius:6px; width:fit-content;padding:5px;margin-left:0px;"
                          >
                            Contratación:<em style="color:black">
                              {{ this.confirmacontratacion }}</em
                            >
                          </h4>
                        </v-col>
                      </v-row>
                    </v-flex>

                    <v-row>
                      <v-col md="4" class="text-center">
                        <br />
                        <p>
                          <strong> Lugar</strong>
                        </p>
                        <p
                          style=" border-radius:6px; "
                        >
                          <strong>
                            {{ this.elestado }} - {{ this.laciudad }}
                          </strong>
                        </p>
                      </v-col>
                      <v-col md="4" xs="6" class="text-center">
                        <br />
                        <p><strong> Fecha de publicación</strong></p>

                        <p style=" border-radius:6px;">
                          <strong>
                            {{ moment().format("LL") }}
                          </strong>
                        </p>
                      </v-col>
                      <v-col md="4" xs="12" class="text-center">
                        <br />
                        <p><strong> Área</strong></p>
                        <p
                          v-if="this.model"
                          style=" border-radius:6px;"
                        >
                          <strong>
                            {{ this.model.especialidad }}
                          </strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <div class="text-center">
          <div>
                 <v-btn v-if="this.gratis==0" dark @click="comprar()">
              Comprar
            </v-btn>
            <v-btn v-else color="primary" @click="prueba()">
              Confirmar 
            </v-btn>
            <!-- <v-btn v-else style="background-color:#f2441d;" @click="comprar()">
              Comprar
            </v-btn> -->
            <v-btn @click="e1 = 2" style="margin-left:5px;" dark>
              Anterior
            </v-btn>
          </div>
        </div>
      </v-stepper-content>
      <v-stepper-content step="4">
        <v-card class="mb-12 " color="#e0e0e0">
          <v-row>
            <v-col cols="12">
              <div class="text-center" style="padding:20px">
                <h3 class="text-center" style="font-size:24px;">Finalizado</h3>

                <p style="text-align:center;color:rgb(26 115 232); font-size:22px;">
                 <strong>
                  ¡La vacante se publico correctamente!
                 </strong>
                </p>
                
                <div class="text-center" style="background-color:#2afed6;height:250px; width:100%;">
                  
                 <p style="text-align:center;color:black; font-size:22px;padding:14px;">
                 <strong>
                  ¡Haz que tu próxima vacante sea más llamativa y dure más tiempo!
                 </strong>
                </p>
                <div class="text-center" style="background-color:black;height:120px; width:220px; display:block;margin:auto; ">
                  <p style="margin-top:10px;text-align:center;color:white; font-size:22px;;">
                 <strong>
                  Vacante Elite
                 </strong>
                </p>
                  <v-btn @click="comprar()" style="margin-top:10px; color:black;" color="secondary">
                    Comprar
                  </v-btn>

                </div>
                
                </div>
                <br />
                <br />
              
                <p style="text-align:center;color: black;">
                  <strong>
                    Puedes desactivar la vacante cuando lo desees en “Mis
                    Vacantes Profesionistas” y ya no se mostrara mas en el
                    listado de vacantes.
                  </strong>
                </p>
              </div>
              <div class="text-center">
              <v-btn color="primary" @click="salir()">
          Salir
        </v-btn>
            </div>
            </v-col>
            
          </v-row>
        </v-card>

        
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import Estado from "../Buscador/Estado.vue";
import Ciudad from "../Buscador/Ciudad.vue";
import Giro from "../Buscador/Giro.vue";
import Sector from "../Buscador/Sector.vue";
import HabilidadesAdministrativo from "../Buscador/HabilidadesAdministrativo.vue";

import nivelEducativo from "../Buscador/nivelEducativo.vue";
var moment = require("moment");
import axios from "axios";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      SueldoBase: null,
      errors: [],
      correo: [],
      errores: [],
      erroros: [],
      baseSalarial: null,
      valida: true,
      valid: true,
      valorSencilla: null,
      valorPremium: null,
      isLoading: false,
      items: [],
      model: null,
      search: null,
      mask: "##########",
      tab: null,
      carrera: "",
      cp: null,
      previousLength: 0,
      value: "1234567.89",
      options: {
        locale: "ja-JP",
        prefix: "$",
        suffix: "",
        length: 10,
        precision: 2,
      },
      searchResult: null,
      searchString: "",
      selected: "",
      menu: false,
      modal: false,
      menu2: false,
      moment: moment,
      logos: null,
      e1: 1,
      posicion: "",
      gratis: null,
      descripcion_corta: "",
      descripcion_detallada: "",
      fecha_inicio: "",
      sueldo_minimo: null,
      sueldo_superior: null,
      correo: "",
      contratacion: "",
      confirmacontratacion: "",
      confirmajornada: "",
      confirmaarea: "",
      habilidades: [],
      whatsap: null,
      telefono: null,
      jornada: "",
      area: "",
      checkbox: null,
      sector: "",
      confirmapublicacion: null,
      confirmaAnonima: null,
      giro: "",
      nivel: "",
      sueldo: 10,
      edad_minima: null,
      edad_maxima: null,
      años_experiencia: "",
      tipoVacante: "",
      creditos: "",
      empresaNombre: "",
      hola: 0,
      token:
        "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",

      sueldo_mayor: [
        // (y) => y > this.sueldo_minimo || "Tiene que ser mayor que el minimo",
      ],
      reglasPosicion: [(v) => v.length <= 45 || "Máximo 45 Caracteres"],
      reglasTel: [(v) => v.length <= 11 || "Máximo 10 Caracteres"],
    };
  },
  watch: {},
  mounted() {
      axios
          .post(
            "/vacantes/vacantegratis",
            {
              empresa: this.$store.state.id.empresa,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            console.log(response.data)
            this.gratis = response.data[0].vacantes;

          })

          .catch((error) => {
            this.gratis = 0;
          });
    this.$store.dispatch("autoLogin");
    axios
      .get("/catalogos/confvacante/1", {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      })
      .then((response) => {
        this.valorSencilla = response.data[0].creditos;
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post(
        "/empresas/perfilempresa",
        {
          empresa: this.$store.state.id.empresa,
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        }
      )
      .then((response) => {
        this.empresaNombre = response.data[0].nom_comercial;
        this.correo = response.data[0].correo;
        this.logos = response.data[0].logo;
      });
    axios
      .get("/catalogos/confvacante/2", {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      })
      .then((response) => {
        this.valorPremium = response.data[0].creditos;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/creditos/creditosempresa/" + this.$store.state.id.empresa, {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      })
      .then((response) => {
        this.creditos = response.data.creditos;
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/catalogos/especialidades", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.items = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  computed: {
     id() {
      return this.$store.state.id;
    },
    ...mapState([
      "elestado",
      "laciudad",
      "elPais",
      "id",
      "elidioma",
      "lainstitucion",
      "folioinstitucion",
      "nuevahabilidad",
      "nuevogiro",
      "nuevosector",
      "lahabilidad",
      "nivelPrueba",
      "nuevaespecialidad",
    ]),
  },
  components: {
    Estado,
    Ciudad,
    Sector,
    Giro,
    nivelEducativo,
    HabilidadesAdministrativo,
  },
  methods: {
    comprar() {
      this.$router.push({ name: "ComprarCreditos" });
    },
    validacion2() {
      if (this.elestado && this.laciudad && this.nivelPrueba && this.model) {
        this.e1 = 3;
      } else {
        this.errores = [];
        if (!this.elestado) this.errores.push("Estado.");
        if (!this.model) this.errores.push("Área.");
        if (!this.laciudad) this.errores.push("Ciudad.");
        if (!this.nivelPrueba) this.errores.push("Nivel Educativo.");
      }
    },
    validacion1() {
      this.errors = [];

      if (
        this.posicion &&
        this.telefono &&
        this.descripcion_detallada 

      ) {
        this.e1 = 2;
      }

      if (!this.posicion) this.errors.push("Posición.");
      if (!this.telefono) this.errors.push("Teléfono.");
      if (!this.descripcion_detallada)
        this.errors.push("Descripción Detallada.");
    },
    verifica3() {
      if (this.confirmapublicacion) {
        this.e1 = 4;
      } else {
        this.erroros = [];
        if (!this.confirmapublicacion)
          this.erroros.push("No has especificado el tipo de publicación");
      }
    },
    pruebas(e) {
      console.log(e);
    },
    agregarHabilidad() {
      this.habilidades.push({
        habilidad: this.lahabilidad,
      });
    },
    salirVacantes() {
      this.$router.push({ name: "EmpresasVacantes" });
    },
    verificarcontratacion() {
      if (this.contratacion) {
        this.confirmacontratacion = "Temporal";
      } else {
        this.confirmacontratacion = "Indeterminado";
      }
    },

    verificarbase() {
      if (this.baseSalarial == 0) {
        this.confirmabase = 0;
      } else {
        this.confirmabase = 1;
      }
    },
    verificarjornada() {
      if (this.jornada == 0) {
        this.confirmajornada = "Completa";
      }
      if (this.jornada == 1) {
        this.confirmajornada = "Medio Tiempo";
      }
    },
    verificararea() {
      if (this.area == 0) {
        this.confirmaarea = "Presencial";
      }
      if (this.area == 1) {
        this.confirmaarea = "Hibrida";
      }
      if (this.area == 2) {
        this.confirmaarea = "Home Office";
      }
    },
    verificatipoPublicacion() {
      if (this.tipoVacante == 0) {
        this.confirmapublicacion = 1;
      }
      if (this.tipoVacante == 1) {
        this.confirmapublicacion = 2;
      }
    },
    verificaAnonima() {
      if (this.checkbox == 0) {
        this.confirmaAnonima = false;
      } else if (this.checkbox == 1) {
        this.confirmaAnonima = true;
      }
    },
    ...mapMutations(["setEspecialidad", "setsubEspecialidad"]),
    logueado() {
      return this.$store.state.token;
    },
    cambiar() {
      this.e1 = 1;
    },
    cambiar2() {
      this.e1 = 2;
    },

    cambiar3() {
      this.e1 = 3;
    },
    cambiar4() {
      this.e1 = 4;
    },
    cambiar5() {
      this.e1 = 5;
    },
    cambiar6() {
      this.e1 = 6;
    },
    cambiar7() {
      this.e1 = 7;
    },

    prueba() {
      console.log(
        "empresa:",
        this.$store.state.id.empresa,
        "usuario: ",
        this.$store.state.id.id,
        "origen: ",
        1,
        "posicion:",
        this.posicion,
        "dcorta: ",
        "null",
        "desc: ",
        this.descripcion_detallada,
        "sinf: ",
        this.sueldo_minimo,
        "ssup: ",
        this.sueldo_superior,
        "edo: ",
        this.elestado,
        "cd: ",
        this.laciudad,
        "cp: ",
        3111,
        "tcon:",
        this.confirmacontratacion,
        "tjor:",
        this.confirmajornada,
        "tare: ",
        this.confirmaarea,
        "tpub: ",
        1,
        "esp: ",
        this.nuevaespecialidad.folio,
        "sube: ",
        1,
        "einf: ",
        "1",
        "esup:",
        "1",
        "exp:",
        "1",
        "nedu: ",
        this.nivelPrueba,
        "anonimo: ",
        this.confirmaAnonima,
        "tel: ",
        this.telefono,
        "whats: ",
        this.whatsap,
        "base: ",
        this.confirmabase
      );

      axios
        .post(
          "/vacantes/creavacante",
          {
            empresa: this.$store.state.id.empresa,
            usuario: this.$store.state.id.id,
            origen: 1,
            posicion: this.posicion,
            dcorta: null,
            desc: this.descripcion_detallada,
            sinf: this.sueldo_minimo,
            ssup: this.sueldo_superior,
            edo: this.elestado,
            cd: this.laciudad,
            cp: 3111,
            tcon: this.confirmacontratacion,
            tjor: this.confirmajornada,
            tare: this.confirmaarea,
            tpub: 1,
            esp: this.nuevaespecialidad.folio,
            sube: 1,
            einf: 25,
            esup: 55,
            exp: 1,
            nedu: this.nivelPrueba,
            anonimo: this.confirmaAnonima,
            tel: this.telefono,
            base: this.confirmabase,
                whats: this.whatsap,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.e1 = 4;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salir() {
      this.$router.push({ name: "EmpresasVacantes" });
    },
  },
};
</script>

<style>
.vacantePosicion {
  font-size: 34px !important;
}
@media (max-width: 950px) and (orientation: landscape) {
  .botonCreditos {
    margin-bottom: 15px;
  }
  .botonComprar {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 768px) {
  /* Este es el celular */
  .headernegro {
    height: 320px !important;
  }
  #pc {
    display: none;
  }
}

@media only screen and (min-width: 868px) {
  .headernegro {
    height: 200px !important;
  }

  .miimagen {
    border-radius: 40px;
  }
  .imagen {
    border: 4px solid white;
    width: 120px;
    height: 120px;
    border-radius: 40px;
    display: flex;
    margin-left: 20px;
    background-color: #ddd;
  }
  .mitarjeta {
    margin-top: -75px;
    height: 160px;
  }
  .vacantePosicion {
    font-size: 28px !important;
  }

  .micontenedor {
    margin-top: 210px;
  }
  #cel {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .vacantePosicion {
    font-size: 34px !important;
  }
  .pequeño {
    font-size: 10px !important;
  }
  .micontenedor {
    margin-top: 260px;
  }
  .mitarjeta {
    margin-top: -65px;
    height: 220px;
  }
  .vacanteRango {
    font-size: 13px;
  }
  .large .v-radio {
    padding: 10px;
  }

  .large i {
    font-size: 48px;
  }

  .large label {
    padding-left: 24px;
  }

  .large [class*="__ripple"] {
    left: 0;
  }
  #pc {
    display: none;
  }
}
</style>
