<template>

        <v-select
          :items="items"
          label="Sexo"
          outlined
          v-model="model"
          :click="setSexo(model)"

        ></v-select>
     
</template>
<script>
 import { mapMutations, mapState } from 'vuex'
  export default {
    data: () => ({
      items: ['H', 'M','Indistinto'],
      model:'',
    }),
      computed:{
      ...mapState (['nuevoSexo'])
    },
     methods:{
       ...mapMutations (['setSexo'])
     }
  }
</script>