<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8" md="8">
        <v-card>
          <v-toolbar class="text-h5 text-center primary darken-1 " dense>
            <v-img
              alt="Logo"
              id="milogo"
              class="shrink mr-2 "
              contain
              src="../assets/pngblanco.png"
              transition="scale-transition"
              width="120"
            />
            <v-spacer></v-spacer>
            <v-toolbar-title>Datos del Cliente</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon></v-icon>
            </v-btn>

            <v-btn icon>
              <v-icon></v-icon>
            </v-btn>

            <v-btn icon>
              <v-icon></v-icon>
            </v-btn>
          </v-toolbar>

          <v-container>
            <div class="text-center">
              <p style="padding:-2px;">{{ this.nombre }}</p>
              <p style="padding:-2px;margin-top:-10px;">{{ this.rfc }}</p>
              <p style="padding:-2px;margin-top:-10px;">{{ this.calle }}, {{ this.numero }}</p>
              <p style="padding:-2px;margin-top:-10px;">{{ this.evento }}, {{ this.mievento }}</p>
              <p style="padding:-2px;margin-top:-10px;">{{ this.eventoregimen }}</p>
              <p style="padding:-2px;margin-top:-10px;">{{ this.uso }}</p>
              <p style="padding:-2px;margin-top:-10px;">{{ this.correo }}</p>
            </div>
          </v-container>

          <v-row>
            <v-col>
              <div class="text-center">
                <v-dialog v-model="holas" width="700">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      style="margin-top:30px;color:black;"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Editar Datos
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 primary text-center darken-1">
                      Datos Facturación
                    </v-card-title>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              label="Razon Social"
                              hide-details="auto"
                              v-model="nombre"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="rfc"
                              name="rfc"
                              height="5"
                              outlined
                              type="text"
                              color="primary"
                              label="R.F.C"
                              v-mask="mask2"
                              class="rfc"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete
                              outlined
                              v-model="eventoregimen"
                              label="Régimen Fiscal"
                              clearable
                              :items="regimenes"
                              item-text="descripcion"
                              item-value="descripcion"
                              autocomplete="nope"
                              color="primary"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" style="margin-top:-30px;">
                            <v-text-field
                              label="Calle"
                              hide-details="auto"
                              v-model="calle"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              v-model="numero"
                              label="Numero"
                              hide-details="auto"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field
                              v-model="colonia"
                              label="Colonia"
                              hide-details="auto"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Codigo Postal"
                              hide-details="auto"
                              v-model="cp"
                              outlined
                              v-mask="mask"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete
                              outlined
                              v-model="evento"
                              :click="setState(evento)"
                              label="Estado"
                              clearable
                              :items="estados"
                              item-text="estado"
                              item-value="value"
                              autocomplete="nope"
                              color="primary"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete
                              outlined
                              v-model="mievento"
                              :click="setCiudad(mievento)"
                              label="Ciudad"
                              clearable
                              :items="ciudades"
                              item-text="municipio"
                              item-value="ciudad"
                              autocomplete="nope"
                              color="primary"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="6" style="margin-top:-30px;">
                            <v-autocomplete
                              outlined
                              :items="items"
                              placeholder="Uso de CFDI"
                              item-text="descripcion"
                              item-value="folio"
                              v-model="uso"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="6" style="margin-top:-30px;">
                            <v-text-field
                              label="Correo"
                              hide-details="auto"
                              v-model="correo"
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-container>
                        <div class="text-center">
                          <v-btn
                            color="primary darken-1"
                            @click="holas = false"
                            style="color:black;"
                          >
                            Cancelar
                          </v-btn>
                        </div>
                      </v-container>
                      <v-spacer></v-spacer>
                      <v-container>
                        <div class="text-center">
                          <v-btn
                            :disabled="!valid"
                            color="primary darken-1"
                            @click="actualizoDatos()"
                            style="color:black;"
                          >
                            Guardar
                          </v-btn>
                        </div>
                      </v-container>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="4" md="4">
        <v-card>
          <v-toolbar class="text-h5 text-center primary darken-1 " dense>
            <v-icon style="color:black;">mdi-book-check-outline</v-icon>
            <v-spacer></v-spacer>

            <v-toolbar-title>Orden No</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              large
              style="background-color:white;border-radius:18px;color: red;font-size:20px;"
            >
              00{{ this.$store.state.id.empresa }}
            </v-btn>

            <v-btn icon> </v-btn>

            <v-btn icon>
              <v-icon></v-icon>
            </v-btn>
          </v-toolbar>

          <v-container
            ><v-row>
              <v-col>
                <div class="text-center">
                  <p>Fecha del Recibo: {{ moment(fecha).format("LL") }}</p>
                  <p>
                    Fecha de Vencimiento:
                    {{
                      moment()
                        .add(15, "days")
                        .format("LL")
                    }}
                  </p>
                </div>
              </v-col>
            </v-row></v-container
          >

          <v-toolbar class="text-h5 text-center primary darken-1 " dense>
            <v-spacer></v-spacer>

            <v-toolbar-title>Pagar a</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container
            ><v-row>
              <v-col>
                <div class="text-center">
                  <p>OPERADORA DE ROCHEN DE MEXICO ORM210518H18</p>
                  <p>CLABE: 136150259832200135</p>
                </div>
              </v-col>
            </v-row></v-container
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-toolbar class="text-h5 text-center primary darken-1 " dense>
          <p style="margin-top:15px;">Descripción</p>
          <v-spacer></v-spacer>
          <v-toolbar-title></v-toolbar-title>

          <v-spacer></v-spacer>

          <p style="margin-top:15px;">Importe</p>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
      <v-col cols="8">
        <p style="margin-left:10px; background-color:white; padding:6px">
          Vacanta Light
        </p>
        <p style="margin-left:10px; background-color:white; padding:6px">
          16.00% IVA:
        </p>
        <p style="margin-left:10px;  padding:6px">Descuento:</p>
        <v-divider></v-divider>

        <p style="margin-left:10px;  padding:6px;font-size:20px;">TOTAL</p>
      </v-col>
      <v-col cols="4">
        <p style="margin-left:10px;  padding:6px; border-radius:10px;">
          $ 490.00 MXN
        </p>
        <p style="margin-left:10px; background-color:white; padding:6px">
          $ 78.40 MXN
        </p>
        <p style="margin-left:10px;  padding:6px">$ 0.00 MXN</p>
        <v-divider></v-divider>

        <p style="margin-left:10px;  padding:6px; font-size:20px;">$ 780.40 MXN</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
var moment = require("moment");
moment.locale("es-mx");
import axios from "axios";
import { mapMutations, mapState } from "vuex";

const hoy = moment();
let foto;

export default {
  name: "PerfilEmpresas",
  data() {
    return {
      uso: null,
      calle: null,
      numero: null,
      colonia: null,
      mask2: "AAAN#####NNNN",
      mask: "#####",
      valid: true,
      mievento: null,
      estados: [],
      regimenes: [],
      eventoregimen: null,
      holas: false,
      evento: "",
      moment: moment,
      vacanteFolio: null,
      usuario: null,
      file: null,
      avisoFoto: "",
      errorM: "",
      avisoVitae: "",
      errorVitae: "",
      foto: "",
      nombre: "",
      prueba: 0,
      apellido: "",
      items: [],
      puesto: "",
      estado: "",
      ciudad: "",
      activo: "",
      salarioMayor: "",
      salarioMenor: "",
      celular: "",
      nacimiento: "",
      correo: "",
      curriculum: null,
      cp: "",
      num_empleados: null,
      web: null,
      rfc: null,
      token:
        "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
      razonsocial: null,
      ciudades: [], // <-- La lista de especialidades
      mievent: null,
      rulesCP: [(v) => v.length == 5 || "El C.P. debe ser de 5 digitos"],
      rulesNum: [(v) => v.length <= 10 || "El Máximo son 10 digitos"],
    };
  },

  beforeMount() {
    this.$store.dispatch("autoLogin");
    /*   axios
      .post(
        "/empresas/perfilempresa",
        {
          empresa: this.$store.state.id.empresa,
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        }
      )
      .then((response) => {
        //
        this.cp = response.data[0].cp;
        this.foto = response.data[0].logo;
        this.nombre = response.data[0].nom_comercial;
        this.num_empleados = response.data[0].num_empleados;
        this.razonsocial = response.data[0].razonsocial;
        this.rfc = response.data[0].rfc;
        this.web = response.data[0].web;
        this.evento = response.data[0].estado;
        this.mievento = response.data[0].ciudad;
        this.correo = response.data[0].correo;
      })
      .catch((error) => {
        console.log(error);
      });  Actualizo datos al entrar a la pagina*/
  },
  mounted() {
    axios
      .get("/catalogos/estados", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.estados = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/catalogos/regimenes", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.regimenes = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    ...mapState(["id", "miempresa", "elestado", "laciudad", "precio"]),
    logueado() {
      return this.$store.state.token;
    },
  },
  watch: {
    elestado: function(value) {
      axios
        .get("/catalogos/ciudades/" + this.elestado, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.ciudades = response.data;
        })
        .catch((error) => {});
    },
  },
  methods: {
    ...mapMutations(["setState", "setCiudad"]),

    rerender() {
      this.prueba++;
    },
    actualizoDatos() {
      axios
        .post(
          "/empresas/capturaDatosFiscales",
          {
            empresa: this.$store.state.id.empresa,
            rfc: this.rfc,
            razonsocial: this.nombre,
            direccion: this.calle,
            noext: this.numero,
            noint: "",
            colonia: this.colonia,
            estado: this.evento,
            ciudad: this.mievento,
            cp: this.cp,
            email: this.email,
            usocfdi: this.num_empleados,
            regimen: this.eventoregimen,
            municipio: "",
            localidad: "",
            pais: "",
            tel: "",
            limcre: "",
            diacred: "",
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .then((data) => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.tarjeta-postulaciones {
  padding: 25px;
  border-radius: 15px;
  background-color: #2afed6;
}
.tarjeta-trabajos {
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey;
}
.tarjeta-trabajos {
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey;
}
.hola {
  border-radius: 25px;
}
.nombre {
  color: white;
  margin-left: -196px;
  margin-top: 16px;
  font-size: 20px;
}
#espacio {
  display: none;
}
.miimagen {
  border-radius: 40px;
}
.imagen {
  border: 4px solid white;
  width: 120px;
  height: 120px;
  margin-top: 11px;
  border-radius: 40px;
  display: flex;
  margin-left: 120px;
  background-color: #ddd;
}
.gris {
  background-color: #2afed6;
  width: 100%;
  height: 200px;
  border-radius: 25px;
}
.estado {
  margin-left: 390px;
}
.percepcion {
  margin-top: -5px;
  margin-left: 395px;
}
.trabajando {
  margin-top: -5px;
  margin-left: 395px;
}
.idiomas {
  margin-top: -25px;
  margin-left: 395px;
}
@media only screen and (max-width: 768px) {
  .imagen {
    border: 4px solid white;
    width: 100px;
    height: 100px;
    margin-top: 20px;
    border-radius: 40px;
    display: flex;
    margin-left: -0px;
    background-color: #ddd;
  }
  .nombre {
    color: white;
    margin-left: -30px;
    font-size: 24px;
    text-align: center;
    margin-top: 0px;
  }
  .titulo {
    margin-top: 10px;
    margin-left: -39px;
    text-align: center;
    font-size: 15px;
  }
  .estado {
    margin-left: 50px;
    margin-bottom: 10px;
  }

  .espacio {
    display: initial;
  }
  .percepcion {
    margin-left: 50px;
  }
  .trabajando {
    margin-left: 50px;
    margin-top: 0px;
  }
  .idiomas {
    margin-left: 50px;
    margin-top: -30px;
  }
  .gris {
    background-color: #2afed6;

    height: 230px;
    border-radius: 25px;
  }
}
@media only screen and (max-width: 768px) {
  /* todo lo de cel */
  .imagen {
    border: 4px solid white;
    width: 100px;
    height: 100px;
    margin-top: -6px;
    border-radius: 40px;
    display: flex;
    margin-left: -6px;
    background-color: #ddd;
  }
}
</style>
