<template>
  
    <v-autocomplete
      v-model="event"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      hide-details
      autocomplete="nope"
      clearable
      hide-selected
      item-text="sub"
      item-value="symbol"
      label="Sub-Área"
      color="primary"
      outlined
      :click="setsubEspecialidad(event)"
      return-object
       @change="search = ''"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Busca tu 
            <strong>Sub Especialidad </strong>
          </v-list-item-title>
        </v-list-item>
      </template>
   
      <template v-slot:item="{ item }">
        <v-list-item-avatar
          color="#14a6a6"
          class="text-h5 font-weight-light white--text"
        >
          {{ item.sub.charAt(0) }}
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.sub"></v-list-item-title>
          <v-list-item-subtitle v-text="item.symbol"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
      </template>
</template>
<script>

 import { mapMutations, mapState } from 'vuex'

  export default {
    data: () => ({
      isLoading: false,
      items: [],
      event: '',
      search: null,
      tab: null,
    }),
       computed:{
      ...mapState (['nuevaespecialidad','nuevasubespecialidad'])
    },
     methods:{
       ...mapMutations (['setsubEspecialidad'])
     },


    watch: {
      event (val) {
        if (val != null) this.tab = 0
        else this.tab = null
      },
      search (val) {
        // Items have already been loaded
        if (this.items.length > 0) return

        this.isLoading = true

        // Lazily load input items
        fetch('http://tisdcsecondary.ddns.net:8000/api/catalogos/subespe/' + this.nuevaespecialidad.folio)
          .then(res => res.clone().json())
          .then(res => {
            this.items = res
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
       select() {
    this.search = ''
  }
    },
  }
</script>