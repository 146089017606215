<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
        Información
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step @click=saltar2() :complete="e1 > 2" step="2">
        Confirmación
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        Finalizado
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="mb-18" color="grey lighten-1" height="620px">
          <div style="padding:20px">
            <h3 class="text-center">Nuevo Anuncio</h3>

            <p style="text-align:center;">
Llena la información del anuncio y da click en continuar            </p>
            <br />
            <v-alert
              icon="mdi-alert"
              color="primary"
              style="position:fixed; z-index:999; margin-top:-200px;"
              v-if="errors.length"
            >
              <b>Por favor llena los siguientes campos:</b>
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </v-alert>

            <v-row>
              <v-col cols="12">
                <template>
                  <v-text-field
                    v-model="posicion"
                    outlined
                    label="Titulo del servicio que ofreces (Ej. Carpintero, Maestro de Ingles)"
                    required
                  ></v-text-field>
                </template>

                <div></div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-mask="mask"
                  v-model="telefono"
                  outlined
                  label="Teléfono"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-mask="mask"
                  v-model="whats"
                  outlined
                  label="WhatsApp"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-textarea
                      v-bind="attrs"
                      v-on="on"
                      v-model="descripcion_detallada"
                      outlined
                      label="Descripción de tus servicios"
                      required
                    >
                    </v-textarea>
                  </template>
                  <span
                    >Se recomienda que agregues el texto en forma de lista,<br />
                    especificando los servicios que ofreces
                  </span>
                </v-tooltip>
              </v-col>
              <v-col cols="12">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-autocomplete
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      v-model="model"
                      :click="setEspecialidad(model)"
                      label="Área al que pertenece el servicio"
                      clearable
                      :items="items"
                      item-text="area"
                      item-value="area"
                      autocomplete="nope"
                      color="primary"
                      return-object
                    ></v-autocomplete>
                  </template>
                  <span>Selecciona al área al que pertenece tu Anuncio</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <br>
        <div class="text-center">
          <v-btn color="primary" @click="valida()">
            continuar
          </v-btn>

          
        </div>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card class="mb-12 tarjeton" color="grey lighten-1">
          <v-card
            class="tarjeta"
            style="padding:0px !important; border-radius:20px;"
          >
            <v-container fluid class="headernegro">
              <v-row>
                <!--cel -->

                <v-col cols="5" class="cel">
                  <div v-if="this.foto" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="this.foto"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>

                <v-col cols="7" id="cel">
                  <div class="text-center">
                    <p
                      class="vacantePosicion"
                      style="color:white; text-align:center; "
                    >
                      {{ this.posicion }}
                    </p>

                    <p
                      style="color: rgb(255, 192, 0);
text-align: center;
"
                    >
                      {{ this.nombre }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" id="cel"> </v-col>
                <v-col cols="12" class="cel" style="margin-top:-28px;">
                  <div class="text-center">
                    <p class="text-center" style="color:white;font-size:18px;">
                      <strong> Da click para contactarte</strong>
                    </p>
                    <div v-if="this.telefono">
                       <v-icon style="color:yellow;margin-top:-10px;"
                            >mdi-phone</v-icon
                          >
                          <a
                            style="color:white;font-size:14px;margin-top:-20px;"
                            target="_blank"
                            v-if="this.telefono"
                            class="pc"
                            :href="'tel:' + this.telefono"
                            >{{ this.telefono }}</a
                          >
                     
                    </div>

                   <div v-if="this.whats">
                       <v-icon style="color:green">mdi-whatsapp</v-icon>
                    <a
                            
                            v-if="this.whats"
                            style="color:white;font-size:14px;"
                            target="_blank"
                            :href="
                              'https://api.whatsapp.com/send?phone=52' +
                                this.whats +
                                '&text=¡Hola!%20vi%20la%20vacante%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20mas%20información.'
                            "
                            >{{ this.whats }}</a>
                     
                      
        </div>
                    <div>
                       <v-icon style="color:red">mdi-mail</v-icon>
                        <a
                         
                          v-if="this.correo"
                          style="color:white;font-size:14px;"
                          target="_blank"
                          :href="
                            'mailto:' +
                              this.correo +
                              '?Subject=CV%20enviado%20desde%20VACANTA'
                          "
                          >{{ this.correo }}</a
                        >
                     
                     
                    </div>
                  </div>
                </v-col>

                <!-- PC -->

                <v-col cols="2" class="pc">
                  <div v-if="this.foto" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="this.foto"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>

                <v-col cols="6" class="text-center pc">
                  <p
                    class="vacantePosicion"
                    style="color:white; text-align:center; "
                  >
                    {{ this.posicion }}
                  </p>

                  <h3 style="color:#FFC000;" class="vacanteEmpresa text-center">
                    <v-icon>mdi-office-building</v-icon>

                    {{ this.nombre }}
                  </h3>
                </v-col>
                <v-col cols="4" class="pc">
                  <div class="text-center">
                    <p
                      class="text-center"
                      style="color:white;font-size:18px;margin-top:40px"
                    >
                      <strong>Da click para contactarte</strong>
                    </p>
                    <div v-if="this.telefono">
                       <v-icon style="color:yellow;margin-top:-10px;"
                            >mdi-phone</v-icon
                          >
                          <a
                            style="color:white;font-size:14px;margin-top:-20px;"
                            target="_blank"
                            v-if="this.telefono"
                            class="pc"
                            :href="'tel:' + this.telefono"
                            >{{ this.telefono }}</a
                          >
                      
                      
                      
                      
                    </div>

                    <div v-if="this.whats">
                                               <v-icon style="color:green">mdi-whatsapp</v-icon>

                     <a
                            
                            v-if="this.whats"
                            style="color:white;font-size:14px;"
                            target="_blank"
                            :href="
                              'https://api.whatsapp.com/send?phone=52' +
                                this.whats +
                                '&text=¡Hola!%20vi%20la%20vacante%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20mas%20información.'
                            "
                            >{{ this.whats }}</a>
                     
                      
                    </div>

                  <v-icon style="color:red">mdi-mail</v-icon>
                        <a
                          class="pc"
                          v-if="this.correo"
                          style="color:white;font-size:14px;"
                          target="_blank"
                          :href="
                            'mailto:' +
                              this.correo +
                              '?Subject=CV%20enviado%20desde%20VACANTA'
                          "
                          >{{ this.correo }}</a
                        >

                   
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid style="background-color:#dddddd">
              <v-row> </v-row>

              <!-- <-- aqui esta el proble -->
              <v-row>
                <v-flex xs12 sm12 md6 class="columna3">
                  <br />
                  <h2 class="text-center">
                    Mis Servicios
                  </h2>
                  <br />
                  <v-textarea
                    dark
                    background-color="#028008"
                    outlined
                    v-model="this.descripcion_detallada"
                    name="tbox"
                    rows="5"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md5 class="columna4">
                  <br />
                  <h2 class="text-center">
                    Conóceme
                  </h2>
                
                  <div class="text-center" v-if="this.web" style="margin-top:20px;">
                    
                    <v-btn
                       
                        v-if="this.web"
                        style="color:black;font-size:14px; margin-top:4px; border-radius:17px;width:200px;"
                        target="_blank"
                        :href=" this.web"
                        color="white"
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-web
                        </v-icon>
                        Página Web
                      </v-btn>
                    
                  </div>

                  <div class="text-center" v-if="this.facebook" style="margin-top:20px;">
                  
                    <v-btn
                       
                        v-if="this.facebook"
                        style="color:white;font-size:14px; margin-top:4px; border-radius:17px;width:200px;"
                        target="_blank"
                        :href="'https://www.facebook.com/' + this.facebook"
                        color="#3B5998"
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-facebook
                        </v-icon>
                        Facebook
                      </v-btn>
                  </div>

                  <div class="text-center" v-if="this.instagram" style="margin-top:20px;">
                    
                    <v-btn
                       
                        v-if="this.instagram"
                        style="color:white;font-size:14px; margin-top:4px; border-radius:17px;width:200px;"
                        target="_blank"
                        :href="'https://www.instagram.com/' + this.instagram"
                        color="#D23693"
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-instagram
                        </v-icon>
                        Instagram
                      </v-btn>
                  </div>
                </v-flex>
              </v-row>
              <!-- <-- aqui esta el problecel-->

              <v-row>
                <v-col md="6" class="text-center">
                  <p>
                    <strong> Lugar</strong>
                  </p>
                  <p style="background-color:rgb(255, 192, 0); border-radius:6px; ">
                    <strong>
                      {{ this.ciudad }} ,
                      {{ this.estado }}
                    </strong>
                  </p>
                </v-col>

                <v-col md="6" xs="12" class="text-center">
                  <p><strong> Área: </strong></p>
                  <p
                    v-if="this.model"
                    style="background-color:rgb(255, 192, 0); border-radius:6px;"
                  >
                    <strong>
                      {{ this.model.area }}
                    </strong>
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-card>
        <div class="text-center">
          <v-btn color="primary" @click="creaServicio()">
            continuar
          </v-btn>

          <v-btn @click="e1 = 1" text>
            cancelar
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="mb-12" color="grey lighten-1" height="200px">
          <div style="padding:20px">
            <h3 class="text-center">Confirmación</h3>

            <p style="text-align:center;">
              Tu servicio ha sido publicado correctamente, se añadió el servicio a la lista de tus anuncios.

            </p>
          </div>
        </v-card>
        <div class="text-center">
          <v-btn color="primary" @click="salir()">
            Salir
          </v-btn>

        
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      dialogWhats: false,
      dialogoC: false,
      mask: "##########",
      telefono: null,
      whats: null,
      e1: 1,
      posicion: null,
      descripcion_detallada: null,
      model: null,
      items: [],
      foto: null,
      nombre: null,
      correo: null,
      whatsapp: null,
      celular: null,
      ciudad: null,
      estado: null,
      instagram: null,
      facebook: null,
      web: null,
      errors: [],
      token:
        "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
    };
  },
  methods: {
    saltar2(){
      this.e1=2;
    },
    valida() {
      this.errors = [];

      if (
        this.posicion &&
        this.model &&
        this.descripcion_detallada &&
        this.telefono
      ) {
        this.e1 = 2;
      }

      if (!this.posicion) this.errors.push("Posición.");
      if (!this.telefono) this.errors.push("Teléfono.");
      if (!this.descripcion_detallada)
        this.errors.push("Descripción y Requisitos.");
      if (!this.model) this.errors.push("Área al que pertenece el servicio.");
    },
    salir() {
      this.$router.push({ name: "MisServicios" });
    },
    ...mapMutations(["setEspecialidad"]),
    creaServicio() {
      console.log(
        "empresa: ",
        this.$store.state.id.id,
        "dcorta: ",
        this.posicion,
        "desc: ",
        this.descripcion_detallada,
        "area: ",
        this.model.folio,
        "whats: ",
        this.whats,
        "tel: ",
        this.telefono
      );
      axios
        .post(
          "/servicios/creaservicio",
          {
            empresa: this.$store.state.id.id,
            dcorta: this.posicion,
            desc: this.descripcion_detallada,
            area: this.model.folio,
            whats: this.whats,
            tel: this.telefono,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.e1 = 3;
        })
        .catch((error) => {
          console.log("error");
        });
    },
  },
  mounted() {
    this.$store.dispatch("autoLogin");
    axios
      .post(
        "/servicios/perfilEmpresaServicio",
        {
          empresa: this.$store.state.id.id,
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        }
      )
      .then((response) => {
        console.log(response.data)
        this.foto = response.data[0].logo;
        this.nombre = response.data[0].nom_comercial;
        this.celular = response.data[0].celular;
        this.whatsapp = response.data[0].whatsapp;
        this.correo = response.data[0].correo;
        this.ciudad = response.data[0].ciudad;
        this.estado = response.data[0].estado;
        this.facebook = response.data[0].facebook;
        this.instagram = response.data[0].instagram;
        this.web = response.data[0].web;
      })
      .catch((error) => {
        console.log("error");
      });
    axios
      .get("/catalogos/areas_servicio", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.items = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
#autocompletado2 {
  margin-top: 12px;
}

@media only screen and (min-width: 868px) {
  /* Esta es la pc */
  .headernegro {
    height: 250px !important;
    background-color: black;
  }
  .vacantePosicion {
    font-size: 34px;
  }
  #cel {
    display: none;
  }
  .vacanteRango {
    font-size: 17px;
  }
  .miimagen {
    border-radius: 40px;
  }
  .imagen {
    border: 4px solid white;
    width: 120px;
    height: 120px;
    border-radius: 40px;
    display: flex;
    margin-left: 20px;
    background-color: #ddd;
  }
  .columna3 {
    margin-left: 25px;
  }
  .columna4 {
    margin-left: 25px;
  }
  .columna2 {
    padding: 15px;
    border-radius: 15px;
  }
  .tarjeta {
    padding: 15px;
    margin-top: 20px;
    border-radius: 15px;
    background-color: #ddd;
  }
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 20px;
    font-size: 15px;
    padding: 10px;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 768px) {
  /* Celular*/
  .headernegro {
    height: 310px !important;
    background-color: black;
  }
  #pc {
    display: none;
  }
}

@media (min-width: 700px) and (orientation: portrait) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
  .headernegro {
    height: 240px;
    background-color: black;
  }
}

@media (min-width: 700px) and (orientation: landscape) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
}
</style>
