<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
      <v-card style="background-color: #e0e0e0;">
        <v-container>
          <v-toolbar dark color="grey darken-4" height="140px">
            <v-container>
              <v-toolbar-title class="text-center">
                <v-toolbar-title>
                  <v-img
                    src="https://vacanta.sistemasysoluciones.mx/img/pngblanco.1a7eeef7.png"
                    width="200px"
                    style="margin:auto; display:content;"
                  ></v-img>
                  <br />
                </v-toolbar-title>
                <h2 style="color:white;">Empresas</h2>
              </v-toolbar-title>
            </v-container>
          </v-toolbar>
        </v-container>
        <v-card-text>
          <v-alert v-if="errors.length">
              <b>Porfavor corrige los siguientes errores:</b>
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </v-alert>
          <v-text-field
            v-model="correo"
            :rules="emailRules"
            type="email"
            outlined
            color="primary"
            label="Email"
            append-icon="mdi-at"
          >
          </v-text-field>

          <v-text-field
            @keyup.enter="ingresar()"
            name="password"
            label="Ingresa tu password"
            :append-icon="value ? 'visibility' : 'visibility_off'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            v-model="password"
            :rules="passwordRules"
            outlined
          ></v-text-field>
          <v-container>
            <v-row>
              <v-col class="text-center">
                <p>¿Aún no te registras?</p>
                <router-link :to="{ name: 'EmpresasRegistro' }">
                  <a href="">Registrarse</a>
                </router-link>
              </v-col>
              <v-col class="text-center">
                <p>¿Olvidaste la contraseña?</p>
                <router-link :to="{ name: 'OlvideEmpresas' }" class="olvide">
                  <a style="color=black;" href="">Recupera tu contraseña</a>
                </router-link>
              </v-col>
            </v-row>
          </v-container>

          <v-flex class="red--text" v-if="errorM">
            {{ errorM }}
          </v-flex>
        </v-card-text>

        <div class="text-center">
          <v-card-actions class="px-3 pb-3">
            <v-flex text-cs-right>
              <v-btn @click="ingresar()" color="primary" style="color:black"
                >Enviar</v-btn
              >
            </v-flex>
          </v-card-actions>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      valid: true ,
      value: String,
      correo: "",
      password: "",
      errors: [],
      errorM: null,
      emailRules: [
        (v) => !!v || "E-mail es necesario",
        (v) =>
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(v) ||
          "E-mail no valido",
      ],
      passwordRules: [(v) => v.length >= 6 || "Minimo 6 Caracteres"],
    };
  },
  methods: {
    ...mapMutations(["setCorreo", "setEmpresa"]),
    ...mapActions(["guardarEmpresa"]),
    ingresar() {
      if (this.password && this.correo) {
        this.setCorreo(this.correo);
        axios
          .post("/auth/empresa/login", {
            correo: this.correo,
            password: this.password,
          })
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            this.guardarEmpresa(81);
            this.$store.dispatch("guardarToken", data.token);
            this.$router.push({ name: "PerfilEmpresas" });
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status == 401) {
              this.errorM = "Las credenciales son incorrectas";
            } else if (error.response.status == 400) {
              this.errorM = "El usuario no existe favor de registrarse";
            } else {
              this.errorM = "Ocurrio un error con el servidor";
            }
          });
      } else {
        this.errors = [];
        if (!this.correo) this.errors.push("Correo es requerido.");
        if (!this.password) this.errors.push("Password es requerido.");
      }
    },
  },
};
</script>

<style scoped>
.v-application a {
  color: black !important ;
}
</style>
