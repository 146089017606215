<template>
  
    <v-autocomplete
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      hide-details
       clearable
      hide-selected
      item-text="sector"
      item-value="symbol"
      label="Sector"
      color="primary"
      outlined
      :click="setSector(model)"
      return-object
         @change="searchInput=''"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Busca tu 
            <strong>Sector</strong>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attr, on, item, selected }">
        <v-chip
          v-bind="attr"
          :input-value="selected"
          color="primary"
          class="black--text"
          v-on="on"
        >
          <span v-text="item.sector"></span>
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-avatar
          color="#14a6a6"
          class="text-h5 font-weight-light white--text"
        >
          {{ item.sector.charAt(0) }}
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.sector"></v-list-item-title>
          <v-list-item-subtitle v-text="item.symbol"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
      </template>
</template>
<script>
 import { mapMutations, mapState } from 'vuex'
  export default {
    data: () => ({
      isLoading: false,
      items: [],
      model: null,
      search: null,
      tab: null,
    }), 
  computed:{
      ...mapState (['nuevosector'])
    },
     methods:{
       ...mapMutations (['setSector'])
     },

    watch: {
      model (val) {
        if (val != null) this.tab = 0
        else this.tab = null
      },
      search (val) {
        // Items have already been loaded
        if (this.items.length > 0) return

        this.isLoading = true

        // Lazily load input items
        console.log("hola")
        fetch('http://tisdcsecondary.ddns.net:8000/api/catalogos/sector')
          .then(res => res.clone().json())
          .then(res => {
            this.items = res
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
       select() {
    this.search = ''
  }
    },
  }
</script>