<template>
  <v-app id="app">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      v-if="logueado"
    >
      <v-list dense>
        <template>
          <v-container v-if="this.$store.state.id.empresa">
            <router-link to="/perfilEmpresas">
              <v-avatar
                style="display:block;
margin:auto;"
                width="95px"
                height="95px"
              >
                <img :src="this.foto" alt="Avatar" v-if="this.foto" />
                <img v-else src="./assets/PERFIL.jpeg" alt="Avatar" />
              </v-avatar>
              <br />
              <div class="text-center">
                <a
                  style="margin-left:5px;font-size:20px;text-align:center;"
                  :to="{ name: 'perfil' }"
                >
                  {{ this.nombre }}
                </a>
              </div>
            </router-link>
          </v-container>
          <v-container v-else-if="this.$store.state.id.servicio">
            <router-link to="/PerfilEmpresaServicios">
              <v-avatar
                style="display:block;
margin:auto;"
                width="95px"
                height="95px"
              >
                <img :src="this.foto" alt="Avatar" v-if="this.foto" />
                <img v-else src="./assets/PERFIL.jpeg" alt="Avatar" />
              </v-avatar>
              <br />
              <div class="text-center">
                <a
                  style="margin-left:5px;font-size:20px;text-align:center;"
                  :to="{ name: 'perfil' }"
                >
                  {{ this.nombre }}
                </a>
              </div>
            </router-link>
          </v-container>
          <v-container v-else>
            <router-link to="/perfil">
              <v-avatar
                style="display:block;
margin:auto;"
                width="95px"
                height="95px"
              >
                <img :src="this.foto" alt="Avatar" v-if="this.foto" />

                <img v-else src="./assets/PERFIL.jpeg" alt="Avatar" />
              </v-avatar>
              <br />
              <div class="text-center">
                <a
                  style="margin-left:5px;font-size:20px;"
                  :to="{ name: 'perfil' }"
                >
                  {{ this.nombre }} {{ this.apellido }}
                </a>
              </div>
            </router-link>
          </v-container>
        </template>

        <template v-if="this.$store.state.id.empresa">
          <v-container v-if="this.$store.state.id.empresa">
            <v-list-item :to="{ name: 'PerfilEmpresas' }">
              <v-list-item-action>
                <v-icon>mdi-account</v-icon>
              </v-list-item-action>
              <v-list-item-content style="margin-left:-15px;">
                <v-list-item-title>
                  Mi Perfil
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'EmpresasVacantes' }">
              <v-list-item-action>
                <v-icon>mdi-account-check</v-icon>
              </v-list-item-action>
              <v-list-item-content style="margin-left:-15px;">
                <v-list-item-title>
                  Mis Vacantes Profesionistas
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'VacantesOperativas' }">
              <v-list-item-action>
                <v-icon>mdi-account-check</v-icon>
              </v-list-item-action>
              <v-list-item-content style="margin-left:-15px;">
                <v-list-item-title>
                  Mis Vacantes Operativas
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'ComprarCreditos' }">
              <v-list-item-action>
                <v-icon>mdi-currency-usd</v-icon>
              </v-list-item-action>
              <v-list-item-content style="margin-left:-15px;">
                <v-list-item-title>
                  Comprar
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-container>
        </template>
        <v-container v-else-if="this.$store.state.id.servicio">
          <v-container>
            <v-list-item :to="{ name: 'PerfilEmpresaServicios' }">
              <v-list-item-action>
                <v-icon>mdi-account</v-icon>
              </v-list-item-action>
              <v-list-item-content style="margin-left:-15px;">
                <v-list-item-title>
                  Mi Perfil
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'MisServicios' }">
              <v-list-item-action>
                <v-icon>mdi-text-box-multiple-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content style="margin-left:-15px;">
                <v-list-item-title>
                  Mis Anuncios
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-container>
        </v-container>
        <template v-else>
          <v-list-item :to="{ name: 'perfil' }">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content style="margin-left:-15px;">
              <v-list-item-title>
                Mi Perfil
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <div v-if="this.$store.state.id.empresa">
        <template append>
          <div class="pa-2" v-if="this.creditos>0">
            <v-btn v-if="this.creditos<400" block dark>
              Vacantes Elite: {{ creditos.toLocaleString("en-US") }}
            </v-btn>
            <v-btn v-else block dark>
              Paquete Elite: Activado
            </v-btn>
          </div>
          <div class="pa-2" v-if="this.pendientes>0">
              <v-btn v-if="pendientes>100" block dark>
               Paquete Elite: Pendiente
            </v-btn>
            <v-btn v-else-if="pendientes >= 1" block dark>
              Pendientes Elite: {{ pendientes.toLocaleString("en-US") }}
            </v-btn>
          
             
          </div>
          <div v-if="this.creditos" >
          </div>
          <div v-else class="pa-2">
            <v-btn block dark>VACANTES GRATUITAS: {{this.gratis}}</v-btn>
          </div>
        </template>
      </div>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="grey darken-4"
      dark
    >
      <v-app-bar-nav-icon
        v-if="logueado"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <v-img
            alt="Logo"
            id="milogo"
            class="shrink mr-2 "
            contain
            src="./assets/pngblanco.png"
            transition="scale-transition"
            width="120"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <router-link :to="{ name: 'LoginServicios' }">
        <v-btn
          v-show="$route.name == 'Servicios'"
          id="como"
          color="primary"
          style="color:black;  text-decoration: none;"
        >
          PUBLICA TU SERVICIO
        </v-btn>
      </router-link>

      <div>
        <v-btn
          v-show="$route.name == 'Profesionistas'"
          color="primary"
          style="color:black;  text-decoration: none;margin-left:-5px"
          small
          :to="{ name: 'Registro' }"
        >
          REGISTRA TU CV
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="salir()" v-if="logueado">
        Salir
        <v-icon style="margin-left:5px" color="primary">logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <div v-show="$route.name == 'Profesionistas'">
        <Busca></Busca>
        <v-card color="grey darken-4" dark fluid> </v-card>
      </div>
      <div v-show="$route.name == 'Operativas'">
        <BuscaOperativo></BuscaOperativo>
      </div>
      <div v-show="$route.name == 'Servicios'">
        <BuscaServicios></BuscaServicios>
      </div>

      <div v-show="$route.name == 'vacante/id'">
        <v-card color="grey lighten-4" flat dark tile>
          <v-toolbar dense>
            <router-link :to="{ name: 'Profesionistas' }">
              <v-icon>mdi-arrow-left-circle</v-icon>

              <a href="" style="color:white;padding:6px">Regresar</a>
            </router-link>

            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card>
      </div>

      <!--   <div v-if="$route.name == 'NuevaVacante'">
        <v-card color="grey lighten-4" flat dark tile>
          <v-toolbar dense>
            <router-link :to="{ name: 'PerfilEmpresas' }">
              <v-btn color="primary">
                Perfil
              </v-btn>
            </router-link>
            <router-link :to="{ name: 'EmpresasVacantes' }">
              <v-btn color="primary" style="margin-left:10px">
                Vacantes
              </v-btn>
            </router-link>
            <v-btn color="primary" style="margin-left:10px">
              Lista de Candidatos
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </div> -->

      <v-container fluid>
        <v-slide-y-transition mode="out-in">
          <router-view />
        </v-slide-y-transition>
      </v-container>
    </v-main>
    <v-footer color="grey darken-4" dark padless>
      <v-row justify="center" no-gutters>
        <v-col cols="12" class="white--text text-center">
          <br />
          <br />
        </v-col>
        <v-col cols="4" class="white--text text-center">
          <h3 style="color:#2afed6">Generales</h3>
          <br />
          <div>
            <template>
              <v-row justify="center">
                <v-dialog
                  v-model="dialog"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      style="color:white !important;margin-bottom:25px;"
                      v-bind="attrs"
                      v-on="on"
                      class="preguntas"
                      >Preguntas Frecuentes</a
                    >
                    <br />
                  </template>
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-btn icon dark @click="dialog = false">
                        <v-icon style="color:black">mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title style="color:black"
                        >Preguntas Frecuentes</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn
                          dark
                          text
                          @click="dialog = false"
                          style="color:black"
                        >
                          Salir
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-list three-line subheader>
                      <br />
                      <v-list-item-title class="text-center"
                        ><strong>Candidatos</strong></v-list-item-title
                      >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Qué es VACANTA?</v-list-item-title
                          >
                          <v-card-text
                            >VACANTA es una plataforma que tienen como principal
                            objetivo conectar a candidatos y empresas a través
                            del sitio www.vacanta.mx. Las empresas que busquen
                            candidatos para sus puestos de trabajo podrán
                            publicar ofertas de empleo dentro del portal.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Cómo puedo contactarme con la empresa que está
                            publicando una vacante?</v-list-item-title
                          >
                          <v-card-text
                            >Es muy sencillo solo selecciona, en la vacante de
                            tu interés, el medio por que el que deseas solicitar
                            más información o enviar tu CV a la empresa
                            (teléfono, WhatsApp y/o correo)</v-card-text
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Qué significa que una vacante aparezca publicada
                            por una empresa con el nombre de “Empresa
                            Líder”?</v-list-item-title
                          >
                          <v-card-text
                            >Esto se debe a que la empresa ha seleccionado
                            publicar de manera anónima, esto puede suceder
                            debido a las políticas de la empresa.</v-card-text
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Cuál es el rol de VACANTA en el proceso de
                            selección?</v-list-item-title
                          >
                          <v-card-text
                            >VACANTA no interfiere en ningún momento en el
                            proceso de selección de los candidatos.</v-card-text
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Por qué no me llaman las
                            empresas?</v-list-item-title
                          >
                          <v-card-text
                            >VACANTA no se involucra en los procesos de
                            selección ni de contratación de personal. Los
                            motivos principales pueden ser que tu perfil no sea
                            el mas adecuado para cubrir el puesto.</v-card-text
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Qué tipo de vacantes se
                            publican?</v-list-item-title
                          >
                          <v-card-text
                            >En VACANTA publicamos todo tipo de vacantes para
                            todo tipo de perfiles. Somos una plataforma que
                            busca brindar oportunidades para todos.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Qué beneficios tengo al registrar mi CV en
                            VACANTA?</v-list-item-title
                          >
                          <v-card-text
                            >Si registras tu CV, se te avisara por correo cuando
                            se publiquen vacantes del área de tu interés.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Qué beneficios tengo al registrar mi CV en
                            VACANTA?</v-list-item-title
                          >
                          <v-card-text
                            >Si registras tu CV, se te avisara por correo cuando
                            se publiquen vacantes del área de tu interés.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Las empresas piden algún pago para entrar a un
                            proceso de selección?</v-list-item-title
                          >
                          <v-card-text
                            >Las empresas no piden ningún tipo de pago para los
                            procesos de selección, si te han solicitado alguno,
                            te recomendamos que omitas esa oferta.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list three-line subheader>
                      <br />
                      <v-list-item-title class="text-center"
                        ><strong>Empresas</strong></v-list-item-title
                      >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Qué es VACANTA?</v-list-item-title
                          >
                          <v-card-text
                            >VACANTA es una plataforma que tienen como principal
                            objetivo conectar a candidatos y empresas a través
                            del sitio www.vacanta.mx. Las empresas que busquen
                            candidatos para sus puestos de trabajo podrán
                            publicar ofertas de empleo dentro del portal.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Cuál es el rol de VACANTA en el proceso de
                            selección?</v-list-item-title
                          >
                          <v-card-text
                            >VACANTA no interfiere en ningún momento en el
                            proceso de selección de los candidatos.</v-card-text
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Como registrar mi empresa en VACANTA?
                          </v-list-item-title>
                          <v-card-text
                            >Es muy sencillo solo selecciona en “Busco Personal”
                            y presiona en “Registrarse” ahí llenaras la
                            información de la empresa que es solicitada. El
                            correo que utilices para crea la cuenta, será tu
                            usuario para entrar al perfil de la empresa.
                          </v-card-text>
                          <em style="font-size:12px;"
                            >*Si registraste a la empresa con un correo personal
                            y en un futuro ya no utilizaras más ese correo para
                            entrar al portal, tendrás que seguir los pasos para
                            cambiar de correo/usuario. Recomendamos utilizar un
                            correo genérico como (rh@tuempresa.com o
                            reclutamiento@tuempresa.com).</em
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Necesito cambiar de correo (usuario) y
                            contraseña??</v-list-item-title
                          >
                          <v-card-text
                            >Para cambiar de correo solo tienes que mandarnos un
                            correo a info@vacanta.mx en donde se tendrá que
                            mandar la siguiente información: El correo que
                            deseas reemplazar y el nuevo correo que deseas
                            utilizar, RFC y Razón Social de la empresa. En breve
                            recibirás, en el correo nuevo, la confirmación del
                            cambio con una contraseña preestablecida. Para
                            cambiar la contraseña solo debes de ingresar en
                            ¿Olvide mi contraseña? Y seguir los pasos.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Cuánto tardan las ofertas en aparecer en la
                            página?</v-list-item-title
                          >
                          <v-card-text
                            >Las ofertas se publican automáticamente una vez
                            completado el proceso de publicación de una vacante.
                            Las ofertas de empleo son revisadas
                            aleatoriamente.</v-card-text
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Necesito cubrir un puesto
                            operativo?</v-list-item-title
                          >
                          <v-card-text
                            >Publica tu oferta de empleo en la sección de
                            “Vacantes Operativas”.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Qué significan lo estatus de las vacantes
                            publicadas?</v-list-item-title
                          >
                          <v-card-text
                            >• Las publicaciones en estado "Activa” significa
                            que la vacante se encuentra en el listado de
                            vacantes.<br />
                            • Las publicaciones en estado "Ocupada” significa
                            que la vacante ya no se encuentra en el listado de
                            vacantes debido a que la empresa selecciono a uno de
                            los candidatos postulados a la vacante. <br />
                            • Las publicaciones en estado "Desactivada”
                            significa que la vacante ya no se encuentra en el
                            listado de vacantes debido a que el tiempo de la
                            publicación ha caducado o bien la empresa decidió
                            desactivarla por algún motivo propio. <br />
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Mi empresa ha sido dada de baja. ¿Cuál es el
                            motivo?</v-list-item-title
                          >
                          <v-card-text style="color:gray;">
                            Los motivos más comunes para dar de baja a una
                            empresa son los siguientes:
                          </v-card-text>
                          <v-card-text
                            >• El RFC de la empresa es invalido. <br />
                            • El nombre de la empresa o razón social son
                            inexistentes. <br />
                            • Cuenta duplicada: si se encuentra más de una
                            cuenta registrada de la empresa. <br />
                            • También puede ocurrir que se elimine la empresa
                            porque sus ofertas no cumplen con los criterios de
                            publicación. <br />
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Existen restricciones para publicar ofertas de
                            empleo en VACANTA?</v-list-item-title
                          >
                          <v-card-text
                            >Para revisar las restricciones consulta nuestros
                            Términos y Condiciones.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Puedo editar o eliminar una oferta ya
                            publicada?</v-list-item-title
                          >
                          <v-card-text
                            >Para eliminar una oferta publicada solo debes de
                            ir, a el perfil de tu empresa, a “Mis Vacantes
                            profesionistas” si es vacante administrativa o a
                            “Mis Vacantes Operativas” si es vacante operativa y
                            ahí mismo podrás desactivar tu vacante. No será
                            posible editar una vacante ya publicada, es por tal
                            motivo que debes revisar muy bien que todos los
                            requisitos de los campos solicitados cuenten con la
                            información adecuada. Al seleccionar “Confirmar” ya
                            no podrás editar la vacante publicada.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Cómo puedo modificar los datos de mi
                            empresa?</v-list-item-title
                          >
                          <v-card-text
                            >Para modificar los datos de tu empresa debes
                            acceder a la pestaña de “Editar Datos” en tu perfil
                            de empresa. Desde allí puedes cambiar los datos como
                            el nombre de contacto, número de empleados y página
                            web. Hay ciertos campos que no son editables como el
                            nombre de la empresa, la razón social, RFC, Estado y
                            Ciudad. Para cambiar estos datos tienes que enviar
                            una solicitud a info@vacanta.mx
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            >¿Cómo se puede eliminar la cuenta de la
                            empresa?</v-list-item-title
                          >
                          <v-card-text
                            >Puedes solicitar que se elimine tu perfil de
                            empresa del portal enviándonos una solicitud a
                            info@vacanta.mx
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </div>

          <div>
            <v-row justify="center">
              <v-dialog
                v-model="dialogo"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a
                    style="color:white !important;margin-bottom:25px;"
                    v-bind="attrs"
                    v-on="on"
                    class="preguntas"
                    >Aviso de Privacidad</a
                  >
                  <br />
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialogo = false">
                      <v-icon style="color:black">mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title style="color:black"
                      >Aviso de Privacidad</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                        dark
                        text
                        @click="dialogo = false"
                        style="color:black"
                      >
                        Salir
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-list three-line subheader style="text-align:justify;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-center"
                          style="margin-top:10px;"
                        >
                          <strong
                            >Aviso de privacidad integral para
                            candidatos</strong
                          >
                        </v-list-item-title>
                        <v-card-text>
                          Este aviso de privacidad integral fue redactado en
                          fecha 19 de octubre de 2021 y actualizado en fecha 07
                          de mayo de 2022, con base en la Ley Federal de
                          Protección de Datos Personales en Posesión de los
                          Particulares (“Ley”) publicada en el Diario Oficial de
                          la Federación (“DOF”) en fecha 05 de julio de 2010, el
                          Reglamento de la Ley (“Reglamento”) publicado en el
                          DOF en fecha 21 de diciembre de 2011 y los
                          Lineamientos del Aviso de Privacidad publicados en el
                          DOF el 17 de enero de 2013 (“Lineamientos”).
                        </v-card-text>
                        <v-card-text>
                          A través del presente aviso de privacidad, el
                          responsable del tratamiento de sus datos personales
                          pretende cumplir con el principio de información
                          previsto en la Ley, al dar a conocer a los titulares
                          de los datos personales la información relativa a la
                          existencia y características principales del
                          tratamiento al que serán sometidos los mismos.
                        </v-card-text>
                        <v-card-text>
                          En ese sentido, a continuación se presenta el
                          contenido del presente aviso de privacidad integral,
                          conforme lo dispuesto en el lineamiento vigésimo de
                          los Lineamientos:
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>I. Responsable</v-list-item-title>
                        <v-card-text>
                          El responsable que tratará los datos personales de los
                          titulares será la persona moral denominada Operadora
                          Rochen de México, S.A. de C.V., comercialmente
                          conocida como “Vacanta”, con domicilio ubicado en
                          Calle Hacienda del Carrizal, número 3204, Colonia Las
                          Haciendas, C.P. 31215, Chihuahua, Chihuahua, México.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >II. Datos personales</v-list-item-title
                        >

                        <v-card-text style="color:gray;">
                          A continuación se listan los datos personales que se
                          tratarán para la consecución de las finalidades que
                          más adelante se precisan, los cuales en su totalidad
                          son obtenidos directamente del titular:
                        </v-card-text>
                        <v-card-text>
                          1. Nombre completo.<br />
                          2. Fecha de nacimiento.<br />
                          3. Código postal de su domicilio.<br />
                          4. Estado y ciudad donde radica.<br />
                          5. Expectativa salarial con mínimo y máximo. <br />
                          6. Historial profesional.<br />
                          7. Aptitudes y capacidades. <br />
                          8. Historial académico. <br />
                          9. Correo electrónico. <br />
                          10. Número de teléfono celular.<br />
                          11. Fotografía.<br />
                          12. Currículo. <br />
                          13. A aquellos titulares que soliciten comprobante
                          fiscal digital por Internet, les será solicitada su
                          información fiscal.
                        </v-card-text>
                        <v-card-text>
                          El responsable conservará y estará autorizado para
                          tratar los datos personales mientras los titulares
                          estén registrados en el portal de internet que el
                          responsable utilice para la prestación de los
                          servicios y en el cual se hayan registrado los
                          titulares directamente o a través del responsable,
                          salvo que el titular ejerza su derecho a la
                          cancelación de los mismos mediante el procedimiento
                          correspondiente.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >III. Datos personales sensibles</v-list-item-title
                        >
                        <v-card-text>
                          El responsable no recabará ni tratará datos personales
                          sensibles de los titulares.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >IV. Finalidades del tratamiento</v-list-item-title
                        >
                        <v-card-text style="color:gray">
                          El responsable recabará y tratará los datos personales
                          de los titulares con las siguientes finalidades:
                        </v-card-text>
                        <v-card-text>
                          1. Identificar y ofrecerle información sobre posibles
                          oportunidades de trabajo o prestación de servicios que
                          se adecúen a su perfil. <br />
                          2. Procurarle un servicio eficiente y proveer una
                          mejor atención.<br />
                          3. Dar cumplimiento a obligaciones legales.<br />
                          4. Evaluar la calidad del servicio y realizar estudios
                          sobre hábitos de consumo. <br />
                          5. En su caso, para procesar los pagos electrónicos
                          por los servicios que den origen a la relación
                          jurídica entre el titular y el responsable.<br />
                          6. Generar y compartir información agregada que no
                          identifica al titular para fines analíticos y
                          estadísticos, que permite un mejor conocimiento de los
                          usuarios del portal web del responsable y permite
                          ofrecerles un mejor servicio.<br />
                          7. Mercadotecnia, publicidad y prospección comercial
                          de los productos y servicios que ofrezca el
                          responsable y los terceros a los que en su caso se le
                          transfieran los datos personales, en términos del
                          presente aviso de privacidad, la Ley, el Reglamento y
                          los Lineamientos. <br />
                          8. Otra información - Cookies.- Cuando el titular
                          ingresa en el portal web del Responsable le autoriza
                          almacenar alguna información en su computadora bajo la
                          forma de “Cookie” o archivo similar que puede serle
                          útil de varias formas. Con la mayoría de los
                          exploradores para Internet, el titular puede borrar
                          las Cookies del disco rígido de su computadora,
                          bloquear todas las Cookies o recibir un mensaje de
                          alerta antes de que se almacene una Cookie. El titular
                          debe consultar las instrucciones de su explorador para
                          conocer más sobre estas funciones.
                          <br />
                          Para tal efecto, se precisa que todas las finalidades
                          del tratamiento de los datos personales que se recaban
                          del titular son necesarias para la existencia,
                          mantenimiento y cumplimiento de la relación jurídica
                          entre el titular y el responsable
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >V. Mecanismos para manifestar negativa al
                          tratamiento</v-list-item-title
                        >
                        <v-card-text>
                          Si el titular no está de acuerdo en que el responsable
                          realice el tratamiento de sus datos personales para
                          aquellas finalidades que no son necesarias para la
                          existencia, mantenimiento y cumplimiento de la
                          relación jurídica con el responsable, según lo
                          referido en el numeral anterior, podrá enviar un
                          mensaje en esos términos a la dirección de correo
                          electrónico info@vacanta.mx y el responsable estará
                          obligado a responder su solicitud conforme lo
                          dispuesto en la legislación aplicable.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VVI. Transferencias a terceros</v-list-item-title
                        >
                        <v-card-text style="color:gray">
                          Los datos personales podrán ser transferidos a
                          terceros nacionales o internacionales en los
                          siguientes términos:
                        </v-card-text>
                        <v-card-text>
                          1. Podrán ser transmitidos o puestos a disposición de
                          terceros que se encuentren en búsqueda de candidatos
                          para cubrir vacantes laborales y que posiblemente
                          puedan contactar al titular para iniciar un proceso de
                          reclutamiento y en su caso realizarle un ofrecimiento
                          de empleo o prestación de servicios. En ese sentido,
                          en este momento no se tiene identificado un tercero
                          receptor en específico al que se le transferirían los
                          datos personales, sino que se transferirán a aquellos
                          terceros que busquen perfiles laborales con las
                          características del titular, a criterio del
                          responsable o del titular.<br />
                          2. Podrán ser transferidos a terceros que tengan
                          interés en ofrecerles productos o servicios, es decir,
                          una transferencia con motivos de mercadotecnia,
                          publicidad o prospección comercial. En ese sentido, en
                          este momento no se tiene identificado un tercero
                          receptor en específico, sino que dependerá de aquel
                          tercero interesado en adquirir la información que haya
                          recabado el responsable.<br />
                          3. En su caso, podrán ser transferidos a aquellos
                          terceros que procesen los pagos electrónicos o
                          facturación por los servicios que den originen a la
                          relación jurídica entre el titular y el
                          responsable.<br />
                          4. Podrán ser transferidos a terceros que presten
                          servicio de almacenamiento de información, quienes se
                          obligarán contractualmente a mantener la
                          confidencialidad de los datos y conforme al presente
                          aviso de privacidad. <br />
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VII. Cláusula de aceptación de
                          transferencia</v-list-item-title
                        >
                        <v-card-text>
                          Mediante el otorgamiento del consentimiento del
                          presente aviso de privacidad integral, el titular
                          otorga su aceptación a que el responsable pueda
                          transferir sus datos personales en los términos
                          referidos en el apartado anterior.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VIII. Medios y procedimiento para ejercer los
                          derechos ARCO</v-list-item-title
                        >
                        <v-card-text>
                          El titular de los datos personales tiene derecho al
                          acceso, rectificación, cancelación y oposición
                          (“Derechos ARCO”) al tratamiento que les otorgue el
                          responsable. Para su ejercicio, el titular podrá
                          enviar un mensaje en sus términos a la dirección de
                          correo electrónico info@vacanta.mx.
                          <br />
                          El departamento de datos personales del responsable
                          será el encargado de dar trámite a las solicitudes de
                          los titulares para el ejercicio de los derechos ARCO,
                          en los términos previstos en la legislación aplicable,
                          a quien se puede contactar en la dirección de correo
                          electrónico info@vacanta.mx.
                        </v-card-text>
                        <v-card-text style="color:gray;">
                          El procedimiento para el ejercicio de los Derechos
                          ARCO será el siguiente:
                        </v-card-text>
                        <v-card-text>
                          1. El titular o quien se ostente como su representante
                          deberá escribir y firmar su solicitud de ejercicio de
                          los derechos ARCO de forma clara, completa, en idioma
                          español y con toda la información que permita al
                          responsable y a la persona por él encargada para dar
                          trámite a su solicitud comprender a plenitud cuál es
                          la solicitud del titular, cual es el Derecho ARCO en
                          específico que pretende ejercer y a cuáles de sus
                          datos personales se refiere. Asimismo, el titular
                          deberá proporcionar una dirección de correo
                          electrónico en la cual autorice recibir la respuesta
                          correspondiente por parte del responsable.<br />
                          2. En caso que sea el titular quien directamente
                          presente la solicitud, deberá acompañar a la misma
                          alguna identificación oficial vigente con fotografía
                          que le permita al responsable corroborar que
                          efectivamente se trata del titular de los datos
                          personales y que la firma de la solicitud coincide con
                          la de la identificación.<br />
                          3. En caso que sea un representante o apoderado del
                          titular quien presente la solicitud, deberá acompañar
                          a la misma los documentos que indudablemente lo
                          acrediten con tal carácter, además de su
                          identificación oficial vigente.<br />
                          4. El responsable comunicará al titular, en un plazo
                          máximo de veinte días, contados desde la fecha en que
                          se recibió la solicitud para ejercer alguno o varios
                          de los derechos ARCO, la determinación adoptada, a
                          efecto de que, si resulta procedente, se haga efectiva
                          la misma dentro de los quince días siguientes a la
                          fecha en que se comunicó la respuesta. Estos plazos
                          podrán ampliarse una sola vez por un periodo igual,
                          siempre y cuando así lo justifiquen las circunstancias
                          del caso. <br />
                          5. El responsable dará respuesta al titular a través
                          de un mensaje de correo electrónico a la dirección que
                          para tal efecto haya señalado el titular en su
                          solicitud inicial. <br />
                          6. El titular obtendrá la información o datos
                          personales que en su caso haya solicitado a través del
                          ejercicio del derecho de acceso, mediante documentos
                          electrónicos y mensajes compartidos vía correo
                          electrónico. <br />
                          7. Hasta el momento de la elaboración del presente
                          aviso de privacidad, el responsable no ha desarrollado
                          algún formulario, sistema o método simplificado en
                          específico para facilitar al titular el ejercicio de
                          sus derechos ARCO, con excepción de las comunicaciones
                          que el responsable envíe al titular vía correo
                          electrónico, en las cuales el titular podrá dar click
                          en la opción que para tal efecto se le proporcione en
                          el cuerpo del mensaje enviado por ese medio. <br />
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>IX. Revocación</v-list-item-title>
                        <v-card-text>
                          El titular podrá enviar un mensaje a la dirección de
                          correo electrónico info@vacanta.mx para manifestar la
                          revocación de su consentimiento al tratamiento de sus
                          datos personales, lo cual se resolverá por el
                          responsable conforme corresponda según la Ley, el
                          Reglamento y los Lineamientos, tomando en
                          consideración si es factible o no el mantenimiento y
                          cumplimiento de la relación jurídica con el
                          responsable sin esos datos personales o incluso si es
                          interés del responsable continuar prestando sus
                          servicios en los términos solicitados por el titular.
                          Para lo no previsto en el presente numeral, aplicará
                          lo dispuesto en el numeral VIII del presente
                          documento.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >X. Opciones para limitar el uso o
                          divulgación</v-list-item-title
                        >
                        <v-card-text>
                          En caso que el titular deseé limitar el uso o
                          divulgación de sus datos personales, podrá enviar un
                          mensaje con su intención a la dirección de correo
                          electrónico info@vacanta.mx, lo cual se resolverá por
                          el responsable conforme corresponda según la Ley, el
                          Reglamento y los Lineamientos, tomando en
                          consideración si es factible o no el mantenimiento y
                          cumplimiento de la relación jurídica con el
                          responsable sin esos datos personales o incluso si es
                          interés del responsable continuar prestando sus
                          servicios en los términos solicitados por el titular.
                          Para lo no previsto en el presente numeral, aplicará
                          lo dispuesto en el numeral VIII del presente
                          documento.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >XI. Cambios en el aviso de
                          privacidadn</v-list-item-title
                        >
                        <v-card-text>
                          El contenido del presente aviso de privacidad
                          integral, y por ende el tratamiento de los datos
                          personales de los titulares, podrá ser modificado en
                          el futuro en cualquier momento, para lo cual se
                          publicará su versión actualizada en el portal de
                          internet: www.vacanta.mx y/o www.vacanta.com.mx, sin
                          necesidad de darse aviso en particular al titular por
                          parte del responsable.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >XII. Consentimiento</v-list-item-title
                        >
                        <v-card-text>
                          El consentimiento al presente aviso de privacidad
                          podrá otorgarse por el responsable de forma expresa o
                          tácita. Si el titular utiliza los servicios ofrecidos
                          por el responsable significa que ha leído, entendido y
                          aceptado los términos del presente aviso de
                          privacidad. Este aviso de privacidad se pone a
                          disposición del titular al momento de recabar sus
                          datos personales, el cual lo consiente al momento de
                          dar click en el apartado correspondiente para
                          manifestar su consentimiento, disfrutar de los
                          servicios del responsable o firmar un tanto impreso de
                          este documento.
                          <br />
                          La oposición a la aceptación del presente aviso de
                          privacidad traerá como consecuencia la imposibilidad
                          para prestar el servicio por parte del titular. En
                          caso de no aceptar el presente aviso de privacidad, el
                          titular no debe proporcionar sus datos personales.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list three-line subheader style="text-align:justify;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-center"
                          style="margin-top:10px;"
                        >
                          <strong
                            >Aviso de privacidad integral para
                            reclutadores</strong
                          >
                        </v-list-item-title>
                        <v-card-text>
                          Este aviso de privacidad integral fue redactado en
                          fecha 19 de octubre de 2021 y actualizado en fecha 07
                          de mayo de 2022, con base en la Ley Federal de
                          Protección de Datos Personales en Posesión de los
                          Particulares (“Ley”) publicada en el Diario Oficial de
                          la Federación (“DOF”) en fecha 05 de julio de 2010, el
                          Reglamento de la Ley (“Reglamento”) publicado en el
                          DOF en fecha 21 de diciembre de 2011 y los
                          Lineamientos del Aviso de Privacidad publicados en el
                          DOF el 17 de enero de 2013 (“Lineamientos”).
                        </v-card-text>
                        <v-card-text>
                          A través del presente aviso de privacidad, el
                          responsable del tratamiento de sus datos personales
                          pretende cumplir con el principio de información
                          previsto en la Ley, al dar a conocer a los titulares
                          de los datos personales la información relativa a la
                          existencia y características principales del
                          tratamiento al que serán sometidos los mismos.
                        </v-card-text>
                        <v-card-text>
                          En ese sentido, a continuación se presenta el
                          contenido del presente aviso de privacidad integral,
                          conforme lo dispuesto en el lineamiento vigésimo de
                          los Lineamientos:
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>I. Responsable</v-list-item-title>
                        <v-card-text>
                          El responsable que tratará los datos personales de los
                          titulares será la persona moral denominada Operadora
                          Rochen de México, S.A. de C.V., comercialmente
                          conocida como “Vacanta”, con domicilio ubicado en
                          Calle Hacienda del Carrizal, número 3204, Colonia Las
                          Haciendas, C.P. 31215, Chihuahua, Chihuahua, México.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >II. Datos personales</v-list-item-title
                        >

                        <v-card-text style="color:gray;">
                          A continuación se listan los datos personales que se
                          tratarán para la consecución de las finalidades que
                          más adelante se precisan, los cuales en su totalidad
                          son obtenidos directamente del titular:
                        </v-card-text>
                        <v-card-text>
                          1. Nombre completo en caso de ser persona física o
                          denominación y razón social en caso de ser una persona
                          moral.<br />
                          2. Nombre comercial.<br />
                          3. Registro Federal de Contribuyentes.<br />
                          4. Código postal, ciudad y estado donde se ubica.<br />
                          5. Giros, sectores, industrias o actividades
                          económicas. <br />
                          6. Nombre completo del reclutador y/o usuario.<br />
                          7. Correo electrónico del reclutador y/o usuario.<br />
                          8. Número de teléfono celular, número de teléfono
                          físico, número de contacto de la aplicación WhatsApp
                          del reclutador y/o usuario.<br />
                          9. Dirección de correo electrónico del reclutador y/o
                          usuario.<br />
                          10. Fotografía del reclutador y/o usuario.<br />
                          11. Logotipo.<br />
                          12. Descripción de las vacantes u oportunidades
                          laborales para potenciales empleados o prestadores de
                          servicios, en particular:<br />
                          a. Nombre del puesto.<br />
                          b. Rango salarial con mínimo y máximo. <br />
                          c. Descripción de las actividades sujetas al
                          puesto.<br />

                          d. Requisitos de aptitudes, capacidades,
                          conocimientos, formación académica, experiencia
                          profesional previa y rango de edades. <br />
                          e. Ubicación del lugar donde se desarrollarían las
                          actividades. <br />
                          13. A aquellos titulares que soliciten comprobante
                          fiscal digital por Internet, les será solicitada su
                          información fiscal. <br />
                        </v-card-text>
                        <v-card-text>
                          El responsable conservará y estará autorizado para
                          tratar los datos personales mientras los titulares
                          estén registrados en el portal de internet que el
                          responsable utilice para la prestación de los
                          servicios y en el cual se hayan registrado los
                          titulares directamente o a través del responsable,
                          salvo que el titular ejerza su derecho a la
                          cancelación de los mismos mediante el procedimiento
                          correspondiente.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >III. Datos personales sensibles</v-list-item-title
                        >
                        <v-card-text>
                          El responsable no recabará ni tratará datos personales
                          sensibles de los titulares.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >IV. Finalidades del tratamiento</v-list-item-title
                        >
                        <v-card-text style="color:gray">
                          El responsable recabará y tratará los datos personales
                          de los titulares con las siguientes finalidades:
                        </v-card-text>
                        <v-card-text>
                          1. Identificar y ofrecerle información sobre posibles
                          oportunidades de trabajo o prestación de servicios que
                          se adecúen a su perfil. <br />
                          2. Procurarle un servicio eficiente y proveer una
                          mejor atención.<br />
                          3. Dar cumplimiento a obligaciones legales.<br />
                          4. Evaluar la calidad del servicio y realizar estudios
                          sobre hábitos de consumo. <br />
                          5. En su caso, para procesar los pagos electrónicos
                          por los servicios que den origen a la relación
                          jurídica entre el titular y el responsable.<br />
                          6. Generar y compartir información agregada que no
                          identifica al titular para fines analíticos y
                          estadísticos, que permite un mejor conocimiento de los
                          usuarios del portal web del responsable y permite
                          ofrecerles un mejor servicio.<br />
                          7. Mercadotecnia, publicidad y prospección comercial
                          de los productos y servicios que ofrezca el
                          responsable y los terceros a los que en su caso se le
                          transfieran los datos personales, en términos del
                          presente aviso de privacidad, la Ley, el Reglamento y
                          los Lineamientos. <br />
                          <br />
                          Para tal efecto, se precisa que todas las finalidades
                          del tratamiento de los datos personales que se recaban
                          del titular son necesarias para la existencia,
                          mantenimiento y cumplimiento de la relación jurídica
                          entre el titular y el responsable
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >V. Mecanismos para manifestar negativa al
                          tratamiento</v-list-item-title
                        >
                        <v-card-text>
                          Si el titular no está de acuerdo en que el responsable
                          realice el tratamiento de sus datos personales para
                          aquellas finalidades que no son necesarias para la
                          existencia, mantenimiento y cumplimiento de la
                          relación jurídica con el responsable, según lo
                          referido en el numeral anterior, podrá enviar un
                          mensaje en esos términos a la dirección de correo
                          electrónico info@vacanta.mx y el responsable estará
                          obligado a responder su solicitud conforme lo
                          dispuesto en la legislación aplicable.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VVI. Transferencias a terceros</v-list-item-title
                        >
                        <v-card-text style="color:gray">
                          Los datos personales podrán ser transferidos a
                          terceros nacionales o internacionales en los
                          siguientes términos:
                        </v-card-text>
                        <v-card-text>
                          1. Podrán ser puestos a disposición del público en
                          general mediante publicación en el portal de internet
                          del responsable y transmitidos o puestos a disposición
                          de terceros que se encuentren en búsqueda de
                          oportunidades laborales o de prestación de servicios
                          independientes y que posiblemente puedan contactar al
                          titular con la intención de iniciar un proceso de
                          reclutamiento y posiblemente aceptar un ofrecimiento
                          de empleo o de prestación de servicios del titular. En
                          ese sentido, en este momento no se tiene identificado
                          un tercero receptor en específico al que se le
                          transferirían los datos personales, sino que se
                          transferirán a aquellos terceros que busquen empleos o
                          prestar servicios con las características que ofrezca
                          el titular, a criterio del responsable o los terceros
                          receptores.<br />
                          2. Podrán ser transferidos a terceros que tengan
                          interés en ofrecerles productos o servicios, es decir,
                          una transferencia con motivos de mercadotecnia,
                          publicidad o prospección comercial. En ese sentido, en
                          este momento no se tiene identificado un tercero
                          receptor en específico, sino que dependerá de aquel
                          tercero interesado en adquirir la información que haya
                          recabado el responsable.<br />
                          3. En su caso, podrán ser transferidos a aquellos
                          terceros que procesen los pagos electrónicos o
                          facturación por los servicios que den originen a la
                          relación jurídica entre el titular y el
                          responsable.<br />
                          4. 4. Podrán ser transferidos a terceros que presten
                          servicio de almacenamiento de información, quienes se
                          obligarán contractualmente a mantener la
                          confidencialidad de los datos y conforme al presente
                          aviso de privacidad. <br />
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VII. Cláusula de aceptación de
                          transferencia</v-list-item-title
                        >
                        <v-card-text>
                          Mediante el otorgamiento del consentimiento del
                          presente aviso de privacidad integral, el titular
                          otorga su aceptación a que el responsable pueda
                          transferir sus datos personales en los términos
                          referidos en el apartado anterior.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VIII. Medios y procedimiento para ejercer los
                          derechos ARCO</v-list-item-title
                        >
                        <v-card-text>
                          El titular de los datos personales tiene derecho al
                          acceso, rectificación, cancelación y oposición
                          (“Derechos ARCO”) al tratamiento que les otorgue el
                          responsable. Para su ejercicio, el titular podrá
                          enviar un mensaje en sus términos a la dirección de
                          correo electrónico info@vacanta.mx.
                          <br />
                          El departamento de datos personales del responsable
                          será el encargado de dar trámite a las solicitudes de
                          los titulares para el ejercicio de los derechos ARCO,
                          en los términos previstos en la legislación aplicable,
                          a quien se puede contactar en la dirección de correo
                          electrónico info@vacanta.mx.
                        </v-card-text>
                        <v-card-text style="color:gray;">
                          El procedimiento para el ejercicio de los Derechos
                          ARCO será el siguiente:
                        </v-card-text>
                        <v-card-text>
                          1. El titular o quien se ostente como su representante
                          deberá escribir y firmar su solicitud de ejercicio de
                          los derechos ARCO de forma clara, completa, en idioma
                          español y con toda la información que permita al
                          responsable y a la persona por él encargada para dar
                          trámite a su solicitud comprender a plenitud cuál es
                          la solicitud del titular, cual es el Derecho ARCO en
                          específico que pretende ejercer y a cuáles de sus
                          datos personales se refiere. Asimismo, el titular
                          deberá proporcionar una dirección de correo
                          electrónico en la cual autorice recibir la respuesta
                          correspondiente por parte del responsable.<br />
                          2. En caso que sea el titular quien directamente
                          presente la solicitud, deberá acompañar a la misma
                          alguna identificación oficial vigente con fotografía
                          que le permita al responsable corroborar que
                          efectivamente se trata del titular de los datos
                          personales y que la firma de la solicitud coincide con
                          la de la identificación.<br />
                          3. En caso que sea un representante o apoderado del
                          titular quien presente la solicitud, deberá acompañar
                          a la misma los documentos que indudablemente lo
                          acrediten con tal carácter, además de su
                          identificación oficial vigente.<br />
                          4. El responsable comunicará al titular, en un plazo
                          máximo de veinte días, contados desde la fecha en que
                          se recibió la solicitud para ejercer alguno o varios
                          de los derechos ARCO, la determinación adoptada, a
                          efecto de que, si resulta procedente, se haga efectiva
                          la misma dentro de los quince días siguientes a la
                          fecha en que se comunicó la respuesta. Estos plazos
                          podrán ampliarse una sola vez por un periodo igual,
                          siempre y cuando así lo justifiquen las circunstancias
                          del caso. <br />
                          5. El responsable dará respuesta al titular a través
                          de un mensaje de correo electrónico a la dirección que
                          para tal efecto haya señalado el titular en su
                          solicitud inicial. <br />
                          6. El titular obtendrá la información o datos
                          personales que en su caso haya solicitado a través del
                          ejercicio del derecho de acceso, mediante documentos
                          electrónicos y mensajes compartidos vía correo
                          electrónico. <br />
                          7. Hasta el momento de la elaboración del presente
                          aviso de privacidad, el responsable no ha desarrollado
                          algún formulario, sistema o método simplificado en
                          específico para facilitar al titular el ejercicio de
                          sus derechos ARCO, con excepción de las comunicaciones
                          que el responsable envíe al titular vía correo
                          electrónico, en las cuales el titular podrá dar click
                          en la opción que para tal efecto se le proporcione en
                          el cuerpo del mensaje enviado por ese medio. <br />
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>IX. Revocación</v-list-item-title>
                        <v-card-text>
                          El titular podrá enviar un mensaje a la dirección de
                          correo electrónico info@vacanta.mx para manifestar la
                          revocación de su consentimiento al tratamiento de sus
                          datos personales, lo cual se resolverá por el
                          responsable conforme corresponda según la Ley, el
                          Reglamento y los Lineamientos, tomando en
                          consideración si es factible o no el mantenimiento y
                          cumplimiento de la relación jurídica con el
                          responsable sin esos datos personales o incluso si es
                          interés del responsable continuar prestando sus
                          servicios en los términos solicitados por el titular.
                          Para lo no previsto en el presente numeral, aplicará
                          lo dispuesto en el numeral VIII del presente
                          documento.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >X. Opciones para limitar el uso o
                          divulgación</v-list-item-title
                        >
                        <v-card-text>
                          En caso que el titular deseé limitar el uso o
                          divulgación de sus datos personales, podrá enviar un
                          mensaje con su intención a la dirección de correo
                          electrónico info@vacanta.mx, lo cual se resolverá por
                          el responsable conforme corresponda según la Ley, el
                          Reglamento y los Lineamientos, tomando en
                          consideración si es factible o no el mantenimiento y
                          cumplimiento de la relación jurídica con el
                          responsable sin esos datos personales o incluso si es
                          interés del responsable continuar prestando sus
                          servicios en los términos solicitados por el titular.
                          Para lo no previsto en el presente numeral, aplicará
                          lo dispuesto en el numeral VIII del presente
                          documento.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >XI. Cambios en el aviso de
                          privacidad</v-list-item-title
                        >
                        <v-card-text>
                          El contenido del presente aviso de privacidad
                          integral, y por ende el tratamiento de los datos
                          personales de los titulares, podrá ser modificado en
                          el futuro en cualquier momento, para lo cual se
                          publicará su versión actualizada en el portal de
                          internet: www.vacanta.mx y/o www.vacanta.com.mx, sin
                          necesidad de darse aviso en particular al titular por
                          parte del responsable.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >XII. Consentimiento</v-list-item-title
                        >
                        <v-card-text>
                          El consentimiento al presente aviso de privacidad
                          podrá otorgarse por el responsable de forma expresa o
                          tácita. Si el titular utiliza los servicios ofrecidos
                          por el responsable significa que ha leído, entendido y
                          aceptado los términos del presente aviso de
                          privacidad. Este aviso de privacidad se pone a
                          disposición del titular al momento de recabar sus
                          datos personales, el cual lo consiente al momento de
                          dar click en el apartado correspondiente para
                          manifestar su consentimiento, disfrutar de los
                          servicios del responsable o firmar un tanto impreso de
                          este documento.
                          <br />
                          La oposición a la aceptación del presente aviso de
                          privacidad traerá como consecuencia la imposibilidad
                          para prestar el servicio por parte del titular. En
                          caso de no aceptar el presente aviso de privacidad, el
                          titular no debe proporcionar sus datos personales.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-dialog>
            </v-row>
          </div>
          <div>
            <v-row justify="center">
              <v-dialog
                v-model="dialogoTer"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a
                    style="color:white !important;margin-bottom:25px;"
                    v-bind="attrs"
                    class="preguntas"
                    v-on="on"
                    >Términos y Condiciones</a
                  >
                  <br />
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialogoTer = false">
                      <v-icon style="color:black">mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title style="color:black"
                      >Términos y Condiciones</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                        dark
                        text
                        @click="dialogoTer = false"
                        style="color:black"
                      >
                        Salir
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-list three-line subheader style="text-align:justify;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-card-text>
                          Los sitios web www.vacanta.mx y www.vacanta.com.mx (en
                          lo sucesivo los “Sitios Web”) son responsabilidad de
                          la persona moral denominada Operadora Rochen de
                          México, S.A. de C.V., inscrita ante el Registro
                          Público de Comercio en los Estados Unidos Mexicanos
                          bajo el folio mercantil electrónico N-2021034609, cuyo
                          nombre comercial con el que se ostenta ante el público
                          en general es Vacanta (en lo sucesivo “Vacanta”).
                        </v-card-text>
                        <v-card-text>
                          El domicilio convencional de Vacanta para efectos del
                          presente instrumento jurídico es el ubicado en Calle
                          Hacienda del Carrizal, número 3204, Colonia Las
                          Haciendas, C.P. 31215, Chihuahua, Chihuahua, México,
                          además se señala como correo electrónico para efectuar
                          comunicaciones la dirección info@vacanta.mx y el
                          número teléfono celular 614 606 5278.
                        </v-card-text>
                        <v-card-text>
                          El domicilio fiscal de Vacanta es el referido en el
                          párrafo anterior y su clave es ORM210518H18.
                        </v-card-text>
                        <v-card-text>
                          Vacanta es titular de las marcas mixtas con la
                          denominación “VACANTA” registradas ante el Instituto
                          Mexicano de la Propiedad Industrial con los números de
                          registro 2317600 y 2320070 y números de expediente
                          2602579 y 2602592, ambas con vigencia hasta el año
                          2031.
                        </v-card-text>

                        <v-card-text>
                          A través del presente instrumento jurídico se informan
                          los términos y condiciones bajo los cuales Vacanta
                          ofrece al público en general los servicios que más
                          adelante se precisan a favor de personas físicas o
                          morales que desean reclutar personal para cubrir
                          ofertas laborales (en lo sucesivo los “Reclutadores”)
                          y a favor de personas físicas que desean obtener
                          información sobre ofertas laborales o desean
                          contactarse directamente con las Reclutadores para
                          cubrir las mismas (en lo sucesivo “Candidatos” y en
                          conjunto con los Reclutadores los “Usuarios”). En ese
                          sentido, si los Usuarios continúan haciendo uso de los
                          Sitios Web, otorgan su consentimiento sobre los
                          derechos y obligaciones previstos en el presente
                          instrumento jurídico. Consecuentemente, en caso que
                          los Usuarios no estén de acuerdo con una o varias de
                          las disposiciones aquí previstas, se solicita que se
                          abstengan de usar o continuar utilizando los Sitios
                          Web y los servicios que Vacanta ofrece.
                        </v-card-text>
                        <v-card-text>
                          1. Capacidad legal. Los Usuarios que accedan a los
                          Sitos Web deberán tener plena capacidad jurídica de
                          goce y ejercicio para contratar y obligarse en
                          términos del presente instrumento, ya sea por sus
                          propios derechos o en representación de la persona
                          moral respecto de la cual se ostenten como
                          representantes, agentes o empleados. En caso que los
                          Usuarios carezcan de plena capacidad jurídica, deberán
                          acompañarse de sus padres o sus representantes
                          legítimos o jurídicos para el uso de los Sitios Web.
                        </v-card-text>
                        <v-card-text>
                          2. Modificaciones. Vacanta podrá modificar los
                          presentes términos y condiciones, así como las
                          condiciones de los servicios que se ofrezcan a través
                          de los Sitios Web, conforme a su mejor interés
                          convenga, por lo que se sugiere a los Usuarios que se
                          informen respecto de ellos en cada ocasión que
                          utilicen los Sitios Web y otorguen su consentimiento
                          tácita o explícitamente, según se les requiera, en
                          cada ocasión que soliciten los servicios ofrecidos por
                          Vacanta.
                        </v-card-text>
                        <v-card-text>
                          3. Reserva. Vacanta se reserva el derecho de omitir
                          prestar sus servicios y omitir el acceso o disfrute de
                          los Sitios Web a aquellas personas que así estime
                          conveniente para sus intereses, incluyendo sin limitar
                          a aquellas que incumplan alguna de las estipulaciones
                          del presente instrumento o realicen un uso de los
                          Sitios Web que Vacanta considere inconveniente a su
                          exclusivo criterio, violatorio de la normatividad
                          aplicable, la moral o las buenas costumbres.
                        </v-card-text>
                        <v-card-text>
                          4. Objeto. Vacanta ofrece a través de los Sitios Web
                          en beneficio de los Reclutadores los servicios
                          consistente en solicitarles y recabar la información
                          correspondiente para (i) crear y publicar en los
                          Sitios Web ofertas laborales para el reclutamiento de
                          personal, las cuales serán de libre acceso para el
                          público en general que acceda a los Sitios Web y para
                          aquellos Candidatos que ingresen la plataforma, con la
                          finalidad de que Candidatos se postulen para dicha
                          oferta laboral a consideración de los reclutadores
                          mediante los mecanismos establecidos en el Sitio Web o
                          se contacten directamente con los Reclutadores
                          mediante los medios de comunicación que hayan puesto a
                          disposición del público en general y de los Candidatos
                          y que éstos sometan su perfil a consideración de los
                          Reclutadores a través los medios que posteriormente
                          acuerden de forma privada entre el Reclutador y el
                          Candidato y (ii) hacer del conocimiento de los
                          Reclutadores la información del Candidato que haya
                          proporcionado su información a Vacanta y ésta
                          considere a su exclusivo criterio que cuentan con las
                          características adecuadas para la oferta laboral que
                          ofrecen los Reclutadores.
                        </v-card-text>
                        <v-card-text>
                          Vacanta ofrece a través de los Sitios Web en beneficio
                          de los Reclutadores los servicios consistente en
                          solicitarles y recabar la información correspondiente
                          para (i) crear y publicar en los Sitios Web ofertas
                          laborales para el reclutamiento de personal, las
                          cuales serán de libre acceso para el público en
                          general que acceda a los Sitios Web y para aquellos
                          Candidatos que ingresen la plataforma, con la
                          finalidad de que Candidatos se postulen para dicha
                          oferta laboral a consideración de los reclutadores
                          mediante los mecanismos establecidos en el Sitio Web o
                          se contacten directamente con los Reclutadores
                          mediante los medios de comunicación que hayan puesto a
                          disposición del público en general y de los Candidatos
                          y que éstos sometan su perfil a consideración de los
                          Reclutadores a través los medios que posteriormente
                          acuerden de forma privada entre el Reclutador y el
                          Candidato y (ii) hacer del conocimiento de los
                          Reclutadores la información del Candidato que haya
                          proporcionado su información a Vacanta y ésta
                          considere a su exclusivo criterio que cuentan con las
                          características adecuadas para la oferta laboral que
                          ofrecen los Reclutadores.
                        </v-card-text>
                        <v-card-text>
                          5. Conocimiento de las leyes. Los Usuarios reconocen
                          que será a su exclusiva responsabilidad el
                          conocimiento o desconocimiento de la normatividad que
                          regule su relación con Vacanta y entre Reclutadores y
                          Candidatos cuya información se intercambie a través de
                          los Sitios Web en incluso las relaciones laborales o
                          de servicios que los Usuarios puedan celebrar entre
                          ellos, liberando a Vacanta de cualquier
                          responsabilidad al respecto. En ese sentido, los
                          Usuarios reconocen que sus actos podrán estar sujetos
                          y por ende asumen sus obligaciones, enunciativa mas no
                          limitativamente, de la Constitución de los Estados
                          Unidos Mexicanos, la normatividad laboral, fiscal y de
                          seguridad social. En particular, los Reclutadores
                          reconocen que no se publicarán vacantes en los Sitios
                          Web cuyo salario sea inferior al mínimo autorizado
                          para la región donde se llevarán a cabo las
                          actividades laborales, se pretenda un pago a los
                          Candidatos exclusivamente de comisiones por ventas, se
                          requiera al Candidato efectuar algún pago como parte
                          del proceso de selección para cubrir la oferta
                          laboral, corresponda a actividades de venta multinivel
                          o piramidal, sean actividades de voluntariado o
                          pro-bono o se trate de actividades inmorales, ilegales
                          o que inciten a la charlatanería, incluyendo pero no
                          limitado a la prostitución, trata de personas y
                          actividades eróticas, a exclusivo criterio de Vacanta
                          quien estará facultado para interrumpir los servicios
                          y el uso de los Sitios Web e incluso dar vista a las
                          autoridades competentes para que procedan conforme a
                          derecho corresponda.
                        </v-card-text>
                        <v-card-text>
                          6. Uso de los Sitios Web. Los Usuarios se obligan a
                          usar los Sitios Web exclusivamente para los fines
                          previstos en el presente instrumento, en particular
                          los previstos previamente en el numeral cuatro, por lo
                          que están de acuerdo en omitir su uso para cualquier
                          finalidad diversa. Adicionalmente, los Usuarios se
                          obligan a cumplir con la legislación aplicable y
                          omitir realizar actividades contrarias a la
                          normatividad, la moral y las buenas costumbres a
                          través de los Sitios Web o con la información que de
                          los mismos obtengan y omitir afectar derechos de
                          terceros o de Vacanta, asumiendo y liberando a Vacanta
                          de cualquier responsabilidad por contravenir lo
                          dispuesto en esta disposición.
                        </v-card-text>
                        <v-card-text>
                          7. No transferencia de la información. Los Usuarios se
                          obligan a no transferir o permitir el conocimiento de
                          terceros respecto de la información que Vacanta les
                          proporcione o haga de su conocimiento a través de los
                          Sitios Web y reconocen que eso podría ocasionar un
                          perjuicio económico a Vacanta, puesto que recolectar,
                          identificar y proporcionar a terceros dicha
                          información consiste en su actividad profesional por
                          la cual pretende obtener un beneficio económico
                          legítimo y prestar el mismo servicio a terceros y el
                          público en general. En ese sentido, los Usuarios que
                          infrinjan esta disposición se obligan a indemnizar a
                          Vacanta por los daños y perjuicios que le ocasionen.
                        </v-card-text>
                        <v-card-text>
                          8. Características de las ofertas laborales. Los
                          Reclutadores se obligan a no publicar ofertas
                          laborales con requisitos o características que
                          resulten violatorias de la legislación aplicable,
                          incluyendo sin limitar a la normatividad que proteja a
                          los Candidatos u otros usuarios de los Sitios Web de
                          actos o conductas discriminatorias por sexo, edad,
                          condición socioeconómica, orientación sexual,
                          religión, estado de salud, apariencia física, origen
                          étnico, discapacidad, opiniones, preferencias
                          políticas, estado civil o cualquier otra que atente
                          contra la dignidad humana. En ese sentido, los
                          Reclutadores se obligan a sacar en paz y a salvo en
                          caso que se presente en contra de Vacanta algún
                          reclamo o sanción por contravenir lo dispuesto en este
                          numeral.
                        </v-card-text>
                        <v-card-text>
                          9. Respecto a derechos de Vacanta. Los Usuarios se
                          obligan a respetar los derechos que Vacanta tiene
                          respecto de los Sitios Web y su desarrollo, por lo que
                          se obligan a no realizar acciones tendientes a su
                          reproducción, adaptación, modificación total o parcial
                          de los contenidos de los Sitios Web sin autorización
                          expresa de Vacanta, ni realizar ninguna acción que
                          pueda perjudicar la funcionalidad de los Sitios Web.
                        </v-card-text>
                        <v-card-text>
                          10. Cuidado de usuario y contraseña.Los Usuarios
                          podrán crear un usuario y contraseña para disfrutar
                          plenamente de los servicios que ofrece Vacanta a
                          través de los Sitios Web. En ese sentido, los Usuarios
                          serán los únicos responsables por el cuidado de su
                          usuario y contraseña, por lo que excluyen de
                          responsabilidad a Vacanta en caso que terceros hayan
                          obtenido dicha información y manipulen la cuenta e
                          información de los Usuarios. En su caso, los Usuarios
                          se obligan a notificar de inmediato a Vacanta de tal
                          filtración, a fin de tomar las medidas necesarias para
                          resguardar la integridad de la información y recursos
                          de los Usuarios, pero bajo ninguna circunstancia será
                          responsabilidad de Vacanta algún perjuicio que por tal
                          motivo puedan sufrir. Adicionalmente, los Usuarios se
                          obligan a no transmitir ni poner a disposición de
                          terceros su usuario y contraseña para el uso de los
                          Sitios Web o los servicios de Vacanta, pues reconocen
                          que el usuario y la contraseña es para uso exclusivo
                          personal o de la persona moral que representan y que
                          en su caso los terceros deberán crear su propio
                          usuario y contraseña para tener acceso a los Sitios
                          Web y beneficiarse de los servicios que ofrece
                          Vacanta.
                        </v-card-text>
                        <v-card-text>
                          11. Información de terceros. Los Usuarios se
                          comprometen a no utilizar información de otros
                          Usuarios publicada en los Sitios Web para fines
                          distintos al objeto de los servicios aquí previstos y
                          que pretendan el beneficio personal o de terceros,
                          incluyendo sin limitar el ofrecer servicios o
                          productos a Usuarios de los Sitios Web.
                        </v-card-text>
                        <v-card-text>
                          12. Integridad y veracidad de información. Los
                          Usuarios se obligan a proporcionar integralmente toda
                          la información que solicite Vacanta para la mejor
                          prestación de los servicios y funcionalidad de los
                          Sitios Web; asimismo, los Usuarios se comprometen a
                          que dicha información sea veraz, pues reconocen que en
                          caso de proporcionar información incompleta, inexacta
                          o falsa podría implicar un perjuicio a Vacanta, los
                          Usuarios o terceros. Adicionalmente, los Usuarios se
                          obligan a informar a Vacanta de inmediato en caso que
                          resulte errónea o varíe la información que hayan
                          proporcionado a Vacanta y a mantener actualizada la
                          información que hayan proporcionado. Vacanta se
                          reserva el derecho a prestar sus servicios o permitir
                          la funcionalidad de los Sitios Web en caso que los
                          Usuarios no proporcionen información integral y veraz,
                          a exclusivo criterio de Vacanta.
                        </v-card-text>
                        <v-card-text>
                          13. Características de los Usuarios. Los Usuarios
                          reconocen que Vacanta no será responsable por la
                          veracidad de la información que obtengan de los Sitios
                          Web o remota, pues reconocen que dicha información es
                          proporcionada a exclusiva responsabilidad de los
                          Reclutadores y los Usuarios y no se encuentra al
                          alcance de Vacanta cerciorarse de la veracidad de
                          dicha información. En ese sentido, los Candidatos
                          liberan de responsabilidad a Vacanta por la incompleta
                          o falsa información que resulte de una oferta laboral
                          publicada a nombre de los Reclutadores; mientras que
                          los Reclutadores liberan de responsabilidad a Vacanta
                          por la incompleta o falsa información que resulte de
                          un Candidato publicada a su nombre. Adicionalmente,
                          los Candidatos liberan de responsabilidad a Vacanta
                          respecto de cualquier requisito de ofertas laborales
                          publicadas a nombre de Reclutadores que pudiera
                          resultar discriminatorio, puesto que reconocen que los
                          requisitos referidos son responsabilidad exclusiva de
                          los Reclutadores.
                        </v-card-text>

                        <v-card-text>
                          14. Titularidad sobre la información.Los Reclutadores
                          se obligan a contar con las autorizaciones
                          correspondientes para proporcionar la información de
                          las ofertas laborales que publiciten y para utilizar
                          el nombre, correos electrónicos, números de teléfonos
                          fijos y celulares, números de contacto de la
                          aplicación de Whatsapp, marcas y demás signos que
                          identifiquen a aquellas personas físicas y morales
                          respecto de las cuales ellos se ostenten como
                          representantes, agentes o empleados. En ese sentido,
                          los Reclutadores reconocen que utilizar información de
                          terceros sin autorización u ostentarse dolosamente
                          como representantes, agentes o empleados de terceros
                          sin serlo puede ser causal de responsabilidades
                          civiles, administrativas y penales, respecto de las
                          cuales asumen su exclusiva responsabilidad y se
                          obligan sacar en paz y a salvo a Vacanta. Asimismo,
                          los Candidatos se obligan a utilizar solamente su
                          información personal, íntegra y veraz, y reconocen que
                          utilizar información de terceros u ostentarse con la
                          identidad de terceros o perfiles falsos puede ser
                          causal de responsabilidades civiles, administrativas y
                          penales, respecto de las cuales asumen su exclusiva
                          responsabilidad y se obligan sacar en paz y a salvo a
                          Vacanta. En ese sentido, los Usuarios reconocen que
                          Vacanta tendrá el derecho de dar aviso inmediato a las
                          autoridades competentes en caso de percatarse de
                          cualquier conducta que pueda incurrir en violación a
                          la normatividad aplicable por parte de los Usuarios.
                        </v-card-text>
                        <v-card-text>
                          15. Uso de datos personales. Vacanta recaba
                          información personal de los Usuarios y les informa
                          mediante el correspondiente aviso de privacidad, entre
                          otras consideraciones, que dicha información podrá ser
                          transmitida o puesta a disposición de los Usuarios
                          para el fin de facilitar cubrir una oferta laboral. En
                          ese sentido, los Usuarios se obligan a no transmitir a
                          terceros la información personal de Usuarios que
                          reciban o tengan acceso a través de los Sitios Web ni
                          utilizarla para otros fines distintos a los previstos
                          en este instrumento, bajo la pena de incurrir en las
                          violaciones y correspondientes responsabilidades que
                          contemple la normatividad aplicable.
                        </v-card-text>
                        <v-card-text>
                          16. Uso de marca. Los Reclutadores conceden a Vacanta
                          la autorización para usar su marca en su material
                          publicitario.
                        </v-card-text>
                        <v-card-text>
                          17. Eliminación de información. Los Usuarios reconocen
                          que Vacanta tendrá el derecho de eliminar cualquier
                          información que a su exclusivo criterio no resulte de
                          beneficio para Vacanta, Reclutadores, Candidatos o
                          terceros y también reconocen que Vacanta tendrá el
                          derecho de dar vista a las autoridades
                          correspondientes respecto de cualquier información o
                          conducta que pueda resultar violatoria de la
                          normatividad aplicable.
                        </v-card-text>
                        <v-card-text>
                          18. Terminación anticipada del servicio. Los Usuarios
                          reconocen que Vacanta tendrá el derecho unilateral y a
                          su exclusivo criterio de interrumpir o dar por
                          terminado la prestación de servicio o el uso de los
                          Sitios Web, sin necesidad de aviso previo ni
                          declaración administrativa ni judicial, y que dicho
                          acto no generará ninguna penalidad, sino solamente la
                          obligación de devolver los créditos a favor con que
                          cuente si la interrupción excede el plazo de un mes o
                          en caso de una terminación anticipada.
                        </v-card-text>
                        <v-card-text>
                          19. Autorización de Vacanta. Los Usuarios reconocen
                          que la información que proporcionen, tanto
                          Reclutadores como Candidatos, podrá estar sujeta a
                          revisión de Vacanta y en su caso su autorización para
                          su publicación en los Sitios Web, a exclusivo criterio
                          de Vacanta y sin obligación de justificar su decisión
                          a acreditar alguna causal. En ese sentido, los
                          usuarios aceptan que su información podrá demorar su
                          publicación el tiempo que Vacanta estime oportuno para
                          su análisis.
                        </v-card-text>
                        <v-card-text>
                          20. Vigencia de ofertas de laborales. Los Reclutadores
                          tendrán derecho a que se publique su oferta laboral en
                          los Sitios Web por los periodos que se determinen para
                          las diferentes modalidades en los Sitios Web, durante
                          los cuales candidatos podrán postularse y/o
                          contactarse directamente con los Reclutadores con la
                          intención de cubrir el puesto. Vacanta no se hace
                          responsable de la postulación o no de candidatos o de
                          que algún Candidato se contacte o envié su información
                          para cubrir una oferta laboral, pues los Reclutadores
                          reconocen que queda a la exclusiva libertad de los
                          Candidatos el postularse, contactarse y/o enviar su
                          información a alguna oferta laboral en particular. Una
                          vez transcurrido los plazos correspondientes, las
                          ofertas laborales dejarán de publicarse en los Sitios
                          Web. Los Reclutadores podrán solicitar a Vacanta que
                          deje de publicar una oferta laboral en los Sitios Web
                          antes de que termine el plazo previamente referido, ya
                          sea porque la oferta laboral haya sido cubierta o por
                          cualquier motivo que así convenga a los intereses de
                          los Reclutadores, aunque aceptan que en ningún
                          supuesto tendrán derecho al reembolso de las
                          cantidades pagadas a favor de Vacanta, incluyendo sin
                          limitación los supuestos en que la oferta laboral haya
                          sido cancelada por los Reclutadores o porque hayan
                          logrado cubrir la oferta laboral por medios ajenos a
                          los Sitios Web o los servicios que ofrece Vacanta.
                        </v-card-text>
                        <v-card-text>
                          21. Modalidades de ofertas laborales. Vacanta ofrecerá
                          a los Reclutadores diversas modalidades para
                          publicitar sus ofertas laborales en los Sitios Web,
                          las cuales se regularán por los derechos y
                          obligaciones que así se precise en los Sitios Web,
                          mismos que podrán modificarse a exclusivo criterio de
                          Vacanta, por lo que se sugiere a los Reclutadores
                          informarse de los mismos previo a la solicitud de cada
                          servicio.
                        </v-card-text>
                        <v-card-text>
                          22. Modalidad solicitud de Candidatos. Vacanta también
                          ofrece a los Reclutadores, en caso de que sea
                          seleccionando la opción de este servicio, el servicio
                          consistente en proporcionar la información de número
                          limitado de Candidatos que a criterio exclusivo de
                          Vacanta cumplan total o parcialmente con las
                          características que requieran los Reclutadores para
                          una oferta de trabajo en particular. Bajo esta
                          modalidad, los Reclutadores deberán proporcionar toda
                          la información de la oferta laboral que solicite
                          Vacanta, misma que será procesada internamente por
                          Vacanta en su base de datos de Candidatos y Vacanta se
                          obliga a proporcionar a los Reclutadores dentro del
                          plazo no superior a 24 horas la información del perfil
                          y contacto del número de Candidatos al que se haya
                          comprometido a través de los Sitios Web que a criterio
                          exclusivo de Vacanta cumplan parcial o totalmente con
                          las características de la oferta laboral en cuestión.
                          En caso que Vacanta no identifique dicho número de
                          Candidatos potencialmente idóneos para cubrir la
                          oferta laboral, lo hará del conocimiento de los
                          Reclutadores previo a requerirles el pago de la
                          contraprestación correspondiente. Vacanta hará del
                          conocimiento de los Reclutadores la información de los
                          candidatos mediante publicación en los Sitios Web, lo
                          cual incluirá su perfil y datos de contacto. Dicha
                          información se pondrá a disposición de los
                          Reclutadores por el plazo de al menos 48 horas y
                          después se eliminará. Si los Reclutadores desean tener
                          acceso nuevamente a la información con posterioridad a
                          ese plazo, deberán solicitar un nuevo servicio a
                          Vacanta a través de los Sitios Web. Los Reclutadores
                          reconocen que no será responsabilidad de Vacanta
                          contactar a los Candidatos y que tampoco será
                          responsabilidad de Vacanta si los Candidatos no
                          aceptan iniciar un proceso de reclutamiento con los
                          Reclutadores para cubrir la oferta laboral.
                        </v-card-text>
                        <v-card-text>
                          23. Modalidades de servicios a Candidatos. Vacanta no
                          cobrará contraprestación alguna por facilitar a los
                          Candidatos que se contacten con los Reclutadores para
                          cubrir una oferta laboral o la creación de un usuario
                          y contraseña en los Sitios Web. Los Candidatos
                          reconocen que no será responsabilidad de Vacanta el
                          efectivamente ser contactados por los Reclutadores,
                          iniciar un proceso de reclutamiento o incluso ser
                          seleccionado para cubrir una oferta laboral, puesto
                          que reconoce que quedará a exclusivo criterio de los
                          Reclutadores el identificar los perfiles que estimen
                          más convenientes a sus intereses.
                        </v-card-text>
                        <v-card-text>
                          24. Precio. La contraprestación por los servicios
                          ofrecidos por Vacanta a través de los Sitios Web, se
                          hará del conocimiento de los Usuarios a través de los
                          referidos Sitios Web, antes de que acepten la
                          prestación del servicio correspondiente. El monto de
                          la contraprestación podrá variar a exclusivo criterio
                          de Vacanta, por lo que se sugiere a los Usuarios que
                          se informen del mismo antes de la solicitud de cada
                          servicio.
                        </v-card-text>
                        <v-card-text>
                          25. Métodos de pago. Vacanta ofrecerá a los Usuarios
                          diversos métodos de pago por sus servicios, entre
                          ellos (i) pago mediante tarjeta de débito o crédito a
                          través de una plataforma de pagos administrada por un
                          tercero, la cual podrá ser utilizada por los Usuarios
                          a su exclusiva responsabilidad y (ii) pago mediante
                          depósito o transferencia bancaria previo la emisión de
                          una orden de compra en la cuenta bancaria titularidad
                          de Vacanta que para tal efecto se informe
                          oportunamente. En cualquier caso, los Usuarios tendrán
                          derecho a solicitar a Vacanta el comprobante fiscal
                          digital por internet (en lo sucesivo “CFDI”)
                          correspondiente dentro del mes en que hayan realizado
                          el pago respectivo.
                        </v-card-text>
                        <v-card-text>
                          26. Medidas de seguridad. Vacanta tomará las medidas
                          requeridas por la normatividad aplicable para proteger
                          la información y la disponibilidad de uso de la
                          plataforma; sin embargo, no se hará responsable si,
                          aún tomando las medidas previamente referidas,
                          terceros acceden sin autorización y previa violación
                          de las medidas de seguridad a los Sitios Web o su
                          central operativa, interfieren con su disponibilidad
                          de uso o vulneran la protección de la información y
                          los datos personales de los Usuarios.
                        </v-card-text>
                        <v-card-text>
                          27. Limitación de daños. Los Usuarios reconocen que
                          Vacanta no será responsable por los daños que pudieran
                          sufrir los Usuarios derivado de los servicios que
                          disfruten de Vacanta y/o a través de los Sitios Web,
                          incluyendo sin limitar (i) el que no se satisfagan las
                          expectativas o necesidades de los Reclutadores o los
                          Candidatos con la información que en su caso obtengan
                          de los Sitios Web (ii) el supuesto en se cubra una
                          oferta laboral pero alguna de las partes no se
                          encuentre satisfecha con el comportamiento o
                          características de los Candidatos o los Reclutadores y
                          las empresas que en su caso representen y (iii) daños
                          que puedan causar los Candidatos o los Reclutadores a
                          una de las partes o a terceros ya sea por acción u
                          omisión negligente, incluyendo acciones que se puedan
                          tipificar como conductas delictivas.
                        </v-card-text>
                        <v-card-text>
                          28. Misceláneos. Los Usuarios reconocen que Vacanta
                          tendrá derecho a subcontratar total o parcialmente los
                          servicios objeto de este instrumento jurídico y a
                          ceder total o parcialmente los derechos y obligaciones
                          del presente instrumento jurídico sin necesidad de
                          consentimiento ni informe a los Usuarios. Las partes
                          reconocen que los términos identificados con mayúscula
                          inicial y que hayan sido específicamente definidos en
                          este instrumento jurídico tendrán el mismo significado
                          en singular que en plural y en femenino que en
                          masculino. Para la interpretación del presente
                          instrumento jurídico las partes se refieren a la
                          legislación aplicable en Chihuahua, Chihuahua, México
                          y para el cumplimiento de sus derechos y obligaciones
                          las partes se someten a los órganos jurisdiccionales
                          con residencia en Chihuahua, Chihuahua, México.
                        </v-card-text>
                        <v-card-text>
                          Fecha de actualización más reciente: 06 de Mayo de
                          2022
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-dialog>
            </v-row>
          </div>
        </v-col>
        <v-col cols="4" class="white--text text-center">
          <h3 style="color:#2afed6">Nosotros</h3>
          <br />
          <div>
            <template>
              <v-row justify="center">
                <v-dialog
                  v-model="dialogosnuevo"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      style="color:white !important;margin-bottom:25px;"
                      v-bind="attrs"
                      class="preguntas"
                      v-on="on"
                      >Quienes Somos</a
                    >
                    <br />
                  </template>
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-btn icon dark @click="dialogosnuevo = false">
                        <v-icon style="color:black">mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title style="color:black"
                        >Quienes Somos</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn
                          dark
                          text
                          @click="dialogosnuevo = false"
                          style="color:black"
                        >
                          Salir
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-list three-line subheader>
                      <br />
                      <v-list-item-title
                        class="text-center"
                      ></v-list-item-title>
                      <v-list-item>
                        <br />

                        <v-list-item-content>
                          <v-list-item-title
                            ><strong>¿Qué es Vacanta? </strong>
                          </v-list-item-title>
                          <v-card-text>
                            Vacanta es una bolsa de trabajo, enfocado en
                            facilitar y agilizar la conexión entre empresas y
                            candidatos a puestos de trabajo.
                            <br />
                          </v-card-text>

                          <v-list-item-title
                            ><strong>¿Qué nos hace diferentes?</strong>
                          </v-list-item-title>
                          <v-card-text>
                            Las vacantes que son publicadas en Vacanta, incluyen
                            botones interactivos que permiten comunicarse
                            directamente al candidato con la empresa, agilizando
                            y facilitando la conexión del candidato con las
                            vacantes de su interés.
                          </v-card-text>

                          <!-- 
                          <v-list-item-title
                            ><strong>Listado de Candidatos</strong>
                          </v-list-item-title>
                          <v-card-text>
                            • Llena los requisitos de la vacante y podrás visualizar una lista con la información de todos los
candidatos de nuestra base de datos que coincidan con los requisitos.<br />
                            • Ideal para tener rápidamente a la mano opciones de
                            candidatos que puedan cubrir la vacante.<br />
                            • Tú decides que tan especifico quieres que sean los
                            filtros para la búsqueda de candidatos potenciales.
                            <br />
                            • Para consultar el precio debes de estar
                            registrado, hazlo es gratuito, rápido y sencillo.<br />
                          </v-card-text>

                          <v-list-item-title
                            ><strong>Listado de Candidatos Operativos</strong>
                          </v-list-item-title>
                          <v-card-text>
                            • Ideal para la búsqueda de personal para puestos
                            como: <br />
                            Operadores de Producción, Limpieza, Vigilancia,
                            Ayudante General, Cocinas y Cajeros.<br />
                            • llena los requisitos de la vacante y recibirás una
                            lista con la información de 7 candidatos de perfil
                            operativo que coincidan con los requisitos.<br />
                            • Con esta herramienta evitaras la colocación de
                            lonas en las fachadas de la empresa, así como pedir
                            referencias al mismo personal de la empresa.
                            Agilizaras el proceso para cubrir tu vacante
                            operativa. <br />
                            • Para consultar el precio debes de estar
                            registrado, hazlo es gratuito, rápido y sencillo.<br />
                          </v-card-text> -->

                          <v-list-item-title
                            ><strong>¿Dónde se promueven las vacantes?</strong>
                          </v-list-item-title>
                          <v-card-text>
                            Además de promover constantemente las visitas a
                            nuestra página mediante estrategias de mercadotecnia
                            y publicidad, las vacantes que publicamos en la
                            página de Vacanta también son publicadas en nuestras
                            redes sociales, promoviendo el ingreso de candidatos
                            al portal para que puedan revisar las oportunidades
                            de empleo y contactarse directamente con las
                            empresas. También, por medio de nuestra línea
                            empresarial de WhatsApp canalizamos a los
                            candidatos, que nos escriben preguntando por
                            oportunidades de trabajo, con vacantes que van de
                            acuerdo a su experiencia o área de interés.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </div>
          <div>
            <template>
              <v-row justify="center">
                <br />
                <v-dialog
                  v-model="dialogos"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      style="color:white !important;margin-bottom:25px;"
                      v-bind="attrs"
                      class="preguntas"
                      v-on="on"
                      >Productos</a
                    >
                    <br />
                  </template>
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-btn icon dark @click="dialogos = false">
                        <v-icon style="color:black">mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title style="color:black"
                        >Productos</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn
                          dark
                          text
                          @click="dialogos = false"
                          style="color:black"
                        >
                          Salir
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-list three-line subheader>
                      <br />
                      <v-list-item-title
                        class="text-center"
                      ></v-list-item-title>
                      <v-list-item>
                        <br />

                        <v-list-item-content>
                          <v-container style="vertical-align:center">
                            <v-row>
                              <v-col style="margin-top: 50px;">
                                <v-card
                                  class="mx-auto"
                                  style="color:#FFC000;text-align:center;background-color:black;border-radius:50px; height:450px;"
                                >
                                  <br />
                                  <v-card-text
                                    class="pt-6"
                                    style="position: relative;"
                                  >
                                    <div
                                      style="color:#2afed6;text-align:center;background-color:black"
                                      class="  text-h4 mb-2"
                                    >
                                      <strong> Vacante Gratuita</strong>
                                    </div>

                                    <v-row>
                                      <v-container>
                                        <br />

                                        <div class="text-center">
                                          <v-row justify="center">
                                            <v-dialog
                                              v-model="dialogGratis"
                                              fullscreen
                                              hide-overlay
                                              transition="dialog-bottom-transition"
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  large
                                                  style="margin-top:100px; text-decoration:underline;font-size:30px;"
                                                  dark
                                                  text
                                                >
                                                  VER DETALLES
                                                  <v-icon
                                                    style="font-size:30px;margin-top:0px;"
                                                    >mdi-arrow-down</v-icon
                                                  ></v-btn
                                                >
                                              </template>
                                              <v-card
                                                style="background-color:black !important;"
                                              >
                                                <v-toolbar dark color="black">
                                                  <v-btn
                                                    icon
                                                    dark
                                                    @click="
                                                      dialogGratis = false
                                                    "
                                                  >
                                                    <v-icon>mdi-close</v-icon>
                                                  </v-btn>
                                                  <v-toolbar-title
                                                    >Vacante
                                                    Gratuita</v-toolbar-title
                                                  >
                                                  <v-spacer></v-spacer>
                                                  <v-toolbar-items>
                                                  </v-toolbar-items>
                                                </v-toolbar>
                                                <v-container>
                                                  <v-row>
                                                    <v-col>
                                                      <v-card
                                                        style="background-color:white !important; height:550px;border-radius:40px"
                                                      >
                                                        <div
                                                          class="text-center"
                                                        >
                                                          <br />
                                                          <h1
                                                            style="color:rgb(255 192 0);;margin-top:50px; font-size:40px;"
                                                          >
                                                            Vacante Gratuita
                                                          </h1>

                                                          <h2
                                                            style="margin-top:50px;"
                                                          >
                                                            La publicación
                                                            incluye lo
                                                            siguiente:
                                                          </h2>
                                                          <br>
                                                          <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                            Duración de 15 días
                                                            en el portal.
                                                          </p>
                                                          <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                            Logotipo de la
                                                            empresa.
                                                          </p>
                                                          <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                            Botones de teléfono, WhatsApp
                                                            y correo de la
                                                            empresa.
                                                          </p>
                                                           <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                            3 Vacantes gratuitas al mes
                                                          </p>
                                                           <v-btn
                                            style="margin-top:20px;color:black;"
                                            color="primary"
                                            large
                                            @click="
                                                      dialogGratis = false
                                                    "
                                          >
                                            Salir
                                           
                                          </v-btn>
                                                        </div>
                                                      </v-card>
                                                    </v-col>
                                                  </v-row>
                                                </v-container>
                                              </v-card>
                                            </v-dialog>
                                          </v-row>
                                          <br />
                                          <v-btn
                                            style="margin-top:90px;color:black;"
                                            color="primary"
                                            large
                                            @click="irEmpresas()"
                                          >
                                            SELECCIONAR
                                            <v-icon style="color:black;"
                                              >mdi-gesture-tap</v-icon
                                            >
                                          </v-btn>
                                        </div>
                                      </v-container>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                              <v-col style="margin-top:50px;">
                                <v-card
                                  class="mx-auto"
                                  style="color:#FFC000;text-align:center;background-color:black;border-radius:50px;height:450px;"
                                >
                                  <v-card-text
                                    class="pt-6"
                                    style="position: relative;"
                                  >
                                    <br />
                                    <div
                                      style="color:#2afed6;text-align:center;background-color:black"
                                      class="  text-h4 mb-2"
                                    >
                                      <strong>Vacante Elite</strong>
                                      <br />
                                      <br />
                                      <span style="color:#ffc000"
                                        >$ 399.00 mas I.V.A
                                      </span>
                                    </div>

                                    <v-row>
                                      <v-container>
                                        <div class="text-center">
                                          <v-row justify="center">
                                            <v-dialog
                                              v-model="dialogElite"
                                              fullscreen
                                              hide-overlay
                                              transition="dialog-bottom-transition"
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  large
                                                  style="margin-top:40px; text-decoration:underline;font-size:30px;"
                                                  dark
                                                  text
                                                >
                                                  VER DETALLES
                                                  <v-icon
                                                    style="font-size:30px;margin-top:0px;"
                                                    >mdi-arrow-down</v-icon
                                                  ></v-btn
                                                >
                                              </template>
                                              <v-card
                                                style="background-color:black;"
                                              >
                                                <v-toolbar dark color="black">
                                                  <v-btn
                                                    icon
                                                    dark
                                                    @click="dialogElite = false"
                                                  >
                                                    <v-icon>mdi-close</v-icon>
                                                  </v-btn>
                                                  <v-toolbar-title
                                                    >Vacante
                                                    Elite</v-toolbar-title
                                                  >
                                                  <v-spacer></v-spacer>
                                                  <v-toolbar-items>
                                                  </v-toolbar-items>
                                                </v-toolbar>
                                                 <v-container>
                                                  <v-row>
                                                    <v-col>
                                                      <v-card
                                                        style="background-color:white !important;;border-radius:40px"
                                                      >
                                                        <div
                                                          class="text-center"
                                                        >
                                                          
                                                          <h1
                                                            style="color:rgb(255 192 0);;margin-top:0px; font-size:40px;"
                                                          >
                                                            Vacante Elite
                                                          </h1>

                                                          <h2
                                                            style="margin-top:0px;"
                                                          >
                                                            La publicación
                                                            incluye lo
                                                            siguiente:
                                                          </h2>
                                                          <br>
                                                          <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                           Duración de 30 días en el portal.
                                                          </p>
                                                          <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                            Logotipo de la
                                                            empresa.
                                                          </p>
                                                          <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                            Botones de teléfono, WhatsApp
                                                            y correo de la
                                                            empresa.
                                                          </p>
                                                         
                                                          <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                            Publicación de la vacante en redes sociales de Vacanta.

                                                          </p>
                                                          <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                           La vacante se comparte a candidatos que preguntan por <br> vacantes a nuestra línea empresarial de WhatsApp.
                                                          </p>
                                                          <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                          Anuncio de la vacante con colores llamativos.

                                                          </p>
                                                          <small>*Tienes 30 días para publicar la vacante a
partir de la acreditación del pago. </small>
<br>
<v-btn
                                            style="margin-top:0px;color:black;"
                                            color="primary"
                                            large
                                            @click="
                                                      dialogElite = false
                                                    "
                                          >
                                            Salir
                                            
                                          </v-btn>
                                                        </div>
                                                      </v-card>
                                                    </v-col>
                                                  </v-row>
                                                </v-container>
                                              </v-card>
                                            </v-dialog>
                                          </v-row>
                                          <br />
                                          <v-btn
                                            style="margin-top:80px;color:black;"
                                            color="primary"
                                            large
                                            @click="irEmpresas()"
                                          >
                                            COMPRAR
                                            <v-icon style="color:black;"
                                              >mdi-cart</v-icon
                                            >
                                          </v-btn>
                                        </div>
                                      </v-container>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                              <v-col style="margin-top:50px;">
                                <v-card
                                  class="mx-auto"
                                  style="color:#FFC000;text-align:center;background-color:black;border-radius:50px;height:450px;"
                                >
                                  <v-card-text
                                    class="pt-6"
                                    style="position: relative;"
                                  >
                                    <br />
                                    <div
                                      style="color:#2afed6;text-align:center;background-color:black"
                                      class="  text-h4 mb-2"
                                    >
                                      <strong>Paquete Elite</strong>
                                      <br />
                                      <br />
                                      <span style="color:#ffc000"
                                        >$ 3,599.00 mas I.V.A
                                      </span>
                                    </div>

                                    <v-row>
                                      <v-container>
                                        <div class="text-center">
                                          <v-row justify="center">
                                            <v-dialog
                                              v-model="dialogPaquete"
                                              fullscreen
                                              hide-overlay
                                              transition="dialog-bottom-transition"
                                            >
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  large
                                                  style="margin-top:40px; text-decoration:underline;font-size:30px;"
                                                  dark
                                                  text
                                                >
                                                  VER DETALLES
                                                  <v-icon
                                                    style="font-size:30px;margin-top:0px;"
                                                    >mdi-arrow-down</v-icon
                                                  ></v-btn
                                                >
                                              </template>
                                              <v-card
                                                style="background-color:black;"
                                              >
                                                <v-toolbar dark color="black">
                                                  <v-btn
                                                    icon
                                                    dark
                                                    @click="
                                                      dialogPaquete = false
                                                    "
                                                  >
                                                    <v-icon>mdi-close</v-icon>
                                                  </v-btn>
                                                  <v-toolbar-title
                                                    >Paquete
                                                    Elite</v-toolbar-title
                                                  >
                                                  <v-spacer></v-spacer>
                                                  <v-toolbar-items>
                                                  </v-toolbar-items>
                                                </v-toolbar>
                                                 <v-container>
                                                  <v-row>
                                                    <v-col>
                                                      <v-card
                                                        style="background-color:white !important; height:550px;border-radius:40px"
                                                      >
                                                        <div
                                                          class="text-center"
                                                        >
                                                          <br />
                                                          <h1
                                                            style="color:rgb(255 192 0);;margin-top:50px; font-size:40px;"
                                                          >
                                                            Paquete Elite

                                                          </h1>

                                                          <h2
                                                            style="margin-top:50px;"
                                                          >
                                                            El paquete
                                                            incluye lo
                                                            siguiente:
                                                          </h2>
                                                          <br>
                                                          <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                            Publica vacantes Elite de forma ilimitada por 30 días.
                                                          </p>
                                                          <p style="font-size:27px;">
                                                            <v-icon
                                                              style="color:rgb(0 176 80);"
                                                              >mdi-check-circle</v-icon
                                                            >
                                                            Las publicaciones incluyen todos los beneficios de la vacante Elite.
                                                          </p>
                                                          
                                                          <small>*Los días comenzar a partir de la acreditación del pago.</small>
                                                          <br>
                                                          <v-btn
                                            style="margin-top:40px;color:black;"
                                            color="primary"
                                            large
                                            @click="
                                                      dialogPaquete = false
                                                    "
                                          >
                                            Salir
                                           
                                          </v-btn>
                                                          
                                                        </div>
                                                      </v-card>
                                                    </v-col>
                                                  </v-row>
                                                </v-container>
                                              </v-card>
                                            </v-dialog>
                                          </v-row>

                                          <br />
                                          <v-btn
                                            style="margin-top:80px;color:black;"
                                            color="primary"
                                            large
                                            @click="irEmpresas()"
                                          >
                                            COMPRAR
                                            <v-icon style="color:black;"
                                              >mdi-cart</v-icon
                                            >
                                          </v-btn>
                                        </div>
                                      </v-container>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </div>

          <div>
            <template>
              <v-row justify="center">
                <v-dialog
                  v-model="dialoges"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      style="color:white !important;margin-bottom:25px;"
                      v-bind="attrs"
                      class="preguntas"
                      v-on="on"
                      >Misión y Visión</a
                    >
                    <br />
                  </template>
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-btn icon dark @click="dialoges = false">
                        <v-icon style="color:black">mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title style="color:black"
                        >Misión y Visión</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn
                          dark
                          text
                          @click="dialoges = false"
                          style="color:black"
                        >
                          Salir
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-list three-line subheader>
                      <br />
                      <v-list-item-title
                        style="font-size:35px;"
                        class="text-center"
                        ><strong>Misión</strong></v-list-item-title
                      >
                      <v-list-item>
                        <v-list-item-content>
                          <v-card-text
                            style="font-size:25px;margin-bottom:20px;"
                            class="text-center"
                            >Brindar soluciones que agilicen y faciliten el
                            proceso de conexión entre candidatos y vacantes.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list three-line subheader>
                      <br />
                      <v-list-item-title
                        style="font-size:35px;"
                        class="text-center"
                        ><strong>Visión </strong></v-list-item-title
                      >
                      <v-list-item>
                        <v-list-item-content>
                          <v-card-text
                            class="text-center"
                            style="font-size:25px;line-height:normal;"
                            >Ser un medio reconocido por brindar un servicio
                            confiable, rápido y sencillo, promocionando
                            oportunidades laborales para todo tipo de perfiles.
                            Contribuyendo a crear un mejor futuro para las
                            personas.
                          </v-card-text>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-img
                          src="./assets/PNG2.png"
                          width="200px"
                          style="margin:auto; display:content;"
                        ></v-img>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </div>
        </v-col>
        <v-col cols="4" class="white--text text-center">
          <h3 style="color:#2afed6">Contacto</h3>

          <div style="margin-top:10px;">
            <a style="color:white; " href="mailto:info@vacanta.mx"
              >info@vacanta.mx</a
            >
            <br />
            <a target="_blank" href="https://www.facebook.com/vacanta.mx/">
              <v-icon color="white">mdi-facebook</v-icon>
            </a>
            <a target="_blank" href="https://twitter.com/Vacantamx">
              <v-icon color="white" style="margin-left:10px"
                >mdi-twitter</v-icon
              >
            </a>

            <a target="_blank" href="https://www.instagram.com/vacanta.mx/">
              <v-icon color="white" style="margin-left:10px"
                >mdi-instagram</v-icon
              >
            </a>

            <br />
            <v-divider style="margin-top:30px;"></v-divider>
            <br />
            <p class="white--text text-center">
              Vacanta ® Derechos Reservados {{ new Date().getFullYear() }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import BuscaOperativo from "../src/components/BuscaOperativo.vue";
import BuscaServicios from "../src/components/BuscaServicios.vue";
import Busca from "../src/components/Busca.vue";
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      dialogGratis: false,
      dialogElite: false,
      dialogPaquete: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog: false,
      dialogo: false,
      dialoges: false,
      dialogos: false,
      dialogosnuevo: false,
      dialogoTer: false,
      drawer: null,
      foto: null,
      nombre: null,
      apellido: null,
      creditos: 0,
      pendientes: 0,
      gratis: 0,
    };
  },
  watch: {
    id: function() {
      if (this.id.empresa) {

          axios
          .post(
            "/vacantes/vacantegratis",
            {
              empresa: this.$store.state.id.empresa,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            console.log(response.data)
            this.gratis = response.data[0].vacantes;

          })

          .catch((error) => {
            this.gratis = 0;
          });

        axios
          .post(
            "/empresas/perfilempresa",
            {
              empresa: this.$store.state.id.empresa,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.foto = response.data[0].logo;
            this.nombre = response.data[0].nom_comercial;
          });

        axios
          .post(
            "/creditos/creditosPendientes",
            {
              empresa: this.$store.state.id.empresa,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.pendientes = response.data[0].pendientes;
          })

          .catch((error) => {
            this.pendientes = 0;
          });

         
        axios
          .get("/creditos/creditosempresa/" + this.$store.state.id.empresa, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.creditos = response.data.creditos;

          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.id.servicio) {
        axios
          .post(
            "/servicios/perfilEmpresaServicio",
            {
              empresa: this.$store.state.id.id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.foto = response.data[0].logo;
            this.nombre = response.data[0].nom_comercial;
          })
          .catch((error) => {
            console.log("error");
          });
      } else if (this.id) {
        axios
          .get("/candidatos/" + this.$store.state.id.id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.foto = response.data.foto;
            this.nombre = response.data.nombre;
          })
          .catch((error) => {});
      } else {
        console.log("hola");
      }
    },
  },

  computed: {
    ...mapState(["correo", "empresa", "id"]),

    logueado() {
      return this.$store.state.token;
    },
    id() {
      return this.$store.state.id;
    },
    created() {
      this.$store.dispatch("autoLogin");
    },
  },

  methods: {
    irEmpresas() {
      this.dialogos = false;
      this.$router.push({ name: "loginEmpresas" });
    },
    probando() {
      this.$router.go(this.$router.currentRoute);
    },
    salir() {
      this.$store.dispatch("salir");
    },
  },
  components: {
    Busca,
    BuscaOperativo,
    BuscaServicios,
  },
};
</script>

<style scoped>
.div_contenedor {
  position: relative;
  padding-bottom: 56.25%; /*panorámico*/
  padding-top: 25px;
  height: 0;
}
.div_contenedor iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.v-application a {
  text-decoration: none;
}
@media only screen and (min-width: 868px) {
  #como {
    font-size: 15px;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 768px) {
  #milogo {
    width: 140px !important;
  }
  .social-buttons {
    display: none;
  }
  .boton-tr {
    display: none;
  }
  .logo {
    display: none;
  }
  .texto-logo {
    font-size: 20px;
  }
  .usuario {
    margin-left: -120px;
  }
  .autocompletado-estado {
    display: none;
  }
  .autocompletado-tipo {
    display: none;
  }
  .autocompletado-puesto {
    width: 74px;
  }
  ::v-deep .autocompletado-puesto .v-label {
    font-size: 11.5px;
  }
  ::v-deep .autocompletado-puesto .v-icon {
    font-size: 18px;
    margin-left: 26px;
  }
  ::v-deep .autocompletado-ciudad .v-label {
    font-size: 13px;
  }
  ::v-deep .autocompletado-ciudad .v-icon {
    font-size: 18px;
    margin-left: 26px;
  }
  .autocompletado-ciudad .v-label {
    font-size: 14px;
    width: 50px;
  }
  .autocompletado-ciudad {
    width: 70px;
  }
  .boton-buscar {
    width: 86px;
  }
}
.v-list-item--active {
  background-color: #2afed6;
}
@media only screen and (max-width: 768px) {
  /* todo lo de cel */
  #como {
    font-size: 12px;
    margin-left: -16px;
    text-decoration: underline;
  }
  .preguntas {
    font-size: 12px;
  }
  #titulovideo {
    font-size: 14px;
  }
  #botonvideo {
    font-size: 13px;
  }
  .login {
    font-size: 10px !important;
  }
}
</style>
