<template>
  <v-app>
    <v-container fluid>
      <br />

      <!-- Publicidad cabezera -->
      <v-row>
        <v-col cols="12" v-for="imagen of header" :key="imagen.folio">
          <a :href="imagen.url" target="_blank">
            <v-img
              href="imagen.url"
              style="border-radius:10px;margin-top:-15px"
              :src="imagen.imagen"
            >
            </v-img>
          </a>
        </v-col>
      </v-row>
      <!-- Todo -->
      <v-row>
        <v-col cols="12" sm="12" md="9" lg="9" style="margin-top:-18px;">
          <div v-if="misvacantes.length < 1">
            <p>
              Tu busqueda no tiene resultados, modifique los filtros.
            </p>
          </div>

          <v-card
            class="tarjeta"
            v-for="vacante in misvacantes"
            :key="vacante.folio"
            style="padding:0px !important; border-radius:20px;"
            
          >
            <v-container v-if="vacante.tipo_publicacion == 2"  fluid class="headernegro">
              <v-row>
                <!--cel -->

                <v-col cols="5" class="cel" v-if="vacante.anonimo == false">
                  <div v-if="vacante.logo" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="vacante.logo"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="12" class="cel" v-if="vacante.anonimo == true">
                  <div class="text-center">
                    <p
                      class="vacantePosicion"
                      style="color:white; text-align:center; "
                    >
                      {{ vacante.posicion }}
                    </p>
                    <span
                      v-if="vacante.anonimo == true"
                      style="color:#FFC000;font-size:14px;text-align:center;"
                    >
                      <strong>Empresa Líder </strong></span
                    >
                  </div>
                  
                </v-col>
                <v-col cols="7" id="cel">
                  <div v-if="vacante.anonimo == false" class="text-center">
                    <p
                      class="vacantePosicion"
                      style="color:white; text-align:center; "
                    >
                      {{ vacante.posicion }}
                    </p>

                    <p
                      v-if="vacante.anonimo == false"
                      style="color: rgb(255, 192, 0);
text-align: center;
"
                    >
                      {{ vacante.empresa }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" id="cel" style="margin-top:-20px;">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:#2afed6; padding:10px; border-radius:12px; text-align:center;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 1">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 0">
                          Mensual</span
                        ><br />
                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}

                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>

                  
                </v-col>
                <v-col cols="12" class="cel" style="margin-top:-20px;">
                  <div class="text-center">
                    <p class="text-center" style="color:white;font-size:18px;">
                      <strong> Da click para contactarte</strong>
                    </p>
                    <div v-if="vacante.telefono">
                      <v-btn
                        @click="
                          estadisticaCel(vacante.folioempresa, vacante.folio)
                        "
                        color="#004AAD"
                        style="background-color:#004AAD;color:white;font-size:14px;margin-top:-20px;border-radius:17px;"
                        target="_blank"
                        v-if="vacante.telefono"
                        :href="'tel:' + vacante.telefono"
                        ><v-icon style="padding:12px;margin-left:-7px;">
                          mdi-phone
                        </v-icon>
                        Llamar
                      </v-btn>
                    </div>

                    <div v-if="vacante.whatsapp">
                      <v-btn
                        color="#20B038"
                        @click="
                          estadisticaW(vacante.folioempresa, vacante.folio)
                        "
                        v-if="vacante.whatsapp"
                        style="color:white;font-size:14px;border-radius:17px;"
                        target="_blank"
                        :href="
                          'https://api.whatsapp.com/send/?phone=52' +
                            vacante.whatsapp +
                            
                            '&text=¡Hola!%20vi%20la%20vacante%20' + vacante.posicion +  '%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20más%20información'
                        "
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-whatsapp
                        </v-icon>
                        Enviar WhatsApp
                      </v-btn>
                    </div>

                    <div>
                      <v-btn
                        @click="
                          estadisticaCorreo(vacante.folioempresa, vacante.folio)
                        "
                        v-if="vacante.correo"
                        style="color:white;font-size:14px; margin-top:4px; border-radius:17px;"
                        target="_blank"
                        :href="
                          'mailto:' +
                            vacante.correo +
                            '?Subject=CV%20enviado%20desde%20VACANTA' +
                            '&body=Escribe%20aqui%20el%20nombre%20del%20puesto%20para%20el%20que%20estas%20enviando%20tu%20Cv'
                        "
                        color="#FFC000"
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-email
                        </v-icon>
                        Enviar CV
                      </v-btn>
                    </div>
                    <br />

                  
                  </div>
                </v-col>

                <!-- PC -->

                <v-col cols="2" class="pc" v-if="vacante.anonimo == false">
                  <div v-if="vacante.logo" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="vacante.logo"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>

                <v-col cols="8" class="text-center pc" v-if="vacante.anonimo">
                  <p
                    class="vacantePosicion"
                    style="color:white; text-align:center; "
                  >
                    {{ vacante.posicion }}
                  </p>

                  <h3 style="color:#FFC000;" class="vacanteEmpresa text-center">
                   {{ vacante.empresa}}
                  </h3>

              
                </v-col>
                <v-col cols="4" class="pc" v-if="vacante.anonimo">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:#2afed6; padding:10px; border-radius:12px; text-align:center;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 1">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 0">
                          Mensual</span
                        ><br />
                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}

                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>

                  
                </v-col>
                <v-col
                  cols="6"
                  class="text-center pc"
                  v-if="vacante.anonimo == false"
                >
                  <p
                    class="vacantePosicion"
                    style="color:white; text-align:center; "
                  >
                    {{ vacante.posicion }}
                  </p>

                  <h3 style="color:#FFC000;" class="vacanteEmpresa text-center">
                 {{ vacante.empresa }}
                  </h3>
                                    

                </v-col>
                <v-col cols="4" class="pc" v-if="vacante.anonimo == false">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:#2afed6; padding:10px; border-radius:12px; text-align:center;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 1">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 0">
                          Mensual</span
                        ><br />
                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}

                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>
                  <div class="text-center">
                  
                  </div>
                </v-col>
                <!-- Nuevo Renglon -->
          
                  <v-col cols="4" class="pc">
                  <div class="text-center"  v-if="vacante.telefono">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialogT"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              prueba(
                                vacante.folioempresa,
                                vacante.folio,
                                vacante
                              )
                            "
                            color="#004AAD"
                            style="background-color:#004AAD;color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                            target="_blank"
                            v-if="vacante.telefono"
                            ><v-icon style="padding:12px;margin-left:-7px;">
                              mdi-phone
                            </v-icon>
                            Llamar
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            Teléfono
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ objeto }}</v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialogT = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>

                </v-col>
                <v-col cols="4" class="pc">
                  <div class="text-center" v-if="vacante.whatsapp">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialogoW"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              prueba2(
                                vacante.folioempresa,
                                vacante.folio,
                                vacante
                              )
                            "
                            color="#20B038"
                            style="color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                            target="_blank"
                          >
                            <v-icon style="padding:12px;margin-left:-7px;">
                              mdi-whatsapp
                            </v-icon>
                            Enviar WhatsApp
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            WhatsApp
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ objeto }}
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialogoW = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                </v-col>
                <v-col cols="4" class="pc">
                      <div class="text-center">

                    <v-dialog
                      :retain-focus="false"
                      v-model="dialogoC"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            prueba3(
                              vacante.folioempresa,
                              vacante.folio,
                              vacante
                            )
                          "
                          color="#FFC000"
                          style="color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                          target="_blank"
                        >
                          <v-icon style="padding:12px;margin-left:-7px;">
                            mdi-email
                          </v-icon>
                          Enviar CV
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Correo
                        </v-card-title>
                        <v-card-text
                          style="font-size:18px; text-align:center;"
                          >{{ objeto }}</v-card-text
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            style="color:black;"
                            @click="dialogoC = false"
                          >
                            Salir
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                      </div>
                    
                    


                </v-col>
                
                <v-row>
                <v-col md="4" class="text-center">
                  <p style="color:#2afed6">
                    <strong> Lugar</strong>
                  </p>
                  <p style=" border-radius:6px;color:white; ">
                    <strong>
                      {{ vacante.ciudad }} ,
                      {{ vacante.estado }}
                    </strong>
                  </p>
                </v-col>
                <v-col md="4" xs="6" class="text-center">
                  <p style="color:#2afed6"><strong> Fecha de publicación</strong></p>
                  <p style=" border-radius:6px;color:white;">
                    <strong>
                      {{ moment(vacante.fechacreacion).format("LL") }}
                    </strong>
                  </p>
                </v-col>
                <v-col md="4" xs="12" class="text-center">
                  <p style="color:#2afed6"><strong> Área </strong></p>
                  <p style="color:white; border-radius:6px;">
                    <strong> {{ vacante.sectordesc }}</strong>
                  </p>
                </v-col>
                <v-col cols="12" style="margin-top:-25px;">
                  <div class="text-center">
                     <v-btn  large style="text-decoration:underline;font-size:20px;" dark text v-on:click="postularme(vacante.folio)" >  VER MÁS DETALLES
                        <v-icon style="font-size:20px;margin-top:0px;"
                          >mdi-arrow-down</v-icon
                        ></v-btn>
                  </div>
                </v-col>
              </v-row>
              </v-row>
            </v-container>

             <v-container v-if="vacante.tipo_publicacion == 1"  fluid class="headerblanco" >
              <v-row>
                <!--cel -->

                <v-col cols="5" class="cel" v-if="vacante.anonimo == false">
                  <div v-if="vacante.logo" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="vacante.logo"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="12" class="cel" v-if="vacante.anonimo == true">
                  <div class="text-center">
                    <p
                      class="vacantePosicion"
                      style="color:black; text-align:center; "
                    >
                      {{ vacante.posicion }}
                    </p>
                    <span
                      v-if="vacante.anonimo == true"
                      style="color:#FFC000;font-size:14px;text-align:center;"
                    >
                      <strong>Empresa Líder </strong></span
                    >
                  </div>
                  
                </v-col>
                <v-col cols="7" id="cel">
                  <div v-if="vacante.anonimo == false" class="text-center">
                    <p
                      class="vacantePosicion"
                      style="color:black; text-align:center; "
                    >
                      {{ vacante.posicion }}
                    </p>

                    <p
                      v-if="vacante.anonimo == false"
                      style="color: black
text-align: center;
"
                    >
                      {{ vacante.empresa }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" id="cel" style="margin-top:-20px;">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:white; padding:10px; border-radius:12px; text-align:center;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 1">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 0">
                          Mensual</span
                        ><br />
                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}

                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>

                 
                </v-col>
                <v-col cols="12" class="cel" style="margin-top:-20px;">
                  <div class="text-center">
                    <p class="text-center" style="color:black;font-size:18px;">
                      <strong> Da click para contactarte</strong>
                    </p>
                    <div v-if="vacante.telefono">
                      <v-btn
                        @click="
                          estadisticaCel(vacante.folioempresa, vacante.folio)
                        "
                        color="#A8B8CD"
                        style="background-color:#A8B8CD;color:white;font-size:14px;margin-top:-20px;border-radius:17px;"
                        target="_blank"
                        v-if="vacante.telefono"
                        :href="'tel:' + vacante.telefono"
                        ><v-icon style="padding:12px;margin-left:-7px;">
                          mdi-phone
                        </v-icon>
                        Llamar
                      </v-btn>
                    </div>

                    <div v-if="vacante.whatsapp">
                      <v-btn
                        color="#8CDB6B"
                        @click="
                          estadisticaW(vacante.folioempresa, vacante.folio)
                        "
                        v-if="vacante.whatsapp"
                        style="color:white;font-size:14px;border-radius:17px;"
                        target="_blank"
                        :href="
                          'https://api.whatsapp.com/send/?phone=52' +
                            vacante.whatsapp +
                            
                            '&text=¡Hola!%20vi%20la%20vacante%20' + vacante.posicion +  '%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20más%20información'
                        "
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-whatsapp
                        </v-icon>
                        Enviar WhatsApp
                      </v-btn>
                    </div>

                    <div>
                      <v-btn
                        @click="
                          estadisticaCorreo(vacante.folioempresa, vacante.folio)
                        "
                        v-if="vacante.correo"
                        style="color:white;font-size:14px; margin-top:4px; border-radius:17px;"
                        target="_blank"
                        :href="
                          'mailto:' +
                            vacante.correo +
                            '?Subject=CV%20enviado%20desde%20VACANTA' +
                            '&body=Escribe%20aqui%20el%20nombre%20del%20puesto%20para%20el%20que%20estas%20enviando%20tu%20Cv'
                        "
                        color="#EFCC63"
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-email
                        </v-icon>
                        Enviar CV
                      </v-btn>
                    </div>
                    <br />

                  
                  </div>
                </v-col>

                <!-- PC -->

                <v-col cols="2" class="pc" v-if="vacante.anonimo == false">
                  <div v-if="vacante.logo" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="vacante.logo"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>

                <v-col cols="6" class="text-center pc" v-if="vacante.anonimo">
                  <p
                    class="vacantePosicion"
                    style="color:black; text-align:center; "
                  >
                    {{ vacante.posicion }}
                  </p>

                  <h3 style="color:black" class="vacanteEmpresa text-center">
                   {{ vacante.empresa }}
                  </h3>

              
                </v-col>
                <v-col cols="6" class="pc" v-if="vacante.anonimo">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:white; padding:10px; border-radius:12px; text-align:center;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 1">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 0">
                          Mensual</span
                        ><br />
                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}

                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>

                  
                </v-col>
                <v-col
                  cols="6"
                  class="text-center pc"
                  v-if="vacante.anonimo == false"
                >
                  <p
                    class="vacantePosicion"
                    style="color:black; text-align:center; "
                  >
                    {{ vacante.posicion }}
                  </p>

                  <h3 style="color:black;" class="vacanteEmpresa text-center">
                 {{ vacante.empresa }}
                  </h3>
                                    

                </v-col>
                <v-col cols="4" class="pc" v-if="vacante.anonimo == false">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:white; padding:10px; border-radius:12px; text-align:center;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 1">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 0">
                          Mensual</span
                        ><br />
                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}

                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>
                  <div class="text-center">
                  
                  </div>
                </v-col>
                <!-- Nuevo Renglon -->

                  <v-col cols="4" class="pc">
                  <div class="text-center"  v-if="vacante.telefono">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialogT"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              prueba(
                                vacante.folioempresa,
                                vacante.folio,
                                vacante
                              )
                            "
                            color="#A8B8CD"
                            style="background-color:#A8B8CD;color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                            target="_blank"
                            v-if="vacante.telefono"
                            ><v-icon style="padding:12px;margin-left:-7px;">
                              mdi-phone
                            </v-icon>
                            Llamar
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            Teléfono
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ objeto }}</v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialogT = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>

                </v-col>
                <v-col cols="4" class="pc">
                   <div class="text-center">
                      <v-dialog
                       v-if="vacante.whatsapp"
                        :retain-focus="false"
                        v-model="dialogoW"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              prueba2(
                                vacante.folioempresa,
                                vacante.folio,
                                vacante
                              )
                            "
                            color="#8CDB6B"
                            style="color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                            target="_blank"
                          >
                            <v-icon style="padding:12px;margin-left:-7px;">
                              mdi-whatsapp
                            </v-icon>
                            Enviar WhatsApp
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            WhatsApp
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ objeto }}
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialogoW = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  
                </v-col>
                <v-col cols="4" class="pc">
                      <div class="text-center">
                        

                    <v-dialog
                      :retain-focus="false"
                      v-model="dialogoC"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                  
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            prueba3(
                              vacante.folioempresa,
                              vacante.folio,
                              vacante
                            )
                          "
                          color="#EFCC63"
                          style="color:white;font-size:14px;margin-top:0px;border-radius:17px;"
                          target="_blank"
                        >
                          <v-icon style="padding:12px;margin-left:-7px;">
                            mdi-email
                          </v-icon>
                          Enviar CV
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Correo
                        </v-card-title>
                        <v-card-text
                          style="font-size:18px; text-align:center;"
                          >{{ objeto }}</v-card-text
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            style="color:black;"
                            @click="dialogoC = false"
                          >
                            Salir
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                      </div>
                    
                    


                </v-col>

        

              <v-row>
                <v-col cols="4" class="text-center">
                  <p style="color:black">
                    <strong> Lugar</strong>
                  </p>
                  <p style=" border-radius:6px;color:black; ">
                    <strong>
                      {{ vacante.ciudad }} ,
                      {{ vacante.estado }}
                    </strong>
                  </p>
                </v-col>
                <v-col cols="4" xs="6" class="text-center">
                  <p style="color:black"><strong> Fecha de publicación</strong></p>
                  <p style=" border-radius:6px;color:black;">
                    <strong>
                      {{ moment(vacante.fechacreacion).format("LL") }}
                    </strong>
                  </p>
                </v-col>
                <v-col cols="4" xs="12" class="text-center">
                  <p style="color:black"><strong> Área </strong></p>
                  <p style="color:black; border-radius:6px;">
                    <strong> {{ vacante.sectordesc }}</strong>
                  </p>
                </v-col>
                <v-col cols="12">
                  <div class="text-center">
                     <v-btn  large style="text-decoration:underline;font-size:20px;" light text v-on:click="postularme(vacante.folio)" >  VER MÁS DETALLES
                        <v-icon style="font-size:20px;margin-top:0px;"
                          >mdi-arrow-down</v-icon
                        ></v-btn>
                  </div>
                </v-col>
              </v-row>
              </v-row>
            </v-container>

          </v-card>
        </v-col>

        <v-col
          v-for="imagen of portrait"
          :key="imagen.folio"
          class="columna1"
          sm="12"
          xs="12"
          md="3"
          style=""
        >
          <a :href="imagen.url" target="_blank">
            <v-img
              style="border-radius:10px; "
              :src="imagen.imagen"
              :href="imagen.url"
            >
            </v-img>
          </a>
        </v-col>
        <v-col cols="12">
          <div class="text-center" @click="paginacion(page)">
            <v-pagination
              v-model="page"
              :length="totalPaginas"
              circle
              dark
            ></v-pagination>
          </div>
        </v-col>

        <v-col v-for="imagen of footer" :key="imagen.folio" cols="12">
          <a :href="imagen.url" target="_blank">
            <v-img
              style="border-radius:10px;"
              :src="imagen.imagen"
              :href="imagen.url"
            >
            </v-img>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState, mapActions } from "vuex";
var moment = require("moment");
moment.locale("es-mx");

export default {
  data() {
    return {
      moment: moment,
      posts: [],
      min: 0,
      max: 45000,
      range: [5000, 25000],
      header: [],
      token:
        "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
      dialog: false,
      dialogWhats: false,
      dialogoVacante: false,
      dialogT: false,
      dialogoW: false,
      dialogoC: false,
      footer: [],
      portrait: [],
      nombre: "",
      objeto: null,
      page: 1,
      totalPaginas: null,
      textoVacante:"",
      nivelVacante:"",
      vacanteJornada: "",
      vacanteModalidad: "",
      vacanteContratacion: ""
    };
  },
  computed: {
    id() {
      return this.$store.state.id;
    },
    logueado() {
      return this.$store.state.token;
    },
    ...mapState([
      "elestado",
      "laciudad",
      "puesto",
      "rangoInf",
      "rangoSup",
      "Urlheader",
      "VacanteFolio",
      "miempresa",
      "misvacantes",
      "nuevaPagina",
      "id",
      "Empresa",
      "minuevaEmpresa",
      "minuevaArea",
    ]),
  },
  methods: {
     ...mapMutations([
      "setVacantes",
      "setEspecialidad",
      "setEmpresa",
      "setnuevaEmp",
      "setVacante",
      "setState",
      "setCiudad",
      "setPaginacion",
    ]),
     postularme(id){
        this.$router.push('/vacante/' + id)

      },
    redireccionVacante(vacante){
      console.log(vacante)

    },
    probando(vacante){
    
      this.textoVacante = vacante.descripcion;
      this.nivelVacante = vacante.nivel_educativo;
      this.vacanteJornada = vacante.tipo_jornada;
      this.vacanteModalidad = vacante.tipo_area;
      this.vacanteContratacion = vacante.tipo_contratacion;
    },
    prueba(a, b, c) {
      console.log(a, b, c);
      this.objeto = c.telefono;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: a,
            folio: b,
            donde: "2",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prueba2(a, b, c) {
      console.log(a, b, c);
      this.dialogW = true;
      this.objeto = c.whatsapp;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: a,
            folio: b,
            donde: "3",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prueba3(a, b, c) {
      console.log(a, b, c);
      this.dialogoC = true;
      this.objeto = c.correo;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: a,
            folio: b,
            donde: "1",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaW(emrpresa, folio) {
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: emrpresa,
            folio: folio,
            donde: "3",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaCel(emrpresa, folio) {
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: emrpresa,
            folio: folio,
            donde: "2",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaCorreo(emrpresa, folio) {
      console.log("1", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "1",
            empresa: emrpresa,
            folio: folio,
            donde: "1",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
   
    ...mapActions(["irVacante"]),
    buscaVacante(value) {
      this.posts = value;
    },
    paginacion(page) {
      console.log(page);
      this.setPaginacion(page);
      window.scrollTo(0, 0);
 
    },
  },

   mounted() {
    console.log( "Pagina: ", this.nuevaPagina,"Estado: ", this.elestado,"Area: ", this.minuevaArea,"Ciudad: ", this.laciudad)
    if (this.elestado && this.laciudad && this.minuevaArea) {
      console.log("Estado: ",this.elestado,"Ciudad: ", this.laciudad , "Area: ", this.minuevaArea);
      axios
        .post("/vacantes/buscavacante", {
          edo: this.elestado.estado,
          cd: this.laciudad,
          especialidad: this.minuevaArea.folio,
          sueldo: null,
          paginacion: 20,
          pagina: 1,
          candidato: null,
          empresa: null
        })
        .then((response) => {
          //  this.posts = response.data
          this.setVacantes(response.data);
          console.log(response.data);
          window.scrollTo(0, 0);
          //this.nuevaPagina = 1;
        });
    } 
      else if (this.elestado && this.laciudad) {
      console.log(this.elestado, this.laciudad);
      axios
        .post("/vacantes/buscavacante", {
          edo: this.elestado,
          cd: this.laciudad,
          especialidad: null,
          sueldo: null,
          paginacion: 20,
          pagina: 1,
          candidato: null,
          empresa: null
        })
        .then((response) => {
          this.setVacantes(response.data);
          console.log(response.data);
          window.scrollTo(0, 0);
        
          //this.nuevaPagina = 1;
        });
    }  else if (this.elestado && this.minuevaArea) {
      console.log(this.elestado.estado, this.minuevaArea);
      axios
        .post("/vacantes/buscavacante", {
          edo: this.elestado.estado,
          cd: null,
          especialidad: this.minuevaArea.folio,
          sueldo: null,
          paginacion: 20,
          pagina: 1,
          candidato: null,
          empresa: null
        })
        .then((response) => {
          this.setVacantes(response.data);
          console.log(response.data);
          window.scrollTo(0, 0);
        
          //this.nuevaPagina = 1;
        });
    }  else if (this.minuevaArea) {

      axios
        .post("/vacantes/buscavacante", {
          edo: null,
          cd: null,
          especialidad: this.minuevaArea.folio,
          sueldo: null,
          paginacion: 20,
          pagina: 1,
          candidato: null,
          empresa: null,
        })
        .then((response) => {
          //  this.posts = response.data
          this.setVacantes(response.data);
          console.log(response.data);
          window.scrollTo(0, 0);
          //this.nuevaPagina = 1;
        });
    }else if (this.elestado) {

      axios
        .post("/vacantes/buscavacante", {
          edo: this.elestado.estado,
          cd: null,
          especialidad: null,
          sueldo: null,
          paginacion: 20,
          pagina: 1,
          candidato: null,
          empresa: null,
        })
        .then((response) => {
          //  this.posts = response.data
          this.setVacantes(response.data);
          console.log(response.data);
          window.scrollTo(0, 0);
          //this.nuevaPagina = 1;
        });
    } else if (this.nuevaPagina > 1) {
      this.page = this.nuevaPagina;
      axios
        .post("/vacantes/buscavacante", {
          edo: null,
          cd: null,
          especialidad: null,
          sueldo: null,
          paginacion: 20,
          pagina: this.nuevaPagina,
          candidato: null,
          empresa: null,
        })
        .then((response) => {
          //  this.posts = response.data
          this.setVacantes(response.data);
          console.log(response.data);
          window.scrollTo(0, 0);
          //this.nuevaPagina = 1;
        });
    } else {
      axios
        .post("/vacantes/buscavacante", {
          edo: null,
          cd: null,
          especialidad: null,
          sueldo: null,
          paginacion: 20,
          pagina: 1,
          candidato: null,
          empresa: null,
        })
        .then((response) => {
          //  this.posts = response.data
          this.setVacantes(response.data);
          console.log(response.data);
          window.scrollTo(0, 0);
          //this.nuevaPagina = 1;
        });
    }
  
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "1",
      })
      .then((response) => {
        this.footer = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  created() {
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "3",
      })
      .then((response) => {
        this.portrait = response.data;
      })
      .catch((error) => {
        console.log(error);
      }),
      this.$store.dispatch("autoLogin");
  },
  beforeMount() {
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "4",
      })
      .then((response) => {
        this.header = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    nuevaPagina: function(value) {
    
     console.log( this.nuevaPagina )
    this.page = this.nuevaPagina

    },

    Empresa: {
      deep: true,
      handler: (value) => {
        console.log("Las vacantes son ", value);
      },
    },

    misvacantes: {
      
      deep: true,

      handler(misvacantes) {
        if (misvacantes[0].total < 21) {
          this.totalPaginas = 1;
        } else if (misvacantes[0].total < 41) {
          this.totalPaginas = 2;
        } else if (misvacantes[0].total < 61) {
          this.totalPaginas = 3;
        } else if (misvacantes[0].total < 81) {
          this.totalPaginas = 4;
        } else if (misvacantes[0].total < 101) {
          this.totalPaginas = 5;
        } else if (misvacantes[0].total < 121) {
          this.totalPaginas = 6;
        } else if (misvacantes[0].total < 141) {
          this.totalPaginas = 7;
        } else if (misvacantes[0].total < 161) {
          this.totalPaginas = 8;
        } else if (misvacantes[0].total < 181) {
          this.totalPaginas = 9;
        } else {
          this.totalPaginas = 0;
        }
        // Fetch data about
        fetch(misvacantes).then((response) => {});
      },
    },
    /*  misvacantes(value){
      posts =  value
    } */
  },

 
};
</script>

<style scoped>
#autocompletado2 {
  margin-top: 12px;
}

@media only screen and (min-width: 868px) {
  /* Esta es la pc */
  .headernegro {
    height: 100% !important;
    background-color: #44546A;
  }
  .headerblanco {
    height: 100% !important;
    background-color: #EFEFEF
  }
  .vacantePosicion {
    font-size: 34px;
  }
  #cel {
    display: none;
  }
  .vacanteRango {
    font-size: 17px;
  }
  .miimagen {
    border-radius: 40px;
  }
  .imagen {
    border: 4px solid white;
    width: 120px;
    height: 120px;
    border-radius: 40px;
    display: flex;
    margin-left: 20px;
    background-color: #ddd;
  }
  .columna3 {
    margin-left: 25px;
  }
  .columna4 {
    margin-left: 25px;
  }
  .columna2 {
    padding: 15px;
    border-radius: 15px;
  }
  .tarjeta {
    padding: 15px;
    margin-top: 20px;
    border-radius: 15px;
    background-color: #44546A;
  }
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 20px;
    font-size: 15px;
    padding: 10px;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 768px) {
  /* Celular*/
  .vacantePosicion{
  font-size:20px !important;
  }

  .imagen {
    border: 4px solid white;
    width: 100px;
    height: 100px;
    border-radius: 40px;
    display: flex;
    margin-left: 20px;
    background-color: #ddd;
  }
  .headernegro {
    height: 100% !important;
    background-color: #44546A;;
  }
   .headerblanco {
    height: 100% !important;
    background-color: #EFEFEF
  }
  #pc {
    display: none;
  }
}
/* 
@media only screen and (max-width: 768px) {
  /* Este es el celular 
  
 

  
  
  .columna1 {
    margin-bottom: 30px;
  }
  .tarjeta {
    background-color: #ddd;
    margin-bottom: 70px;
    margin-top: 10px;
  }
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  .botonPostularme {
    width: 110px;
    font-size: 10px;
    margin-left: -25px !important;
  }
  .v-btn__content {
    width: 20px !important;
    font-size: 8px !important;
  }
  .headernegro {
    height: 200px;
    background-color: black;
  }
  #milogo {
    width: 140px !important;
  }
  .social-buttons {
    display: none;
  }
  .boton-tr {
    display: none;
  }
  .logo {
    display: none;
  }
  .texto-logo {
    font-size: 20px;
  }
  .usuario {
    margin-left: -120px;
  }
  .autocompletado-estado {
    display: none;
  }
  .autocompletado-tipo {
    display: none;
  }
  .autocompletado-puesto {
    width: 74px;
  }
  ::v-deep .autocompletado-puesto .v-label {
    font-size: 11.5px;
  }
  ::v-deep .autocompletado-puesto .v-icon {
    font-size: 14px;
    margin-left: 26px;
  }
  ::v-deep .autocompletado-ciudad .v-label {
    font-size: 13px;
  }
  ::v-deep .v-input__slot {
    width: 80px !important;
    margin-left: -7px;
  }
  ::v-deep .autocompletado-ciudad .v-icon {
    font-size: 14px;
    margin-left: 26px;
  }
  .autocompletado-ciudad .v-label {
    font-size: 12px;
    margin-left: -20px;
  }
  .autocompletado-ciudad {
    width: 70px !important;
  }
  .boton-buscar {
    width: 86px;
  }
}

 */
@media (min-width: 700px) and (orientation: portrait) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
  .headernegro {
    height: 100%;
    background-color: #44546A;
  }
   .headerblanco {
    height: 100%;
    background-color: #EFEFEF
  }
}

@media (min-width: 700px) and (orientation: landscape) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
}
</style>
