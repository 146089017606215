<template>
  <v-container fluid>
    <v-toolbar dark color="grey darken-1" height="60px">
      <router-link style="text-decoration: none;" :to="{ name: 'PerfilEmpresas' }">
        <v-btn>Perfil </v-btn>
      </router-link>

      <router-link style="text-decoration: none;" :to="{ name: 'EmpresasPerfilUsuario' }">
        <v-btn style="margin-left:15px">Usuario </v-btn>
      </router-link>
      <h3 style="margin-left:25px;">Perfil de usuario de Empresa</h3>
    </v-toolbar>
    <br />
    <v-alert v-if="this.celular == null" type="warning">
      Es importante tener tu perfil actualizado y lleno para tener mas
      posibilidades de obtener una vacante.</v-alert
    >
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-toolbar dark prominent height="120px" class="hola">
            <v-container>
              <v-row>
                <v-col>
                  <div class="imagen">
                    <v-img class="miimagen" :src="this.foto" alt=""></v-img>
                  </div>
                </v-col>
                <v-col>
                  <h2 class="nombre">{{ this.nombre }} {{ this.apellido }}</h2>
                </v-col>
              </v-row>
            </v-container>
            <v-spacer></v-spacer>
            <div class="espaciador"></div>
          </v-toolbar>
          <v-container fluid style="margin-top:-12px">
            <v-row>
              <v-col>
                <div class="gris">
                  <br />
                  <p class="estado">
                    <v-icon>mdi-briefcase</v-icon> {{ this.miempresa }}
                  </p>
                  <p class="estado">
                    <v-icon>mdi-map-marker</v-icon> {{ this.estado }} ,
                    {{ this.ciudad }}
                  </p>

                  <br />
                </div>
              </v-col>
            </v-row>
          </v-container>

          <br />

          <v-row>
            <v-col>
              <div class="text-center">
                <v-dialog width="500" color="#2afed6">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#2afed6"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      style="color:black;"
                    >
                      Adjunta una Foto
                      <v-icon style="margin-left:10px">mdi-camera</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-alert type="primary" v-if="avisoFoto">
                      {{ this.avisoFoto }}
                    </v-alert>
                    <v-alert type="warning" v-if="errorM">
                      {{ this.errorM }}
                    </v-alert>
                    <div style="padding:10px;">
                      <v-card-title class="text-h5 grey lighten-2">
                        Adjunta una Fotografia
                      </v-card-title>
                      <br />
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Escoge tu Fotografía"
                        label="Fotografía"
                        v-model="foto"
                        outlined
                      ></v-file-input>
                    </div>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        x-large
                        @click="subirFoto()"
                        class="text-center"
                      >
                        Adjuntar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              </v-col>
             
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
var moment = require("moment");
moment.locale("es-mx");
import axios from "axios";
import { mapMutations, mapState } from "vuex";

const hoy = moment();
let foto;

export default {
  name: "verPerfilUsuario",
  data() {
    return {
      moment: moment,
      vacanteFolio: null,
      usuario: null,
      fecha: null,
      educacion: [],
      idiomas: [],
      trabajos: [],
      cursos: [],
      especialidades: [],
      file: null,
      avisoFoto: "",
      errorM: "",
      avisoVitae: "",
      errorVitae: "",
      foto: "",
      nombre: "",
      apellido: "",
      puesto: "",
      estado: "",
      ciudad: "",
      activo: "",
      salarioMayor: "",
      salarioMenor: "",
      celular: "",
      nacimiento: "",
      correo: "",
      curriculum: null,
      empresa: null,
      miempresa: null,
    }
  },
  beforeMount() {
    console.log("hola")
    this.$store.dispatch("autoLogin");
  },
  mounted(){
    console.log("hola")
  },
  
  computed: {

    logueado() {
      return this.$store.state.token;
    },
  },
  methods: {
     ...mapState (['fechaNac','id','elestado']),
      actualizoDatos(){
      axios
        .put(
          "/empresas/actualizaempresa" , { 
            folio: this.$store.state.id.empresa,
            nom_comercial: this.nombre,
            num_empleados: this.num_empleados,
            rfc: this.rfc,
            razonsocial: this.razonsocial,
            web: this.web,
            sector: this.sector,
            giro: this.giro,
            cp: this.cp,
            estado: this.estado,
            ciudad: this.ciudad
            }   )
        .then((response) => {
          console.log(response.data);
        })
        .then((data) => {
          this.avisoFoto =
            "La foto se ha actualizado con exito al ingresar de nuevo podras verla";
           this.$router.go(this.$router.currentRoute)
        })
        .catch((error) => {
          this.errorM =
            "La foto que tratas de subir es muy pesada o no cuenta con extension: jpg, png o jpeg";
          console.log(error);
        });

    },
    subirFoto() {
      const fd = new FormData();
      fd.append("image", this.foto, this.foto.name);
      axios
        .put(
          "/candidatos/actualizafoto/" +
            this.$store.state.id.id,
          fd
        )
        .then((response) => {
          console.log(response.data);
        })
        .then((data) => {
          this.avisoFoto =
            "La foto se ha actualizado con exito al ingresar de nuevo podras verla";
          this.$router.go()
        })
        .catch((error) => {
          this.errorM =
            "La foto que tratas de subir es muy pesada o no cuenta con extension: jpg, png o jpeg";
          console.log(error);
        });
    },
    subirVitae() {
      const cv = new FormData();
      cv.append("vitae", this.file, this.file.name);
      console.log(this.file);
      console.log(this.$store.state.id.id);
      axios
        .put(
          "/candidatos/actualizavitae/" +
            this.$store.state.id.id,
          cv
        )
        .then((response) => {
          console.log(response.data);
        })
        .then((data) => {
          this.avisoVitae =
            "El CV se ha actualizado con exito al ingresar de nuevo podras verlo";
          this.$router.go()
        })
        .catch((error) => {
          this.errorVitae = "Error";
          console.log(error);
        });
    },
  },
  
  
}
   /*  axios
      .post(/empresas/perfilusuario", {
        idemp: this.$store.state.id.empresa
      })
      .then((response) => {
        this.foto = response.data[0].foto;
        this.nombre = response.data[0].nombre;
        this.apellido = response.data[0].apellido;
        this.empresa = response.data[0].empresa;
        axios
          .post(
            "/empresas/perfilempresa",
            { empresa: this.empresa }
          )
          .then((respuesta) => {

            this.miempresa = respuesta.data[0].nom_comercial;
            this.ciudad = respuesta.data[0].ciudad;
            this.estado = respuesta.data[0].estado;
          });
      })
      .catch((error) => {
        console.log(error);
      });
  },
}; */
</script>

<style scoped>
.tarjeta-postulaciones {
  padding: 25px;
  border-radius: 15px;
  background-color: #2afed6;
}
.tarjeta-trabajos {
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey;
}
.tarjeta-trabajos {
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey;
}
.hola {
  border-radius: 25px;
}
.nombre {
  color: white;
 margin-left: 36px;
  margin-top: 26px;
  font-size: 30px;
}
#espacio {
  display: none;
}
.miimagen {
  border-radius: 40px;
}
.imagen {
    border: 4px solid white;
 width: 120px;
height: 120px;
 margin-top: 11px;
  border-radius: 40px;
  display: flex;
  margin-left: 120px;
  background-color: #ddd;
}
.gris {
  background-color: darkgrey;
  width: 100%;
  height: 120px;
  border-radius: 25px;
}
.estado {
  margin-left: 390px;
}
.percepcion {
  margin-top: -5px;
  margin-left: 395px;
}
.trabajando {
  margin-top: -5px;
  margin-left: 395px;
}
.idiomas {
  margin-top: -25px;
  margin-left: 395px;
}
@media only screen and (max-width: 768px) {
  .imagen {
    border: 4px solid white;
    width: 100px;
    height: 100px;
    margin-top: 20px;
    border-radius: 40px;
    display: flex;
    margin-left: -0px;
    background-color: #ddd;
  }
  .nombre {
    color: white;
    margin-left: -30px;
    font-size: 24px;
    text-align: center;
    margin-top: 0px;
  }
  .titulo {
    margin-top: 10px;
    margin-left: -39px;
    text-align: center;
    font-size: 15px;
  }
  .estado {
    margin-left: 50px;
    margin-bottom: 10px;
  }

  .espacio {
    display: initial;
  }
  .percepcion {
    margin-left: 50px;
  }
  .trabajando {
    margin-left: 50px;
    margin-top: 0px;
  }
  .idiomas {
    margin-left: 50px;
    margin-top: -30px;
  }
  .gris {
    background-color: darkgrey;
    width: 100%;
    height: 170px;
    border-radius: 25px;
  }
}
</style>
