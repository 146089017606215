<template>
  <v-layout style="margin-top:40px; " align-center justify-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
      <v-card style="background-color:#e0e0e0">
          
        <v-toolbar color="primary">
          <v-container>
            <v-toolbar-title class="text-center">
              <h2>Olvide mi contraseña</h2>
            </v-toolbar-title>
          </v-container>
        </v-toolbar>
        <v-card-text>
            <v-alert v-if="errors.length">
              <b>Porfavor corrige los siguientes errores:</b>
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </v-alert>
          <v-text-field
            v-model="correo"
            autofocus
            outlined
            label="Email"
            required
          >
          </v-text-field>
          <v-container>
            <v-row>
              <v-col>
                <router-link  :to="{ name: 'login' }">
                  <a href="">Regresar</a>
                </router-link>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <div class="text-center">
          <v-card-actions class="px-3 pb-3">
            <v-flex text-cs-right>
              <v-btn @click="cambiar()" style="color:black" color="primary"
                >Enviar</v-btn
              >
            </v-flex>
          </v-card-actions>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      correo: "",
      password: "",
      errorM: null,
      errors: [],
    };
  },
  methods: {
    cambiar() {
      if (this.correo) {
        console.log(this.correo);
        axios
          .post("/candidatos/cambiocontrasena", {
            correo: this.correo,
            origen: 1,
          })
          .then((response) => {
            console.log(response.data);
          })
          .then((data) => {
            this.$router.push({ name: "RecuperacionDeContrasena" });
          })
          .catch((error) => {
            console.log(error);
            this.errorM = null;
            if (error.response.status == 401) {
              this.errorM = "Las credenciales son incorrectas";
            } else if (error.response.status == 400) {
              this.errorM = "El usuario no existe favor de registrarse";
            } else {
              this.errorM = "Ocurrio un error con el servidor";
            }
          });
      } else {
        this.errors = [];
        if (!this.correo) this.errors.push("Correo es requerido.");
      }
    },
  },
};
</script>
<style scoped>
.v-application a {
  color: black !important ;
}
</style>
