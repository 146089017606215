<template>
  <v-app>
    <v-container fluid>
      <br />
      <div></div>
      <v-row>
        <v-col cols="12" v-for="imagen of header" :key="imagen.folio">
          <a :href="imagen.url" target="_blank">
            <v-img
              href="imagen.url"
              style="border-radius:10px;margin-top:-15px"
              :src="imagen.imagen"
            >
            </v-img>
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="9" style="margin-top:-18px;">
          <div v-if="this.servicios.length < 1">
            <p>
              Tu busqueda no tiene resultados, modifique los filtros.
            </p>
          </div>

          <v-card
            class="tarjeta"
            v-for="vacante in servicios"
            :key="vacante.folio"
            style="padding:0px !important; border-radius:20px;"
          >
            <v-container fluid class="headernegro">
              <v-row>
                <!--cel -->

                <v-col cols="5" class="cel">
                  <div v-if="vacante.logo" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="vacante.logo"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>

                <v-col cols="7" class="cel">
                  <div class="text-center">
                    <p style="color:white; text-align:center; ">
                      {{ vacante.desc_corta }}
                    </p>

                    <p
                      style="color: rgb(255, 192, 0);
text-align: center;
"
                    >
                      {{ vacante.nom_comercial }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" id="cel">
                  <div class="text-center">
                   
                  </div>
                </v-col>
                <v-col cols="12" class="cel" style="margin-top:-38px;">
                  <div class="text-center">
                    <p class="text-center" style="color:white;font-size:18px;">
                      <strong> Da click para contactarte</strong>
                    </p>
                    <div v-if="vacante.tel">
                      <v-btn
                        @click="estadisticaCel(vacante.empresa, vacante.folio)"
                        color="#004AAD"
                        style="background-color:#004AAD;color:white;font-size:14px;margin-top:-20px;border-radius:17px;"
                        target="_blank"
                        v-if="vacante.tel"
                        :href="'tel:' + vacante.tel"
                        ><v-icon style="padding:12px;margin-left:-7px;">
                          mdi-phone
                        </v-icon>
                        Llamar
                      </v-btn>
                    </div>

                    <div v-if="vacante.whats">
                      <v-btn
                        color="#20B038"
                        @click="estadisticaW(vacante.empresa, vacante.folio)"
                        v-if="vacante.whats"
                        style="color:white;font-size:14px;border-radius:17px;"
                        target="_blank"
                        :href="
                          'https://api.whatsapp.com/send?phone=52' +
                            vacante.whats +
                            '&text=¡Hola!%20vi%20su%20anuncio%20en%20la%20página%20de%20VACANTA,%20me%20comunico%20para%20solicitar%20mayor%20información%20de%20sus%20servicios.'
                        "
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-whatsapp
                        </v-icon>
                        Enviar WhatsApp
                      </v-btn>
                    </div>

                    <div>
                      <v-btn
                        @click="
                          estadisticaCorreo(vacante.empresa, vacante.folio)
                        "
                        v-if="vacante.correo"
                        style="color:white;font-size:14px; margin-top:4px; border-radius:17px;"
                        target="_blank"
                        :href="
                          'mailto:' +
                            vacante.correo +
                            '?Subject=Enviado%20desde%20VACANTA' +
                            '&body=¡Hola!%20vi%20su%20anuncio%20en%20la%20página%20de%20VACANTA,%20me%20comunico%20para%20solicitar%20mayor%20información%20de%20sus%20servicios.'
                        "
                        color="#FFC000"
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-email
                        </v-icon>
                        Enviar Correo
                      </v-btn>
                    </div>
                  </div>
                </v-col>

                <!-- PC -->

                <v-col cols="2" class="pc">
                  <div v-if="vacante.logo" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="vacante.logo"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>

                <v-col cols="6" class="text-center pc">
                  <p
                    class="vacantePosicion"
                    style="color:white; text-align:center; "
                  >
                    {{ vacante.desc_corta }}
                  </p>

                  <h3 style="color:#FFC000;" class="vacanteEmpresa text-center">
                    <v-icon>mdi-office-building</v-icon>
                    {{ vacante.nom_comercial }}
                  </h3>
                </v-col>
                <v-col cols="4" class="pc">
                  <div class="text-center">
                    <p
                      class="text-center"
                      style="color:white;font-size:18px;margin-top:40px"
                    >
                      <strong>Da click para contactarte</strong>
                    </p>
                    <div v-if="vacante.tel">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialog"
                        persistent
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              prueba(vacante.empresa, vacante.folio, vacante)
                            "
                            color="#004AAD"
                            style="background-color:#004AAD;color:white;font-size:14px;margin-top:-20px;border-radius:17px;"
                            v-if="vacante.tel"
                            ><v-icon style="padding:12px;margin-left:-7px;">
                              mdi-phone
                            </v-icon>
                            Llamar
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            Teléfono
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ objecto }}</v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialog = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>

                    <div v-if="vacante.whats">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialogW"
                        persistent
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="#20B038"
                            @click="
                              prueba2(vacante.empresa, vacante.folio, vacante)
                            "
                            style="color:white;font-size:14px;border-radius:17px;"
                            target="_blank"
                          >
                            <v-icon style="padding:12px;margin-left:-7px;">
                              mdi-whatsapp
                            </v-icon>
                            Enviar WhatsApp
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            WhatsApp
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ objecto }}</v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialogW = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>

                    <v-dialog
                      :retain-focus="false"
                      v-model="dialogoC"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            prueba3(vacante.empresa, vacante.folio, vacante)
                          "
                          color="#FFC000"
                          style="color:white;font-size:14px;margin-top:3px;border-radius:17px;"
                          target="_blank"
                        >
                          <v-icon style="padding:12px;margin-left:-7px;">
                            mdi-email
                          </v-icon>
                          Enviar Correo
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Correo
                        </v-card-title>
                        <v-card-text
                          style="font-size:18px; text-align:center;"
                          >{{ objecto }}</v-card-text
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            style="color:black;"
                            @click="dialogoC = false"
                          >
                            Salir
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid style="background-color:#dddddd">
              <v-row> </v-row>

              <!-- <-- aqui esta el proble -->
              <v-row>
                <v-flex xs12 sm12 md7 class="columna3">
                  <br />
                  <h2 class="text-center">
                    Mis Servicios
                  </h2>
                  <br />

                  <v-textarea
                   dark
                    background-color="#028008"
                    outlined
                    v-model="vacante.descripcion"
                    name="tbox"
                    rows="5"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md4 class="columna4">
                  <br class="pc" />
                  <h2 class="text-center">
                    Conóceme
                  </h2>
               

                  <div class="text-center" v-if="vacante.web" style="margin-top:20px;">
                   
                    <v-btn
                        @click="estadisticaWeb(vacante.empresa, vacante.folio)"

                        v-if="vacante.web "
                        style="color:black;font-size:14px; margin-top:4px; border-radius:17px;width:200px;"
                        target="_blank"
                        :href="'https://' + vacante.web "
                        color="white"
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-web
                        </v-icon>
                        Página Web
                      </v-btn>
                  </div>

                  <div class="text-center" v-if="vacante.facebook" style="margin-top:20px;">
                    <v-btn
                      @click="estadisticaFace(vacante.empresa, vacante.folio)"
                      v-if="vacante.facebook"
                      style="color:white;font-size:14px; margin-top:4px; border-radius:17px;width:200px;"
                      target="_blank"
                      :href="'https://www.facebook.com/' + vacante.facebook"
                      color="#3B5998"
                    >
                      <v-icon style="padding:12px;margin-left:-7px;">
                        mdi-facebook
                      </v-icon>
                      Facebook
                    </v-btn>
                  </div>

                  <div class="text-center" v-if="vacante.instagram" style="margin-top:20px;">
                    <v-btn
                      @click="estadisticaInsta(vacante.empresa, vacante.folio)"
                      v-if="vacante.instagram"
                      style="color:white;font-size:14px; margin-top:4px; border-radius:17px;width:200px;"
                      target="_blank"
                      :href="'https://www.instagram.com/' + vacante.instagram"
                      color="#D23693"
                    >
                      <v-icon style="padding:12px;margin-left:-7px;">
                        mdi-instagram
                      </v-icon>
                      Instagram
                    </v-btn>
                  </div>
                </v-flex>
              </v-row>
              <!-- <-- aqui esta el problecel-->

              <v-row>
                <v-col md="6" class="text-center">
                  <p>
                    <strong> Lugar:</strong>
                  </p>
                  <p style="background-color:#FFC000; border-radius:6px; ">
                    <strong>
                      {{ vacante.ciudad }} ,
                      {{ vacante.estado }}
                    </strong>
                  </p>
                </v-col>

                <v-col md="6" xs="12" class="text-center">
                  <p><strong> Área: </strong></p>
                  <p style="background-color:#FFC000; border-radius:6px;">
                    <strong> {{ vacante.desc_area }}</strong>
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <v-col
          v-for="imagen of portrait"
          :key="imagen.folio"
          class="columna1"
          sm="12"
          xs="12"
          md="3"
        >
          <a :href="imagen.url" target="_blank">
            <v-img
              style="border-radius:10px;"
              :src="imagen.imagen"
              :href="imagen.url"
            >
            </v-img>
          </a>
        </v-col>
        <v-col cols="12">
          <div class="text-center" @click="pagination(page)">
            <v-pagination
              v-model="page"
              :length="totalPaginas"
              circle
              dark
            ></v-pagination>
          </div>
        </v-col>

        <v-col v-for="imagen of footer" :key="imagen.folio" cols="12">
          <a :href="imagen.url" target="_blank">
            <v-img
              style="border-radius:10px;"
              :src="imagen.imagen"
              :href="imagen.url"
            >
            </v-img>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState, mapActions } from "vuex";

var moment = require("moment");
moment.locale("es-mx");

export default {
  data() {
    return {
      objecto: null,
      dialog: false,
      dialogW: false,
      dialogoC: false,
      moment: moment,
      posts: [],
      min: 0,
      max: 45000,
      range: [5000, 25000],
      header: [],
      footer: [],
      portrait: [],
      eltelefono: "",
      whatsapp: "",
      token:
        "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
      var1: "hola",
      nombre: "",
      page: 1,
      totalPaginas: null,
      lasvacantes: [],
    };
  },
  computed: {
    id() {
      return this.$store.state.id;
    },
    logueado() {
      return this.$store.state.token;
    },
    ...mapState([
      "elestado",
      "laciudad",
      "puesto",
      "rangoInf",
      "rangoSup",
      "Urlheader",
      "VacanteFolio",
      "miempresa",
      "id",
      "vacantesOperativas",
      "totalVacantes",
      "servicios",
      "totalServicios",
    ]),
  },
  methods: {
    prueba(a, b, c) {
      console.log(a, b, c);
      this.dialog = true;
      this.objecto = c.tel;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "3",
            empresa: a,
            folio: b,
            donde: "2",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prueba2(a, b, c) {
      console.log(a, b, c);
      this.dialogW = true;
      this.objecto = c.whats;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "3",
            empresa: a,
            folio: b,
            donde: "3",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prueba3(a, b, c) {
      console.log(a, b, c);
      this.dialogoC = true;
      this.objecto = c.correo;

      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "3",
            empresa: a,
            folio: b,
            donde: "1",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaW(emrpresa, folio) {
      console.log("3", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "3",
            empresa: emrpresa,
            folio: folio,
            donde: "3",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaCel(emrpresa, folio) {
      console.log("3", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "3",
            empresa: emrpresa,
            folio: folio,
            donde: "2",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaCorreo(emrpresa, folio) {
      console.log("3", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "3",
            empresa: emrpresa,
            folio: folio,
            donde: "1",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaWeb(emrpresa, folio) {
      console.log("3", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "3",
            empresa: emrpresa,
            folio: folio,
            donde: "4",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaFace(emrpresa, folio) {
      console.log("3", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "3",
            empresa: emrpresa,
            folio: folio,
            donde: "5",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaInsta(emrpresa, folio) {
      console.log("3", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "3",
            empresa: emrpresa,
            folio: folio,
            donde: "6",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapMutations([
      "setVacanteFolio",
      "setPaginacion",
      "setVacantesOperativas",
      "setTotalVacantesOperativas",
      "setServicios",
      "setTotalServicios",
    ]),
    ...mapActions(["irVacante"]),
    buscaVacante(value) {
      this.posts = value;
    },
    pagination(page) {
      this.setPaginacion(page);
      window.scrollTo(0, 0);
    },
  },
  watch: {
    nombre(nuevoValor, valorAnterior) {
      console.log("El nombre pasó de ser %s a %s", valorAnterior, nuevoValor);
    },
    /*   misvacantes:{
      deep:true,
      handler: (value) => {
        console.log("Las vacantes son ", value)
        
      }
     } */

    totalServicios: {
      deep: true,
      handler(totalServicios) {
        if (totalServicios.length < 15) {
          this.totalPaginas = 1;
        } else if (totalServicios.length < 30) {
          this.totalPaginas = 2;
        } else if (totalServicios.length < 45) {
          this.totalPaginas = 3;
        } else if (totalServicios.length < 60) {
          this.totalPaginas = 4;
        } else if (totalServicios.length < 75) {
          this.totalPaginas = 5;
        } else if (totalServicios.length < 90) {
          this.totalPaginas = 6;
        } else if (totalServicios.length < 105) {
          this.totalPaginas = 7;
        } else if (totalServicios.length < 120) {
          this.totalPaginas = 8;
        } else if (totalServicios.length < 135) {
          this.totalPaginas = 9;
        } else {
          this.totalPaginas = 0;
        }
        // Fetch data about
        fetch(totalServicios).then((response) => {});
      },
    },

    /*  misvacantes(value){
      posts =  value
    } */
  },

  beforeCreate() {
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "1",
      })
      .then((response) => {
        this.footer = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  created() {
    axios
      .post(
        "/servicios/buscaservicio",
        {
          edo: null,
          cd: null,
          paginacion: 15,
          pagina: 1,
          espe: null,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
      .then((response) => {
        this.setServicios(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "3",
      })
      .then((response) => {
        this.portrait = response.data;
      })
      .catch((error) => {
        console.log(error);
      }),
      this.$store.dispatch("autoLogin");
  },

  beforeMount() {
    axios
      .post(
        "/servicios/buscaservicio",
        {
          edo: null,
          cd: null,
          paginacion: 1500,
          pagina: 1,
          espe: null,
        },
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      )
      .then((response) => {
        this.setTotalServicios(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    window.scrollTo(0, 0);
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "4",
      })
      .then((response) => {
        this.header = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
#autocompletado2 {
  margin-top: 12px;
}

@media only screen and (min-width: 868px) {
  #cel {
    display: none;
  }
  /* Esta es la pc */
  .headernegro {
    height: 200px !important;
    background-color: black;
  }
  .vacantePosicion {
    font-size: 34px;
  }

  .vacanteRango {
    font-size: 17px;
  }
  .miimagen {
    border-radius: 40px;
  }
  .imagen {
    border: 4px solid white;
    width: 120px;
    height: 120px;
    border-radius: 40px;
    display: flex;
    margin-left: 20px;
    background-color: #ddd;
  }
  .columna3 {
    margin-left: 25px;
  }
  .columna4 {
    margin-left: 25px;
  }
  .columna2 {
    padding: 15px;
    border-radius: 15px;
  }
  .tarjeta {
    padding: 15px;
    margin-top: 20px;
    border-radius: 15px;
    background-color: #ddd;
  }
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 20px;
    font-size: 15px;
    padding: 10px;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .imagen {
    border: 4px solid white;
    width: 100px;
    height: 100px;
    border-radius: 40px;
    display: flex;
    margin-left: 20px;
    background-color: #ddd;
  }
  /* Celular*/

  .headernegro {
    height: 325px !important;
    background-color: black;
  }
  #pc {
    display: none;
  }
  .vacantePosicion {
    font-size: 24px;
  }
}
/* 
@media only screen and (max-width: 768px) {
  /* Este es el celular 
  
 

  
  
  .columna1 {
    margin-bottom: 30px;
  }
  .tarjeta {
    background-color: #ddd;
    margin-bottom: 70px;
    margin-top: 10px;
  }
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  .botonPostularme {
    width: 110px;
    font-size: 10px;
    margin-left: -25px !important;
  }
  .v-btn__content {
    width: 20px !important;
    font-size: 8px !important;
  }
  .headernegro {
    height: 200px;
    background-color: black;
  }
  #milogo {
    width: 140px !important;
  }
  .social-buttons {
    display: none;
  }
  .boton-tr {
    display: none;
  }
  .logo {
    display: none;
  }
  .texto-logo {
    font-size: 20px;
  }
  .usuario {
    margin-left: -120px;
  }
  .autocompletado-estado {
    display: none;
  }
  .autocompletado-tipo {
    display: none;
  }
  .autocompletado-puesto {
    width: 74px;
  }
  ::v-deep .autocompletado-puesto .v-label {
    font-size: 11.5px;
  }
  ::v-deep .autocompletado-puesto .v-icon {
    font-size: 14px;
    margin-left: 26px;
  }
  ::v-deep .autocompletado-ciudad .v-label {
    font-size: 13px;
  }
  ::v-deep .v-input__slot {
    width: 80px !important;
    margin-left: -7px;
  }
  ::v-deep .autocompletado-ciudad .v-icon {
    font-size: 14px;
    margin-left: 26px;
  }
  .autocompletado-ciudad .v-label {
    font-size: 12px;
    margin-left: -20px;
  }
  .autocompletado-ciudad {
    width: 70px !important;
  }
  .boton-buscar {
    width: 86px;
  }
}

 */
@media (min-width: 700px) and (orientation: portrait) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
  .headernegro {
    height: 240px;
    background-color: black;
  }
}

@media (min-width: 700px) and (orientation: landscape) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
}
</style>
