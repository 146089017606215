<template>
 
 
          <v-text-field
          label="Sueldo Minimo Aspirado"
          outlined
          >

          </v-text-field>
    
 
  
</template>

<script>
 import { mapMutations, mapState } from 'vuex'
  export default {
    data () {
      return {
        min: 0,
        max: 60000,
        range: [8000, 20000],
        rango:10000,
        options: {
        locale: "ja-JP",
        prefix: "$",
        suffix: "",
        length: 10,
        precision: 2,
      },
      }
    },
         computed:{
      ...mapState (['rangoInf', 'rangoSup'])
    },
     methods:{
       ...mapMutations (['setrangoSup','setrangoInf'])
     },
    
  }
</script>

<style scoped>

  
</style>