import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import "./plugins/vuetify-money.js";
import { VueMaskDirective } from "v-mask";
import LoadScript from 'vue-plugin-load-script';
import Meta from 'vue-meta'




Vue.directive("mask", VueMaskDirective);
Vue.use(Meta);
Vue.use(LoadScript);
Vue.config.productionTip = false
axios.defaults.baseURL='https://vacanta.mx:4443/api'


  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
