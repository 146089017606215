<template>
  <v-container>
    <v-alert
    dismissible
                                  icon="mdi-alert"
                                  color="secondary"
                                  style="position:fixed; z-index:999;margin-left:0px; "
                                  v-if="respuestaFactura"
                                >
                                  <h3>{{respuestaFactura}}</h3>
                                </v-alert>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      style="background-color:#4caf50"
      v-if="referencia>1"
    >
      <v-col style=" text-align:center;">
        <v-icon style="color:white; font-size:78px;">mdi-check-circle</v-icon>

        <h1 style="text-align:center; color:white">
          ¡Listo! Se acreditó tu pago
        </h1>

        <div
          class="text-center"
          style="display:block;margin:0px auto;
           
           width:200px;height:150px; background-color:white;"
        >
          <p>Pagaste: ${{ this.pago }}.00 pesos</p>
          <p>Referencia: {{ this.referencia }}</p>
          <p>Publicaciones: {{ this.publicaciones }} Disponibles</p>
        </div>
        

        <br />
              <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="background-color: #03a9f4;color:white" dark v-bind="attrs" v-on="on">
              Solicitar Factura
            </v-btn>
          </template>
          <v-card>
            <v-toolbar dark color="dark">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Datos Facturación</v-toolbar-title>
              
              <v-spacer></v-spacer>
              <v-toolbar-items>
               
              </v-toolbar-items>
            </v-toolbar>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <br>
                  <v-col cols="12" style="margin-top:20px;">
                    <v-text-field
                      label="Razon Social"
                      hide-details="auto"
                      v-model="nombre"
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="R.F.C."
                      hide-details="auto"
                      v-model="rfc"
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      disabled
                      outlined
                      v-model="regimen"
                      label="Régimen Fiscal"
                      clearable
                      :items="regimenes"
                      item-text="descripcion"
                      item-value="descripcion"
                      autocomplete="nope"
                      color="primary"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" style="margin-top:-30px;">
                    <v-text-field
                      label="Calle"
                      hide-details="auto"
                      outlined
                      disabled
                      v-model="calle"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                                        <v-text-field
                                        disabled
                                          v-model="numero"
                                          label="Numero"
                                          hide-details="auto"
                                          outlined
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="2">
                                        <v-text-field
                                          v-model="numeroInt"
                                          disabled
                                          label="Numero Interior"
                                          hide-details="auto"
                                          outlined
                                        ></v-text-field>
                                      </v-col>
                  
                  <v-col cols="4">
                    <v-text-field
                      label="Colonia"
                      hide-details="auto"
                      outlined
                      disabled
                      v-model="colonia"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Codigo Postal"
                      hide-details="auto"
                      v-model="cp"
                      outlined
                      disabled
                      v-mask="mask"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      disabled
                      outlined
                      v-model="evento"
                      :click="setState(evento)"
                      label="Estado"
                      clearable
                      :items="estados"
                      item-text="estado"
                      item-value="value"
                      autocomplete="nope"
                      color="primary"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" >
                    <v-autocomplete
                      disabled
                      outlined
                      v-model="mievento"
                      :click="setCiudad(mievento)"
                      label="Ciudad"
                      clearable
                      :items="ciudades"
                      item-text="municipio"
                      item-value="ciudad"
                      autocomplete="nope"
                      color="primary"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" style="margin-top:-30px;">
                    <v-autocomplete
                      outlined
                      :items="usos"
                      placeholder="Uso de CFDI"
                      item-text="descripcion"
                      item-value="descripcion"
                      v-model="Cfdi"
                      return-object
                      disabled
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" style="margin-top:-30px;">
                    <v-text-field
                      label="Correo"
                      hide-details="auto"
                      v-model="correo"
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" style="margin-top:-30px;">
                    <vuetify-money
                    disabled
                      v-model="this.pago"
                     label="Monto"
                      outlined
                    />
                    
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col>
                  <div class="text-center">
                    <v-dialog v-model="holas" width="700">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          style="margin-top:30px;color:black;"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          Editar Datos
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title
                          class="text-h5 primary text-center darken-1"
                        >
                          Datos Facturación
                        </v-card-title>
                        <v-alert
                                  icon="mdi-alert"
                                  color="secondary"
                                  style="position:fixed; z-index:999;margin-left:-400px; "
                                  v-if="errors.length"
                                >
                                  <b>Por favor llena los siguientes campos:</b>
                                  <ul>
                                    <li v-for="error in errors" :key="error">
                                      {{ error }}
                                    </li>
                                  </ul>
                                </v-alert>
                        <v-container>
                          <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  label="Razon Social"
                                  hide-details="auto"
                                  v-model="nombre"
                                  outlined
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="rfc"
                                  name="rfc"
                                  height="5"
                                  outlined
                                  type="text"
                                  color="primary"
                                  label="R.F.C"
                                  v-mask="mask2"
                                  class="rfc"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-autocomplete
                                  outlined
                                  v-model="regimen"
                                  label="Régimen Fiscal"
                                  return-object
                                  clearable
                                  :items="regimenes"
                                  item-text="descripcion"
                                  item-value="descripcion"
                                  autocomplete="nope"
                                  color="primary"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" style="margin-top:-30px;">
                                <v-text-field
                                  label="Calle"
                                  hide-details="auto"
                                  v-model="calle"
                                  outlined
                                ></v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-text-field
                                  v-model="numero"
                                  label="Numero"
                                  hide-details="auto"
                                  outlined
                                ></v-text-field>
                              </v-col>
                              <v-col cols="2">
                                        <v-text-field
                                          v-model="numeroInt"
                                          label="Numero Interior"
                                          hide-details="auto"
                                          outlined
                                        ></v-text-field>
                                      </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="colonia"
                                  label="Colonia"
                                  hide-details="auto"
                                  outlined
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  label="Codigo Postal"
                                  hide-details="auto"
                                  v-model="cp"
                                  outlined
                                  v-mask="mask"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-autocomplete
                                  outlined
                                  v-model="evento"
                                  :click="setState(evento)"
                                  label="Estado"
                                  clearable
                                  :items="estados"
                                  item-text="estado"
                                  item-value="value"
                                  autocomplete="nope"
                                  color="primary"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="6">
                                <v-autocomplete
                                  outlined
                                  v-model="mievento"
                                  :click="setCiudad(mievento)"
                                  label="Ciudad"
                                  clearable
                                  :items="ciudades"
                                  item-text="municipio"
                                  item-value="ciudad"
                                  autocomplete="nope"
                                  color="primary"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="6" style="margin-top:-30px;">
                                <v-autocomplete
                                  outlined
                                  :items="usos"
                                  placeholder="Uso de CFDI"
                                  item-text="descripcion"
                                  item-value="descripcion"
                                  v-model="Cfdi"
                                  return-object
                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="6" style="margin-top:-30px;">
                                <v-text-field
                                  label="Correo"
                                  hide-details="auto"
                                  v-model="correo"
                                  outlined
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-container>
                            <div class="text-center">
                              <v-btn
                                color="primary darken-1"
                                @click="holas = false"
                                style="color:black;"
                              >
                                Cancelar
                              </v-btn>
                            </div>
                          </v-container>
                          <v-spacer></v-spacer>
                          <v-container>
                            <div class="text-center">
                              <v-btn
                                :disabled="!valid"
                                color="primary darken-1"
                                @click="actualizoDatos()"
                                style="color:black;"
                              >
                                Guardar
                              </v-btn>
                            </div>
                          </v-container>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-btn
                      style="color:black;margin-left:20px;margin-top:28px;"
                      class="primary"
                      @click="enviarFactura()"
                    >
                      Enviar Datos
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
       
        <br />
        <br>

        <router-link
          style="text-decoration: none;"
          :to="{ name: 'PerfilEmpresas' }"
        >
          <v-btn large style="background-color: #03a9f4;color:white" href=""
            >SALIR</v-btn
          >
        </router-link>
      </v-col>
    </v-form>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      style="background-color:#f5f242; color=black;"
      v-else-if="referencia == 1"
    >
      <v-col style=" text-align:center;">
        <v-icon style="color:white; font-size:78px;">mdi-reload</v-icon>

        <h1 style="text-align:center; color:white">
          Cargando
        </h1>

        <br />

        <div
          class="text-center"
          style="display:block;margin:0px auto;
           
           width:320px;height:100px; background-color:white;"
        >
          <p>Espera un momento</p>
        </div>
          
        <br />
        <br />
      </v-col>
    </v-form>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      style="background-color:red"
      v-else-if="referencia == 0"
    >
      <v-col style=" text-align:center;">
        <v-icon style="color:white; font-size:78px;">mdi-close-circle</v-icon>

        <h1 style="text-align:center; color:white">
          Hubo un error al procesar tu pago
        </h1>

        <br />
        
        

        <div
          class="text-center"
          style="display:block;margin:0px auto;
           
           width:320px;height:100px; background-color:white;"
        >
          <p>Puedes intentar de nuevo con otra tarjeta o con la misma.</p>
        </div>

        <br />
        <br />
        <router-link
          style="text-decoration: none;"
          :to="{ name: 'ComprarCreditos' }"
        >
          <v-btn large style="background-color: #03a9f4;color:white" href=""
            >Intentar de Nuevo</v-btn
          >
          <br />
        </router-link>


        <br />
        <router-link
          style="text-decoration: none;"
          :to="{ name: 'PerfilEmpresas' }"
        >
          <v-btn large style="background-color: #03a9f4;color:white" href=""
            >Regresar</v-btn
          >
        </router-link>
      </v-col>
    </v-form>
    
  </v-container>
  
</template>
<script>
import axios from "axios";
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  data: () => ({
          Cfdi:[],
          numeroInt: null,
    nuevoRegimen: null,
    respuestaFactura: null,
    holas: false,
    token:
      "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
    errors: [],
    nombre: null,
    rfc: null,
    regimen: [],
    calle: null,
    numero: null,
    colonia: null,
    cp: null,
    evento: null,
    mievento: null,
    usos: [],
    mask2: "AAAN#####NNNN",
    mask: "#####",
    ciudades: [],
    estados: [],
    regimenes: [],

    uso: null,
    correo: null,
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    referencia: 1,
    valid: true,
    cantidad: 100.0,
    publicaciones: null,
    pago: null,
    id: null,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    checkbox: false,
  }),

  methods: {
    cerrar(){
      this.dialog =false;
    },
        uppercase() {
      this.rfc = this.rfc.toUpperCase();
    },
       enviarFactura() {
        console.log(this.pago)
       axios
      .post("/creditos/solicitafactura", {
        empresa: this.$store.state.id.empresa,
        importe: this.pago,
      })
      .then((response) => {
        console.log(response.data);
        
        this.dialog = false;
        this.respuestaFactura = "Tu factura se enviara en un lapso de 48 horas a tu correo"
         this.$router.push({ name: "FacturaEnviada" });
      })
      .catch((error) => {
        console.log(error)
      });
    },
    ...mapMutations(["setPrecio", "setState", "setCiudad"]),
    actualizoDatos() {
      console.log(
        "razon: ", this.nombre,
        "rfc: ", this.rfc,
        "direccion: ", this.calle,
        "numero: ", this.numero,
                "numeroInt: ", this.numeroInt,
                "colonia: " , this.colonia,
                "estado: " , this.evento,
                "ciudad: ", this.mievento,
                "cp: " , this.cp,
        "correo: ", this.correo,
         "uso: " , this.Cfdi.folio,
      "regimen: ", this.regimen.folio,
      "municipio: " , this.mievento,

      );

      if (
        this.rfc &&
        this.nombre &&
        this.numero &&
        this.colonia &&
        this.evento &&
        this.mievento &&
        this.cp &&
        this.correo &&
        this.Cfdi &&
        this.regimen
      ) {
        if(this.numeroInt){
             axios
          .post(
            "/empresas/capturaDatosFiscales",
            {
              empresa: this.$store.state.id.empresa,
              rfc: this.rfc,
              razonsocial: this.nombre,
              direccion: this.calle,
              noext: this.numero,
              noint: this.numeroInt,
              colonia: this.colonia,
              estado: this.evento,
              ciudad: this.mievento,
              cp: this.cp,
              email: this.correo,
              usocfdi: this.Cfdi.folio,
              regimen: this.regimen.folio,
              municipio: this.mievento,
              localidad: "",
              pais: "",
              tel: "",
              limcre: "1000",
              diacred: "30",
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            this.holas = false;
             this.errors = [];
          })
          .then((data) => {})
          .catch((error) => {
            console.log(error);
          });

        }else {   axios
          .post(
            "/empresas/capturaDatosFiscales",
            {
              empresa: this.$store.state.id.empresa,
              rfc: this.rfc,
              razonsocial: this.nombre,
              direccion: this.calle,
              noext: this.numero,
              noint: "",
              colonia: this.colonia,
              estado: this.evento,
              ciudad: this.mievento,
              cp: this.cp,
              email: this.correo,
              usocfdi: this.Cfdi.folio,
              regimen: this.regimen.folio,
              municipio: this.mievento,
              localidad: "",
              pais: "",
              tel: "",
              limcre: "1000",
              diacred: "30",
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            this.holas = false;
             this.errors = [];
          })
          .then((data) => {})
          .catch((error) => {
            console.log(error);
          });

        }
     
      } else {
        this.errors = [];
        if (!this.rfc) this.errors.push("RFC.");
        if (!this.nombre) this.errors.push("Razon Social.");
        if (!this.calle) this.errors.push("Calle.");
        if (!this.numero) this.errors.push("Numero");
        if (!this.colonia) this.errors.push("Colonia.");
        if (!this.evento) this.errors.push("Estado.");
        if (!this.mievento) this.errors.push("Ciudad.");
        if (!this.cp) this.errors.push("Codigo Postal.");
        if (!this.correo) this.errors.push("Correo.");
        if (!this.Cfdi) this.errors.push("Uso de CFDI.");
        if (!this.regimen) this.errors.push("Régimen Fiscal.");
      }
    },
  },
  computed: {
    ...mapState(["id", "miempresa", "elestado", "laciudad", "precio"]),
    logueado() {
      return this.$store.state.token;
    },
  },
  beforeMount() {
    this.$store.dispatch("autoLogin");
    axios
      .post(
        "/empresas/datosfiscales",
        {
          empresa: this.$store.state.id.empresa,
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        this.numero = response.data[0].noext;
        this.colonia = response.data[0].colonia;
        this.calle = response.data[0].direccion;
        this.nombre = response.data[0].razonsocial;
        this.cp = response.data[0].cp;
        this.rfc = response.data[0].rfc;
        this.evento = response.data[0].estado;
        this.mievento = response.data[0].ciudad;
        this.correo = response.data[0].email;
                  this.Cfdi = response.data[1].uso[0]
                          this.numeroInt = response.data[0].noint

        this.regimen = response.data[2].reg[0]
        console.log(this.regimen)
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post("/creditos/candidatostatuspago", {
        checkOutID: this.$route.query.resourcePath,
        empresa: this.$store.state.id.empresa,
        creditos: 800,
      },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
      .then((response) => {
        console.log(response.data);
        this.referencia = response.data.ref;
        this.pago = response.data.importe;
        this.publicaciones = response.data.creditos;
      })
      .catch((error) => {
        this.referencia = 0;
      }); 
  },
  mounted() {
    axios
      .get("/catalogos/estados", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.estados = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/catalogos/usocfdi", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.usos = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/catalogos/regimenes", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.regimenes = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/catalogos/ciudades/" + this.elestado, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.ciudades = response.data;
      })
      .catch((error) => {});
  },
  watch: {
    elestado: function(value) {
      axios
        .get("/catalogos/ciudades/" + this.elestado, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.ciudades = response.data;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style></style>
