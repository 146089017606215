<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
        Tipo de Listado
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        Requisitos
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3">
        Confirmación
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 4" step="4">
        Finalizado
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="mb-12 " color="#e0e0e0">
          <v-row>
            <v-col cols="6">
              <div style="padding:20px;margin-top:20px;">
                <v-alert
                  v-if="mensageTexto != null"
                  color="warning"
                  icon="check_circle"
                >
                  {{ mensageTexto }}
                </v-alert>

                <h3 class="text-center">Tipo de Listado</h3>

                <p style="text-align:center;">
                  Selecciona el tipo de listado que requieres
                </p>
                <br />
                <div class="text-center">
                  <v-radio-group
                    class="small-radio"
                    @change="verificatipoPublicacion"
                    v-model="tipoVacante"
                  >
                    <v-radio
                      selected
                      x-large
                      label="Listado de candidatos"
                    ></v-radio>

                    <v-radio
                      style="margin-top:15px;"
                      label="Listado de candidatos operativos"
                    ></v-radio>

                    <br />
                  </v-radio-group>
                </div>
                <br />
                <div class="text-center" id="pc">
                  <v-btn v-if="creditos" dark
                    >Tienes
                    {{ creditos.toLocaleString("en-US") }} Creditos</v-btn
                  >
                  <v-btn v-else dark>No Tienes Creditos</v-btn>
                  <v-btn style="margin-left:10px" color="secondary"
                    >Comprar</v-btn
                  >
                </div>
                <div class="text-center" id="cel">
                  <v-btn style="margin-left:-15px;" v-if="creditos" dark
                    >Tienes <br />
                    {{ creditos.toLocaleString("en-US") }} Creditos</v-btn
                  >
                  <v-btn style="margin-left:-15px;" v-else dark
                    >No<br />Tienes Creditos</v-btn
                  >

                  <v-btn
                    style="margin-left:20px;margin-top:10px;"
                    color="secondary"
                    >Comprar</v-btn
                  >
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div
                v-if="confirmapublicacion == 1"
                style="padding:20px,margin-left:-10px"
              >
                <h3 style="margin-top:40px; " class="text-center">
                  Descripción
                </h3>
                <p>
                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;">•</span>
                   Llena los requisitos de la vacante y podrás visualizar una lista con la información de todos los
candidatos de nuestra base de datos que coincidan con los requisitos. <br />
                  <br />
                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;">•</span>
                   Ideal para tener rápidamente opciones de candidatos que
                  puedan cubrir la vacante.<br />
                  <br /><span style="font-size:23px; color:#2afed6;margin-bottom:1px;">•</span>
                   Tu decides que tan especifico quieres que sean los filtros
                  para la búsqueda de candidatos potenciales.<br />
                </p>
                <div v-if="confirmapublicacion == 1" class="text-center">
                  <v-btn dark>{{ creditosAdministrativo }} créditos</v-btn>
                </div>
              </div>
              <div
                v-if="confirmapublicacion == 2"
                style="padding:20px,margin-left:-10px; margin-top:15px"
              >
                <h3 class="text-center">
                  Descripción
                </h3>
                <p>
                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;">•</span>
                   Busca personal para los siguientes puestos:<br />
                  <br />
                  <span style="font-size:19px; color:#2afed6;margin-bottom:1px;">•</span>
                   Operadores de Producción <br />
                   <span style="font-size:19px; color:#2afed6;margin-bottom:1px;">•</span>
                   Limpieza <br />
                  <span style="font-size:19px; color:#2afed6;margin-bottom:1px;">•</span>
                   Ayudante General <br />
                  <span style="font-size:19px; color:#2afed6;margin-bottom:1px;">•</span>
                   Cocinas <br />
                  <span style="font-size:19px; color:#2afed6;margin-bottom:1px;">•</span>
                   Vigilancia <br />
                  <span style="font-size:19px; color:#2afed6;margin-bottom:1px;">•</span>
                   Cajeros <br />
                  <br />
                  <span style="font-size:23px; color:#2afed6;margin-bottom:1px;">•</span>
                   Llena los requisitos de la vacante y recibirás una lista con
                  la <br />
                  información de 5 candidatos de nivel operativo. <br />
                </p>
              </div>
              <div v-if="confirmapublicacion == 2" class="text-center">
                <v-btn dark>{{ creditosOperativo }} créditos</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <div class="text-center">
         
            <v-btn v-if="creditos<800" @click="comprar()" color="secondary" style="color:white">
            Comprar
          </v-btn>

          
            <v-btn v-else @click="prueba()" color="primary" style="color:black">
            Continuar
          </v-btn>

          

          <v-btn dark @click="salirAmiPerfil()" style="margin-left:10px;">
            Anterior
          </v-btn>
        </div>
      </v-stepper-content>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-stepper-content step="2">
          <v-card class="mb-12 " color="#e0e0e0">
            <div style="padding:20px">
              <h3 class="text-center">Requisitos</h3>

              <p style="text-align:center;">
                Llena los requisitos sobre la cual se genera un listado con posibles
candidatos.
              </p>
              <br />
              <div v-show="confirmapublicacion == 2">
                <v-alert v-if="errors.length">
                  <b>Porfavor corrige los siguientes errores:</b>
                  <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
              </div>
              <div v-show="confirmapublicacion == 1">
                <v-alert v-if="errores.length">
                  <b>Porfavor corrige los siguientes errores:</b>
                  <ul>
                    <li v-for="error in errores" :key="error">{{ error }}</li>
                  </ul>
                </v-alert>
              </div>

              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="nombre"
                    outlined
                    label="Puesto"
                    required
                  ></v-text-field>
                </v-col>
                <v-flex md4 xs6 v-show="confirmapublicacion == 1">
                  <v-col >
                    <v-select
                      class="this"
                      :items="items"
                      outlined
                      label="Disponibilidad para Reubicarse"
                      v-model="reubicarse"
                    ></v-select>
                  </v-col>
                </v-flex>
                <v-flex md4 xs6>
                  <v-col>
                    <v-autocomplete
                      outlined
                      v-model="evento"
                      :click="setState(evento)"
                      label="Estado"
                      clearable
                      :items="estados"
                      item-text="estado"
                      item-value="value"
                      autocomplete="nope"
                      color="primary"
                    ></v-autocomplete>
                  </v-col>
                </v-flex>

                <v-flex md4 xs6>
                  <v-col>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-autocomplete
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          v-model="mieventc"
                          :click="setCiudad(mieventc)"
                          label="Ciudad"
                          clearable
                          :items="ciudades"
                          item-text="municipio"
                          item-value="ciudad"
                          autocomplete="nope"
                          color="primary"
                        ></v-autocomplete>
                      </template>
                      <span
                        >Para busqueda en todo el estado omita la ciudad</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-flex>
                <v-flex md4 xs12>
                  <v-col>
                    <Sexo></Sexo>
                  </v-col>
                </v-flex>

                

                <v-flex md2 xs6>
                  <v-col>
                    <v-text-field
                      v-model="edad_minima"
                      outlined
                      label="Edad (Mínima)"
                      required
                    ></v-text-field>
                  </v-col>
                </v-flex>
                <v-flex md2 xs6>
                  <v-col>
                    <v-text-field
                      v-model="edad_maxima"
                      outlined
                      label="Edad (Máxima)"
                      required
                      :rules="edadMaxima"
                    ></v-text-field>
                  </v-col>
                </v-flex>
                <v-flex md6 xs9 v-show="confirmapublicacion == 2">
                  <v-col>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-autocomplete
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          v-model="model"
                          :click="setHabilidades(model)"
                          label="Área"
                          clearable
                          :items="lashabilidads"
                          item-text="habilidad"
                          autocomplete="nope"
                          color="primary"
                        ></v-autocomplete>
                      </template>
                      <span
                        >Selecciona el área al que pertenece el puesto...</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-flex>
                <v-flex md2 xs2 v-show="confirmapublicacion == 2" id="pc">
                  <v-col>
                    <v-btn
                      @click="agregarHabilidad()"
                      color="primary"
                      style="color:black;"
                    >
                      Agregar
                    </v-btn>
                  </v-col>
                </v-flex>
                <v-flex md2 xs2 v-show="confirmapublicacion == 2" id="cel">
                  <v-col>
                    <v-btn
                      @click="agregarHabilidad()"
                      color="primary"
                      style="color:black;margin-left:-10px;"
                    >
                      +
                    </v-btn>
                  </v-col>
                </v-flex>
                <v-flex md4 xs6 v-show="confirmapublicacion == 1">
                  <v-col>
                    <v-text-field
                      v-model="años_experiencia"
                      outlined
                      label="Años de Experiencia Laboral"
                      required
                    ></v-text-field>
                  </v-col>
                </v-flex>

                <v-flex md4 xs12 v-show="confirmapublicacion == 1">
                  <v-col>
                    <nivelEducativo></nivelEducativo>
                  </v-col>
                </v-flex>
                <v-col cols="12">
                  <div class="text-center">
                    <p><strong>Rango de Sueldo de la Vacante</strong></p>
                  </div>
                </v-col>

                <v-col cols="6" v-if="confirmapublicacion == 1">
                  <div>
                    <vuetify-money
                      v-model="sueldo_minimo"
                      label="Mínimo (Mensual)"
                      v-bind:options="options"
                      outlined
                    />
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="text-center"
                  v-if="confirmapublicacion == 1"
                >
                  <div>
                    <vuetify-money
                      v-model="sueldo_superior"
                      label="Máximo (Mensual)"
                      v-bind:options="options"
                      outlined
                    />
                  </div>
                </v-col>
                <v-col cols="6" v-if="confirmapublicacion == 2">
                  <div>
                    <vuetify-money
                      v-model="sueldo_minimo"
                      label="Minimo (Semanal)"
                      v-bind:options="options"
                      outlined
                    />
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="text-center"
                  v-if="confirmapublicacion == 2"
                >
                  <div>
                    <vuetify-money
                      v-model="sueldo_superior"
                      label=" Máximo (Semanal)"
                      v-bind:options="options"
                      outlined
                    />
                  </div>
                </v-col>

                <v-col cols="9" v-if="confirmapublicacion == 1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-autocomplete
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        v-model="modelito"
                        :click="setIdioma(modelito)"
                        label="Idiomas"
                        clearable
                        :items="losidiomas"
                        item-text="idioma"
                        item-value="ciudad"
                        autocomplete="nope"
                        color="primary"
                      ></v-autocomplete>
                    </template>
                    <span>De no requerir un idioma omita este campo...</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="2" v-if="confirmapublicacion == 1" id="pc">
                  <v-btn
                    v-on:click="agregarIdioma()"
                    color="primary"
                    style="color:black;"
                  >
                    Agregar
                  </v-btn>
                </v-col>
                <v-col cols="2" v-if="confirmapublicacion == 1" id="cel">
                  <v-btn
                    v-on:click="agregarIdioma()"
                    color="primary"
                    style="color:black;"
                  >
                    +
                  </v-btn>
                </v-col>
                <br />
              </v-row>
              <v-row style="margin-top:10px;">
                <v-col cols="12" v-if="confirmapublicacion == 1">
                  <p class="text-center">
                    <strong>
                      Agrega el área y la subárea de donde requieras que se tenga
conocimiento. <br />Puedes agregar como máximo 5</strong
                    >
                  </p>
                </v-col>

                <v-col cols="5" v-if="confirmapublicacion == 1">
                  <v-autocomplete
                    outlined
                    return-object
                    v-model="event"
                    :click="setEspecialidad(event)"
                    clearable
                    :items="misespecialidades"
                    label="Área"
                    color="primary"
                    item-text="especialidad"
                    item-value="value"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="5" v-if="confirmapublicacion == 1">
                  <v-autocomplete
                    outlined
                    return-object
                    v-model="mievent"
                    :click="setsubEspecialidad(mievent)"
                    label="Subáreas"
                    clearable
                    :items="subespecialidades"
                    item-text="sub"
                    item-value="value"
                    name="nombre"
                    id="nombre"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" v-if="confirmapublicacion == 1" id="pc">
                  <v-btn
                    v-on:click="agregarEspecialidad()"
                    color="primary"
                    style="color:black;"
                  >
                    Agregar
                  </v-btn>
                </v-col>
                <v-col cols="2" v-if="confirmapublicacion == 1" id="cel">
                  <v-btn
                    v-on:click="agregarEspecialidad()"
                    color="primary"
                    style="color:black;margin-left:-15px;"
                  >
                    +
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" v-if="confirmapublicacion == 1">
                  <h3>Áreas y Subáreas</h3>
                  <ul>
                    <li
                      v-for="(especialidad, index) of especialidades"
                      :key="especialidad.folio"
                    >
                      {{ especialidad.especialidad }} - {{ especialidad.sub }}

                      <v-btn
                        @click="borraEspecialidades(index)"
                        dark
                        style="margin:5px;padding:5px;"
                        >Borrar</v-btn
                      >
                    </li>
                  </ul>
                </v-col>

                <v-col cols="6" v-if="confirmapublicacion == 1">
                  <h3>Idiomas</h3>
                  <ul>
                    <li v-for="(idioma, index) of idiomas" :key="idioma.idioma">
                      {{ idioma.idioma }}
                      <v-btn
                        @click="borraIdiomas(index)"
                        dark
                        style="margin:5px;padding:5px;"
                        >Borrar</v-btn
                      >
                    </li>
                  </ul>
                </v-col>
                <v-col v-if="confirmapublicacion == 2" cols="6">
                  <div style="padding:4px; margin-top:5px;">
                    <h3>Área</h3>
                    <ul>
                      <li
                        v-for="(habilidad, index) of mishabilidades"
                        :key="habilidad.habilidad"
                      >
                        {{ habilidad.habilidad }}
                        <v-btn
                          @click="borraHabilidades(index)"
                          dark
                          style="margin:5px;padding:5px;"
                          >Borrar</v-btn
                        >
                      </li>
                    </ul>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <div class="text-center">
            <v-btn
              v-if="confirmapublicacion == 2"
              @click="CuantosEnListaOperativo()"
              color="primary"
            >
              Continuar
            </v-btn>
            <v-btn
              v-if="confirmapublicacion == 1"
              @click="CuantosEnListaAdministrativo()"
              color="primary"
            >
              Continuar
            </v-btn>

            <v-btn @click="cancelarRequisitos()" dark style="margin-left:5px;">
              Anterior
            </v-btn>
          </div>
        </v-stepper-content>
      </v-form>

      <v-stepper-content step="3">
        <v-card class="mb-12 " color="#e0e0e0" v-if="confirmapublicacion == 2">
          <v-row>
            <v-col cols="12">
              <div style="padding:20px">
                <h3 style="text-align:center;">
                  Tu lista para la búsqueda de candidatos para el puesto de "{{ this.nombre }}" esta
                    por generarse
                  
                </h3>
                <h4 class="text-center" style="color:red;">
                  Revisa bien la información que seleccionaste, ya que una vez que hagas
click en confirmar, ya no se podrán editar los datos nuevamente.
                </h4>
                <br />
                
              </div>
            </v-col>
            <v-flex md3 xs6>
              <v-col>
                <div style="padding:20px">
                  <p>
                    <strong>Localidad: </strong> {{ this.elestado }},{{
                      this.laciudad
                    }}
                  </p>
                </div>
              </v-col>
            </v-flex>
            <v-flex md3 xs6>
              <v-col>
                <div style="padding:20px">
                  <p>
                    <strong>Edad<br> </strong> {{ this.edad_minima }} a
                    {{ this.edad_maxima }} años
                  </p>
                </div>
              </v-col>
            </v-flex>
            <v-flex md3 xs6>
              <v-col>
                <div style="padding:20px">
                  <p v-if="this.nuevoSexo">
                    <strong>Sexo:</strong> {{ this.nuevoSexo }}
                  </p>
                  <p v-else><strong>Sexo:</strong> Indistinto</p>
                </div>
              </v-col>
            </v-flex>
            <v-flex md3 xs12>
              <v-col>
                <div style="padding:20px">
                  <p class="text-center"><strong>Rango de sueldo:</strong></p>
                  <v-row>
                    <v-col>
                      <vuetify-money
                        disabled
                        v-model="sueldo_minimo"
                        label="Mínimo (Semanal)"
                        v-bind:options="options"
                      />
                    </v-col>
                    <v-col>
                      <div>
                        <vuetify-money
                          disabled
                          v-model="sueldo_superior"
                          label=" Máximo (Semanal)"
                          v-bind:options="options"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-flex>
            <v-flex md6 xs6>
              <v-col>
                <div style="padding:20px">
                  <p><strong>Área</strong></p>
                  <ul>
                    <li
                      v-for="habilidad of mishabilidades"
                      :key="habilidad.habilidad"
                    >
                      {{ habilidad.habilidad }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-flex>

            <v-col cols="12">
              <div style="padding:20px">
                <p style="color:red;" v-if="this.cuantos<=5">
                  De acuerdo con los requisitos para tu listado, el numero de
candidatos que cumple con estos es de {{ this.cuantos }} ¿deseas adquirir la lista?
                  
                </p>
                
                <br />
                <p>
                  Si quieres regresar presiona el botón de “ANTERIOR” para editar los
requisitos nuevamente.
                </p>
                <br />
                <p>
                  Con esta lista de candidatos seran consumidos 100 créditos, te
                  quedarán {{(creditos-100).toLocaleString("en-US")}} créditos disponibles
                </p>
                <router-link to="/ComprarCreditos">
                <v-btn color="secondary" style="color:black;">Comprar Créditos</v-btn>
                </router-link>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mb-12 " color="#e0e0e0" v-if="confirmapublicacion == 1">
          <v-row>
            <v-col cols="12">
              <div style="padding:20px">
                <h3 style="text-align:center;">
                  Tu lista de candidatos "{{ this.nombre }}" esta por generarse
                </h3>
                <br />
                <p class="text-center" style="color:red;">
                  Revisa bien la información que seleccionaste, ya que una vez que hagas 
                  click en confirmar, ya no se podrán editar los datos nuevamente.
                </p>
              </div>
            </v-col>
            <v-flex md3 xs6>
              <v-col>
                <div style="padding:20px">
                  <p>
                    <strong>Localidad: </strong> {{ this.elestado }},{{
                      this.laciudad
                    }}
                  </p>
                </div>
              </v-col>
            </v-flex>
            <v-flex md3 xs6>
              <v-col>
                <div style="padding:20px">
                  <p>
                    <strong>Edad: </strong> {{ this.edad_minima }} a
                    {{ this.edad_maxima }} años
                  </p>
                </div>
              </v-col>
            </v-flex>

            <v-flex md3 xs6>
              <v-col>
                <div style="padding:20px">
                  <p v-if="this.nuevoSexo">
                    <strong>Sexo:</strong> {{ this.nuevoSexo }}
                  </p>
                  <p v-else><strong>Sexo:</strong> Indistinto</p>
                </div>
                <div style="padding:20px" id="cel">
                  <p><strong>Idiomas</strong></p>
                  <ul>
                    <li v-for="idioma of idiomas" :key="idioma.idioma">
                      {{ idioma.idioma }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-flex>
            <v-flex md3 xs6>
              <v-col>
                <div style="padding:20px">
                  <p class="text-center"><strong>Rango de sueldo:</strong></p>
                  <v-row>
                    <v-col cols="6">
                      <vuetify-money
                        disabled
                        v-model="sueldo_minimo"
                        label="Minimo (Mensual)"
                        v-bind:options="options"
                      />
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <vuetify-money
                          disabled
                          v-model="sueldo_superior"
                          label=" Máximo (Mensual)"
                          v-bind:options="options"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-flex>
            <v-flex md3 xs12>
              <v-col>
                <div style="padding:20px">
                  <h3>Áreas y Subáreas</h3>
                  <ul>
                    <li
                      v-for="especialidad of especialidades"
                      :key="especialidad.folio"
                    >
                      {{ especialidad.especialidad }} - {{ especialidad.sub }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-flex>
            <v-flex md3 xs6 id="pc">
              <v-col>
                <div style="padding:20px">
                  <h3>Idiomas</h3>
                  <ul>
                    <li v-for="idioma of idiomas" :key="idioma.idioma">
                      {{ idioma.idioma }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-flex>
            <v-flex md3 xs12>
              <v-col>
                <div v-if="confirmapublicacion == 1" style="padding:20px">
                  <h4>Reubicarse</h4>
                  <br />

                  <p v-if="reubicarse == 'Si'">Si reubicarse</p>
                  <p v-if="reubicarse == 'No'">No reubicarse</p>
                  <p v-if="reubicarse == null">Indistinto</p>
                </div>
              </v-col>
            </v-flex>

            <v-col cols="12">
              <div style="padding:20px">
                <p style="color:red;" v-if="this.cuantos<5">
                  De acuerdo con los requisitos para tu listado, el numero de 
candidatos que cumple con estos es de
                  {{ this.cuantos }} ¿deseas adquirir la lista?
                </p>
                <br />
                <p>
                  Con esta lista de candidatos seran consumidos 150 créditos, te
                  quedarán {{(creditos-150).toLocaleString("en-US")}} créditos disponibles
                </p>
                <br />
                <router-link to="/ComprarCreditos">
                <v-btn color="secondary" style="color:black;">Comprar Créditos</v-btn>
                </router-link>
              </div>
               
            </v-col>
          </v-row>
        </v-card>

        <div class="text-center">
          <div v-if="cuantos">
            <v-btn
              style="color:black"
              v-show="creditos"
              v-if="confirmapublicacion == 2"
              @click="GeneraListaOperativo()"
              color="primary"
            >
              Confirmar
            </v-btn>
            <v-btn
              style="color:black"
              v-show="creditos"
              v-if="confirmapublicacion == 1"
              @click="GeneraListaAdmon()"
              color="primary"
            >
              Confirmar
            </v-btn>
             <v-btn @click="cambiar2()" style="margin-left:10px" dark>
            Anterior
          </v-btn>
           
          </div>
          <div v-else>
            <v-btn color="primary" style="color:black;"
              >No se han encontrado Candidatos</v-btn
            >
            <br />
             <v-btn @click="cambiar2()" style="margin-left:10px" dark>
            Anterior
          </v-btn>
          </div>
          <br />

         
        </div>
      </v-stepper-content>
      <v-stepper-content step="4">
        <v-card class="mb-12 " color="#e0e0e0">
          <v-row>
            <v-col cols="12">
              <div class="text-center" style="padding:20px;">
                <h3>¡Tu lista de candidatos ha sido generada exitosamente!</h3>
                <p>Esta lista se ha enviado a tu correo electrónico.</p>
                <p>
                  Fueron consumidos 150 créditos, tienes
                  {{ (creditos-150).toLocaleString("en-US") }} creditos disponibles
                </p>

                <v-dialog v-model="dialog" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      style="display:none"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Open Dialog
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      Listado {{ this.nombre }}
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                      <v-data-table
                        :headers="headers"
                        :items="candidatos"
                        :items-per-page="5"
                        filterable
                        :search="search"
                        @click:row="handleClick"
                      >
                        <template v-slot:item.nacimiento="{ item }">
                          <p>
                            {{ moment().diff(item.nacimiento, "years", false) }}
                            años
                          </p>
                        </template>
                        <template v-slot:item.celular="{ item }">
                          <input v-mask="mask" v-model="item.celular" />
                        </template>
                        <template v-slot:item.OP_salario="{ item }">
                          <input v-mask="mascara" v-model="item.OP_salario" />
                        </template>
                        <template v-slot:item.op_salario_hi="{ item }">
                          <input
                            v-mask="mascara"
                            v-model="item.op_salario_hi"
                          />
                        </template>
                      </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="green darken-1"
                        text
                        @click="dialog = false"
                      >
                        Salir
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogo" width="900px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      style="color:black; display:none;"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Ver Datos
                      <v-icon>mdi-account-circle</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 dark">
                      Datos del Candidato 
                    </v-card-title>
                    <v-container>
                      <v-col> </v-col>
                      <div class="text-center">
                        <v-row>
                          <v-col cols="6">
                            <div class="text-center">
                              <v-img
                                class="miimagen"
                                :src="candidato.foto"
                                alt=""
                              ></v-img>
                              <br>
                               <h3 >
                                {{ candidato.nombre}} {{ candidato.apellido }}
                              </h3>
                              <br>
                              <p>
                              Edad:
                              {{
                                moment().diff(
                                  candidato.nacimiento,
                                  "years",
                                  false
                                )
                              }}
                              años
                            </p>
                            <p>
                              <strong>Vive en:</strong> <br>
                              {{ candidato.estado }},
                              {{ candidato.ciudad }}
                            </p>
                            <br>
                            <p
                              class="trabajando"
                              v-if="candidato.activo == true"
                            >
                            <strong>Estatus Laboral</strong> <br>
                             Trabajando
                            </p>
                            <p class="trabajando" v-else>
                             Sin trabajo
                            </p>
                            </div>
                          </v-col>
                          <v-col cols="6">
                           
                             
                              
                              <a
                                target="_blank"
                                style="color:#2afed6"
                                :href="candidato.curriculum"
                                >Curriculum Vitae</a
                              >
                          
                            
                            <br>
                            <br>
                            <p><strong>Salario Esperado:</strong></p>
                            <p v-if="candidato.OP_salario && candidato.op_salario_hi" >
                                {{
                              candidato.OP_salario.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }}
                               -  {{
                              candidato.op_salario_hi.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }} </p>
                            
                            <br>

                            <p><strong>Telefono</strong></p>
                            <p>{{candidato.celular}}</p>
                            <br>
                            <p><strong>Correo</strong></p>
                            <p>{{candidato.correo}}</p>
                            <br>
                          </v-col>
                          
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-card
                              color="grey darken-1"
                              class="tarjeta-trabajos"
                            >
                              <h2>Trabajos</h2>
                              <br />
                              <div
                                v-for="value in trabajos"
                                :key="value.empresa"
                              >
                                <h4>
                                  {{ value.empresa.toUpperCase() }} -
                                  {{ value.puesto.toUpperCase() }}
                                </h4>
                                <br>
                                <textarea style="font-size:13px ;color:black;padding:5px;margin-bottom:10px;"   disabled wrap="soft" v-model="value.descripcion" name="" id="" cols="25" rows="2"></textarea>   


                                <h5>
                                  Fecha: {{ value.inicio }} -
                                  {{ value.final }}
                                </h5>
                                <br />
                                <div class="text-center">
                                <h5  style="background-color:#2afed6; width:auto; border-radius:5px; display: block;
margin-left: auto;
margin-right: auto;" v-if="value.actual">Trabajo Actual</h5>

                                </div>
                                <br>
                              </div>
                              <h2 class="text-center">Cursos</h2>
                              <br>
                              <div
                                v-for="value in cursos"
                                :key="value.candidato"
                              >
                                <h4>{{ value.curso }} <br /></h4>
                                <h5>Descripción: {{ value.descripcion }}</h5>
                              </div>
                              <br>
                              <h2 class="text-center">Habilidades</h2>
                              <br />
                               <div v-for="value in habilidades" :key="value.folio">
                  <h4>{{ value.habilidad }}</h4>
                  <br />
                </div>
                            </v-card>
                          </v-col>
                          <v-col>
                            <v-card
                              color="grey darken-1"
                              class="tarjeta-postulaciones"
                            >
                              <h2 class="text-center">Estudios</h2>
                              <br />
                              
                              <div v-for="value in educacion" :key="value.nivel">
                                <h4 >
                                <strong style="color:#2afed6;">Nivel Educativo: </strong>
                                {{value.nivel}}<br>
                                  <strong style="color:#2afed6;">Titulo: </strong>{{ value.titulo }} <br><strong style="color:#2afed6;">Institucion: </strong>
                                  {{ value.institucion }}  
                                  <br />
                                <strong style="color:#2afed6;"> Fecha:</strong> {{ value.ano_inicio }} - {{ value.ano_final }}
                               

                                                               
                                </h4>
                               <br>
                               <h5  style="background-color:#2afed6; width:auto; border-radius:5px; display: block;
margin-left: auto;
margin-right: auto;" v-if="value.actual">Estudio Actual</h5>

                                
                              </div>
                              <br>
                              
                             <h2 class="text-center">Idiomas</h2>
                             
                              <div v-for="value in idiomas" :key="value.idioma">
                  <br />
                  <h4> <strong style="color:#2afed6;"> {{ value.idioma }}</strong> - {{ value.nivel }}</h4>
                </div>
                <br>
                               <h2 class="text-center">Áreas de Experiencia</h2>
                                              <br />
                                              <div v-for="value in especialidades" :key="value.sub">
                                                <h4>
                                                  <strong style="color:#2afed6;">
                                                  Area:</strong>
                                                    {{ value.especialidad }}
                                                  <br>
                                                  
                                                  <strong style="color:#2afed6">
                                                  Subárea:</strong>
                                                  {{ value.sub }}
                                                </h4>
                                                <br />
                                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row justify="center"> </v-row>
                      </div>
                    </v-container>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-container>
                        <div class="text-center">
                          <v-btn
                          color="primary"
                            style="margin-left:20px; color:black;"
                            @click="dialogo = false"
                           
                          >
                            Cerrar
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </v-container>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="midialog" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey darken-1"
                      dark
                      style="display:none"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Open Dialog
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      Listado {{ this.nombre }}
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                      <v-data-table
                        :headers="titulos"
                        :items="candidatos"
                        :items-per-page="5"
                        filterable
                        :search="search"
                      >
                        <template style="margin-top:13px;" v-slot:item.nacimiento="{ item }">
                          <p>
                            {{
                              moment().diff(
                                candidato.nacimiento,
                                "years",
                                false
                              )
                            }}
                            años
                          </p>
                        </template>
                        <template style="margin-top:13px;" v-slot:item.celular="{ item }">
                          <input v-mask="mask" v-model="item.celular" />
                        </template>
                        <template style="margin-top:13px;" v-slot:item.sueldo="{ item }">
                          <p>
                            {{
                              item.sueldo.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }}
                          </p>
                        </template>
                        <template style="margin-top:13px;" v-slot:item.sueldo_sup="{ item }">
                          <p>
                            {{
                              item.sueldo_sup.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }}
                          </p>
                        </template>
                      </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="green darken-1"
                        text
                        @click="midialog = false"
                      >
                        Salir
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <div class="text-center">
          <v-btn color="primary" @click="salirAmisListas()">
            Continuar
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import Giro from "../Buscador/Giro.vue";
import Sector from "../Buscador/Sector.vue";
import Sexo from "../Buscador/Sexo.vue";
import nivelEducativo from "../Buscador/nivelEducativo.vue";

import HabilidadesAdministrativo from "../Buscador/HabilidadesAdministrativo.vue";
import Especialidad from "../Buscador/Especialidad.vue";
import SubEspecialidad from "../Buscador/SubEspecialidad.vue";
import nivelEducativoOperativo from "../Buscador/nivelEducativoOperativo.vue";

var moment = require("moment");

import axios from "axios";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      errors: [],
      errores: [],
      erroros: [],
      valida: true,
      valid: true,
      creditosAdministrativo: 1500,
      creditosOperativo: 1000,
      lashabilidads: [],
      salarioMinimo: null,
      estados: [], // <-- La lista de especialidades
      evento: "",
      especialidadSeleccionada: {}, //<-- el seleccionado estará aquí
      ciudades: [], // <-- La lista de especialidades
      mieventc: null,
      estacargando: false,
      losidiomas: [],
      modelito: null,
      buscar: null,
      tabsin: null,
      idioma: null,
      isLoading: false,
      items: [],
      model: null,
      search: null,
      tab: null,
      options: {
        locale: "ja-JP",
        prefix: "$",
        suffix: "",
        length: 10,
        precision: 2,
      },
      midialog: false,
      dialog: false,
      mask: "(###) ###-##-##",

      sueldo_minimo: null,
      sueldo_superior: null,
      search: "",
      event: "",
      mievent: "",
      posts: [],
      dialogo: false,
      searchResult: null,
      searchString: "",
      selected: "",
      nombre: null,
      menu: false,
      modal: false,
      menu2: false,
      moment: moment,
      e1: 1,
      candidato: [],
      educacion: [],
      trabajos: [],
      cursos: [],
      idiomas: [],
      posicion: "",
      mensageTexto: null,
      descripcion_corta: "",
      descripcion_detallada: "",
      fecha_inicio: "",
      sueldo_minimo: "",
      sueldo_superior: "",
      candidatos: [],
      mishabilidades: null,
      telefono: "",
      items: ["Si", "No", "Indistinto"],
      contratacion: "",
      confirmacontratacion: "",
      confirmajornada: "",
      subespecialidades: [],
      especialidades: null,
      misespecialidades: [],
      missubespecialidades: [],
      jornada: "",
      area: "",
      sector: "",
      confirmapublicacion: "",
      giro: "",
      edad_minima: null,
      edad_maxima: null,
      habilidades: [],
      escuelas: [],
      sueldominimos: null,
      sueldomaximos: null,
      años_experiencia: "",
      tipoVacante: null,
      misexo: "",
      creditos: null,
      reubicarse: null,
      edadMaxima: [(v) => v > this.edad_minima || "Edad maxima debe ser mayor"],
      sueldo_minimoRules: [(v) => v.length < 1000000 || "Minimo 6 Caracteres"],
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Apellido", value: "apellido" },
        { text: "Edad", value: "nacimiento" },
        { text: "Estado", value: "estado" },
        { text: "Ciudad", value: "ciudad" },
        { text: "Celular", value: "celular" },
        { text: "Correo", value: "correo" },
        { text: "$ Minimo (Mensual)", value: "OP_salario" },
        { text: "$ Máximo (Mensual)", value: "op_salario_hi" },
      ],
      titulos: [
        { text: "Nombre", value: "nombre" },
        { text: "Apellido", value: "apellido" },
        { text: "Edad", value: "nacimiento" },
        { text: "Estado", value: "estado" },
        { text: "Ciudad", value: "ciudad" },
        { text: "Celular", value: "celular" },
        { text: "$ Minimo (Semanal)", value: "sueldo" },
        { text: "$ Máximo (Semanal)", value: "sueldo_sup" },
        { text: "Nivel Educativo", value: "nivel_educativo" },
      ],
      cuantos: "",
    };
  },
  mounted() {
     this.$store.dispatch("autoLogin");
    axios
      .get("/catalogos/habilidades_op")
      .then((response) => {
        this.lashabilidads = response.data;
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/catalogos/idiomas")
      .then((response) => {
        this.losidiomas = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/catalogos/estados")
      .then((response) => {
        this.estados = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/catalogos/especialidades")
      .then((response) => {
        this.misespecialidades = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        "/creditos/creditosempresa/" +
          this.$store.state.id.empresa
      )
      .then((response) => {
        console.log(response.data);
        this.creditos = response.data.creditos;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/catalogos/habilidades")
      .then((response) => {
        this.habilidades = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    elestado: function(value) {
      axios
        .get(
          "/catalogos/ciudades/" +
            this.elestado
        )
        .then((response) => {
          this.ciudades = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sueldo_minimo: function(value) {
      this.sueldominimos = value;
    },
    sueldo_superior: function(value) {
      this.sueldomaximos = value;
    },
    nuevaespecialidad: function(value) {
      axios
        .get(
          "/catalogos/subespe/" +
            this.nuevaespecialidad.folio
        )
        .then((response) => {
          this.subespecialidades = response.data;
        })
        .catch((error) => {});
    },
  },

  computed: {
    ...mapState([
      "elestado",
      "laciudad",
      "elPais",
      "id",
      "elidioma",
      "lainstitucion",
      "folioinstitucion",
      "nuevaespecialidad",
      "nuevasubespecialidad",
      "nuevahabilidad",
      "nuevogiro",
      "nuevosector",
      "nuevoSexo",
      "lahabilidad",
      "nivelPrueba",
      "nivel",
    ]),
  },
  components: {
    Sector,
    Giro,
    nivelEducativo,
    Sexo,

    HabilidadesAdministrativo,
    Especialidad,
    SubEspecialidad,
    nivelEducativoOperativo,
  },
  methods: {
     comprar(){
       this.$router.push({ name: "ComprarCreditos" });
    },
    handleClick(row) {
      console.log(row);

      axios
        .get("/candidatos/" + row.folio)
        .then((response) => {
          this.candidato = response.data;
          this.idiomas = response.data.idiomas;
          this.educacion = response.data.educacion;
          this.trabajos = response.data.exp_profesional;
          this.especialidades = response.data.especialidades;
          this.cursos = response.data.cursos;
          this.habilidades = response.data.habilidades;
          this.dialogo = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    borraIdiomas: function(index) {
      this.idiomas.splice(index, 1);
    },
    borraHabilidades: function(index) {
      this.mishabilidades.splice(index, 1);
    },
    borraEscuelas: function(index) {
      this.escuelas.splice(index, 1);
    },
    borraEspecialidades: function(index) {
      this.especialidades.splice(index, 1);
    },
    salirAmisListas() {
      this.$router.push({ name: "EmpresasListas" });
    },
    salirAmiPerfil() {
      this.$router.push({ name: "PerfilEmpresas" });
    },
    cancelarRequisitos() {
      (this.nombre = ""), (this.elestado = "");
      (this.laciudad = ""),
        (this.sexo = ""),
        (this.edad_minima = ""),
        (this.edad_maxima = ""),
        (this.habilidades = null),
        (this.e1 = 1);
    },
    agregarIdioma() {
      if (this.idiomas == null) {
        this.idiomas = new Array();
      }
      this.idiomas.push({
        idioma: this.elidioma,
      });
      this.modelito = null;

    },
    agregarEspecialidad() {
       if (this.especialidades == null) {
        this.especialidades = new Array();
       }
      this.especialidades.push({
        f_especialidad: this.nuevaespecialidad.folio,
        f_sub: this.nuevasubespecialidad.folio,
        especialidad: this.nuevaespecialidad.especialidad,
        sub: this.nuevasubespecialidad.sub,
      });
      this.event = "";
      this.mievent = "";
      
    },
    agregarEscuelas() {
      this.escuelas.push({
        nivel: this.nivelPrueba,
      });
    },
    verificarcontratacion() {
      if (this.contratacion) {
        this.confirmacontratacion = "Temporal";
      } else {
        this.confirmacontratacion = "Permanente";
      }
    },
    verificarjornada() {
      if (this.jornada == 0) {
        this.confirmajornada = "Completa";
      }
      if (this.jornada == 1) {
        this.confirmajornada = "Medio Tiempo";
      }
      if (this.jornada == 2) {
        this.confirmajornada = "Otra";
      }
    },
    verificararea() {
      if (this.area == 0) {
        this.confirmaarea = "Oficina";
      }
      if (this.area == 1) {
        this.confirmaarea = "Fabrica";
      }
      if (this.area == 2) {
        this.confirmaarea = "Remota";
      }
    },
    agregarHabilidad() {
      if (this.mishabilidades == null) {
        this.mishabilidades = new Array();
      }
      this.mishabilidades.push({
        habilidad: this.lahabilidad,
      });
       this.mishabilidades = [
          ...this.mishabilidades
            .reduce((map, obj) => map.set(obj.habilidad, obj), new Map())
            .values(),
        ];

    },
    verificatipoPublicacion() {
      if (this.tipoVacante == 0) {
        this.confirmapublicacion = 1;
      }
      if (this.tipoVacante == 1) {
        this.confirmapublicacion = 2;
      }
    },
    ...mapMutations([
      "setEspecialidad",
      "setsubEspecialidad",
      "setIdioma",
      "setState",
      "setCiudad",
      "setHabilidades",
    ]),
    logueado() {
      return this.$store.state.token;
    },
    cambiar() {
      this.e1 = 1;
    },
    cambiar2() {
      this.e1 = 2;
    },

    cambiar3() {
      this.e1 = 3;
    },
    cambiar4() {
      this.e1 = 4;
    },
    cambiar5() {
      this.e1 = 5;
    },
    cambiar6() {
      this.e1 = 6;
    },
    cambiar7() {
      this.e1 = 7;
    },

    CuantosEnListaOperativo() {

      console.log(this.sueldo_minimo, this.sueldo_superior)
             this.errors = [];
              if(this.sueldo_superior>this.sueldo_minimo) this.errors.push("El sueldo mínimo es mayor al inferior")


      /*       console.log(
        "empresa:",
        this.$store.state.id.empresa,
        "Nombre lista:",
        this.nombre,
        "edo:",
        this.elestado,
        "cd:",
        this.laciudad,
        "einf:",
        this.edad_minima,
        "esup:",
        this.edad_maxima,
        "Sexo:",
        this.nuevoSexo,
        "Habilidades:",
        this.mishabilidades
      );
 */
      if (
        
        this.nombre &&
        this.elestado &&
        this.mishabilidades &&
        this.sueldo_superior &&
        this.sueldo_minimo &&
        this.sueldo_superior>this.sueldo_minimo 
    
      ) {
        axios
          .post("/listas/cuantosEnlista_op", {
            empresa: this.$store.state.id.empresa,
            edad_min: this.edad_minima,
            edad_max: this.edad_maxima,
            sexo: this.nuevoSexo,
            estado: this.elestado,
            ciudad: this.laciudad,
            habilidades: this.mishabilidades,
            sueldo_sup: this.sueldo_superior,
            sueldo_inf: this.sueldo_minimo,
          })
          .then((response) => {
            // console.log(response.data);
            this.cuantos = response.data[0].cuantos;
            this.e1 = 3;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (!this.nombre) this.errors.push("Nombre de la lista es requerido.");
        if (!this.edad_minima) this.errors.push("Edad mínima es requerida.");
        if (!this.edad_maxima) this.errors.push("Edad máxima es requerida.");
        if (!this.elestado) this.errors.push("Estado es requerido.");
        if (!this.mishabilidades) this.errors.push("Área es requerido.");
        if (!this.sueldo_minimo)
          this.errors.push("Sueldo mínimo es requerido.");

        if (!this.sueldo_superior)
          this.errors.push("Sueldo máximo es requerido.");
      }
    },
    CuantosEnListaAdministrativo() {
      /* console.log(
        "empresa:",
        this.$store.state.id.empresa,
        "edad_min:",
        this.edad_minima,
        "esup:",
        this.edad_maxima,
        "Años de experiencia:",
        this.años_experiencia,
        "Sexo:",
        this.nuevoSexo,
        "Nombre lista:",
        this.nombre,
        "edo:",
        this.elestado,
        "cd:",
        this.laciudad,
        "Nivel Educativo:",
        this.nivelPrueba,
        "Reubicarse:",
        this.reubicarse,
        "Idiomas:",
        this.idiomas,
        "Especialidades:",
        this.especialidades,
        "sueldo_sup:",
        this.sueldo_superior,
        "sueldo_inf:",
        this.sueldo_minimo
      ); */
          console.log(this.sueldo_minimo, this.sueldo_superior)

                   this.errores = [];
              if(this.sueldo_superior<this.sueldo_minimo) this.errores.push("El sueldo mínimo es mayor al inferior")

      if (
        this.nombre &&
        this.elestado &&
        this.especialidades &&
        this.sueldo_superior &&
        this.sueldo_minimo &&
        this.nivelPrueba &&
        this.edad_minima &&
        this.edad_maxima &&
        this.sueldo_superior>this.sueldo_minimo
      ) {
        if (this.reubicarse == "Indistinto") {
          this.reubicarse = null;
        }
        if (this.idiomas.length == 0) {
          console.log("idiomasvacio");
          this.idiomas = null;
        }

        axios
          .post("/listas/cuantosEnlista", {
            empresa: this.$store.state.id.empresa,
            descripcion: this.nombre,
            creditos: 150,
            edad_min: this.edad_minima,
            edad_max: this.edad_maxima,
            experiencia: this.años_experiencia,
            sexo: this.nuevoSexo,
            estado: this.elestado,
            ciudad: this.laciudad,
            nivel_educativo: this.nivelPrueba,
            reubicarse: this.reubicarse,
            idiomas: this.idiomas,
            especialidades: this.especialidades,
            sueldo_sup: this.sueldo_superior,
            sueldo_inf: this.sueldo_minimo,
          })
          .then((response) => {
            this.cuantos = response.data[0].cuantos;
            this.e1 = 3;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (!this.nombre) this.errores.push("Nombre de la lista es requerido.");
        if (!this.edad_minima) this.errores.push("Edad mínima es requerida.");
        if (!this.edad_maxima) this.errores.push("Edad máxima es requerida.");
        if (!this.elestado) this.errores.push("Estado es requerido.");
        if (!this.nivelPrueba) this.errores.push("Escolaridad es requerida.");
        if (!this.especialidades) this.errores.push("Área es requerido.");
        if (!this.sueldo_minimo)
          this.errores.push("Sueldo mínimo es requerido.");
        if (!this.sueldo_superior)
          this.errores.push("Sueldo máximo es requerido.");
      }
    },
    prueba() {
      if (this.confirmapublicacion == 1) {
        this.e1 = 2;
      } else if (this.confirmapublicacion == 2) {
        this.e1 = 2;
      } else {
        this.mensageTexto = "Es necesario seleccionar un tipo de listado";
      }
    },
    GeneraListaOperativo() {
      this.e1 = 4;
      /* console.log(
        "empresa: ",
        this.$store.state.id.empresa,
        "descripcion; ",
        this.nombre,
        "creditos: 150",
        "edad_min: ",
        this.edad_minima,
        "edad_max: ",
        this.edad_maxima,
        " experiencia: ",
        this.años_experiencia,
        "sexo: ",
        this.nuevoSexo,
        "estado: ",
        this.elestado,
        " ciudad: ",
        this.laciudad,
        "sueldo_sup: ",
        this.sueldo_superior,
        "sueldo_inf: ",
        this.sueldo_minimo,
        "habilidades: ",
        this.mishabilidades
      );
 */
      axios
        .post("/listas/generalista_op", {
          empresa: this.$store.state.id.empresa,
          descripcion: this.nombre,
          creditos: 150,
          edad_min: this.edad_minima,
          edad_max: this.edad_maxima,
          experiencia: this.años_experiencia,
          sexo: this.nuevoSexo,
          estado: this.elestado,
          ciudad: this.laciudad,
          sueldo_sup: this.sueldo_superior,
          sueldo_inf: this.sueldo_minimo,
          habilidades: this.mishabilidades,
        })
        .then((response) => {
          this.midialog = true;
          this.candidatos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GeneraListaAdmon() {
      this.e1 = 4;
      axios
        .post("/listas/generalista", {
          empresa: this.$store.state.id.empresa,
          descripcion: this.nombre,

          creditos: 150,
          edad_min: this.edad_minima,
          edad_max: this.edad_maxima,
          sexo: this.nuevoSexo,
          experiencia: this.años_experiencia,
          estado: this.elestado,
          ciudad: this.laciudad,
          nivel_educativo: this.nivelPrueba,
          reubicarse: this.reubicarse,
          idiomas: this.idiomas,
          especialidades: this.especialidades,
          habilidades: this.mishabilidades,
          sueldo_sup: this.sueldo_superior,
          sueldo_inf: this.sueldo_minimo,
        })
        .then((response) => {
          this.dialog = true;
          this.candidatos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
@media only screen and (min-width: 868px) {
  .mitarjeta {
    margin-top: -75px;
    height: 160px;
  }
  .micontenedor {
    margin-top: 210px;
  }
  #cel {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .micontenedor {
    margin-top: 260px;
  }
  .mitarjeta {
    margin-top: -65px;
    height: 220px;
  }
  #pc {
    display: none;
  }
}
@media (max-width: 950px) and (orientation: landscape) {
  #cel {
    display: none;
  }
}

.small-radio label {
  font-size: 22px;
  padding-left: 0px;
  margin-left: -4px;
}
</style>
