<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step @click="cambiar()" :complete="e1 > 1" step="1">
        Requisitos
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step @click="cambiar2()" :complete="e1 > 2" step="2">
        Requisitos
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step @click="cambiar3()" :complete="e1 > 3" step="3">
        Confirmación
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step @click="cambiar4()" step="4">
        Finalizado
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-stepper-content step="1">
          <v-card class="mb-12 " color="#e0e0e0">
            <div style="padding:20px">
              <h3 class="text-center">Vacante Operativa</h3>

              <p style="text-align:center;">
                Llena los requisitos de la vacante y da click en continuar
              </p>
              <br />
              <v-alert
                icon="mdi-alert"
                color="primary"
                style="position:fixed; z-index:999; margin-top:-200px;"
                v-if="errors.length"
              >
                <b>Por favor llena los siguientes campos:</b>
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
              <v-row>
                <v-col class="col-6 col-md-4">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="posicion"
                        outlined
                        label="Posición"
                        required
                        :rules="reglasPosicion"
                      ></v-text-field>
                    </template>
                    <span>Especifica el puesto que buscas...</span>
                  </v-tooltip>
                  <div></div>
                </v-col>

                <v-col class="col-6 col-md-4">
                  <v-text-field
                    v-model="whats"
                    outlined
                    label="WhatsApp"
                    v-mask="mask"
                  >
                  </v-text-field>
                </v-col>

                <v-col class="col-12 col-md-4">
                  <v-text-field
                    v-model="tel"
                    outlined
                    label="Teléfono"
                    v-mask="mask"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-textarea
                        v-bind="attrs"
                        v-on="on"
                        v-model="descripcion_detallada"
                        outlined
                        label="Requisitos y Ofrecimiento"
                        required
                      >
                      </v-textarea>
                    </template>
                    <span
                      >Se recomienda que agregues el texto en forma de lista,<br />
                      especificando las labores a realizar en la jornada <br />
                      insertando un (*) o (-) al inicio de cada punto
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-12 col-md-6">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-textarea
                        v-bind="attrs"
                        v-on="on"
                        v-model="carrera"
                        outlined
                        label="Horario Laboral y Ubicación"
                        required
                      ></v-textarea>
                    </template>
                    <span>Escribe los días y el horario de trabajo.</span>
                  </v-tooltip>
                  <br />
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-radio-group
                        mandatory
                        @change="verificaAnonima()"
                        v-model="checkbox"
                      >
                        <v-radio
                          label="Publicar Nombre de mi Empresa"
                        ></v-radio>
                        <v-radio
                          label="Publicación Anónima"
                          on-icon="mdi-incognito"
                          v-on="on"
                        ></v-radio>
                      </v-radio-group>
                    </template>
                    <span style="text-align:center;" class="text-center"
                      >Al seleccionar publicación anónima su publicación no
                      mostrara <br />
                      el nombre de la empresa sustituyendola por "Empresa lider"
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="6" class="pc">
  <v-container fluid>
       <h3 style="text-align:center;">
                    <strong
                      >Selecciona si deseas mostrar sueldo en tu vacante</strong
                    >
                  </h3>

    <v-radio-group
      v-model="radios"
      mandatory
    >
      <v-radio
        label="Si mostrar sueldo"
        value="0"
      ></v-radio>
      <v-radio
        label="No mostrar"
        value="1"
      ></v-radio>
     
                  
    </v-radio-group>
  </v-container>

                  <v-container>
                    <div class="text-center" v-if="this.radios==0">
                      <h3>
                        Rango de sueldo de la vacante (Semanal o Mensual)
                      </h3>
                    </div>
                    <br />
                    <v-row  v-if="this.radios==0">
                      <v-col cols="6">
                        <div>
                          <vuetify-money
                            v-model="sueldo_minimo"
                            label="Mínimo"
                            v-bind:options="options"
                            outlined
                          />
                        </div>
                      </v-col>
                      <v-col cols="6" class="text-center">
                        <div>
                          <vuetify-money
                            v-model="sueldo_superior"
                            label="Máximo"
                            v-bind:options="options"
                            outlined
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>

                  <h3  v-if="this.radios==0" style="text-align:center;">
                    <strong
                      >Selecciona si el rango de sueldo es Semanal o
                      Mensual</strong
                    >
                  </h3>
                  <v-radio-group
                  v-if="this.radios==0"
                    v-model="baseSalarial"
                    mandatory
                    style="margin-left:10px"
                    @change="verificarbase"
                    class="large"
                  >
                    <v-radio label="Semanal"></v-radio>
                    <v-radio label="Mensual"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="cel">
                  <v-container>
                    <v-row>
                      <h3 style="text-align:center">
                        Rango de sueldo de la vacante (Semanal o Mensual)
                      </h3>

                      <br />

                      <v-col cols="6">
                        <div>
                          <vuetify-money
                            v-model="sueldo_minimo"
                            label="Mínimo"
                            v-bind:options="options"
                            outlined
                          />
                        </div>
                      </v-col>
                      <v-col cols="6" class="text-center">
                        <div>
                          <vuetify-money
                            v-model="sueldo_superior"
                            label="Máximo"
                            v-bind:options="options"
                            outlined
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>

                  <h3 style="text-align:center;">
                    <strong
                      >Selecciona si el rango de sueldo es Semanal o
                      Mensual</strong
                    >
                  </h3>
                  <v-radio-group
                    v-model="baseSalarial"
                    mandatory
                    style="margin-left:10px"
                    @change="verificarbase"
                    class="large"
                  >
                    <v-radio label="Semanal"></v-radio>
                    <v-radio label="Mensual"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <div class="text-center">
            <v-btn :disabled="!valid" @click="validacion1()" color="primary">
              Continuar
            </v-btn>

            <v-btn @click="salirVacantes()" style="margin-left:5px;" dark>
              Anterior
            </v-btn>
          </div>
        </v-stepper-content>
      </v-form>
      <v-form ref="form" v-model="valida" lazy-validation>
        <v-stepper-content step="2">
          <v-card class="mb-12 " color="#e0e0e0">
            <div style="padding:20px">
              <h3 class="text-center">Requisitos</h3>

              <p style="text-align:center;">
                Llena los requisitos de la vacante y da click en continuar
              </p>
              <br />
              <v-alert
                color="primary"
                icon="mdi-alert"
                style="position:fixed; z-index:999; margin-top:-200px;"
                v-if="errores.length"
              >
                <b>Por favor llena los siguientes campos:</b>
                <ul>
                  <li v-for="error in errores" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
              <v-row>
                <v-col cols="6">
                  <Estado></Estado>
                </v-col>
                <v-col cols="6">
                  <Ciudad></Ciudad>
                </v-col>

                <v-flex md12 xs12>
                  <v-col>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-autocomplete
                          v-bind="attrs"
                          v-on="on"
                          return-object
                          outlined
                          v-model="eventOp"
                          label="Área"
                          clearable
                          :items="area"
                          item-text="area"
                          item-value="value"
                          autocomplete="nope"
                          color="primary"
                        ></v-autocomplete>
                      </template>
                      <span>Selecciona el área al que pertenece el puesto</span>
                    </v-tooltip>
                  </v-col>
                </v-flex>
              </v-row>
              <v-row>
                <!-- <v-col cols="8">
                <HabilidadesAdministrativo></HabilidadesAdministrativo>
              </v-col>
              <v-col cols="1">
                <v-btn @click="agregarHabilidad()" color="primary" style="color:black;">
               +
              </v-btn> 
              </v-col>-->

                <!-- <v-col cols="12">
                <h3>Habilidades</h3>
                <ul>
                  <li
                    v-for="habilidad of habilidades"
                    :key="habilidad.folio"
                  >
                    {{ habilidad.habilidad }}
                  </li>
                </ul>
              </v-col> -->
              </v-row>
            </div>
          </v-card>

          <div class="text-center">
            <v-btn @click="validacion2()" color="primary">
              Continuar
            </v-btn>

            <v-btn @click="e1 = 1" style="margin-left:5px;" dark>
              Anterior
            </v-btn>
          </div>
        </v-stepper-content>
      </v-form>

      <v-stepper-content step="3">
        <v-card class="mb-12 " color="#e0e0e0">
          <v-row>
            <v-col cols="12">
              <div style="padding-top:10px">
                <h2 class="text-center">Confirmación</h2>
                <v-divider></v-divider>
                <br />
                <p style="text-align:center;font-size:22px;">
                  La vacante esta apunto de ser publicada, verifica la
                  información antes de confirmar
                </p>
                <p style="text-align:center;color: red;font-size:22px;">
                  Una vez publicada esta vacante ya no podrás hacer correciones
                </p>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-card class="tarjeta" style="background-color:#EFEFEF">
                <v-container
                  fluid
                  style="background-color:#EFEFEF; "
                  class="contenedor"
                >
                  <v-row>
                    <v-col
                      cols="2"
                      class="pc text-center"
                      v-if="this.confirmaAnonima == false"
                    >
                      <div v-if="this.logos" class="imagen">
                        <v-img
                          class="miimagen"
                          :src="this.logos"
                          alt=""
                        ></v-img>
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      id="pc"
                      v-if="this.confirmaAnonima == false"
                    >
                      <div class="text-center">
                        <p class="vacantePosicion" style="color:black; ">
                          {{ this.posicion }}
                        </p>

                        <h3 v-if="checkbox == 0" style="color:black ">
                          {{ this.empresaNombre }}
                        </h3>

                        <h3 v-if="checkbox == 1" style="color:black ">
                          Empresa Lider
                        </h3>
                      </div>
                    </v-col>
                    <v-col
                      cols="4"
                      id="pc"
                      v-if="this.confirmaAnonima == false"
                    >
                      <p
                        class="vacanteRango"
                        style="background-color:white; padding:10px;text-align:center; border-radius:12px;"
                      >
                        <strong>
                          Rango de Sueldo
                          <span v-if="baseSalarial == 0">Semanal</span>
                          <span v-else-if="baseSalarial == 1"> Mensual</span
                          ><br />

                          ${{ this.sueldo_minimo }} - ${{
                            this.sueldo_superior
                          }}
                        </strong>
                      </p>
                    </v-col>
                    <v-col cols="8" id="pc" v-if="this.confirmaAnonima == true">
                      <div class="text-center">
                        <p class="vacantePosicion" style="color:black; ">
                          {{ this.posicion }}
                        </p>

                        <h3 v-if="checkbox == 0" style="color:black ">
                          {{ this.empresaNombre }}
                        </h3>

                        <h3 v-if="checkbox == 1" style="color:black ">
                          Empresa Lider
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="4" id="pc" v-if="this.confirmaAnonima == true">
                      <p
                        class="vacanteRango"
                        style="background-color:white; padding:10px;text-align:center; border-radius:12px;"
                      >
                        <strong>
                          Rango de Sueldo
                          <span v-if="baseSalarial == 0">Semanal</span>
                          <span v-else-if="baseSalarial == 1"> Mensual</span
                          ><br />

                          ${{ this.sueldo_minimo }} - ${{
                            this.sueldo_superior
                          }}
                        </strong>
                      </p>
                    </v-col>

                    <v-col cols="6" class="text-center cel">
                      <h5 style="color:black; text-align:center;">
                        {{ this.posicion }}
                      </h5>
                    </v-col>
                    <v-col cols="6" class="cel">
                      <p
                        class="vacanteRango"
                        style="background-color:white; padding:10px;text-align:center; border-radius:12px;"
                      >
                        <strong>
                          Rango de Sueldo
                          <span v-if="baseSalarial == 0">Semanal</span>
                          <span v-else-if="baseSalarial == 1"> Mensual</span>
                          <br />

                          ${{ this.sueldo_minimo }} - ${{
                            this.sueldo_superior
                          }}
                        </strong>
                      </p>
                    </v-col>

                    <v-col cols="4" class="cel" style="margin-top:-18px;">
                      <div v-if="this.logos" class="imagen">
                        <v-img
                          class="miimagen"
                          :src="this.logos"
                          alt=""
                        ></v-img>
                      </div>
                    </v-col>
                    <v-col cols="8" class="cel">
                      <div>
                        <p
                          style="color: black;
text-align: center;
"
                        >
                          {{ this.empresaNombre }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="cel">
                    <v-col cols="12" class="cel" style="margin-top:;">
                      <div class="text-center">
                        <div v-if="this.tel">
                          <v-icon style="color:black;margin-top:-10px;"
                            >mdi-phone</v-icon
                          >
                          <a
                            style="background-color:#A8B8CD;color:white;font-size:16px;margin-top:0px;border-radius:17px;"
                            target="_blank"
                            v-if="this.tel"
                            :href="'tel:' + this.tel"
                            >{{ this.tel }}</a
                          >
                        </div>

                        <div v-if="this.whats">
                          <v-icon>mdi-whatsapp</v-icon>
                          <a
                            v-if="this.whats"
                            style="background-color:#8CDB6B;color:white;font-size:16px;border-radius:17px;"
                            target="_blank"
                            :href="
                              'https://api.whatsapp.com/send?phone=52' +
                                this.whats +
                                '&text=¡Hola!%20vi%20la%20vacante%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20mas%20información.'
                            "
                            >{{ this.whats }}</a
                          >
                        </div>

                        <div v-if="this.checkbox == 0">
                          <v-icon>mdi-email</v-icon>
                          <a
                            v-if="this.correo"
                            style="background-color:#EFCC63;color:white;font-size:16px; margin-top:0px; border-radius:10px;"
                            target="_blank"
                            :href="
                              'mailto:' +
                                this.correo +
                                '?Subject=CV%20enviado%20desde%20VACANTA'
                            "
                            >{{ this.correo }}</a
                          >
                        </div>
                      </div>
                    </v-col>
                    
                  </v-row>
                  <v-row id="pc">
                      <!-- se agrega este renglon -->
                      <v-col cols="4">
                        <div class="text-center" v-if="this.tel">
                          <a
                            style="background-color:#A8B8CD;color:white;font-size:22px;margin-top:0px;border-radius:17px;"
                            target="_blank"
                            v-if="this.tel"
                            :href="'tel:' + this.tel"
                            ><v-icon style="padding:12px;margin-left:-7px;">
                              mdi-phone
                            </v-icon>
                            {{ this.tel }}
                          </a>
                        </div></v-col
                      >
                      <v-col cols="4">
                        <div class="text-center" v-if="this.whats">
                          <a
                            v-if="this.whats"
                            style="background-color:#8CDB6B;color:white;font-size:22px;border-radius:17px;"
                            target="_blank"
                            :href="
                              'https://api.whatsapp.com/send/?phone=52' +
                                this.whats +
                                '&text=¡Hola!%20vi%20la%20vacante%20' +
                                this.posicion +
                                '%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20más%20información'
                            "
                          >
                            <v-icon>mdi-whatsapp</v-icon>

                            {{ this.whats }}
                          </a>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="text-center">
                          <a
                            v-if="this.correo"
                            style="background-color:#EFCC63;color:white;font-size:22px; margin-top:0px; border-radius:10px;"
                            target="_blank"
                            :href="
                              'mailto:' +
                                this.correo +
                                '?Subject=CV%20enviado%20desde%20VACANTA' +
                                '&body=Escribe%20aqui%20el%20nombre%20del%20puesto%20para%20el%20que%20estas%20enviando%20tu%20Cv'
                            "
                            color=""
                          >
                            <v-icon style="padding:12px;margin-left:-7px;">
                              mdi-email
                            </v-icon>
                            {{ this.correo }}
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                </v-container>
                <v-container>
                  <v-row>
                    <v-flex xs12 sm8 md5 class="columna3">
                      <br />
                      <h2 class="text-center">
                        Descripción y Requisitos
                      </h2>
                      <br />

                      <v-textarea
                        light
                          background-color="white"
                        outlined
                        v-model="descripcion_detallada"
                        name="tbox"
                        cols="75"
                        rows="6"
                      ></v-textarea>
                    </v-flex>
                    <v-flex xs12 sm8 md5 class="columna4">
                      <br />
                      <h2 class="text-center">
                        Horario y Ubicación
                      </h2>
                      <br />
                      <div class="text-center">
                        <v-textarea
                           light
                          background-color="white"
                          outlined
                          v-model="carrera"
                          
                          name="tbox"
                          cols="75"
                          rows="6"
                        ></v-textarea>
                      </div>
                    </v-flex>

                    <v-row>
                      <v-col md="4" class="text-center">
                        <br />
                        <p>
                          <strong> Lugar</strong>
                        </p>
                        <p style=" border-radius:6px; ">
                          <strong
                            >{{ this.elestado }} -
                            {{ this.laciudad }}
                          </strong>
                        </p>
                      </v-col>
                      <v-col md="4" xs="6" class="text-center">
                        <br />
                        <p><strong> Fecha de publicación</strong></p>

                        <p style=" border-radius:6px;">
                          <strong>
                            {{ moment().format("LL") }}
                          </strong>
                        </p>
                      </v-col>
                      <v-col md="4" xs="12" class="text-center">
                        <br />
                        <p><strong> Área </strong></p>
                        <p style=" border-radius:6px;">
                          <strong>
                            {{ this.eventOp.area }}
                          </strong>
                        </p>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <div class="text-center">
          <div>
            <v-btn v-if="this.gratis==0" dark @click="comprar()">
              Comprar 
            </v-btn>
            <v-btn v-else color="primary" @click="prueba()">
              Confirmar {{this.gratis}}
            </v-btn>
            <!-- <v-btn v-else style="background-color:#f2441d;" @click="comprar()">
              Comprar 
            </v-btn> -->
            <v-btn @click="e1 = 2" style="margin-left:5px;" dark>
              Anterior
            </v-btn>
          </div>
        </div>
      </v-stepper-content>
      <v-stepper-content step="4">
        <v-card class="mb-12 " color="#e0e0e0">
          <v-row>
            <v-col cols="12">
              <div style="padding:20px">
                <h3 class="text-center">Finalizado</h3>

                <p
                  style="text-align:center;color:rgb(26 115 232); font-size:22px;"
                >
                  <strong>
                    ¡La vacante se publico correctamente!
                  </strong>
                </p>

                <div
                  class="text-center"
                  style="background-color:#2afed6;height:250px; width:100%;"
                >
                  <p
                    style="text-align:center;color:black; font-size:22px;padding:14px;"
                  >
                    <strong>
                      ¡Haz que tu próxima vacante sea más llamativa y dure más
                      tiempo!
                    </strong>
                  </p>
                  <div
                    class="text-center"
                    style="background-color:black;height:120px; width:220px; display:block;margin:auto; "
                  >
                    <p
                      style="margin-top:10px;text-align:center;color:white; font-size:22px;;"
                    >
                      <strong>
                        Vacante Elite
                      </strong>
                    </p>
                    <v-btn
                      @click="comprar()"
                      style="margin-top:10px; color:black;"
                      color="secondary"
                    >
                      Comprar
                    </v-btn>
                  </div>
                </div>
                <br />
                <br />
                <p style="text-align:center;color: black;">
                  <strong>
                    Puedes desactivar la vacante cuando lo desees en “Mis
                    Vacantes Operativas” y ya no se mostrara mas en el listado
                    de vacantes.
                  </strong>
                </p>
              </div>
              <div class="text-center">
                <v-btn color="primary" @click="salir()">
                  Salir
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import Estado from "../Buscador/Estado.vue";
import Ciudad from "../Buscador/Ciudad.vue";
import Giro from "../Buscador/Giro.vue";
import Sector from "../Buscador/Sector.vue";
import HabilidadesAdministrativo from "../Buscador/HabilidadesAdministrativo.vue";

import nivelEducativo from "../Buscador/nivelEducativo.vue";
var moment = require("moment");
import axios from "axios";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      gratis: null,
       radios: null,
      errors: [],
      mask: "##########",
      errores: [],
      colonia: null,
      whats: null,
      tel: null,
      erroros: [],
      valida: true,
      valid: true,
      valorSencilla: null,
      valorPremium: null,
      isLoading: false,
      items: [],
      model: null,
      search: null,
      tab: null,
      carrera: "",
      cp: null,
      previousLength: 0,
      value: "1234567.89",
      options: {
        locale: "ja-JP",
        prefix: "$",
        suffix: "",
        length: 10,
        precision: 2,
      },
      searchResult: null,
      searchString: "",
      selected: "",
      menu: false,
      modal: false,
      menu2: false,
      moment: moment,
      correo: null,
      e1: 1,
      eventOp: "",
      posicion: "",
      descripcion_corta: "",
      descripcion_detallada: "",
      fecha_inicio: "",
      sueldo_minimo: null,
      sueldo_superior: null,
      telefono: "",
      contratacion: "",
      confirmacontratacion: "",
      confirmabase: 0,
      confirmajornada: "",
      confirmaarea: "",
      habilidades: [],
      jornada: "",
      baseSalarial: null,
      area: "",
      checkbox: null,
      sector: "",
      confirmapublicacion: null,
      confirmaAnonima: null,
      giro: "",
      nivel: "",
      sueldo: 10,
      edad_minima: null,
      edad_maxima: null,
      años_experiencia: "",
      tipoVacante: "",
      creditos: "",
      empresaNombre: "",
      logos: null,

      hola: 0,
      area: [],
      token:
        "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",

      sueldo_mayor: [
        // (y) => y > this.sueldo_minimo || "Tiene que ser mayor que el minimo",
      ],
      reglasPosicion: [(v) => v.length <= 45 || "Máximo 45 Caracteres"],
    };
  },
  watch: {

     id: function() {

          axios
          .post(
            "/vacantes/vacantegratis",
            {
              empresa: this.$store.state.id.empresa,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            console.log(response.data)
            this.gratis = response.data[0].vacantes;

          })

          .catch((error) => {
            this.gratis = 0;
          });

      },
          sueldo_minimo: function(value) {
      console.log("El nombre pasó de ser %s", this.sueldo_minimo);

      this.hola = this.sueldo_minimo;
    },
    

  },
  mounted() {


    axios
          .post(
            "/vacantes/vacantegratis",
            {
              empresa: this.$store.state.id.empresa,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            console.log(response.data)
            this.gratis = response.data[0].vacantes;

          })

          .catch((error) => {
            this.gratis = 0;
          });
     

    this.$store.dispatch("autoLogin");

    axios
      .get("/catalogos/areas_operativas", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.area = response.data;
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/catalogos/confvacante/1", {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      })
      .then((response) => {
        this.valorSencilla = response.data[0].creditos;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .post(
        "/empresas/perfilempresa",
        {
          empresa: this.$store.state.id.empresa,
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        }
      )
      .then((response) => {
        console.log(response.data)
        this.empresaNombre = response.data[0].nom_comercial;
        this.logos = response.data[0].logo;
        this.correo = response.data[0].correo;
      });
    axios
      .get("/catalogos/confvacante/2", {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      })
      .then((response) => {
        this.valorPremium = response.data[0].creditos;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/creditos/creditosempresa/" + this.$store.state.id.empresa, {
        headers: {
          Authorization: "Bearer " + this.$store.state.token,
        },
      })
      .then((response) => {
        this.creditos = response.data.creditos;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get("/catalogos/especialidades", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.items = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  computed: {
        id() {
      return this.$store.state.id;
    },
    ...mapState([
      "elestado",
      "laciudad",
      "elPais",
      "id",
      "elidioma",
      "lainstitucion",
      "folioinstitucion",
      "nuevahabilidad",
      "nuevogiro",
      "nuevosector",
      "lahabilidad",
      "nivelPrueba",
      "nuevaespecialidad",
    ]),
  },
  components: {
    Estado,
    Ciudad,
    Sector,
    Giro,
    nivelEducativo,
    HabilidadesAdministrativo,
  },
  methods: {
    comprar() {
      this.$router.push({ name: "ComprarCreditos" });
    },
    validacion2() {
      this.errores = [];
      if (this.elestado && this.laciudad && this.eventOp) {
        this.e1 = 3;
      }

      if (!this.elestado) this.errores.push("Estado.");
      if (!this.laciudad) this.errores.push("Ciudad.");
      if (!this.eventOp) this.errores.push("Área.");
    },
    validacion1() {
      this.errors = [];

      if (
        this.tel &&
        this.posicion &&
        this.descripcion_detallada 

      ) {
        this.e1 = 2;
      }

      if (!this.posicion) this.errors.push("Posición.");
      if (!this.descripcion_detallada)
        this.errors.push("Requisitos y Ofrecimiento.");

      if (!this.tel) this.errors.push("Es necesario un teléfono de contacto.");

    },
    verifica3() {
      if (this.confirmapublicacion) {
        this.e1 = 4;
      } else {
        this.erroros = [];
        if (!this.confirmapublicacion)
          this.erroros.push("No has especificado el tipo de publicación");
      }
    },
    pruebas(e) {
      console.log(e);
    },
    agregarHabilidad() {
      this.habilidades.push({
        habilidad: this.lahabilidad,
      });
    },
    salirVacantes() {
      this.$router.push({ name: "EmpresasVacantes" });
    },
    verificarcontratacion() {
      if (this.contratacion) {
        this.confirmacontratacion = "Temporal";
      } else {
        this.confirmacontratacion = "Indeterminado";
      }
    },
    verificarbase() {
      if (this.baseSalarial == 0) {
        this.confirmabase = 0;
      } else {
        this.confirmabase = 1;
      }
    },
    verificarjornada() {
      if (this.jornada == 0) {
        this.confirmajornada = "Completa";
      }
      if (this.jornada == 1) {
        this.confirmajornada = "Medio Tiempo";
      }
    },

    verificatipoPublicacion() {
      if (this.tipoVacante == 0) {
        this.confirmapublicacion = 1;
      }
      if (this.tipoVacante == 1) {
        this.confirmapublicacion = 2;
      }
    },
    verificaAnonima() {
      if (this.checkbox == 0) {
        this.confirmaAnonima = false;
      } else if (this.checkbox == 1) {
        this.confirmaAnonima = true;
      }
    },
    ...mapMutations(["setEspecialidad", "setsubEspecialidad"]),
    logueado() {
      return this.$store.state.token;
    },
    cambiar() {
      this.e1 = 1;
    },
    cambiar2() {
      this.e1 = 2;
    },

    cambiar3() {
      this.e1 = 3;
    },
    cambiar4() {
      this.e1 = 4;
    },
    cambiar5() {
      this.e1 = 5;
    },

    prueba() {
      console.log(
        "empresa:",
        this.$store.state.id.empresa,
        "usuario: ",
        this.$store.state.id.id,
        "origen: ",
        1,
        "posicion:",
        this.posicion,
        "dcorta:",
        this.carrera,
        "desc: ",
        this.descripcion_detallada,
        "sinf: ",
        this.sueldo_minimo,
        "ssup: ",
        this.sueldo_superior,
        "edo: ",
        this.elestado,
        "cd: ",
        this.laciudad,
        "cp: ",
        3111,
        "tcon:",
        this.confirmacontratacion,
        "tjor:",
        this.confirmajornada,
        "tare: ",
        this.confirmaarea,
        "tpub: ",
        1,
        "sube: ",
        1,
        "einf: ",
        this.edad_minima,
        "esup:",
        this.edad_maxima,
        "exp:",
        this.años_experiencia,
        "nedu: ",
        this.nivelPrueba,
        "anonimo: ",
        this.confirmaAnonima
      );

      axios
        .post(
          "/vacantes/creavacanteoperativo",
          {
            empresa: this.$store.state.id.empresa,
            usuario: this.$store.state.id.id,
            origen: 1,
            posicion: this.posicion,
            dcorta: this.carrera,
            desc: this.descripcion_detallada,
            sinf: this.sueldo_minimo,
            ssup: this.sueldo_superior,
            edo: this.elestado,
            cd: this.laciudad,
            tcon: this.confirmacontratacion,
            tjor: this.confirmajornada,
            tare: this.confirmaarea,
            tipo: 1,
            einf: 0,
            esup: 0,
            exp: 0,
            nedu: this.confirmaarea,
            anonimo: this.confirmaAnonima,
            ubicacion: "",
            trabajo: this.carrera,
            whatsapp: this.whats,
            contacto: this.tel,
            base: this.confirmabase,
            area: this.eventOp.folio,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.e1 = 4;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salir() {
      this.$router.push({ name: "VacantesOperativas" });
    },
  },
};
</script>

<style>
@media (max-width: 950px) and (orientation: landscape) {
  .botonCreditos {
    margin-bottom: 15px;
  }
  .botonComprar {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 868px) {
  /*esta es pc*/
  .mitarjeta {
    margin-top: -75px;
    height: 160px;
  }
  .vacantePosicion {
    font-size: 28px !important;
  }
  .columna4 {
    margin-left: 70px;
  }
  .columna3 {
    margin-left: 55px;
  }

  .micontenedor {
    margin-top: 210px;
  }
  #cel {
    display: none;
  }
  .miimagen {
    border-radius: 40px;
  }
  .imagen {
    border: 4px solid white;
    width: 120px;
    height: 120px;
    border-radius: 40px;
    display: flex;
    margin-left: 20px;
    background-color: #ddd;
  }
}

@media only screen and (max-width: 768px) {
  .pequeño {
    font-size: 10px !important;
  }
  .vacantePosicion {
    font-size: 18px !important;
  }
  .micontenedor {
    margin-top: 260px;
  }
  .mitarjeta {
    margin-top: -65px;
    height: 220px;
  }
  .vacanteRango {
    font-size: 13px;
  }
  .large .v-radio {
    padding: 10px;
  }

  .large i {
    font-size: 48px;
  }

  .large label {
    padding-left: 24px;
  }

  .large [class*="__ripple"] {
    left: 0;
  }
  #pc {
    display: none;
  }
}
</style>
