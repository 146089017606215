<template>
  <div>
    <v-autocomplete
      outlined
      v-model="mievento"
      :click="setNivel(mievento)"
      label="Nivel Educativo Requerido"
      clearable
      :items="niveles"
      item-text="nivel"
      item-value="nivel"
      autocomplete="nope"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";
export default {
  data: () => ({
    especialidadSeleccionada: {}, //<-- el seleccionado estará aquí
    niveles: [], // <-- La lista de especialidades
    mievento: "",
        token: "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c" 

  }),
  computed: {
    ...mapState(["nivel"]),
  },
 
      mounted() {
    
            axios
            .get("/catalogos/niveleducativo", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
            .then((response) => {
              this.niveles = response.data;
            })
            .catch((error) => {
              console.log(error);
            });
          
  },
  methods: {
    ...mapMutations(["setNivel"]),
  },
};
</script>

<style></style>
