
<template>
  <div>
    <v-autocomplete
      outlined
      v-model="evento"
      :click="setState(evento)"
      label="Estado"
      clearable
      :items="estados"
      item-text="estado"
      item-value="value"
      autocomplete="nope"
      color="primary"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";
export default {
  data: () => ({
    estados: [], // <-- La lista de especialidades
    evento: "",  
        token: "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c" 

  }),
  computed: {
    ...mapState(["laciudad"]),
  },
     mounted(){
       axios
      .get("/catalogos/estados", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.estados = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
     
     },
  methods: {
    ...mapMutations(["setState"]),
  },
};
</script>

<style></style>
