<template>
<div>
  <v-container>
      <div class="text-center">
         <router-link style="text-decoration: none;" :to="{ name: 'NuevaLista' }">
         <v-btn
  color="primary" style="color:black"
  >Nueva Lista</v-btn>
         </router-link>
      </div>

  </v-container>
  <br>
 <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        outlined
        hide-details
        color="primary"
      ></v-text-field>
 <br>
   
<v-data-table
    :headers="headers"
    :items="item"
    :items-per-page="5"
    filterable 
     :search="search"
    dark
    @click:row="handleClick"
  >

  <template v-slot:item.fecha2="{ item }">
      <p>{{moment(item.fecha2).format("LL")  }}</p>
     
      
    </template>
    <template v-slot:item.creditos="{ item }">
      <p> 
     {{
                              item.creditos.toLocaleString("en-US"                        
                              
)
                            }}
      </p>
    </template>
     <template v-slot:item.abrible="{ item }">
      <v-icon
        
        class="mr-2"
        style="background-color:#2afed6; border-radius:25px; color: black;"
        v-if="item.abrible"
      >
        mdi-check
      </v-icon>
      <v-icon
        
        class="mr-2"
        style="background-color:red; border-radius:25px; color: black;"
        v-else
      >
        mdi-close
      </v-icon>
     
      
    </template>

 

  </v-data-table>

  <v-dialog v-model="midialog" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey darken-1"
                      dark
                      style="display:none"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Open Dialog
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                     
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    
                      <v-data-table
                        :headers="titulos"
                        :items="candidatos"
                        :items-per-page="5"
                        filterable
                        :search="search"
                      >
                         <template style="margin-top:13px;" v-slot:item.nacimiento="{ item }">
                          <p>
                            {{
                              moment().diff(
                                item.nacimiento,
                                "years",
                                false
                              )
                            }}
                            años
                          </p>
                        </template>
                        
                        <template style="margin-top:13px;" v-slot:item.celular="{ item }">
                          <input v-mask="mask" v-model="item.celular" />
                        </template>
                        <template style="margin-top:13px;" v-slot:item.sueldo="{ item }">
                          <p>
                            {{
                              item.sueldo.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }}
                          </p>
                        </template>
                        <template style="margin-top:13px;" v-slot:item.sueldo_sup="{ item }">
                          <p>
                            {{
                              item.sueldo_sup.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }}
                          </p>
                        </template> 
                        
                      </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="green darken-1"
                        text
                        @click="midialog = false"
                      >
                        Salir
                      </v-btn>
                    </v-card-actions>
                  </v-card>
  </v-dialog>
   <v-dialog v-model="dialog" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      style="display:none"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Open Dialog
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                     
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                      <v-data-table
                        :headers="headers2"
                        :items="candidatos"
                        :items-per-page="5"
                        filterable
                        :search="search"
                        @click:row="TraerCandidato"
                      >
                        <template v-slot:item.nacimiento="{ item }">
                          <p>
                            {{ moment().diff(item.nacimiento, "years", false) }}
                            años
                          </p>
                        </template>
                        <template v-slot:item.celular="{ item }">
                          <input v-mask="mask" v-model="item.celular" />
                        </template>
                        <template style="margin-top:13px;" v-slot:item.op_salario="{ item }">
                          <p>
                            {{
                              item.op_salario.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }}
                          </p>
                        </template>
                        <template style="margin-top:13px;" v-slot:item.op_salario_hi="{ item }">
                          <p>
                            {{
                              item.op_salario_hi.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }}
                          </p>
                        </template> 
                        
                      </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="green darken-1"
                        text
                        @click="dialog = false"
                      >
                        Salir
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                 <v-dialog v-model="dialogo" width="900px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      style="color:black; display:none;"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Ver Datos
                      <v-icon>mdi-account-circle</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 dark">
                      Datos del Candidato 
                    </v-card-title>
                    <v-container>
                      <v-col> </v-col>
                      <div class="text-center">
                        <v-row>
                          <v-col cols="6">
                            <div class="text-center">
                              <v-img
                                class="miimagen"
                                :src="candidato.foto"
                                alt=""
                              ></v-img>
                              <br>
                               <h3 >
                                {{ candidato.nombre}} {{ candidato.apellido }}
                              </h3>
                              <br>
                              <p>
                              Edad:
                              {{
                                moment().diff(
                                  candidato.nacimiento,
                                  "years",
                                  false
                                )
                              }}
                              años
                            </p>
                            <p>
                              <strong>Vive en:</strong> <br>
                              {{ candidato.estado }},
                              {{ candidato.ciudad }}
                            </p>
                            <br>
                           
                            </div>
                          </v-col>
                          <v-col cols="6">
                           
                             
                              
                              <a
                                target="_blank"
                                style="color:#2afed6"
                                :href="candidato.curriculum"
                                >Curriculum Vitae</a
                              >
                              
                               <p
                               style="margin-top:15px;"
                              class="trabajando"
                              v-if="candidato.activo == true"
                            >
                            <strong>Estatus Laboral</strong> <br>
                             Trabajando
                            </p>
                            <p class="trabajando" v-else>
                             Sin trabajo
                            </p>
                          
                            
                            
                            <br>
                            <p><strong>Salario Esperado:</strong></p>
                            <p v-if="candidato.OP_salario && candidato.op_salario_hi" >
                                {{
                              candidato.OP_salario.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }}
                               -  {{
                              candidato.op_salario_hi.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }} </p>
                            
                            <br>

                            <p><strong>Telefono</strong></p>
                            <p>{{candidato.celular}}</p>
                            <br>
                            <p><strong>Correo</strong></p>
                            <p>{{candidato.correo}}</p>
                            <br>
                          </v-col>
                          
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-card
                              color="dargray"
                              class="tarjeta-trabajos"
                            >
                              <h2>Trabajos</h2>
                              <br />
                              <div
                                v-for="value in trabajos"
                                :key="value.empresa"
                              >
                                <h4>
                                  {{ value.empresa.toUpperCase() }} -
                                  {{ value.puesto.toUpperCase() }}
                                </h4>
                                <br>
                                <textarea style="font-size:13px ;color:black;padding:5px;margin-bottom:10px;"   disabled wrap="soft" v-model="value.descripcion" name="" id="" cols="25" rows="2"></textarea>   


                                <h5>
                                  Fecha: {{ value.inicio }} -
                                  {{ value.final }}
                                </h5>
                                <br />
                                <div class="text-center">
                                <h5  style="background-color:#2afed6; width:auto; border-radius:5px; display: block;
margin-left: auto;
margin-right: auto;" v-if="value.actual">Trabajo Actual</h5>

                                </div>
                                <br>
                              </div>
                              <h2 class="text-center">Cursos</h2>
                              <br>
                              <div
                                v-for="value in cursos"
                                :key="value.candidato"
                              >
                                <h4>{{ value.curso }} <br /></h4>
                                <h5>Descripción: {{ value.descripcion }}</h5>
                              </div>
                              <br>
                              <h2 class="text-center">Habilidades</h2>
                              <br />
                               <div v-for="value in habilidades" :key="value.folio">
                  <h4>{{ value.habilidad }}</h4>
                  <br />
                </div>
                            </v-card>
                          </v-col>
                          <v-col>
                            <v-card
                              color="darkgray"
                              class="tarjeta-postulaciones"
                            >
                              <h2 class="text-center">Estudios</h2>
                              <br />
                              
                              <div v-for="value in educacion" :key="value.nivel">
                                <h4>
                                <strong style="color:#2afed6;">Nivel Educativo: </strong>
                                {{value.nivel}}<br>
                                  <strong style="color:#2afed6;">Titulo: </strong>{{ value.titulo }} <br><strong style="color:#2afed6;">Institucion: </strong>
                                  {{ value.institucion }}  
                                  <br />
                                <strong style="color:#2afed6;"> Fecha:</strong> {{ value.ano_inicio }} - {{ value.ano_final }}
                                </h4>
                                <br>
                                <h5  style="background-color:#2afed6; width:auto; border-radius:5px; display: block;
margin-left: auto;
margin-right: auto;" v-if="value.actual">Estudio Actual</h5>
                                <br />
                                <br>
                              </div>
                              
                             <h2 class="text-center">Idiomas</h2>
                             
                              <div v-for="value in idiomas" :key="value.idioma">
                  <br />
                  <h4> <strong style="color:#2afed6;"> {{ value.idioma }}</strong> - {{ value.nivel }}</h4>
                </div>
                <br>
                               <h2 class="text-center">Áreas de Experiencia</h2>
                                              <br />
                                              <div v-for="value in especialidades" :key="value.sub">
                                                <h4>
                                                  <strong style="color:#2afed6;">
                                                  Area:</strong>
                                                    {{ value.especialidad }}
                                                  <br>
                                                  
                                                  <strong style="color:#2afed6">
                                                  Subárea:</strong>
                                                  {{ value.sub }}
                                                </h4>
                                                <br />
                                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row justify="center"> </v-row>
                      </div>
                    </v-container>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-container>
                        <div class="text-center">
                          <v-btn
                          color="primary"
                            style="margin-left:20px; color:black;"
                            @click="dialogo = false"
                           
                          >
                            Cerrar
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </v-container>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
</div>
   
</template>

<script>
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
var moment = require("moment");
moment.locale("es-mx");
import axios from 'axios'
import { mapState,mapMutations } from "vuex";
  export default {
    data () {
      return {
         moment: moment,
          candidatos: [],
          candidato: [],
           educacion: [],
      trabajos: [],
      cursos: [],
      idiomas: [],
      especialidades: null,
      habilidades: [],
      dialogo: false,
          mask: "(###) ###-##-##",
          midialog: false,
               dialog: false,
         search: '',
         paginacion: 50,
         titulos: [
        { text: "Nombre", value: "nombre" },
        { text: "Edad", value: "nacimiento" },
        { text: "Estado", value: "estado" },
        { text: "Ciudad", value: "ciudad" },
        { text: "Celular", value: "celular" },
        { text: "$ Minimo (Semanal)", value: "sueldo" },
        { text: "$ Máximo (Semanal)", value: "sueldo_sup" },

      ],
       headers2: [
        { text: "Nombre", value: "nombre" },
        { text: "Edad", value: "nacimiento" },
        { text: "Estado", value: "estado" },
        { text: "Ciudad", value: "ciudad" },
        { text: "Celular", value: "celular" },
        { text: "Correo", value: "correo" },
        { text: "$ Minimo (Mensual)", value: "op_salario" },
        { text: "$ Máximo (Mensual)", value: "op_salario_hi" },
      ],
        headers: [
          { text: '#', value: 'folio' },
          { text: 'CREADA', value: 'fecha2' , dataType: "Date" },
          { text: 'DESCRIPCIÓN', value: 'descripcion' },
          { text: 'CRÉDITOS', value: 'creditos' ,type:"number"},
          { text: 'CANDIDATOS', value: 'candidatos' },
          { text: 'ACTIVA', value: 'abrible' },
        ],
        item: [ ],
      }
    },
     computed: {
    ...mapState(["id"]),
  },
    mounted(){
      this.$store.dispatch("autoLogin")
                axios.post('/listas/listas',{
                    empresa: this.$store.state.id.empresa,
                  
                })
            .then(response=>{
                this.item = response.data
                     })
            .catch(error =>{
                console.log(error);
            
        })
        
    },
    methods:{
      ...mapMutations(["setVacante"]),
      TraerCandidato(row) {
      axios
        .get("/candidatos/" + row.folio)
        .then((response) => {
          this.candidato = response.data;
          this.idiomas = response.data.idiomas;
          this.educacion = response.data.educacion;
          this.trabajos = response.data.exp_profesional;
          this.especialidades = response.data.especialidades;
          this.cursos = response.data.cursos;
          this.habilidades = response.data.habilidades;
          this.dialogo = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
     
      handleClick(row){
        
        if(row.abrible){

         if(row.tipo==1){
           console.log("Admin" + row.folio)

           axios
        .post("/listas/candidatosenlista", {
          folio: row.folio
        })
        .then((response) => {
           this.dialog = true;
           this.candidatos = response.data;
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
           
           
         }else {
           console.log("Operador" + row.folio)
           axios
          .post("/listas/candidatosenlistaOp", {
          folio: row.folio
        })
        .then((response) => {
           this.midialog = true;
           this.candidatos = response.data;
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
          
         }  
        }
        

      },

    }
    
  }
</script>

<style>
.activa{
  color:aqua;
}
.small-radio label {
  font-size: 22px;
  padding-left: 0px;
  margin-left: -4px;
}

</style>