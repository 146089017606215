<template>
  <v-container>
      <h1 style="text-align:center">¿Quienes Somos?</h1>
      <br>
      <br>
      <br>
      <v-row>
          <v-col cols="4">
                <v-img 
                src="../assets/1.jpg"
                width="300px"
                ></v-img>
                  <v-img 
                src="../assets/3.png"
                width="300px"
                ></v-img>
                 
          </v-col>
          <v-col cols="4" style="margin-top:120px">
              <h4 style="text-align:center">Contacto</h4>
              <br>
              <p>614-4-10-20-30 <br>
              <br>
              ventas@vacanta.mx
              </p>
              <a href="">Misión y Visión</a>
              <br>
              <br>
              <a href="">Preguntas Frecuentes</a>
              <br>
              <br>
              <a href="">Publicidad</a>
              <br>
              <br>
                 <router-link :to="{name: 'Profesionistas'}">
                    <a href="">Regresar</a>
                    </router-link>
          </v-col>
          <v-col cols="4">
               <v-img 
                src="../assets/5.png"
                width="250px"
                ></v-img>
                 <v-img 
                src="../assets/2.jpg"
                width="250px"
                ></v-img>
          </v-col>
          
      </v-row>
  
       


          
    
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>