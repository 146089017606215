<template>
  <v-container fluid>
    <v-combobox
      v-model="model"
      :items="items"
      :search-input.sync="search"
      hide-selected
      outlined
      label="Nivel"
      persistent-hint
      small-chips
      @click="setNivel(model)"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </v-container>
</template>
<script>
import { mapMutations, mapState } from "vuex";
  export default {
    data: () => ({
      items: ['Basico', 'Intermedio', 'Avanzado'],
      search: null,
    }),
     computed: {
    ...mapState(["nivelPrueba"]),
  },
    methods: {
    ...mapMutations(["setNivel"]),
  },

    watch: {
      model (val) {
        if (val.length > 5) {
          this.$nextTick(() => this.model.pop())
        }
      },
    },
  }
</script>