<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-card color="grey darken-4" dark class="mitarjeta" fluid>
        <v-toolbar>
          <v-container fluid class="micontenedor">
            <v-row>
              <v-flex class="subir" xs12 sm6 md4 lg4>
                <v-col>
                  <v-select
                    outlined
                    v-model="eventoArea"
                    :click="buscar()"
                    label="Área (Opcional)"
                    clearable
                    :items="area"
                    item-text="area"
                    item-value="value"
                    autocomplete="nope"
                    color="primary"
                    return-object
                  ></v-select>
                </v-col>
              </v-flex>
              <v-flex class="subir" xs6 sm6 md4 lg4 >
                <v-col>
                   <v-autocomplete
                    outlined
                    v-model="evento"
                    :click="buscar()"
                    label="Estado (Opcional)"
                    clearable
                    :items="estados"
                    item-text="estado"
                    item-value="value"
                    autocomplete="nope"
                    color="primary"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-flex>
                <v-flex class="subir" xs6 sm6 md4 lg4>
                <v-col>
                    <v-autocomplete
                    outlined
                    v-model="mievent"
                    label="Ciudad "
                    :click="buscar()"
                    clearable
                    :items="ciudades"
                    item-text="municipio"
                    item-value="ciudad"
                    autocomplete="nope"
                    color="primary"
                  ></v-autocomplete>
                </v-col>
              </v-flex>
            </v-row>
          </v-container>
        </v-toolbar>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
import Especialidad from "../components/Buscador/Especialidad.vue";
import CiudadBuscador from "../components/Buscador/CiudadBuscador.vue";
import Empresa from "../components/Buscador/Empresa.vue";
import rangoSalarial from "../components/Buscador/rangoSalarial.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "Buscador",
  data: () => ({
    area: [],
    dialog: false,
    radioGroup: 1,
    items: [],
    model: "",
    estados: [],
    ciudades: [],
    evento: "",
    eventoArea: "",
    busqueda: "",
    mievent: null,
    objetos: [],
    values: ["Sistemas", "Contabilidad"],
    value: null,
    salario: null,
    jornada: "Cualquiera",
    token:
      "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
  }),
  components: {
    CiudadBuscador,
    Empresa,
    rangoSalarial,
    Especialidad,
  },
  computed: {
    ...mapState([
      "nuevaespecialidad",
      "elestado",
      "ciudadBuscador",
      "puesto",
      "rangoInf",
      "rangoSup",
      "Urlheader",
      "VacanteFolio",
      "miempresa",
      "puesto",
      "vacantes",
      "nuevaPagina2",
      "id",
      "Empresa",
    ]),
    logueado() {
      return this.$store.state.token;
    },
  },

  methods: {
   /*  buscar() {
      this.setPaginacion2(1);
      console.log(this.evento, this.eventoArea);
      if (this.evento) {
        this.setnuevaEmp2(this.evento);
      }
      if (this.eventoArea) {
        this.setnuevaArea2(this.eventoArea);
      }
      axios
        .post(
          "/vacantes/buscavacanteOperativa",
          {
            empresa: this.evento.folio,
            area: this.eventoArea.folio,
            cd: null,
            sueldo: null,
            paginacion: 20,
            pagina: 1,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          //  this.posts = response.data
          this.setVacantesOperativas(response.data);

          //this.nuevapagina2 = 1;
        })
        .catch((error) => {});
    }, */
      buscar() {
         if (this.eventoArea) {

      }else {
                this.setnuevaEmp2(null);
      }
      if (this.evento) {
        
      }else{
        this.setState(null);
      }
      if (this.mievent) {
       
      }else {
         this.setCiudad(null)
      }
       
      if (this.evento && this.eventoArea && this.mievent) {
        this.setPaginacion2(1);
            this.setnuevaArea2(this.eventoArea);
        this.setState(this.evento);
        this.setCiudad(this.mievent);
         axios .post(
            "/vacantes/buscavacanteOperativa",
            {
              empresa: null,
              area: this.eventoArea.folio,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: this.evento.estado,
              cd: this.mievent,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
      this.setVacantesOperativas(response.data)
            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.evento && this.mievent) {
        this.setPaginacion2(1);
        console.log(this.evento, this.mievent)
        this.setState(this.evento);
        this.setCiudad(this.mievent);
        console.log("entrando")
        axios
          .post(
            "/vacantes/buscavacanteOperativa",
            {
              empresa: null,
              area: null,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: this.evento.estado,
              cd: this.mievent,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
            console.log(response.data)
      this.setVacantesOperativas(response.data)
            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.eventoArea && this.evento) {
        this.setPaginacion2(1);

        this.setnuevaArea2(this.eventoArea);
        axios
          .post(
            "/vacantes/buscavacanteOperativa",
            {
              empresa: null,
              area: this.eventoArea.folio,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: this.evento.estado,
              cd: null,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
      this.setVacantesOperativas(response.data)
            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.evento) {
        this.setPaginacion2(1);

        axios
          .post(
            "/vacantes/buscavacanteOperativa",
            {
              empresa: null,
              area: null,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: this.evento.estado,
              cd: null,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
      this.setVacantesOperativas(response.data)
            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });

        this.setnuevaEmp2(this.evento);
      } else if (this.eventoArea) {
        this.setPaginacion2(1);
        this.setnuevaArea2(this.eventoArea);
        axios
          .post(
            "/vacantes/buscavacanteOperativa",
            {
              empresa: null,
              area: this.eventoArea.folio,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: null,
              cd: null,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
      this.setVacantesOperativas(response.data)
      console.log(this.eventoArea.folio)
      console.log(response.data)
            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.evento == null;
        axios
          .post(
            "/vacantes/buscavacanteOperativa",
            {
              empresa: null,
              area: null,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: null,
              cd: null,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
      this.setVacantesOperativas(response.data)
            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    ...mapMutations([
      "setPaginacion2",
      "setVacantesOperativas",
      "setEspecialidad",
      "setEmpresa",
      "setState",
      "setCiudad",
      "setTotalVacantesOperativas",
      "setnuevaArea2",
      "setnuevaEmp2",
    ]),
  },
  mounted() {
    console.log(this.evento)
    axios
      .get("/catalogos/especialidadesvacantesOp", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.area = response.data;
      })
      .catch((error) => {
        console.log(error);
      });


          axios
      .get("/catalogos/estadosvacanteOp", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.estados = response.data;

      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    nuevapagina2: function(value) {
      console.log(
        "Pagina: ",
        this.nuevapagina2,
        "Empresa: ",
        this.evento.folio,
        "Área: ",
        this.eventoArea.folio
      );
      this.setPaginacion2(this.nuevapagina2);

      axios
        .post(
          "/vacantes/buscavacanteOperativa",
          {
            empresa: this.evento.folio,
            area: this.eventoArea.folio,
            cd: null,
            sueldo: null,
            paginacion: 15,
            pagina: this.nuevapagina2,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.setVacantesOperativas(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    evento: function(value) {
            console.log(this.evento)
            axios
            .get("/catalogos/ciudadesvacanteOp/" + this.evento.estado, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
            .then((response) => {
              console.log(response.data)
              this.ciudades = response.data;
            })
            .catch((error) => {
            });
          
      if (this.evento == null) {
        this.evento = null;
        this.setnuevaEmp2(this.evento);
        this.evento = "";
        this.setPaginacion2(1);

        axios
          .post("/vacantes/buscavacanteOperativa", {
            edo: null,
            cd: null,
            sueldo: null,
            paginacion: 15,
            pagina: 1,
            empresa: null,
          })
          .then((response) => {
            this.setVacantesOperativas(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    eventoArea: function(value) {
      if (this.eventoArea == null) {
        console.log("es null")
        this.eventoArea == null;
        this.setnuevaArea2(this.eventoArea);

        this.eventoArea = "";
        this.setPaginacion2(1);

        axios
          .post("/vacantes/buscavacanteOperativa", {
            edo: null,
            cd: null,
            sueldo: null,
            paginacion: 15,
            pagina: 1,
            empresa: null,
          })
          .then((response) => {
            this.setVacantesOperativas(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log("ya entro")
        axios
          .post("/vacantes/buscavacanteOperativa", {
            edo: null,
            cd: null,
            sueldo: null,
            paginacion: 15,
            area: this.eventoArea.folio,
            pagina: 1,
            empresa: null,
          })
          .then((response) => {
            this.setVacantesOperativas(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },

};
</script>

<style scoped>
@media only screen and (min-width: 868px) {
  /* Esta es la pc */
  .mitarjeta {
    margin-top: -85px;
    height: 175px;
  }
  .micontenedor {
    margin-top: 225px;
  }
  #cel {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  /* Este es el celular */
  .micontenedor {
    margin-top: 280px;
  }
  .mitarjeta {
    margin-top: -60px;
    height: 220px;
  }
  #cel {
    margin-left: -8px;
  }
  .subir {
    margin-top: -30px;
  }
  #pc {
    display: none;
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  /* Cel acostado */
  .mitarjeta {
    margin-top: -85px;
    height: 175px;
  }

  .micontenedor {
    margin-top: 225px;
  }
  #cel {
    display: none;
  }
}
@media (min-width: 700px) and (orientation: portrait) {
  /* Tablet parado */
  .mitarjeta {
    margin-top: -85px;
    height: 175px;
  }

  .micontenedor {
    margin-top: 225px;
  }
  #cel {
    display: none;
  }
}
</style>
