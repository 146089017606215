<template>
 <v-autocomplete
      outlined
      v-model="modelito"
        :click="setIdioma(modelito)"
  label="Idiomas"
      clearable
       :items="losidiomas"
         item-text="idioma"
      item-value="value"
      autocomplete="nope"
      color="primary"
    ></v-autocomplete>
  

      </template>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";
export default {
  data: () => ({
     losidiomas: [], // <-- La lista de especialidades
    evento: "",  
    modelito: null,
        token: "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c" 

  }),
  computed: {
    ...mapState(["elidioma"]),
  },
     mounted(){
       axios
      .get("/catalogos/idiomas", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.losidiomas = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
     
     },
   methods:{
       ...mapMutations (['setIdioma'])
     },
};
 
</script>