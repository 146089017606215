<template>
  <v-container fluid div class="fondo">
    <v-row id="bajar">
      <v-col cols="12">
        <div class="text-center">
          <v-img
            style="display: block;
margin-left: auto;
margin-right: auto;"
            width="550px"
            src="../assets/corregido.png"
          >
          </v-img>
        </div>
      </v-col>
      <v-flex xs="12" md="3" id="columna1">
        <div
          v-if="logueado"
          style=" display:flex;
    justify-content: center;
    align-items: center;"
          class="text-center"
        >
          <v-btn
          v-if="id.servicio"
              x-large
              class="boton secondary"
              style="color:black; border-radius:19px;width:200px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
              ><strong>Cuenta Servicios</strong> <br />
            </v-btn>
        
        
        </div>
        <div class="text-center">
          <router-link
            :to="{ name: 'loginEmpresas' }"
            style="text-decoration: none;"
          >
            <v-btn
              x-large
              class="boton secondary"
              style="color:black; border-radius:19px;width:200px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
              ><strong>PUBLICAR VACANTE</strong> <br />
            </v-btn>
          </router-link>
        </div>
      </v-flex>

      <v-flex xs="12" md="3" id="columna1">
        <div
          v-if="logueado"
          style=" display:flex;
    justify-content: center;
    align-items: center;"
          class="text-center"
        >
          <v-btn
            class="boton primary"
            v-if="id.empresa"
            x-large
            style="color:black; border-radius:19px;width:200px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
            color="#2afed6"
          >
            CUENTA EMPRESA
          </v-btn>
          <div
            style=" display:flex;
    justify-content: center;
    align-items: center;"
            v-else-if="id.servicio"
          >

              <v-btn
            class="boton primary"

            x-large
            style="color:black; border-radius:19px;width:200px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
            color="#2afed6"
          >
            CUENTA SERVICIOS
          </v-btn>

          </div>
          <div
            style=" display:flex;
    justify-content: center;
    align-items: center;"
            v-else
          >
            <router-link
              :to="{ name: 'Profesionistas' }"
              style="text-decoration: none;"
            >
              <v-btn
                x-large
                class="boton primary"
                style="color:black; border-radius:19px;width:320px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
                ><strong>VACANTES PARA PROFESIONISTAS</strong> <br />
              </v-btn>
            </router-link>
          </div>
        </div>
        <div
          style=" display:flex;
    justify-content: center;
    align-items: center;"
          v-else
        >
          <router-link
            :to="{ name: 'Profesionistas' }"
            style="text-decoration: none;"
          >
            <v-btn
              x-large
              class="boton primary"
              style="color:black; border-radius:19px;width:320px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
              ><strong>VACANTES PARA PROFESIONISTAS </strong> <br />
            </v-btn>
          </router-link>
        </div>
      </v-flex>
      <v-flex id="columna1" xs="12" md="3">
        <div
          v-if="logueado"
          class="text-center"
          style=" display:flex;
    justify-content: center;
    align-items: center;"
        >
          <v-btn
            class="boton primary"
            v-if="id.empresa"
            x-large
            style="color:black; border-radius:19px;width:320px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
            color="#2afed6"
          >
            CUENTA EMPRESA
          </v-btn>
          <div
            style=" display:flex;
    justify-content: center;
    align-items: center;"
            v-else-if="id.servicio"
          >
           
              <v-btn
            class="boton primary"
            x-large
            style="color:black; border-radius:19px;width:320px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
            color="#2afed6"
          >
            CUENTA SERVICIOS
          </v-btn>
         
          </div>
          <div
            style=" display:flex;
    justify-content: center;
    align-items: center;"
            v-else
          >
            <router-link
              :to="{ name: 'Operativas' }"
              style="text-decoration: none;"
            >
              <v-btn
                x-large
                class="boton primary"
                style="color:black; border-radius:19px;width:310px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
                ><strong>VACANTES PARA OPERATIVOS</strong> <br />
              </v-btn>
            </router-link>
          </div>
        </div>
        <div
          style=" display:flex;
    justify-content: center;
    align-items: center;"
          v-else
        >
          <router-link
            :to="{ name: 'Operativas' }"
            style="text-decoration: none;"
          >
            <v-btn
              x-large
              class="boton "
              style="color:black; border-radius:19px;width:310px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
              ><strong>VACANTES PARA OPERATIVOS</strong> <br />
            </v-btn>
          </router-link>
        </div>
      </v-flex>
      <v-flex id="columna1" xs="12" md="3">
        <div
          v-if="logueado"
          class="text-center"
          style=" display:flex;
    justify-content: center;
    align-items: center;"
        >
          <v-btn
            class="boton primary"
            v-if="id.empresa"
            x-large
            style="color:black; border-radius:19px;width:320px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
            color="#2afed6"
          >
            CUENTA EMPRESA
          </v-btn>
          <div
            style=" display:flex;
    justify-content: center;
    align-items: center;"
            v-else-if="id.servicio"
          >
          <router-link
            :to="{ name: 'Servicios' }"
            style="text-decoration: none;"
          >
            <v-btn
            class="boton"
            x-large
            style="background-color:#ffff01 ;color:black; border-radius:19px;width:320px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
            color="#2afed6"
          >
            SERVICIOS PROFESIONALES
          </v-btn>
          </router-link>
          </div>
        </div>
        <div
          style=" display:flex;
    justify-content: center;
    align-items: center;"
          v-else
        >
          <router-link
            :to="{ name: 'Servicios' }"
            style="text-decoration: none;"
          >
            <v-btn
              x-large
              class="boton "
              style="background-color:#ffff01 ;color:black; border-radius:19px;width:260px;  box-shadow: 2px 2px 5px #999; font-weight:bold"
              ><strong>SERVICIOS PROFESIONALES</strong> <br />
            </v-btn>
          </router-link>
        </div>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Buscador",
  data: () => ({
    dialog: false,
    dialog2: false,
    dialog3: false,
  }),
  computed: {
    logueado() {
      return this.$store.state.token;
    },
    ...mapState(["id"]),
  },
};
</script>

<style>
.fondo {
  background-image: url("../assets/buildings.jpg");
  background-position: bottom; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

@media only screen and (min-width: 800px) {
  /* todo lo de lap */

  #bajar {
    margin-top: 70px;
  }

  .fondo {
    height: 570px !important;
    background-position: top !important;
  }
  #columna1 {
    margin-top: -150px;
  }
  #columna2 {
    margin-top: -150px;
  }

  .boton {
    margin-top: 380px;
  }
}
@media only screen and (max-width: 768px) {
  /* todo lo de cel */

  .fondo {
    height: 450px;
  }
  #bloque {
    margin-top: 70px;
  }
  #columna1 {
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
  #columna2 {
    margin-top: 5px !important;
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 812px) and (max-height: 375px) {
  #columna1 {
    margin-top: -210px !important;
    margin-left: 0 !important;
  }
  #columna2 {
    margin-top: -185px !important;
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 736px) and (max-height: 414px) {
  #bloque {
    margin-top: -60px;
  }
  #columna1 {
    margin-top: -210px !important;
    margin-left: 0 !important;
  }
  #columna2 {
    margin-top: -185px !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 430px) and (max-height: 930px) {
  .fondo {
    height: 550px;
  }
}
@media only screen and (max-width: 667px) and (max-height: 375px) {
  .fondo {
    height: 350px;
  }
  #bloque {
    margin-top: 10px;
  }
  #columna1 {
    margin-top: 10px !important;
    margin-left: 0 !important;
  }
  #columna2 {
    margin-top: 5px !important;
    margin-left: 0 !important;
  }
}
</style>
