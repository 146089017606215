<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-card color="grey darken-4" dark class="mitarjeta" fluid>
        <v-toolbar>
          <v-container fluid class="micontenedor">
            <v-row>
              <v-flex class="subir" xs12 sm6 md4 lg4 xl6>
                <v-col>
                  <v-select
                    outlined
                    v-model="model"
                    :click="buscar()"
                    label="Área (Opcional)"
                    clearable
                    :items="objetos"
                    item-text="especialidad"
                    item-value="ciudad"
                    autocomplete="nope"
                    color="primary"
                    return-object
                  ></v-select>
                </v-col>
              </v-flex>
              <v-flex class="subir" xs6 sm6 md4 lg4 xl6>
                <v-col>
                  <v-autocomplete
                    outlined
                    v-model="evento"
                    :click="buscar()"
                    label="Estado (Opcional)"
                    clearable
                    :items="estados"
                    item-text="estado"
                    item-value="value"
                    autocomplete="nope"
                    color="primary"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-flex>
              <v-flex class="subir" xs6 sm6 md4 lg4 xl6>
                <v-col>
                  <v-autocomplete
                    outlined
                    v-model="mievent"
                    label="Ciudad "
                    :click="buscar()"
                    clearable
                    :items="ciudades"
                    item-text="municipio"
                    item-value="ciudad"
                    autocomplete="nope"
                    color="primary"
                  ></v-autocomplete>
                </v-col>
              </v-flex>
            </v-row>
          </v-container>
        </v-toolbar>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
import Especialidad from "../components/Buscador/Especialidad.vue";
import CiudadBuscador from "../components/Buscador/CiudadBuscador.vue";
import Empresa from "../components/Buscador/Empresa.vue";
import rangoSalarial from "../components/Buscador/rangoSalarial.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "Buscador",
  data: () => ({
    dialog: false,
    radioGroup: 1,
    mievent: null,
    items: [],
    ciudades: [],
    model: "",
    estados: [], // <-- La lista de especialidades
    evento: "",
    busqueda: "",
    objetos: [],
    values: ["Sistemas", "Contabilidad"],
    value: null,
    salario: null,
    jornada: "Cualquiera",
    token:
      "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
  }),
  components: {
    CiudadBuscador,
    Empresa,
    rangoSalarial,
    Especialidad,
  },
  computed: {
    ...mapState([
      "nuevaespecialidad",
      "elestado",
      "ciudadBuscador",
      "puesto",
      "rangoInf",
      "rangoSup",
      "Urlheader",
      "VacanteFolio",
      "miempresa",
      "puesto",
      "vacantes",
      "nuevaPagina",
      "id",
      "Empresa",
    ]),
    logueado() {
      return this.$store.state.token;
    },
  },
  watch: {
    evento: function(value) {
      axios
        .get("/catalogos/ciudadesvacante/" + this.evento.estado, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.ciudades = response.data;
        })
        .catch((error) => {});

      if (this.evento == null) {
        this.evento = null;
        this.setnuevaEmp(this.evento);
        this.evento = "";

        if (this.model == null) {
          axios
            .post("/vacantes/buscavacante", {
              edo: null,
              cd: null,
              especialidad: null,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              empresa: null,
            })
            .then((response) => {
              //  this.posts = response.data
              this.setVacantes(response.data);
              console.log(response.data);
              window.scrollTo(0, 0);
              //this.nuevaPagina = 1;
            });
        } else if (this.model) {
          axios
            .post("/vacantes/buscavacante", {
              edo: null,
              cd: null,
              especialidad: this.model.folio,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              empresa: null,
            })
            .then((response) => {
              //  this.posts = response.data
              this.setVacantes(response.data);
              console.log(response.data);
              window.scrollTo(0, 0);
              //this.nuevaPagina = 1;
            });
        }
      }
    },

    model: function(value) {
      if (this.model == null) {
        this.setPaginacion(1);
        this.model == null;
        this.setnuevaArea(this.model);
        console.log("holamundo2");

        console.log(this.evento);
        if (this.evento) {
          console.log("holamundo");
          axios
            .post("/vacantes/buscavacante", {
              edo: null,
              cd: null,
              especialidad: null,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              empresa: this.evento.folio,
            })
            .then((response) => {
              //  this.posts = response.data
              this.setVacantes(response.data);
              console.log(response.data);

              window.scrollTo(0, 0);
              //this.nuevaPagina = 1;
            });
        } else {
          console.log("holamundo3");
          axios
            .post("/vacantes/buscavacante", {
              edo: null,
              cd: null,
              especialidad: null,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              empresa: null,
            })
            .then((response) => {
              //  this.posts = response.data
              this.setVacantes(response.data);
              console.log(response.data);
              this.setPaginacion(1);
              window.scrollTo(0, 0);
              //this.nuevaPagina = 1;
            });
        }
      }
    },

    nuevaPagina: function(value) {
      console.log(this.nuevaPagina);
      if (this.model && this.evento) {
        console.log(this.model, this.evento);
        axios
          .post("/vacantes/buscavacante", {
            edo: this.elestado,
            cd: this.ciudadBuscador,
            especialidad: this.model.folio,
            sueldo: null,
            paginacion: 20,
            pagina: this.nuevaPagina,
            candidato: null,
            empresa: this.evento.folio,
          })
          .then((response) => {
            this.setVacantes(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.model) {
        console.log(this.model);
        axios
          .post("/vacantes/buscavacante", {
            edo: null,
            cd: null,
            especialidad: this.model.folio,
            sueldo: null,
            paginacion: 20,
            pagina: this.nuevaPagina,
            candidato: null,
          })
          .then((response) => {
            this.setVacantes(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.evento) {
        console.log(this.evento);
        axios
          .post("/vacantes/buscavacante", {
            edo: this.evento.estado,
            cd: null,
            especialidad: "",
            sueldo: null,
            paginacion: 20,
            pagina: this.nuevaPagina,
            candidato: null,
            empresa: null
          })
          .then((response) => {
            this.setVacantes(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axios
          .post("/vacantes/buscavacante", {
            edo: "",
            cd: "",
            especialidad: null,
            sueldo: null,
            paginacion: 20,
            pagina: this.nuevaPagina,
            candidato: null,
          })
          .then((response) => {
            this.setVacantes(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  methods: {
    buscar() {
      if (this.evento && this.model && this.mievent) {
        this.setPaginacion(1);
            this.setnuevaArea(this.model);
        this.setState(this.evento);
        this.setCiudad(this.mievent);
        axios
          .post(
            "/vacantes/buscavacante",
            {
              empresa: null,
              especialidad: this.model.folio,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: this.evento.estado,
              cd: this.mievent,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);

            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.evento && this.mievent) {
        this.setPaginacion(1);
        this.setState(this.evento.estado);
        this.setCiudad(this.mievent);
        console.log("entrando")
        axios
          .post(
            "/vacantes/buscavacante",
            {
              empresa: null,
              especialidad: null,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: this.evento.estado,
              cd: this.mievent,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);

            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.model && this.evento) {
        this.setPaginacion(1);
        this.setState(this.evento)
        this.setnuevaArea(this.model);
        axios
          .post(
            "/vacantes/buscavacante",
            {
              empresa: null,
              especialidad: this.model.folio,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: this.evento.estado,
              cd: null,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);

            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.evento) {
        this.setPaginacion(1);
        this.setState(this.evento)

        axios
          .post(
            "/vacantes/buscavacante",
            {
              empresa: null,
              especialidad: null,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: this.evento.estado,
              cd: null,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);

            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });

        this.setnuevaEmp(this.evento);
      } else if (this.model) {
        this.setPaginacion(1);

        this.setnuevaArea(this.model);
        axios
          .post(
            "/vacantes/buscavacante",
            {
              empresa: null,
              especialidad: this.model.folio,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: null,
              cd: null,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);

            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.evento == null;
        axios
          .post(
            "/vacantes/buscavacante",
            {
              empresa: null,
              especialidad: null,
              sueldo: null,
              paginacion: 20,
              pagina: 1,
              candidato: null,
              edo: null,
              cd: null,
            },
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          )
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);

            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    ...mapMutations([
      "setVacantes",
      "setEspecialidad",
      "setEmpresa",
      "setnuevaEmp",
      "setnuevaArea",
      "setState",
      "setCiudad",
      "setPaginacion",
    ]),
    buscador() {
      if (this.jornada == "Cualquiera") {
        this.salario = null;
      } else if (this.jornada == "Desde $5,000") {
        this.salario = "5000";
      } else if (this.jornada == "Desde $10,000") {
        this.salario = "10000";
      } else if (this.jornada == "Desde $15,000") {
        this.salario = "15000";
      } else if (this.jornada == "Desde $25,000") {
        this.salario = "25000";
      } else if (this.jornada == "Desde $40,000") {
        this.salario = "40000";
      } else if (this.jornada == "Desde $60,000") {
        this.salario = "60000";
      } else {
        this.salario = null;
      }
      if (this.nuevaespecialidad == null && this.id == null) {
        /*  console.log(
          "edo: ",
          this.elestado,
          "cd: ",
          this.ciudadBuscador,
          "especialidad:  null",
          " sueldo:",
          this.salario,
          "paginacion: ",
          "10",
          "pagina: ",
          "1",
          "Iduser: null"
        ); */

        axios
          .post("/vacantes/buscavacante", {
            edo: this.elestado,
            cd: this.ciudadBuscador,
            especialidad: null,
            sueldo: null,
            paginacion: 20,
            pagina: 1,
            candidato: null,
          })
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);

            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.nuevaespecialidad == null) {
        /*  console.log(
          "edo: ",
          this.elestado,
          "cd: ",
          this.ciudadBuscador,
          "especialidad:  null",
          " sueldo:",
          this.salario,
          "paginacion: ",
          "10",
          "pagina: ",
          "1",
          "Iduser: null"
        ); */

        axios
          .post("/vacantes/buscavacante", {
            edo: this.elestado,
            cd: this.ciudadBuscador,
            especialidad: null,
            sueldo: null,
            paginacion: 20,
            pagina: 1,
            candidato: this.id.id,
          })
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);
            // console.log(response.data);
            // this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.id == null) {
        /* console.log(
          "edos: ",
          this.elestado,
          "cd: ",
          this.ciudadBuscador,
          "especialidad: ",
          this.nuevaespecialidad.folio,
          " sueldo:",
          this.salario,
          "paginacion: ",
          "10",
          "pagina: ",
          "1",
          "idnull"
        ); */
        console.log(this.salario);

        axios
          .post("/vacantes/buscavacante", {
            edo: this.elestado,
            cd: this.ciudadBuscador,
            especialidad: this.nuevaespecialidad.folio,
            sueldo: null,
            paginacion: 20,
            pagina: 1,
            candidato: null,
          })
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);
            console.log(response.data);
            console.log(this.salario);
            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log(
          "edos: ",
          this.elestado,
          "cd: ",
          this.ciudadBuscador,
          "especialidad: ",
          this.nuevaespecialidad.folio,
          " sueldo:",
          this.salario,
          "paginacion: ",
          "10",
          "pagina: ",
          "1",
          "Iduser: ",
          this.id.id
        );

        axios
          .post("/vacantes/buscavacante", {
            edo: this.elestado,
            cd: this.ciudadBuscador,
            especialidad: this.nuevaespecialidad.folio,
            sueldo: null,
            paginacion: 20,
            pagina: 1,
            candidato: this.id.id,
          })
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);
            console.log(this.salario);
            // console.log(response.data);
            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    axios
      .get("/catalogos/especialidadesvacantes", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.objetos = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/catalogos/estadosvacante", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => {
        this.estados = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
@media only screen and (min-width: 868px) {
  /* Esta es la pc */
  .mitarjeta {
    margin-top: -85px;
    height: 175px;
  }
  .micontenedor {
    margin-top: 225px;
  }
  #cel {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  /* Este es el celular */
  .micontenedor {
    margin-top:290px;
  }
  .mitarjeta {
    margin-top: -65px;
    height: 220px;
  }
  #cel {
    margin-left: -8px;
  }
  .subir {
    margin-top: -30px;
  }
  #pc {
    display: none;
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  /* Cel acostado */
  .mitarjeta {
    margin-top: -85px;
    height: 175px;
  }

  .micontenedor {
    margin-top: 225px;
  }
  #cel {
    display: none;
  }
}
@media (min-width: 700px) and (orientation: portrait) {
  /* Tablet parado */
  .mitarjeta {
    margin-top: -85px;
    height: 175px;
  }

  .micontenedor {
    margin-top: 225px;
  }
  #cel {
    display: none;
  }
}
</style>
