<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-card color="grey darken-4" dark class="mitarjeta" fluid>
        <v-toolbar>
          <v-container fluid class="micontenedor">
            <v-row>
              <v-flex xs6 sm2 md2 lg2 xl2>
                <v-col>
                  <Estado></Estado>
                </v-col>
              </v-flex>
              <v-flex xs6 sm2 md2 lg2 xl2>
                <v-col>
                  <CiudadBuscador></CiudadBuscador>
                </v-col>
              </v-flex>
              <v-flex class="subir" xs6 sm2 md2 lg4 xl2>
                <v-col>
                  <Especialidad></Especialidad>
                </v-col>
              </v-flex>
              <v-flex class="subir" xs4 sm2 md2 lg2 xl2>
                <v-col>
                  <v-autocomplete
                    outlined
                    label="Rango de Sueldo Mensual (Opcional)"
                    clearable
                    :items="items"
                    item-text="especialidad"
                    item-value="ciudad"
                    autocomplete="nope"
                    color="primary"
                    return-object
                    
                    v-model="jornada"
                  ></v-autocomplete>
                </v-col>
              </v-flex>
              <v-flex xs2 sm2 md2 lg2 xl4>
                <v-col id="pc">
                  <v-btn
                    @click="buscador()"
                    color="primary"
                    style="color:black; margin-left:0px; margin-top:7px"
                  >
                    Buscar Vacantes
                  </v-btn>
                </v-col>
                <v-col id="cel" class="subir">
                  <v-btn
                    @click="buscador()"
                    color="primary"
                    style="color:black; margin-left:0px; margin-top:7px"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-flex>
            </v-row>
          </v-container>
        </v-toolbar>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
import Especialidad from "../components/Buscador/Especialidad.vue";
import CiudadBuscador from "../components/Buscador/CiudadBuscador.vue";
import Estado from "../components/Buscador/Estado.vue";
import rangoSalarial from "../components/Buscador/rangoSalarial.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "Buscador",
  data: () => ({
    dialog: false,
    radioGroup: 1,
    items: [
      "Cualquiera",
      "Desde $5,000",
      "Desde $10,000",
      "Desde $15,000",
      "Desde $25,000",
      "Desde $40,000",
      "Desde $60,000",
    ],
    values: ["Sistemas", "Contabilidad"],
    value: null,
    salario: null,
    jornada: "Cualquiera",
  }),
  components: {
    CiudadBuscador,
    Estado,
    rangoSalarial,
    Especialidad,
  },
  computed: {
    ...mapState([
      "nuevaespecialidad",
      "elestado",
      "ciudadBuscador",
      "puesto",
      "rangoInf",
      "rangoSup",
      "Urlheader",
      "VacanteFolio",
      "miempresa",
      "puesto",
      "vacantes",
      "nuevaPagina",
      "id",
    ]),
    logueado() {
      return this.$store.state.token;
    },
  },
  watch: {
    nuevaPagina: function(value) {
      if(this.id){
         axios
        .post("/vacantes/buscavacante", {
          edo: this.elestado,
          cd: this.ciudadBuscador,
          especialidad: null,
          sueldo: null,
          paginacion: 10,
          pagina: this.nuevaPagina,
          candidato: this.id.id,
        })
        .then((response) => {
          console.log(response.data)
          this.setVacantes(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      } else{
         axios
        .post("/vacantes/buscavacante", {
          edo: this.elestado,
          cd: this.ciudadBuscador,
          especialidad: null,
          sueldo: null,
          paginacion: 10,
          pagina: this.nuevaPagina,
          candidato: null,
        })
        .then((response) => {
          console.log(response.data)
          this.setVacantes(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      }
     
    },

  },
  methods: {
    ...mapMutations(["setVacantes"]),
    buscador() {
      if (this.jornada == "Cualquiera") {
        this.salario = null;
      }
      else if (this.jornada == "Desde $5,000") {
        this.salario = "5000";
      }
      else if (this.jornada == "Desde $10,000") {
        this.salario = "10000";
      }
      else if (this.jornada == "Desde $15,000") {
        this.salario = "15000";
      }
      else if (this.jornada == "Desde $25,000") {
        this.salario = "25000";
      }
      else if (this.jornada == "Desde $40,000") {
        this.salario = "40000";
      }
      else if (this.jornada == "Desde $60,000") {
        this.salario = "60000";
      } else {
        this.salario= null
      }
      if (this.nuevaespecialidad == null && this.id == null) {
       /*  console.log(
          "edo: ",
          this.elestado,
          "cd: ",
          this.ciudadBuscador,
          "especialidad:  null",
          " sueldo:",
          this.salario,
          "paginacion: ",
          "10",
          "pagina: ",
          "1",
          "Iduser: null"
        ); */
        
        axios
          .post("/vacantes/buscavacante", {
            edo: this.elestado,
            cd: this.ciudadBuscador,
            especialidad: null,
            sueldo: null,
            paginacion: 10,
            pagina: 1,
            candidato: null,
          })
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);
            console.log(response.data)
            //this.nuevaPagina = 1;
           
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.nuevaespecialidad == null) {
       /*  console.log(
          "edo: ",
          this.elestado,
          "cd: ",
          this.ciudadBuscador,
          "especialidad:  null",
          " sueldo:",
          this.salario,
          "paginacion: ",
          "10",
          "pagina: ",
          "1",
          "Iduser: null"
        ); */
         
        axios
          .post("/vacantes/buscavacante", {
            edo: this.elestado,
            cd: this.ciudadBuscador,
            especialidad: null,
            sueldo: null,
            paginacion: 10,
            pagina: 1,
            candidato: this.id.id,
          })
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);
            // console.log(response.data);
            // this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.id == null) {
        /* console.log(
          "edos: ",
          this.elestado,
          "cd: ",
          this.ciudadBuscador,
          "especialidad: ",
          this.nuevaespecialidad.folio,
          " sueldo:",
          this.salario,
          "paginacion: ",
          "10",
          "pagina: ",
          "1",
          "idnull"
        ); */
         console.log(this.salario)

        axios
          .post("/vacantes/buscavacante", {
            edo: this.elestado,
            cd: this.ciudadBuscador,
            especialidad: this.nuevaespecialidad.folio,
            sueldo: null,
            paginacion: 10,
            pagina: 1,
            candidato: null,
          })
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);
            console.log(response.data);
   
            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log(
          "edos: ",
          this.elestado,
          "cd: ",
          this.ciudadBuscador,
          "especialidad: ",
          this.nuevaespecialidad.folio,
          " sueldo:",
          this.salario,
          "paginacion: ",
          "10",
          "pagina: ",
          "1",
          "Iduser: ",
          this.id.id
        );

        axios
          .post("/vacantes/buscavacante", {
            edo: this.elestado,
            cd: this.ciudadBuscador,
            especialidad: this.nuevaespecialidad.folio,
            sueldo: null,
            paginacion: 10,
            pagina: 1,
            candidato: this.id.id,
          })
          .then((response) => {
            //  this.posts = response.data
            this.setVacantes(response.data);
             console.log(this.salario)
            // console.log(response.data);
            //this.nuevaPagina = 1;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 868px) {
  /* Esta es la pc */
  .mitarjeta {
    margin-top: -85px;
    height: 175px;
  }
  .micontenedor {
    margin-top: 225px;
  }
  #cel {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  /* Este es el celular */
  .micontenedor {
    margin-top: 270px;
  }
  .mitarjeta {
    margin-top: -65px;
    height: 230px;
  }
  #cel{
    margin-left:-8px;
  }
    .subir{

      margin-top:-30px
    }
  #pc {
    display: none;
  }
}

@media (max-width: 900px) and (orientation: landscape){
/* Cel acostado */
.mitarjeta {
    margin-top: -85px;
    height: 175px;
  }

 .micontenedor {
    margin-top: 225px;
  }
 #cel {
    display: none;
  }
}
@media (min-width: 700px) and (orientation: portrait){
/* Tablet parado */
.mitarjeta {
    margin-top: -85px;
    height: 175px;
  }

 .micontenedor {
    margin-top: 225px;
  }
 #cel {
    display: none;
  }
}
</style>
