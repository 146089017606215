<template>
  <v-app>
    <v-container fluid>
      <br />
       <div>
  </div>
      <v-row>
        <v-col cols="12" v-for="imagen of header" :key="imagen.folio">
          <a :href="imagen.url" target="_blank">
            <v-img
              href="imagen.url"
              style="border-radius:10px;margin-top:-15px"
              :src="imagen.imagen"
            >
            </v-img>
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="8">
          <p v-if="misvacantes.length < 1">
            Tu busqueda no tiene resultados, modifique los filtros.
          </p>

          <v-card
            class="tarjeta"
            v-for="vacante in misvacantes"
            :key="vacante.folio"
            style="padding:0px !important; border-radius:20px;"
          >
            <v-container fluid class="headernegro">
              <v-row>
                <v-col cols="5" class="cel" v-if="vacante.anonimo == false">
                  <div v-if="vacante.logo" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="vacante.logo"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="12" class="cel" v-if="vacante.anonimo == true">
                  <div class="text-center">
                    <p
                      class="vacantePosicion"
                      style="color:white; text-align:center; "
                    >
                      {{ vacante.posicion }}
                    </p>
                    <span
                      v-if="vacante.anonimo == true"
                      style="color:#FFC000;font-size:14px;text-align:center;"
                    >
                      <strong>Empresa Lider </strong></span
                    >
                  </div>
                </v-col>
                <v-col cols="7" id="cel">
                  <div v-if="vacante.anonimo == false" class="text-center">
                    <p
                      class="vacantePosicion"
                      style="color:white; text-align:center; "
                    >
                      {{ vacante.posicion }}
                    </p>

                    <p
                      v-if="vacante.anonimo == false"
                      style="color: rgb(255, 192, 0);
text-align: center;
"
                    >
                      {{ vacante.empresa }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" id="cel">
                  <p
                    class="vacanteRango"
                    style="background-color:#2afed6; padding:10px; border-radius:12px; text-align:center;"
                  >
                    <strong>
                      Rango de Sueldo Mensual<br />
                      {{
                        vacante.sueldo_raninf.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}

                      -
                      {{
                        vacante.sueldo_ransup.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </strong>
                  </p>
                  <div class="text-center">
                    <span
                      class="text-center"
                      v-if="vacante.tipo_publicacion == 2"
                      style="text-align:center !important;font-size:14px;color:#FFC000;"
                      id="pc"
                    >
                      <strong
                        >¡Buscan cubrir rápido la vacante, postúlate ahora!
                      </strong></span
                    >
                  </div>
                </v-col>

                <v-col cols="2" class="pc" v-if="vacante.anonimo == false">
                  <div v-if="vacante.logo" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="vacante.logo"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>

                <v-col cols="6" class="text-center pc" v-if="vacante.anonimo">
                  <p
                    class="vacantePosicion"
                    style="color:white; text-align:center; "
                  >
                    {{ vacante.posicion }}
                  </p>

                  <h3 style="color:#FFC000;" class="vacanteEmpresa text-center">
                    <v-icon>mdi-office-building</v-icon> {{ vacante.empresa }}
                  </h3>
                </v-col>
                <v-col cols="6" class="pc" v-if="vacante.anonimo">
                  <p
                    class="vacanteRango"
                    style="background-color:#2afed6; padding:10px; border-radius:12px; text-align:center;"
                  >
                    <strong>
                      Rango de Sueldo Mensual<br />
                      {{
                        vacante.sueldo_raninf.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}

                      -
                      {{
                        vacante.sueldo_ransup.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </strong>
                  </p>
                  <p class="text-center" style="color:white;font-size:18px;">
                    <strong>Da click para contactarte</strong>
                  </p>
                  <div class="text-center">
                    <v-icon style="color:yellow;margin-top:-10px;"
                      >mdi-phone</v-icon
                    >
                    <a
                      style="color:white;font-size:14px;margin-top:-20px;"
                      target="_blank"
                      v-if="vacante.telefono"
                      class="pc"
                      :href="'tel:' + vacante.telefono"
                      >{{ vacante.telefono }}</a
                    >

                    <br />
                    <v-icon style="color:green">mdi-whatsapp</v-icon>
                    <a
                      class="pc"
                      v-if="vacante.whatsapp"
                      style="color:white;font-size:14px;"
                      target="_blank"
                      :href="
                        'https://api.whatsapp.com/send?phone=52' +
                          vacante.whatsapp +
                          '&text=¡Hola!%20vi%20la%20vacante%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20mas%20información.'
                      "
                      >{{ vacante.whatsapp }}</a
                    >
                    <br>
                    <v-icon style="color:red">mdi-mail</v-icon>
                    <a
                      class="pc"
                      v-if="vacante.correo"
                      style="color:white;font-size:14px;"
                      target="_blank"
                      :href="'mailto:' + vacante.correo + '?Subject=CV%20enviado%20desde%20VACANTA'"
                      
                      >{{ vacante.correo }}</a
                    >
                  </div>

                  <div class="text-center">
                    <span
                      class="text-center"
                      v-if="vacante.tipo_publicacion == 2"
                      style="text-align:center !important;font-size:14px;color:#FFC000;"
                      id="pc"
                    >
                      <strong
                        >¡Buscan cubrir rápido la vacante, postúlate ahora!
                      </strong></span
                    >
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="text-center pc"
                  v-if="vacante.anonimo == false"
                >
                  <p
                    class="vacantePosicion"
                    style="color:white; text-align:center; "
                  >
                    {{ vacante.posicion }}
                  </p>

                  <h3 style="color:#FFC000;" class="vacanteEmpresa text-center">
                    <v-icon>mdi-office-building</v-icon> {{ vacante.empresa }}
                  </h3>
                  
                </v-col>
                <v-col cols="4" class="pc" v-if="vacante.anonimo == false">
                  <p
                    class="vacanteRango"
                    style="background-color:#2afed6; padding:10px; border-radius:12px; text-align:center;"
                  >
                    <strong>
                      Rango de Sueldo Mensual<br />
                      {{
                        vacante.sueldo_raninf.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}

                      -
                      {{
                        vacante.sueldo_ransup.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </strong>
                  </p>
                  <div class="text-center">
                     <p class="text-center" style="color:white;font-size:18px;">
                    <strong>Da click para contactarte</strong>
                  </p>
                    <v-icon style="color:yellow;margin-top:-10px;"
                      >mdi-phone</v-icon
                    >
                    <a
                      style="color:white;font-size:14px;margin-top:-20px;"
                      target="_blank"
                      v-if="vacante.telefono"
                      class="pc"
                      :href="'tel:' + vacante.telefono"
                      >{{ vacante.telefono }}</a
                    >

                    <br />
                    <v-icon style="color:green">mdi-whatsapp</v-icon>
                    <a
                      class="pc"
                      v-if="vacante.whatsapp"
                      style="color:white;font-size:14px;"
                      target="_blank"
                      :href="
                        'https://api.whatsapp.com/send?phone=52' +
                          vacante.whatsapp +
                          '&text=¡Hola!%20vi%20la%20vacante%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20mas%20información.'
                      "
                      >{{ vacante.whatsapp }}</a
                    >
                    <br>
                    <v-icon style="color:red">mdi-mail</v-icon>
                    <a
                      class="pc"
                      v-if="vacante.correo"
                      style="color:white;font-size:14px;"
                      target="_blank"
                      :href="'mailto:' + vacante.correo + '?Subject=CV%20enviado%20desde%20VACANTA'"
                      
                      >{{ vacante.correo }}</a
                    >
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid style="background-color:#dddddd">
              <v-row>
                <v-col cols="12" id="cel" class="text-center">
                  <span
                    v-if="vacante.tipo_publicacion == 2"
                    style="font-size:14px;text-align:center;"
                  >
                    <strong
                      >¡Buscan cubrir rápido la vacante, postúlate ahora!
                    </strong></span
                  >
                </v-col>

                <!--  <v-col cols="12" class="text-center">
                  <div v-if="logueado">
                    <br />
                    <v-btn
                      v-if="id.empresa"
                      style="color:black;"
                      color="#2afed6"
                    >
                      Cuenta Empresa
                    </v-btn>

                    <v-btn
                      v-if="vacante.aplico"
                      style="color:black;"
                      color="#2afed6"
                      >Ya Aplicaste <v-icon>mdi-account-check</v-icon>
                    </v-btn>
                    <div v-else>
                      <v-btn
                        style="color:black;"
                        color="secondary"
                        v-on:click="irVacante(vacante.folio)"
                        >DA CLICK PARA VER LOS DETALLES<v-icon
                          >mdi-account-check</v-icon
                        >
                      </v-btn>
                    </div>
                  </div>
                  <div v-else>
                    <v-btn
                      style="color:black;  width:300px;height:80px; font-size:22px"
                      color="secondary"
                      v-on:click="irVacante(vacante.folio)"
                      >DA CLICK PARA <br />VER LOS DETALLES
                      <v-icon>mdi-account-check</v-icon>
                    </v-btn>
                  </div>
                </v-col> -->
               
              </v-row>

              <!-- <-- aqui esta el proble -->
              <v-row >
                 <v-flex xs12 sm12 md6 class="columna3">
                  <br />
                  <h2 class="text-center">
                    Descripción y Requisitos
                  </h2>
                  <br />

                  <v-textarea
                    color="secondary"
                    background-color="secondary"
                    outlined
                    v-model="vacante.descripcion"
                    name="tbox"
                    
                    rows="5"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md5 class="columna4">
                  <br />
                  <h2 class="text-center">
                    Nivel Educativo Requerido
                  </h2>
                  <br />
                  <div class="text-center">
                    <v-textarea
                      color="secondary"
                      background-color="secondary"
                      outlined
                      v-model="vacante.desc_corta"
                      name="tbox"
                      cols="30"
                      rows="2"
                    ></v-textarea>
                  </div>
                  <v-row style="margin-top:-20px;">
                    <v-col cols="6">
                      <h4
                        style="background-color:black; font-size:14px;color:white; border-radius:6px; width:fit-content;padding:5px;"
                      >
                        Jornada: {{ vacante.tipo_jornada }}
                      </h4>
                      
                      <br />
                      <h4
                          style="background-color:black;text-align:center; font-size:14px;color:white; border-radius:6px; width:200px;padding:5px;margin-left:0px;"
                        >
                          Contratación: {{ vacante.tipo_contratacion }}
                        </h4>
                    </v-col>
                    <v-col cols="6">
                      <h4
                        style="background-color:black; font-size:14px;color:white; border-radius:6px; width:fit-content;padding:5px;"
                      >
                        Modalidad: {{ vacante.tipo_area }}
                      </h4>
                    </v-col>
    
                  </v-row>
                </v-flex>
              </v-row>
              <!-- <-- aqui esta el problecel-->
             























              <v-row>
               
                <v-col md="4" class="text-center">
                  <p>
                    <strong> Lugar:</strong>
                  </p>
                  <p style="background-color:#2afed6; border-radius:6px; ">
                    <strong>
                      {{ vacante.ciudad }} ,
                      {{ vacante.estado }}
                    </strong>
                  </p>
                </v-col>
                <v-col md="4" xs="6" class="text-center">
                  <p><strong> Fecha de publicación</strong></p>
                  <p style="background-color:#2afed6; border-radius:6px;">
                    <strong>
                      {{ moment(vacante.fechacreacion).format("LL") }}
                    </strong>
                  </p>
                </v-col>
                <v-col md="4" xs="12" class="text-center">
                  <p><strong> Área: </strong></p>
                  <p style="background-color:#2afed6; border-radius:6px;">
                    <strong> {{ vacante.sectordesc }}</strong>
                  </p>
                </v-col>
              </v-row>
               
            </v-container>
          </v-card>
        </v-col>

        <v-col
          v-for="imagen of portrait"
          :key="imagen.folio"
          class="columna1"
          sm="12"
          xs="12"
          md="4"
          style=""
        >
          <a :href="imagen.url" target="_blank">
            <v-img
              style="border-radius:10px; "
              :src="imagen.imagen"
              :href="imagen.url"
            >
            </v-img>
          </a>
        </v-col>
        <v-col cols="12">
          <div class="text-center" @click="paginacion(page)">
            <v-pagination
              v-model="page"
              :length="totalPaginas"
              circle
              dark
            ></v-pagination>
          </div>
        </v-col>

        <v-col v-for="imagen of footer" :key="imagen.folio" cols="12">
          <a :href="imagen.url" target="_blank">
            <v-img
              style="border-radius:10px;"
              :src="imagen.imagen"
              :href="imagen.url"
            >
            </v-img>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import Buscador from "../components/Buscador.vue";
import { mapMutations, mapState, mapActions } from "vuex";
var moment = require("moment");
moment.locale("es-mx");

export default {
  data() {
    return {
      moment: moment,
      posts: [],
      min: 0,
      max: 45000,
      range: [5000, 25000],
      header: [],
      footer: [],
      portrait: [],
      nombre: "",
      page: 1,
      totalPaginas: null,
    };
  },
  components:{
    Buscador,
  },
  computed: {
    id() {
      return this.$store.state.id;
    },
    logueado() {
      return this.$store.state.token;
    },
    ...mapState([
      "elestado",
      "laciudad",
      "puesto",
      "rangoInf",
      "rangoSup",
      "Urlheader",
      "VacanteFolio",
      "miempresa",
      "misvacantes",
      "nuevaPagina",
      "id",
    ]),
  },
  methods: {
    ...mapMutations(["setVacanteFolio", "setPaginacion", "setVacantes"]),
    ...mapActions(["irVacante"]),
    buscaVacante(value) {
      this.posts = value;
    },
    paginacion(page) {
      console.log(page);
      this.setPaginacion(page);
      window.scrollTo(0, 0);
    },
  },
  watch: {
    nuevaPagina: function(value) {
      axios
        .post("/vacantes/buscavacante", {
          edo: "Chihuahua",
          cd: "Chihuahua",
          especialidad: null,
          sueldo: this.salario,
          paginacion: 10,
          pagina: this.nuevaPagina,
          candidato: null,
        })
        .then((response) => {
          console.log(response.data);
          this.setVacantes(response.data);
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    nombre(nuevoValor, valorAnterior) {
      console.log("El nombre pasó de ser %s a %s", valorAnterior, nuevoValor);
    },
    /*   misvacantes:{
      deep:true,
      handler: (value) => {
        console.log("Las vacantes son ", value)
        
      }
     } */

    misvacantes: {
      deep: true,
      handler(misvacantes) {
        if (misvacantes[0].total < 11) {
          this.totalPaginas = 1;
        } else if (misvacantes[0].total < 21) {
          this.totalPaginas = 2;
        } else if (misvacantes[0].total < 31) {
          this.totalPaginas = 3;
        } else if (misvacantes[0].total < 41) {
          this.totalPaginas = 4;
        } else if (misvacantes[0].total < 51) {
          this.totalPaginas = 5;
        } else if (misvacantes[0].total < 61) {
          this.totalPaginas = 6;
        } else if (misvacantes[0].total < 71) {
          this.totalPaginas = 7;
        } else if (misvacantes[0].total < 81) {
          this.totalPaginas = 8;
        } else if (misvacantes[0].total < 91) {
          this.totalPaginas = 9;
        } else {
          this.totalPaginas = 0;
        }
        // Fetch data about
        fetch(misvacantes).then((response) => {});
      },
    },
    /*  misvacantes(value){
      posts =  value
    } */
  },

  beforeCreate() {
    axios
      .post("/vacantes/buscavacante", {
        edo: "Chihuahua",
        cd: "Chihuahua",
        especialidad: null,
        sueldo: this.salario,
        paginacion: 10,
        pagina: 1,
        candidato: null,
      })
      .then((response) => {
        //  this.posts = response.data
        this.setVacantes(response.data);
        console.log(response.data);
        window.scrollTo(0, 0);
        //this.nuevaPagina = 1;
      });
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "1",
      })
      .then((response) => {
        this.footer = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  created() {
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "3",
      })
      .then((response) => {
        this.portrait = response.data;
      })
      .catch((error) => {
        console.log(error);
      }),
      this.$store.dispatch("autoLogin");
  },
  beforeMount() {
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "4",
      })
      .then((response) => {
        this.header = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
#autocompletado2 {
  margin-top: 12px;
}

@media only screen and (min-width: 868px) {
}

@media only screen and (max-width: 768px) {
  #milogo {
    width: 140px !important;
  }
  .social-buttons {
    display: none;
  }
  .boton-tr {
    display: none;
  }
  .logo {
    display: none;
  }
  .texto-logo {
    font-size: 20px;
  }
  .usuario {
    margin-left: -120px;
  }
  .autocompletado-estado {
    display: none;
  }
  .autocompletado-tipo {
    display: none;
  }
  .autocompletado-puesto {
    width: 74px;
  }
  ::v-deep .autocompletado-puesto .v-label {
    font-size: 11.5px;
  }
  ::v-deep .autocompletado-puesto .v-icon {
    font-size: 14px;
    margin-left: 26px;
  }
  ::v-deep .autocompletado-ciudad .v-label {
    font-size: 13px;
  }

  ::v-deep .autocompletado-ciudad .v-icon {
    font-size: 14px;
    margin-left: 26px;
  }
  .autocompletado-ciudad .v-label {
    font-size: 12px;
    margin-left: -20px;
  }
  .autocompletado-ciudad {
    width: 70px !important;
  }
  .boton-buscar {
    width: 86px;
  }
}

@media only screen and (min-width: 868px) {
  .columna2 {
    padding: 15px;
    border-radius: 15px;
  }
  .tarjeta {
    padding: 15px;
    margin-top: 20px;
    border-radius: 15px;
    background-color: #ddd;
  }
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 20px;
    font-size: 15px;
    padding: 10px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .columna2 {
    margin-top: 25px;
  }
  .columna1 {
    margin-bottom: 30px;
  }
  .tarjeta {
    background-color: #ddd;
    margin-bottom: 70px;
    margin-top: 10px;
  }
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  .botonPostularme {
    width: 110px;
    font-size: 10px;
    margin-left: -25px !important;
  }
  .v-btn__content {
    width: 20px !important;
    font-size: 8px !important;
  }
}
@media only screen and (min-width: 868px) {
  /* Esta es la pc */
  .vacantePosicion {
    font-size: 34px;
  }
  #cel {
    display: none;
  }
  .vacanteRango {
    font-size: 17px;
  }
  .contenedor {
    height: 200px;
  }
  .columna4 {
    margin-left: 70px;
  }
  .columna3 {
    margin-left: 25px;
  }
  .miimagen {
    border-radius: 40px;
  }
  .imagen {
    border: 4px solid white;
    width: 120px;
    height: 120px;
    border-radius: 40px;
    display: flex;
    margin-left: 20px;
    background-color: #ddd;
  }
}
@media only screen and (max-width: 768px) {
  /* Este es el celular */
  .vacantePosicion {
    font-size: 18px !important;
  }
  .vacanteEmpresa {
    font-size: 14px;
  }
  .vacanteRango {
    font-size: 13px;
  }

  .contenedor {
    height: 330px;
  }

  #pc {
    display: none;
  }
  .area1 {
    width: 200px;
  }
}
@media (min-width: 700px) and (orientation: portrait) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
}

@media (min-width: 700px) and (orientation: landscape) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
}
</style>
