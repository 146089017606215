<template>
  <v-app>
    <v-container fluid>
      <br />
      <div></div>
      <v-row>
        <v-col cols="12" v-for="imagen of header" :key="imagen.folio">
          <a :href="imagen.url" target="_blank">
            <v-img
              href="imagen.url"
              style="border-radius:10px;margin-top:-15px"
              :src="imagen.imagen"
            >
            </v-img>
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="9" style="margin-top:-18px;">
          <div v-if="this.vacantesOperativas.length < 1">
            <p>
              Tu busqueda no tiene resultados, modifique los filtros.
            </p>
          </div>

          <v-card
            v-for="vacante in vacantesOperativas"
            :key="vacante.folio"
           
           style="padding:0px !important; border-radius:20px;"
          >
            <v-container 
              fluid
               v-if="vacante.tipo == 2" 
              style="background-color:#44546A; margin-top:30px;"
              class="contenedor "
            >
              <v-row>
                <v-col
                  cols="3"
                  class="pc text-center"
                  v-if="vacante.anonimo == false"
                >
                  <div v-if="vacante.logo" class="imagen">
                    <v-img class="miimagen" :src="vacante.logo" alt=""></v-img>
                  </div>
                </v-col>
                <v-col
                  cols="5"
                  class="text-center pc"
                  v-if="vacante.anonimo == false"
                >
                  <p
                    class="vacantePosicion"
                    style="color:white; text-align:center;"
                  >
                    {{ vacante.posicion }}
                  </p>

                  <h3 style="color:#FFC000; text-align:center; ">
                    {{ vacante.Nom_empresa }}
                  </h3>
                 
                </v-col>
                <v-col cols="8" class="text-center pc" v-if="vacante.anonimo">
                  <p
                    class="vacantePosicion"
                    style="color:white; text-align:center;"
                  >
                    {{ vacante.posicion }}

                    <br />
                  </p>
                  <p style="color:#FFC000; font-size:20px;">
                    <strong>Empresa Líder</strong>
                  </p>
                  
                </v-col>
                <v-col cols="4" class="pc" v-if="vacante.anonimo == false">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:#2afed6; padding:10px;text-align:center; border-radius:12px;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 0">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 1">
                          Mensual</span
                        >
                        <br />

                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>

                </v-col>
                <v-col cols="4" class="pc" v-if="vacante.anonimo">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:#2afed6; padding:10px;text-align:center; border-radius:12px;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 0">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 1">
                          Mensual</span
                        >
                        <br />

                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>
                 
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5" class="cel" v-if="vacante.anonimo == false">
                  <div v-if="vacante.logo" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="vacante.logo"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="12" class="cel" v-if="vacante.anonimo == true">
                  <div class="text-center">
                    <p
                      class="vacantePosicion"
                      style="color:white; text-align:center; "
                    >
                      {{ vacante.posicion }}
                    </p>
                    <span
                      v-if="vacante.anonimo == true"
                      style="color:#FFC000;font-size:14px;text-align:center;"
                    >
                      <strong>Empresa Líder </strong></span
                    >
                  </div>
                </v-col>
                <v-col cols="7" id="cel">
                  <div v-if="vacante.anonimo == false" class="text-center">
                    <p
                      class="vacantePosicion"
                      style="color:white; text-align:center; "
                    >
                      {{ vacante.posicion }}
                    </p>

                    <p
                      v-if="vacante.anonimo == false"
                      style="color: rgb(255, 192, 0);
text-align: center;
"
                    >
                      {{ vacante.Nom_empresa }}
                    </p>
                    
                  </div>
                </v-col>

                <v-col cols="12" id="cel">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:#2afed6; padding:10px; border-radius:12px; text-align:center;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 0">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 1">
                          Mensual</span
                        ><br />
                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}

                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>

                </v-col>
              </v-row>
              <v-row  id="cel">
                <v-col cols="12">
                  <p class="text-center" style="color:white;font-size:17px;">
                    <strong>Da click para contactarte</strong>
                  </p>

                  <div class="text-center">
                    <v-col
                      cols="12"
                      v-if="vacante.contacto"
                    >
                      <v-btn
                        @click="estadisticaCel(vacante.empresa, vacante.folio)"
                        color="#004AAD"
                        style="background-color:#004AAD;color:white;font-size:14px;margin-top:-20px;border-radius:17px;"
                        target="_blank"
                        v-if="vacante.contacto"
                        :href="'tel:' + vacante.contacto"
                        ><v-icon style="padding:12px;margin-left:-7px;">
                          mdi-phone
                        </v-icon>
                        Llamar
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="12"
                      style="margin-top:-10px;"
                     
                    >
                      <v-btn
                        color="#20B038"
                        @click="estadisticaW(vacante.empresa, vacante.folio)"
                        v-if="vacante.whatsapp"
                        style="color:white;font-size:14px;border-radius:17px;"
                        target="_blank"
                        :href="
                          'https://api.whatsapp.com/send/?phone=52' +
                            vacante.whatsapp +
                             '&text=¡Hola!%20vi%20la%20vacante%20' + vacante.posicion +  '%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20más%20información'
                        "
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-whatsapp
                        </v-icon>
                        Enviar WhatsApp
                      </v-btn>
                        <div>
                      <v-btn
                        @click="
                          estadisticaCorreo(vacante.folioempresa, vacante.folio)
                        "
                        v-if="vacante.correo"
                        style="color:white;font-size:14px; margin-top:14px; border-radius:17px;"
                        target="_blank"
                        :href="
                          'mailto:' +
                            vacante.correo +
                            '?Subject=CV%20enviado%20desde%20VACANTA' +
                            '&body=Escribe%20aqui%20el%20nombre%20del%20puesto%20para%20el%20que%20estas%20enviando%20tu%20Cv'
                        "
                        color="#FFC000"
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-email
                        </v-icon>
                        Enviar CV
                      </v-btn>
                    </div>
                    </v-col>
                      
                  </div>
                </v-col>

              
                
                
              </v-row>
              
               
                <v-row>
                  
                  <v-col cols="4" class="pc">
                   <div class="text-center" v-if="vacante.contacto">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialog"
                        persistent
                      
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                               @click="
                            prueba(
                              vacante.folioempresa,
                              vacante.folio,
                              vacante
                            )
                          "
                            color="#004AAD"
                            style="background-color:#004AAD;color:white;font-size:14px;margin-top:-0px;border-radius:17px;"
                            v-if="vacante.contacto"
                            ><v-icon style="padding:12px;margin-left:-7px;">
                              mdi-phone
                            </v-icon>
                            Llamar
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            Teléfono
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ object }}</v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialog = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>


                </v-col>
                <v-col cols="4" class="pc">
                  
                    <div class="text-center" v-if="vacante.whatsapp">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialogW"
                      
                        persistent
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="#20B038"
                               @click="
                            prueba2(
                              vacante.folioempresa,
                              vacante.folio,
                              vacante
                            )
                          "
                            style="color:white;font-size:14px;border-radius:17px;"
                            target="_blank"
                          >
                            <v-icon style="padding:12px;margin-left:-7px;">
                              mdi-whatsapp
                            </v-icon>
                            Enviar WhatsApp
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            WhatsApp
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ object }}</v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialogW = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                </v-col>
                <v-col cols="4" class="pc">
                      <div class="text-center">

                    <v-dialog
                      :retain-focus="false"
                      v-model="dialogoC"
                       
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="#FFC000"
                            @click="
                            prueba3(
                              vacante.folioempresa,
                              vacante.folio,
                              vacante
                            )
                          "
                          style="color:white;font-size:14px;margin-top:3px;border-radius:17px;"
                          target="_blank"
                        >
                          <v-icon style="padding:12px;margin-left:-7px;">
                            mdi-email
                          </v-icon>
                          Enviar CV
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Correo
                        </v-card-title>
                        <v-card-text
                          style="font-size:18px; text-align:center;"
                          >{{ objeto }}</v-card-text
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            style="color:black;"
                            @click="dialogoC = false"
                          >
                            Salir
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                      </div>
                    
                    


                </v-col>
                

                <v-row>
                  <v-col md="4" class="text-center">
                    <br />
                    <p style="color:#2afed6">
                      <strong> Lugar</strong>
                    </p>
                    <p style="color:white; border-radius:6px; ">
                      <strong
                        > 
                         {{ vacante.ciudad }}, {{ vacante.estado }}
                      </strong>
                    </p>
                  </v-col>
                  <v-col md="4" xs="6" class="text-center">
                    <br />
                    <p  style="color:#2afed6"><strong> Fecha de publicación</strong></p>

                    <p style="color:white; border-radius:6px;">
                      <strong>
                        {{ moment(vacante.fechacreacion).format("LL") }}
                      </strong>
                    </p>
                  </v-col>
                  <v-col md="4" xs="12" class="text-center">
                    <br />
                    <p  style="color:#2afed6"><strong> Área </strong></p>
                    <p style="color:white; border-radius:6px;">
                      <strong>
                        {{ vacante.area_desc }}
                      </strong>
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-center">
                       <v-btn  large style="margin-top:-10px; text-decoration:underline;font-size:20px;" dark text v-on:click="postularme(vacante.folio)" >  VER MÁS DETALLES
                        <v-icon style="font-size:20px;margin-top:0px;"
                          >mdi-arrow-down</v-icon
                        ></v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-row>
                
            </v-container>

            <v-container
            dark
               v-if="vacante.tipo == 1" 
              fluid
              style="background-color:#EFEFEF;margin-top:20px;"
              class="contenedor "
            >
              <v-row>
                <v-col
                  cols="3"
                  class="pc text-center"
                  v-if="vacante.anonimo == false"
                >
                  <div v-if="vacante.logo" class="imagen">
                    <v-img class="miimagen" :src="vacante.logo" alt=""></v-img>
                  </div>
                </v-col>
                <v-col
                  cols="5"
                  class="text-center pc"
                  v-if="vacante.anonimo == false"
                >
                  <p
                    class="vacantePosicion"
                    style="color:black; text-align:center;"
                  >
                    {{ vacante.posicion }}
                  </p>

                  <h3 style="color:black; text-align:center; ">
                    {{ vacante.Nom_empresa }}
                  </h3>
                 
                </v-col>
                <v-col cols="6" class="text-center pc" v-if="vacante.anonimo">
                  <p
                    class="vacantePosicion"
                    style="color:black; text-align:center;"
                  >
                    {{ vacante.posicion }}

                    <br />
                  </p>
                  <p style="color:black; font-size:20px;">
                   <strong>
                     Empresa Líder
                   </strong>
                  </p>
                  
                </v-col>
                <v-col cols="4" class="pc" v-if="vacante.anonimo == false">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:white;padding:10px;text-align:center; border-radius:12px;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 0">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 1">
                          Mensual</span
                        >
                        <br />

                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>

                </v-col>
                <v-col cols="6" class="pc" v-if="vacante.anonimo">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:white; padding:10px;text-align:center; border-radius:12px;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 0">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 1">
                          Mensual</span
                        >
                        <br />

                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>
                 
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="5" class="cel" v-if="vacante.anonimo == false">
                  <div v-if="vacante.logo" class="imagen">
                    <v-img
                      class="miimagen img-fluid"
                      :src="vacante.logo"
                      alt=""
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="12" class="cel" v-if="vacante.anonimo == true">
                  <div class="text-center">
                    <p
                      class="vacantePosicion"
                      style="color:black; text-align:center; "
                    >
                      {{ vacante.posicion }}
                    </p>
                    <span
                      v-if="vacante.anonimo == true"
                      style="color:black;font-size:14px;text-align:center;"
                    >
                      <strong>Empresa Líder </strong></span
                    >
                  </div>
                </v-col>
                <v-col cols="7" id="cel">
                  <div v-if="vacante.anonimo == false" class="text-center">
                    <p
                      class="vacantePosicion"
                      style="color:black; text-align:center; "
                    >
                      {{ vacante.posicion }}
                    </p>

                    <p
                      v-if="vacante.anonimo == false"
                      style="color: black;
text-align: center;
"
                    >
                      {{ vacante.Nom_empresa }}
                    </p>
                    
                  </div>
                </v-col>

                <v-col cols="12" id="cel">
                  <div v-if="vacante.sueldo_raninf > 0">
                    <p
                      class="vacanteRango"
                      style="background-color:white; padding:10px; border-radius:12px; text-align:center;"
                    >
                      <strong>
                        Rango de Sueldo
                        <span v-if="vacante.base_sueldo == 0">Semanal</span>
                        <span v-else-if="vacante.base_sueldo == 1">
                          Mensual</span
                        ><br />
                        {{
                          vacante.sueldo_raninf.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}

                        -
                        {{
                          vacante.sueldo_ransup.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                      </strong>
                    </p>
                  </div>

                </v-col>
              </v-row>
              <v-row  id="cel">
                <v-col cols="12">
                  <p class="text-center" style="color:black;font-size:17px;">
                    <strong>Da click para contactarte</strong>
                  </p>

                  <div class="text-center">
                    <v-col
                      cols="12"
                      v-if="vacante.contacto"
                    >
                      <v-btn
                        @click="estadisticaCel(vacante.empresa, vacante.folio)"
                        color="#004AAD"
                        style="background-color:#A8B8CD;color:white;font-size:14px;margin-top:-20px;border-radius:17px;"
                        target="_blank"
                        v-if="vacante.contacto"
                        :href="'tel:' + vacante.contacto"
                        ><v-icon style="padding:12px;margin-left:-7px;">
                          mdi-phone
                        </v-icon>
                        Llamar
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="12"
                      style="margin-top:-10px;"
                      v-if="vacante.whatsapp"
                    >
                      <v-btn
                        color="#8CDB6B"
                        @click="estadisticaW(vacante.empresa, vacante.folio)"
                        v-if="vacante.whatsapp"
                        style="color:white;font-size:14px;border-radius:17px;"
                        target="_blank"
                        :href="
                          'https://api.whatsapp.com/send/?phone=52' +
                            vacante.whatsapp +
                             '&text=¡Hola!%20vi%20la%20vacante%20' + vacante.posicion +  '%20en%20la%20página%20de%20VACANTA,%20me%20pudiera%20proporcionar%20más%20información'
                        "
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-whatsapp
                        </v-icon>
                        Enviar WhatsApp
                      </v-btn>

                    </v-col>
                    <v-col cols="12">
                                              <div>
                      <v-btn
                        @click="
                          estadisticaCorreo(vacante.folioempresa, vacante.folio)
                        "
                        v-if="vacante.correo"
                        style="color:white;font-size:14px; margin-top:; border-radius:17px;"
                        target="_blank"
                        :href="
                          'mailto:' +
                            vacante.correo +
                            '?Subject=CV%20enviado%20desde%20VACANTA' +
                            '&body=Escribe%20aqui%20el%20nombre%20del%20puesto%20para%20el%20que%20estas%20enviando%20tu%20Cv'
                        "
                        color="#EFCC63"
                      >
                        <v-icon style="padding:12px;margin-left:-7px;">
                          mdi-email
                        </v-icon>
                        Enviar CV
                      </v-btn>
                    </div> 

                    </v-col>
                       
                  </div>
                </v-col>

              
                
                
              </v-row>
              
                 <v-row>
                  
                  <v-col cols="4" class="pc">
                   <div class="text-center" v-if="vacante.contacto">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialog"
                        persistent
                      
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                               @click="
                            prueba(
                              vacante.folioempresa,
                              vacante.folio,
                              vacante
                            )
                          "
                            color="#A8B8CD"
                            style="background-color:#A8B8CD;color:white;font-size:14px;margin-top:-0px;border-radius:17px;"
                            v-if="vacante.contacto"
                            ><v-icon style="padding:12px;margin-left:-7px;">
                              mdi-phone
                            </v-icon>
                            Llamar
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            Teléfono
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ object }}</v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialog = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>


                </v-col>
                <v-col cols="4" class="pc">
                  
                    <div class="text-center" v-if="vacante.whatsapp">
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialogW"
                      
                        persistent
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="#8CDB6B"
                               @click="
                            prueba2(
                              vacante.folioempresa,
                              vacante.folio,
                              vacante
                            )
                          "
                            style="color:white;font-size:14px;border-radius:17px;"
                            target="_blank"
                          >
                            <v-icon style="padding:12px;margin-left:-7px;">
                              mdi-whatsapp
                            </v-icon>
                            Enviar WhatsApp
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            WhatsApp
                          </v-card-title>
                          <v-card-text
                            style="font-size:25px; text-align:center;"
                            >{{ object }}</v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              style="color:black;"
                              @click="dialogW = false"
                            >
                              Salir
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                </v-col>
                <v-col cols="4" class="pc">
                      <div class="text-center">

                    <v-dialog
                      :retain-focus="false"
                      v-model="dialogoC"
                       
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="#EFCC63"
                            @click="
                            prueba3(
                              vacante.folioempresa,
                              vacante.folio,
                              vacante
                            )
                          "
                          style="color:white;font-size:14px;margin-top:3px;border-radius:17px;"
                          target="_blank"
                        >
                          <v-icon style="padding:12px;margin-left:-7px;">
                            mdi-email
                          </v-icon>
                          Enviar CV
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Correo
                        </v-card-title>
                        <v-card-text
                          style="font-size:18px; text-align:center;"
                          >{{ objeto }}</v-card-text
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            style="color:black;"
                            @click="dialogoC = false"
                          >
                            Salir
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                      </div>
                    
                    


                </v-col>
             
                

                <v-row>
                  <v-col md="4" class="text-center">
                    <br />
                    <p style="black">
                      <strong> Lugar</strong>
                    </p>
                    <p style="color:black; border-radius:6px; ">
                      <strong
                        >
       {{ vacante.ciudad }}, {{ vacante.estado }}                      </strong>
                    </p>
                  </v-col>
                  <v-col md="4" xs="6" class="text-center">
                    <br />
                    <p  style="black"><strong> Fecha de publicación</strong></p>

                    <p style="color:black; border-radius:6px;">
                      <strong>
                        {{ moment(vacante.fechacreacion).format("LL") }}
                      </strong>
                    </p>
                  </v-col>
                  <v-col md="4" xs="12" class="text-center">
                    <br />
                    <p  style="black"><strong> Área </strong></p>
                    <p style="color:black; border-radius:6px;">
                      <strong>
                        {{ vacante.area_desc }}
                      </strong>
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-center">
                       <v-btn  large style="margin-top:-25px; text-decoration:underline;font-size:20px;" light text v-on:click="postularme(vacante.folio)" >  VER MÁS DETALLES
                        <v-icon style="font-size:20px;margin-top:0px;"
                          >mdi-arrow-down</v-icon
                        ></v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-row>
            </v-container>
          
          </v-card>
        </v-col>

        <v-col
          v-for="imagen of portrait"
          :key="imagen.folio"
          class="columna1"
          sm="12"
          xs="12"
          md="3"
        >
          <a :href="imagen.url" target="_blank">
            <v-img
              style="border-radius:10px;"
              :src="imagen.imagen"
              :href="imagen.url"
            >
            </v-img>
          </a>
        </v-col>
        <v-col cols="12">
          <div class="text-center" @click="paginacion(page)">
            <v-pagination
              v-model="page"
              :length="totalPaginas"
              circle
              dark
            ></v-pagination>
          </div>
        </v-col>

        <v-col v-for="imagen of footer" :key="imagen.folio" cols="12">
          <a :href="imagen.url" target="_blank">
            <v-img
              style="border-radius:10px;"
              :src="imagen.imagen"
              :href="imagen.url"
            >
            </v-img>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState, mapActions } from "vuex";
var moment = require("moment");
moment.locale("es-mx");

export default {
  data() {
    return {
      moment: moment,
      posts: [],
            objeto: null,
      dialogoVacante: false,
      min: 0,
      max: 45000,
      range: [5000, 25000],
      header: [],
      footer: [],
      portrait: [],
      eltelefono: "",
      whatsapp: "",
      token:
        "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
      dialog: false,
       dialogoC: false,
      dialogW: false,
      dialogo: false,
      dialogoW: false,
      var1: "hola",
      nombre: "",
      page: 1,
      totalPaginas: null,
      lasvacantes: [],
      object: null,
      textoVacante: "",
      nivelVacante: "",
    };
  },
  computed: {
    id() {
      return this.$store.state.id;
    },
    logueado() {
      return this.$store.state.token;
    },
    ...mapState([
      "elestado",
      "laciudad",
      "puesto",
      "rangoInf",
      "rangoSup",
      "Urlheader",
      "VacanteFolio",
      "miempresa",
      "id",
      "vacantesOperativas",
      "totalVacantes",
       "nuevaPagina2",
              "minuevaArea2",
       "minuevaEmpresa2"
    ]),
  },
  methods: {
     prueba3(a, b, c) {
      console.log(a, b, c);
      this.dialogoC = true;
      this.objeto = c.correo;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "2",
            empresa: a,
            folio: b,
            donde: "1",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
     postularme(id){
        this.$router.push('/VacanteOperativa/' + id)

      },
    probando(vacante) {
      this.textoVacante = vacante.descripcion;
      this.nivelVacante = vacante.desc_corta;
    },
    prueba(a, b, c) {
      console.log(a, b, c);
      this.dialog = true;
      this.object = c.contacto;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "2",
            empresa: a,
            folio: b,
            donde: "2",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prueba2(a, b, c) {
      console.log(a, b, c);
      this.dialogW = true;
      this.object = c.whatsapp;
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "2",
            empresa: a,
            folio: b,
            donde: "3",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaW(emrpresa, folio) {
      console.log("2", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "2",
            empresa: emrpresa,
            folio: folio,
            donde: "3",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    estadisticaCel(emrpresa, folio) {
      console.log("2", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "2",
            empresa: emrpresa,
            folio: folio,
            donde: "2",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
       estadisticaCorreo(emrpresa, folio) {
      console.log("2", emrpresa, folio, "3");
      axios
        .post(
          "/empresas/estadistica_contacto",
          {
            area: "2",
            empresa: emrpresa,
            folio: folio,
            donde: "1",
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    ...mapMutations([
      "setVacanteFolio",
      "setPaginacion2",
      "setVacantesOperativas",
      "setTotalVacantesOperativas",
    ]),
    ...mapActions(["irVacante"]),
    buscaVacante(value) {
      this.posts = value;
    },
   paginacion(page) {
       console.log(page)
      this.setPaginacion2(page);
           window.scrollTo(0, 0);
           axios
        .post("/vacantes/buscavacanteOperativa", {
          edo: null,
          cd: null,
          area: null,
          sueldo: null,
          paginacion: 15,
          pagina: page,
          candidato: null,
          empresa: null,
        })
        .then((response) => {
         this.setVacantesOperativas(response.data)
        });

    }, 
  },
  watch: {

    nuevaPagina2: function(value) {
      this.page= this.nuevaPagina2;

    },

    vacantesOperativas: {
      deep: true,
      handler(vacantesOperativas) {
        if (vacantesOperativas[0].total <= 15) {
          this.totalPaginas = 1;
        } else if (vacantesOperativas[0].total <= 30) {
          this.totalPaginas = 2;
        } else if (vacantesOperativas[0].total <= 45) {
          this.totalPaginas = 3;
        } else if (vacantesOperativas[0].total <= 60) {
          this.totalPaginas = 4;
        } else if (vacantesOperativas[0].total <= 75) {
          this.totalPaginas = 5;
        } else if (vacantesOperativas[0].total <= 90) {
          this.totalPaginas = 6;
        } else if (vacantesOperativas[0].total <= 105) {
          this.totalPaginas = 7;
        } else if (vacantesOperativas[0].total <= 120) {
          this.totalPaginas = 8;
        } else if (vacantesOperativas[0].total <= 135) {
          this.totalPaginas = 9;
        } else {
          this.totalPaginas = 0;
        }
        // Fetch data about
        fetch(vacantesOperativas).then((response) => {});
      },
    },

    /*  misvacantes(value){
      posts =  value
    } */
  },

  
beforeMount() {
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "4",
      })
      .then((response) => {
        this.header = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
       console.log(this.nuevaPagina2, this.minuevaEmpresa2, this.minuevaArea2);
    if (this.minuevaEmpresa2 && this.minuevaArea2) {
      console.log(this.minuevaEmpresa2, this.minuevaArea2);
      axios
        .post("/vacantes/buscavacanteOperativa", {
          edo: null,
          cd: null,
          area: this.minuevaArea2.folio,
          sueldo: null,
          paginacion: 15,
          pagina: 1,
          candidato: null,
          empresa: this.minuevaEmpresa2.folio,
        })
        .then((response) => {
         
          this.setVacantesOperativas(response.data)
        });
    } else if (this.minuevaEmpresa2) {
      console.log(this.minuevaEmpresa);
        this.page = 1
      axios
        .post("/vacantes/buscavacanteOperativa", {
          edo: null,
          cd: null,
          area: null,
          sueldo: null,
          paginacion: 15,
          pagina: 1,
          candidato: null,
          empresa: this.minuevaEmpresa.folio,
        })
        .then((response) => {
          //  this.posts = response.data
        
         this.setVacantesOperativas(response.data)
        });
    } else if (this.minuevaArea2) {
      console.log(this.minuevaArea2.folio);
        this.page= 1
      axios
        .post("/vacantes/buscavacanteOperativa", {
          edo: null,
          cd: null,
          area: this.minuevaArea2.folio,
          sueldo: null,
          paginacion: 15,
          pagina: 1,
          candidato: null,
          empresa: null,
        })
        .then((response) => {
          this.setVacantesOperativas(response.data)
        
        });
    } else if (this.nuevaPagina2 > 1) {
      this.page = this.nuevaPagina2;
      axios
        .post("/vacantes/buscavacanteOperativa", {
          edo: null,
          cd: null,
          area: null,
          sueldo: null,
          paginacion: 15,
          pagina: this.nuevaPagina2,
          candidato: null,
          empresa: null,
        })
        .then((response) => {
         this.setVacantesOperativas(response.data)
        });
    } else {
      axios
        .post("/vacantes/buscavacanteOperativa", {
          edo: null,
          cd: null,
          area: null,
          sueldo: null,
          paginacion: 15,
          pagina: 1,
          candidato: null,
          empresa: null,
        })
        .then((response) => {
         this.setVacantesOperativas(response.data)
        });
    }
    
    window.scrollTo(0, 0);
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "4",
      })
      .then((response) => {
        this.header = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  beforeCreate() {
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "1",
      })
      .then((response) => {
        this.footer = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  created() {
    axios
      .post("/publicidad", {
        cuantos: "1",
        tipo: "3",
      })
      .then((response) => {
        this.portrait = response.data;
      })
      .catch((error) => {
        console.log(error);
      }),
      this.$store.dispatch("autoLogin");
  }



};
</script>

<style scoped>
#autocompletado2 {
  margin-top: 12px;
}

@media only screen and (min-width: 868px) {
}

@media only screen and (max-width: 768px) {
  #milogo {
    width: 140px !important;
  }
  .social-buttons {
    display: none;
  }
  .boton-tr {
    display: none;
  }
  .logo {
    display: none;
  }
  .texto-logo {
    font-size: 20px;
  }
  .usuario {
    margin-left: -120px;
  }
  .autocompletado-estado {
    display: none;
  }
  .autocompletado-tipo {
    display: none;
  }
  .autocompletado-puesto {
    width: 74px;
  }
  ::v-deep .autocompletado-puesto .v-label {
    font-size: 11.5px;
  }
  ::v-deep .autocompletado-puesto .v-icon {
    font-size: 14px;
    margin-left: 26px;
  }
  ::v-deep .autocompletado-ciudad .v-label {
    font-size: 13px;
  }

  ::v-deep .autocompletado-ciudad .v-icon {
    font-size: 14px;
    margin-left: 26px;
  }
  .autocompletado-ciudad .v-label {
    font-size: 12px;
    margin-left: -20px;
  }
  .autocompletado-ciudad {
    width: 70px !important;
  }
  .boton-buscar {
    width: 86px;
  }
}

@media only screen and (min-width: 868px) {
  .columna2 {
    padding: 15px;
    border-radius: 15px;
  }
  .tarjeta {
    padding: 15px;
    margin-top: 20px;
    border-radius: 15px;
    background-color: #44546A !important;
  }
   .tarjeta2 {
    padding: 15px;
    margin-top: 20px;
    border-radius: 15px;
    background-color:white !important;
  }
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 20px;
    font-size: 15px;
    padding: 10px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .columna2 {
    margin-top: 25px;
  }
  .columna1 {
    margin-bottom: 30px;
  }
  .tarjeta {
    background-color: #44546A  !important;
    margin-bottom: 70px;
    margin-top: 10px;
  }
  .tarjeta2 {
    background-color: white  !important;
    margin-bottom: 70px;
    margin-top: 10px;
  }
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  .botonPostularme {
    width: 110px;
    font-size: 10px;
    margin-left: -25px !important;
  }
  .v-btn__content {
    width: 20px !important;
    font-size: 8px !important;
  }
}
@media only screen and (min-width: 868px) {
  /* Esta es la pc */
  .vacantePosicion {
    font-size: 34px;
  }
  #cel {
    display: none;
  }
  .vacanteRango {
    font-size: 17px;
  }
  .contenedor {
    height: 100%;
  }
  .columna4 {
    margin-left: 70px;
  }
  .columna3 {
    margin-left: 25px;
  }
  .miimagen {
    border-radius: 40px;
  }
  .imagen {
    border: 4px solid white;
    width: 120px;
    height: 120px;
    border-radius: 40px;
    display: flex;
    margin-left: 20px;
    background-color: #ddd;
  }
}
@media only screen and (max-width: 768px) {
  /* Este es el celular */
  .imagen {
    border: 4px solid white;
    width: 100px;
    height: 100px;
    border-radius: 40px;
    display: flex;
    margin-left: 5px;
    background-color: #ddd;
  }
  .vacantePosicion {
    font-size: 20px !important;
  }
  .vacanteEmpresa {
    font-size: 14px;
  }
  .vacanteRango {
    font-size: 13px;
  }

  .contenedor {
    height: 100%;
  }

  #pc {
    display: none;
  }
  .area1 {
    width: 200px;
  }
}
@media (min-width: 700px) and (orientation: portrait) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
}

@media (min-width: 700px) and (orientation: landscape) {
  .botonAviso {
    background-color: black;
    color: white;
    border-radius: 14px;
    font-size: 14px;
    padding: 4px;
  }
  #cel {
    display: none;
  }
}
</style>
