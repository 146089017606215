<template>
  <v-layout style="margin-top:10px; " align-center justify-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
      <v-card style="background-color: #e0e0e0;">
        <v-toolbar dark color="#14a6a6">
          <v-container>
            <v-toolbar-title class="text-center">
              <h3>Recuperación de Contraseña</h3>
            </v-toolbar-title>
          </v-container>
        </v-toolbar>
        <v-card-text>
          <v-text-field
          append-icon="mdi-at"
            v-model="correo"
            autofocus
            color="primary"
            outlined
            label="Correo"
            required
          >
          </v-text-field>
          <v-text-field
       
            name="password"
            label="Ingresa tu nueva contraseña"
            :append-icon="value ? 'visibility' : 'visibility_off'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            v-model="confirmacion"
            :rules="passwordRules"
            color="primary"
            outlined
          ></v-text-field>
          
          
          <v-text-field
            v-model="digitos"
            color="primary"
            outlined
            label="5 Digitos enviados a tu Correo"
            required
          >
          </v-text-field>
          <v-container>
            <v-row>
              <v-col>
                <router-link :to="{ name: 'login' }">
                  <a href="">Regresar</a>
                </router-link>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <div class="text-center">
          <v-card-actions class="px-3 pb-3">
            <v-flex text-cs-right>
              <v-btn @click="cambiar()" color="#14a6a6">Enviar</v-btn>
            </v-flex>
          </v-card-actions>
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
        value: String,
      digitos: "",
      contrasena: "",
      confirmacion: "",
      correo: "",
      errorM: null,
      passwordRules: [(v) => v.length >= 6 || "Minimo 6 Caracteres"],
    };
  },
  methods: {
    cambiar() {
      console.log(
        "correo:",
        this.correo,
        "numeros:",
        this.digitos,
        "contraseña:",
        this.confirmacion
      );
      axios
        .post("/candidatos/confirmacontrasenacandidato", {
          correo: this.correo,
          codigo: this.digitos,
          password: this.confirmacion,
        })
        .then((response) => {
          console.log(response.data);
        })
        .then((data) => {
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          console.log(error);
          this.errorM = null;
          if (error.response.status == 401) {
            this.errorM = "Las credenciales son incorrectas";
          } else if (error.response.status == 400) {
            this.errorM = "El usuario no existe favor de registrarse";
          } else {
            this.errorM = "Ocurrio un error con el servidor";
          }
        });
    },
  },
};
</script>
<style scoped>
.v-application a {
  color: black !important ;
}
</style>
