<template>
  <v-container fluid>
    <v-alert v-if="this.respuesta" class="primary" style="color:black;">
      {{ this.respuesta.msg }}
    </v-alert>
    <v-alert v-if="this.mirespuesta" class="primary" style="color:black;">
      Tu candidato se a seleccionado correctamente
    </v-alert>
    <v-row>
      <v-flex md12 xs12>
        <v-col>
        <v-card style="padding:18px;">
          <v-row>
            <v-col cols="6"> <strong>Fecha: {{moment(fecha).format('LL')}}</strong></v-col>
            <v-col cols="6">
               <v-dialog v-model="dialogos" persistent max-width="570">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            style="color:black;"
                          >
                            Desactivar Vacante
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h6">
                             ¿Estas seguro que deseas desactivar la vacante?
                          </v-card-title>
                          <v-card-text
                            >Al desactivar la vacante dejara de aparecer en el listado de
vacantes.
                                      </v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            
                            <div class="text-center">
                              <v-btn
                              color="primary darken-1"
                              text
                              @click="dialogos = false"
                            >
                              Cancelar
                            </v-btn>
                           <v-dialog v-model="dialogonuevo" persistent max-width="570">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            style="color:black;"
                          >
                            Aceptar
                          </v-btn>
                        </template>
                        <v-card>
                            <v-alert
                icon="mdi-alert"
                color="primary"
                v-if="errors.length"
              >
                <b>Por favor selecciona una opción:</b>
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
                          <v-card-title class="text-h6">
                            La vacante ha sido desactivada, por favor selecciona la
opción por la cual fue desactivada:

                          </v-card-title>
                          <v-card-text
                            >

                            <v-radio-group
                      v-model="motivo"
                      
                     style="margin-left:10px"
                      @change="seleccionaMotivo"
                    >
                      <v-radio label="La vacante ya fue cubierta"></v-radio>
                      <v-radio label="Otros motivos."></v-radio>
                    </v-radio-group>
                                      </v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            
                            <div class="text-center">
                              
                            <v-btn
                              color="primary darken-1"
                              text
                              @click="prueba()"
                            >
                              Aceptar
                            </v-btn>

                            </div>
                            
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                            </div>
                            
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
              <!-- <v-btn id="pc" @click="prueba()" color="primary" style="color:black">
                Desactivar Vacante<v-icon>mdi-cancel</v-icon></v-btn>
               <v-btn small id="cel" @click="prueba()" color="primary" style="color:black; margin-left:-25px;">
                Desactivar Vacante<v-icon>mdi-cancel</v-icon></v-btn> -->
              
              </v-col
            >
            <v-col cols="12">
              <h2>Información del puesto</h2>
            </v-col>
            <v-col cols="6">
              <h4>Posición</h4>
              <p>{{ posicion }}</p>

              <h4>Descripción del puesto</h4>
              <textarea disabled wrap="soft"  v-model="descripcion" name="" id="" cols="30" rows="4"></textarea>
              
            </v-col>

            <v-col cols="6">
              <h4>Area del Puesto</h4>
              <p>{{ this.nombreSector }}</p>

              <h4>Carreras</h4>
              <textarea disabled wrap="soft"  v-model="descripcionCorta" name="" id="" cols="30" rows="4"></textarea>
              
            </v-col>

            <v-col cols="12">
              <v-divider> </v-divider>
              <br />
              <h2>Prestaciones y tipo de contratación</h2>
            </v-col>

            <v-flex md4 xs6>

           
            <v-col >
              <h4>Sueldo (rango inf)</h4>
              <p>
                {{
                  sueldo_inf.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
              </p>

              <h4>Modalidad</h4>
              <p>{{ area }}</p>
            </v-col>
            </v-flex>
            <v-flex md4 xs6>

           
            <v-col >
              <h4>Sueldo (rango sup)</h4>
              <p>
                {{
                  sueldo_sup.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
              </p>

              <h4>Contratación</h4>
              <p>{{ contratacion }}</p>
            </v-col>
             </v-flex>
            <v-col cols="4">
              <h4>Jornada</h4>
              <p>{{ jornada }}</p>
            </v-col>
            <v-col cols="12">
              <v-divider> </v-divider>
              <br />
            </v-col>
            <v-col cols="6">
              <h4>Localidad</h4>
              <p>{{ estado }}, {{ ciudad }}</p>
            </v-col>
          
            <v-col cols="6">
              <h4>Años de experiencia</h4>
              <p>{{ experiencia }} años.</p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      </v-flex>
        <!-- <v-flex md4 xs12>
        <v-col>
        <v-card style="padding:18px;">
          <h2>Candidatos</h2>
          <p v-if="cubierta" style="color:red">Esta vacante ha sido cubierta</p>
          <p v-else style="color:#2afed6">Esta vacante no ha sido cubierta</p>
          <v-card
            v-for="post in posts"
            :key="post.folio"
            class="text-center"
            style="padding-bottom:13px; margin-bottom:8px;"
          >
            <v-icon v-if="post.preferencial" style="position: right;"
              >mdi-star</v-icon
            >
            <a color="black" style="margin-left:8px">{{ post.nombre }}</a>
            <br />

            <v-dialog  :retain-focus="false" persistent v-model="dialog" width="900px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  @click="infoVacante(post)"
                  style="color:black;"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Ver Datos
                  <v-icon>mdi-account-circle</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 dark">
                  Datos del Candidato 
                </v-card-title>
                <v-container>
                  <v-col> </v-col>
                  <div class="text-center">
                    <v-row>
                      <v-col cols="6">
                        <div class="text-center">
                          <v-img
                            class="miimagen"
                            :src="candidato.foto"
                            alt=""
                          ></v-img>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="text-center">
                          
                          <br />
                          <a
                          
                            target="_blank"
                            style="color:#2afed6"
                            :href="candidato.curriculum"
                            >Curriculum Vitae</a
                          >
                          <br>
                          <br>
                          
                        <p class="trabajando" >
                         <strong> Estatus Laboral </strong> <br>
                          {{candidato.status}}
                          
                          <br>
                        </p>
                        
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <h3>
                            {{ candidato.nombre }} {{ candidato.apellido }}
                          </h3>
                       
<br>
                        <p>
                           <strong>Edad</strong> <br>
                          {{ moment().diff(candidato.nacimiento, "years", false) }} años 
                        </p>
                        
                        <p>
                          <strong>Vive en</strong> <br>
                     
                          {{ candidato.estado }},
                          {{ candidato.ciudad }}
                        </p>

                       
                      </v-col>

                      <v-col cols="6">
                        
                        <p><strong>Salario Esperado</strong>
                           <br>
                          <span v-if="candidato.OP_salario">
                              {{candidato.OP_salario.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}}
                          </span> -   
                          <span v-if="candidato.op_salario_hi">
                            {{candidato.op_salario_hi.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}}
                          </span>
                       
                        </p>
                        
                        <p>
                          <strong>Teléfono</strong> <br> {{ candidato.celular }}
                        </p>
                         <p>
                           <strong>Correo</strong> <br> {{candidato.correo}}
                         </p>

                      
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-card
                         
                          class="tarjeta-trabajos"
                        >
                          <h2>Trabajos</h2>
                          <br />
                          <div v-for="value in trabajos" :key="value.empresa">
                            <h4>
                              {{ value.empresa.toUpperCase() }} -
                              {{ value.puesto.toUpperCase() }}
                            </h4>
                            <v-col cols="12"> 
                    
                <textarea style="font-size:13px ;color:black;padding:5px;margin-bottom:10px;"   disabled wrap="soft" v-model="value.descripcion" name="" id="" cols="70" rows="2"></textarea>   

                      <br>
                       </v-col>

                            <h5>
                              Fecha: {{ value.inicio }} - {{ value.final }}
                            </h5>
                            
                            <p style="background-color:#2afed6; border-radius:7px; color: white; font-size:12px;" v-if="value.actual">Trabajo Actual</p>
                            <br>
                          </div>
                            
                            <h2>Cursos</h2>
                            <div v-for="value in cursos" :key="value.candidato">
                              <h5> <strong style="color:#2afed6;">
                                Nombre:</strong> {{ value.curso }} <br /></h5>
                              <h5> <strong style="color:#2afed6;">
                                Descripción:</strong > {{ value.descripcion }}</h5>
                            </div>
                          
                          <br>
                          <h2>Idiomas</h2>
                           <div v-for="value in idiomas" :key="value.idioma">
                         
                          <h5>
                         <strong style="color:#2afed6;"> {{ value.idioma }} </strong> - Nivel: {{ value.nivel }}</h5> 
                        </div>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-card
                          
                          class="tarjeta-postulaciones"
                        >
                          <h2 class="text-center">Estudios</h2>
                          <br />
                          <div v-for="value in educacion" :key="value.nivel">
                            <h5>
                              
                              <strong style="color:#2afed6;">
                                Nivel Educativo:</strong>
                                {{ value.nivel }} <br>
                                <strong style="color:#2afed6;">
                                  Titulo:
                                </strong>
                                {{ value.titulo }}
                                  <br />
                                  <strong style="color:#2afed6;">
                                  Institucion:

                                </strong>
                                {{ value.institucion }}
                                  <br />
                                   <strong style="color:#2afed6;">
                                  Fecha:

                                </strong>
                                {{value.ano_inicio}} - {{ value.ano_final }} 
                                  <br />
                                       <p style="background-color:#2afed6; border-radius:7px; color: white; font-size:12px;" v-if="value.actual">Estudio Actual</p>

                              
                            </h5>
                            <br />
                          </div>
                          <h2 class="text-center">Areas de Experiencia</h2>
                          <br />
                          <div v-for="value in misespecialidades" :key="value.sub">
                            <h5>
                              <strong style="color:#2afed6;">
                                Área:
                              </strong>
                              {{ value.especialidad }} <br><strong style="color:#2afed6;"> Subárea:</strong> 
                              {{value.sub}}
                            </h5>
                            <br />
                          </div>
                          <h2 class="text-center">Habilidades</h2>
                          <br />
                          <div v-for="value in habilidades" :key="value.sub">
                            <h5>
                             <strong style="color:#2afed6;"> </strong>
                              {{ value.habilidad }}
                            
                            </h5>
                            <br />
                          </div>
                          
                          
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-dialog v-model="dialogo" persistent max-width="570">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            style="color:black;"
                          >
                            Seleccionar Candidato
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h6">
                            ¿Estas seguro que deseas seleccionar este candidato?
                          </v-card-title>
                          <v-card-text
                            >Al seleccionar a este candidato, la vacante se marcara como cubierta y dejara de aparecer en el listado
                                      de vacantes.
                                      </v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <div class="text-center">
                              <v-btn
                              color="primary darken-1"
                              text
                              @click="dialog = false"
                            >
                              Cancelar
                            </v-btn>
                            <v-btn
                              color="primary darken-1"
                              text
                              @click="seleccionaCandidato()"
                            >
                              Aceptar
                            </v-btn>

                            </div>
                            
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </div>
                </v-container>
  <br>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-container>
                    <div class="text-center">
                      <v-btn
                        style="margin-left:20px; color:black;"
                        @click="dialog = false"
                        color="primary darken-1"
                      >
                        Cerrar
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-container>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-card>
      </v-col>
        </v-flex> -->
    </v-row>
  </v-container>
</template>

<script>
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
var moment = require("moment");
moment.locale("es-mx");
import perfil from "../Perfil.vue";
import axios from "axios";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
               errors: [],
      motivo: null,
      confirmaMotivo: null,
      dialogonuevo: false,
      moment: moment,
      posicion: "",
      descripcion: "",
      laespe: "",
      descripcionCorta: "",
      subespe: "",
      fecha: null,
      sueldo_inf: "",
      dialogo: false,
      dialogos: false,
      dialog: false,
      sueldo_sup: "",
      jornada: "",
      area: "",
      contratacion: "",
      fechaContrato: null,
      estado: "",
      ciudad: "",
      experiencia: "",
      posts: [],
      misespecialidades: [],
      subespes: [],
      nombreSector: "",
      nombreGiro: "",
      candidato: [],
      idiomas: [],
      educacion: [],
      token: "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
      trabajos: [],
      especialidades: [],
      cursos: [],
      habilidades: [],
      respuesta: null,
      miestatus: null,
      respuesta: null,
      edadmenor: null,
      edadmayor: null,
      cancelada: null,
      micandidato: null,
      cubierta: null,
      mirespuesta: null,
    };
  },
  computed: {
    ...mapState(["VacanteFolio"]),
  },
  methods: {
       seleccionaMotivo() {
      if (this.motivo == 0) {
        this.confirmaMotivo = 1;
      }
      if (this.motivo == 1) {
        this.confirmaMotivo = 2;
      }
      if (this.motivo == 2) {
        this.confirmaMotivo = 3;
      }
    },
    probando() {
      console.log(this.dialog)
      this.dialog = false;
    },
    seleccionaCandidato() {
      axios
        .post(
          "/vacantes/cubrevaacante",
          {
            candidato: this.micandidato,
            vacante: this.$store.state.VacanteFolio,
          }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   }
        )
        .then((response) => {
          console.log(response.data);
          this.dialog = false;
          this.mirespuesta = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prueba() {
       this.errors = [];

      if (
        this.confirmaMotivo
        
      ){
        console.log("motivo: ", this.confirmaMotivo, "vacante: ", this.$store.state.VacanteFolio)
      axios
        .post(
          "/vacantes/motivocancelavacante",
          {
            motivo: this.confirmaMotivo,
            vacante: this.$store.state.VacanteFolio,
          }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   }
        )
        .then((response) => {
          console.log(response.data);
          this.dialogonuevo = false;
          this.dialogos= false;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .post(
          "/vacantes/cancelavacante",
          { folio: this.$store.state.VacanteFolio }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   }
        )
        .then((response) => {
          console.log(response.data);
          this.respuesta = response.data;
          this.dialogos= false;
          this.$router.push({ name: "PerfilEmpresas" });

        })
        .catch((error) => {
          console.log(error);
        });
      }

            if (!this.confirmaMotivo) this.errors.push("Motivo por el cual desactivas la vacante:");

      
    },
    infoVacante(post) {
      this.micandidato = post.folio;
      axios
        .get("/candidatos/" + post.folio , { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
        .then((response) => {
          this.candidato = response.data;
          this.idiomas = response.data.idiomas;
          this.educacion = response.data.educacion;
          this.trabajos = response.data.exp_profesional;
          this.misespecialidades = response.data.especialidades;
          this.cursos = response.data.cursos;
          this.habilidades = response.data.habilidades;
        })
        .catch((error) => {
          console.log(error);
        });
         axios
        .post(
          "/vacantes/avisovista",
          { candidato: this.micandidato , vacante:this.$store.state.VacanteFolio}, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   }
        )
        .then((response) => {
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
       
    },
  },
  beforeCreate() {
    axios
      .post("/vacantes/", {
        id: this.$store.state.VacanteFolio,
      }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
      .then((response) => {
        this.posicion = response.data[0].posicion;
        this.descripcion = response.data[0].descripcion;
        this.laespe = response.data[0].sector;
        this.subespe = response.data[0].giro;
        this.fecha = response.data[0].fechacreacion;
        this.descripcionCorta = response.data[0].desc_corta;
        this.sueldo_inf = response.data[0].sueldo_raninf;
        this.sueldo_sup = response.data[0].sueldo_ransup;
        this.jornada = response.data[0].tipo_jornada;
        this.sueldo_sup = response.data[0].sueldo_ransup;
        this.jornada = response.data[0].tipo_jornada;
        this.area = response.data[0].tipo_area;
        this.contratacion = response.data[0].tipo_contratacion;
        this.fechaContrato = response.data[0].fechacreacion;
        this.estado = response.data[0].estado;
        this.ciudad = response.data[0].ciudad;
        this.experiencia = response.data[0].experiencia;
        this.edadmenor = response.data[0].edad_inf;
        this.edadmayor = response.data[0].edad_sup;
        this.cancelada = response.data[0].cancelada;
        this.cubierta = response.data[0].cubierta;
      })
      .catch((error) => {
        console.log(error);
      });

   
  },
  components: {
    perfil,
  },
  mounted(){
     axios
      .get(
        "/vacantes/candidatosxvacante/" +
          this.$store.state.VacanteFolio
      , { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
      .then((response) => {
        this.posts = response.data;


      })
      .catch((error) => {
        console.log(error);
      });
     this.$store.dispatch("autoLogin");
     axios
      .get("/catalogos/especialidades", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.especialidades = response.data;
        
        const o = this.especialidades.find((elemento) => {
          return elemento.folio === this.laespe;
        });
        this.nombreSector = o.especialidad;
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        "/catalogos/subespe/" +
          this.laespe
      , { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.subespes = response.data;
        const g = this.subespes.find((elemento) => {
          return elemento.folio === this.subespe;
        });
        this.nombreGiro = g.sub;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  beforeUpdate() {
    
  },
};
</script>

<style>
.miimagen {
  width: 220px;
  border-radius: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.tarjeta-trabajos {
  padding: 25px;
  border-radius: 15px;
  background-color: darkgray !important;
}
.tarjeta-postulaciones {
  padding: 25px;
  border-radius: 15px;
  background-color: darkgray !important;
}
</style>
