<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
      <v-card style="background-color: #e0e0e0;">
        <v-container>
          <v-toolbar dark color="black" height="140px">
            <v-container>
              <v-toolbar-title class="text-center">
                <v-toolbar-title>
                  <v-img
                    src="/assets/pngblanco.png"
                    width="250px"
                    style="margin:auto; display:content;"
                  ></v-img>
                </v-toolbar-title>
                <br />

                <h2 style="color:white;">Registro de Empresas</h2>
              </v-toolbar-title>
            </v-container>
          </v-toolbar>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-alert autofocus v-if="errors.length">
                <b>Porfavor corrige los siguientes errores:</b>
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="razon"
                    name="razon"
                    outlined
                    color="primary"
                    type="text"
                    label="Nombre Comercial o Razón Social"
                    required
                    :rules="nombrempresa"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="nombre"
                    name="nombre"
                    autofocus
                    outlined
                    type="text"
                    color="primary"
                    label="Nombre Contacto"
                    style="margin-top:-13px"
                    :rules="nombrecontacto"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="rfc"
                    name="rfc"
                    height="5"
                    autofocus
                    outlined
                    type="text"
                    color="primary"
                    label="R.F.C"
                    v-mask="mask"
                    style="margin-top:-13px"
                    @keyup="uppercase"
                    class="rfc"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    outlined
                    v-model="evento"
                    :click="setState(evento)"
                    label="Estado"
                    clearable
                    :items="estados"
                    item-text="estado"
                    item-value="value"
                    autocomplete="nope"
                    color="primary"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    outlined
                    v-model="mievent"
                    :click="setCiudad(mievent)"
                    label="Ciudad"
                    clearable
                    :items="ciudades"
                    item-text="municipio"
                    item-value="ciudad"
                    autocomplete="nope"
                    color="primary"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-text-field
                v-model="correo"
                outlined
                color="primary"
                type="email"
                label="Email"
                required
                :rules="rulesEmail"
                append-icon="mdi-at"
              >
              </v-text-field>
              <v-text-field
                name="password"
                label="Ingresa tu password"
                :append-icon="value ? 'visibility' : 'visibility_off'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
                v-model="password"
                :rules="passwordRules"
                outlined
              ></v-text-field>
              <v-row justify="center">
                <v-dialog
                  v-model="dialogoTer"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      style="margin-bottom:25px;"
                      v-bind="attrs"
                      class="preguntas"
                      v-on="on"
                      >Términos y Condiciones</a
                    >
                    <br />
                  </template>
                   <v-card>
                  <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialogoTer = false">
                      <v-icon style="color:black">mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title style="color:black"
                      >Términos y Condiciones</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                        dark
                        text
                        @click="dialogoTer = false"
                        style="color:black"
                      >
                        Salir
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-list three-line subheader style="text-align:justify;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-card-text>
                          Los sitios web www.vacanta.mx y www.vacanta.com.mx (en lo sucesivo los “Sitios Web”) son responsabilidad de la persona moral denominada Operadora Rochen de México, S.A. de C.V., inscrita ante el Registro Público de Comercio en los Estados Unidos Mexicanos bajo el folio mercantil electrónico N-2021034609, cuyo nombre comercial con el que se ostenta ante el público en general es Vacanta (en lo sucesivo “Vacanta”).
                        </v-card-text>
                        <v-card-text>
                          El domicilio convencional de Vacanta para efectos del presente instrumento jurídico es el ubicado en Calle Hacienda del Carrizal, número 3204, Colonia Las Haciendas, C.P. 31215, Chihuahua, Chihuahua, México, además se señala como correo electrónico para efectuar comunicaciones la dirección info@vacanta.mx y el número teléfono celular 614 606 5278. 
                        </v-card-text>
                        <v-card-text>
                          El domicilio fiscal de Vacanta es el referido en el párrafo anterior y su clave es ORM210518H18. 
                        </v-card-text>
                        <v-card-text>
                         Vacanta es titular de las marcas mixtas con la denominación “VACANTA” registradas ante el Instituto Mexicano de la Propiedad Industrial con los números de registro 2317600 y 2320070 y números de expediente 2602579 y 2602592, ambas con vigencia hasta el año 2031.
                        </v-card-text>

                        <v-card-text>
A través del presente instrumento jurídico se informan los términos y condiciones bajo los cuales Vacanta ofrece al público en general los servicios que más adelante se precisan a favor de personas físicas o morales que desean reclutar personal para cubrir ofertas laborales (en lo sucesivo los “Reclutadores”) y a favor de personas físicas que desean obtener información sobre ofertas laborales o desean contactarse directamente con las Reclutadores para cubrir las mismas (en lo sucesivo “Candidatos” y en conjunto con los Reclutadores los “Usuarios”). En ese sentido, si los Usuarios continúan haciendo uso de los Sitios Web, otorgan su consentimiento sobre los derechos y obligaciones previstos en el presente instrumento jurídico. Consecuentemente, en caso que los Usuarios no estén de acuerdo con una o varias de las disposiciones aquí previstas, se solicita que se abstengan de usar o continuar utilizando los Sitios Web y los servicios que Vacanta ofrece. 
                        </v-card-text>
                        <v-card-text>
                          1. Capacidad legal. Los Usuarios que accedan a los Sitos Web deberán tener plena capacidad jurídica de goce y ejercicio para contratar y obligarse en términos del presente instrumento, ya sea por sus propios derechos o en representación de la persona moral respecto de la cual se ostenten como representantes, agentes o empleados. En caso que los Usuarios carezcan de plena capacidad jurídica, deberán acompañarse de sus padres o sus representantes legítimos o jurídicos para el uso de los Sitios Web. 
                        </v-card-text>
                        <v-card-text>
                          2. Modificaciones. Vacanta podrá modificar los presentes términos y condiciones, así como las condiciones de los servicios que se ofrezcan a través de los Sitios Web, conforme a su mejor interés convenga, por lo que se sugiere a los Usuarios que se informen respecto de ellos en cada ocasión que utilicen los Sitios Web y otorguen su consentimiento tácita o explícitamente, según se les requiera, en cada ocasión que soliciten los servicios ofrecidos por Vacanta. 
                        </v-card-text>
                        <v-card-text>
                          3. Reserva. Vacanta se reserva el derecho de omitir prestar sus servicios y omitir el acceso o disfrute de los Sitios Web a aquellas personas que así estime conveniente para sus intereses, incluyendo sin limitar a aquellas que incumplan alguna de las estipulaciones del presente instrumento o realicen un uso de los Sitios Web que Vacanta considere inconveniente a su exclusivo criterio, violatorio de la normatividad aplicable, la moral o las buenas costumbres. 
                        </v-card-text>
                        <v-card-text>
                          4. Objeto. Vacanta ofrece a través de los Sitios Web en beneficio de los Reclutadores los servicios consistente en solicitarles y recabar la información correspondiente para (i) crear y publicar en los Sitios Web ofertas laborales para el reclutamiento de personal, las cuales serán de libre acceso para el público en general que acceda a los Sitios Web y para aquellos Candidatos que ingresen la plataforma, con la finalidad de que Candidatos se postulen para dicha oferta laboral a consideración de los reclutadores mediante los mecanismos establecidos en el Sitio Web o se contacten directamente con los Reclutadores mediante los medios de comunicación que hayan puesto a disposición del público en general y de los Candidatos y que éstos sometan su perfil a consideración de los Reclutadores a través los medios que posteriormente acuerden de forma privada entre el Reclutador y el Candidato y (ii) hacer del conocimiento de los Reclutadores la información del Candidato que haya proporcionado su información a Vacanta y ésta considere a su exclusivo criterio que cuentan con las características adecuadas para la oferta laboral que ofrecen los Reclutadores. 
                        </v-card-text>
                        <v-card-text>
                           Vacanta ofrece a través de los Sitios Web en beneficio de los Reclutadores los servicios consistente en solicitarles y recabar la información correspondiente para (i) crear y publicar en los Sitios Web ofertas laborales para el reclutamiento de personal, las cuales serán de libre acceso para el público en general que acceda a los Sitios Web y para aquellos Candidatos que ingresen la plataforma, con la finalidad de que Candidatos se postulen para dicha oferta laboral a consideración de los reclutadores mediante los mecanismos establecidos en el Sitio Web o se contacten directamente con los Reclutadores mediante los medios de comunicación que hayan puesto a disposición del público en general y de los Candidatos y que éstos sometan su perfil a consideración de los Reclutadores a través los medios que posteriormente acuerden de forma privada entre el Reclutador y el Candidato y (ii) hacer del conocimiento de los Reclutadores la información del Candidato que haya proporcionado su información a Vacanta y ésta considere a su exclusivo criterio que cuentan con las características adecuadas para la oferta laboral que ofrecen los Reclutadores.  
                        </v-card-text>
                        <v-card-text>
                          5. Conocimiento de las leyes. Los Usuarios reconocen que será a su exclusiva responsabilidad el conocimiento o desconocimiento de la normatividad que regule su relación con Vacanta y entre Reclutadores y Candidatos cuya información se intercambie a través de los Sitios Web en incluso las relaciones laborales o de servicios que los Usuarios puedan celebrar entre ellos, liberando a Vacanta de cualquier responsabilidad al respecto. En ese sentido, los Usuarios reconocen que sus actos podrán estar sujetos y por ende asumen sus obligaciones, enunciativa mas no limitativamente, de la Constitución de los Estados Unidos Mexicanos, la normatividad laboral, fiscal y de seguridad social. En particular, los Reclutadores reconocen que no se publicarán vacantes en los Sitios Web cuyo salario sea inferior al mínimo autorizado para la región donde se llevarán a cabo las actividades laborales, se pretenda un pago a los Candidatos exclusivamente de comisiones por ventas, se requiera al Candidato efectuar algún pago como parte del proceso de selección para cubrir la oferta laboral, corresponda a actividades de venta multinivel o piramidal, sean actividades de voluntariado o pro-bono o se trate de actividades inmorales, ilegales o que inciten a la charlatanería, incluyendo pero no limitado a la prostitución, trata de personas y actividades eróticas, a exclusivo criterio de Vacanta quien estará facultado para interrumpir los servicios y el uso de los Sitios Web e incluso dar vista a las autoridades competentes para que procedan conforme a derecho corresponda. 
                        </v-card-text>
                        <v-card-text>
                          6. Uso de los Sitios Web. Los Usuarios se obligan a usar los Sitios Web exclusivamente para los fines previstos en el presente instrumento, en particular los previstos previamente en el numeral cuatro, por lo que están de acuerdo en omitir su uso para cualquier finalidad diversa. Adicionalmente, los Usuarios se obligan a cumplir con la legislación aplicable y omitir realizar actividades contrarias a la normatividad, la moral y las buenas costumbres a través de los Sitios Web o con la información que de los mismos obtengan y omitir afectar derechos de terceros o de Vacanta, asumiendo y liberando a Vacanta de cualquier responsabilidad por contravenir lo dispuesto en esta disposición. 
                        </v-card-text>
                        <v-card-text>
                          7. No transferencia de la información. Los Usuarios se obligan a no transferir o permitir el conocimiento de terceros respecto de la información que Vacanta les proporcione o haga de su conocimiento a través de los Sitios Web y reconocen que eso podría ocasionar un perjuicio económico a Vacanta, puesto que recolectar, identificar y proporcionar a terceros dicha información consiste en su actividad profesional por la cual pretende obtener un beneficio económico legítimo y prestar el mismo servicio a terceros y el público en general. En ese sentido, los Usuarios que infrinjan esta disposición se obligan a indemnizar a Vacanta por los daños y perjuicios que le ocasionen.
                        </v-card-text>
                        <v-card-text>
                          8. Características de las ofertas laborales. Los Reclutadores se obligan a no publicar ofertas laborales con requisitos o características que resulten violatorias de la legislación aplicable, incluyendo sin limitar a la normatividad que proteja a los Candidatos u otros usuarios de los Sitios Web de actos o conductas discriminatorias por sexo, edad, condición socioeconómica, orientación sexual, religión, estado de salud, apariencia física, origen étnico, discapacidad, opiniones, preferencias políticas, estado civil o cualquier otra que atente contra la dignidad humana. En ese sentido, los Reclutadores se obligan a sacar en paz y a salvo en caso que se presente en contra de Vacanta algún reclamo o sanción por contravenir lo dispuesto en este numeral. 
                        </v-card-text>
                        <v-card-text>
                          9. Respecto a derechos de Vacanta. Los Usuarios se obligan a respetar los derechos que Vacanta tiene respecto de los Sitios Web y su desarrollo, por lo que se obligan a no realizar acciones tendientes a su reproducción, adaptación, modificación total o parcial de los contenidos de los Sitios Web sin autorización expresa de Vacanta, ni realizar ninguna acción que pueda perjudicar la funcionalidad de los Sitios Web.
                        </v-card-text>
                        <v-card-text>
                          10. Cuidado de usuario y contraseña.Los Usuarios podrán crear un usuario y contraseña para disfrutar plenamente de los servicios que ofrece Vacanta a través de los Sitios Web. En ese sentido, los Usuarios serán los únicos responsables por el cuidado de su usuario y contraseña, por lo que excluyen de responsabilidad a Vacanta en caso que terceros hayan obtenido dicha información y manipulen la cuenta e información de los Usuarios. En su caso, los Usuarios se obligan a notificar de inmediato a Vacanta de tal filtración, a fin de tomar las medidas necesarias para resguardar la integridad de la información y recursos de los Usuarios, pero bajo ninguna circunstancia será responsabilidad de Vacanta algún perjuicio que por tal motivo puedan sufrir. Adicionalmente, los Usuarios se obligan a no transmitir ni poner a disposición de terceros su usuario y contraseña para el uso de los Sitios Web o los servicios de Vacanta, pues reconocen que el usuario y la contraseña es para uso exclusivo personal o de la persona moral que representan y que en su caso los terceros deberán crear su propio usuario y contraseña para tener acceso a los Sitios Web y beneficiarse de los servicios que ofrece Vacanta.
                        </v-card-text>
                        <v-card-text>
                          11. Información de terceros. Los Usuarios se comprometen a no utilizar información de otros Usuarios publicada en los Sitios Web para fines distintos al objeto de los servicios aquí previstos y que pretendan el beneficio personal o de terceros, incluyendo sin limitar el ofrecer servicios o productos a Usuarios de los Sitios Web. 
                        </v-card-text>
                        <v-card-text>
                          12. Integridad y veracidad de información. Los Usuarios se obligan a proporcionar integralmente toda la información que solicite Vacanta para la mejor prestación de los servicios y funcionalidad de los Sitios Web; asimismo, los Usuarios se comprometen a que dicha información sea veraz, pues reconocen que en caso de proporcionar información incompleta, inexacta o falsa podría implicar un perjuicio a Vacanta, los Usuarios o terceros. Adicionalmente, los Usuarios se obligan a informar a Vacanta de inmediato en caso que resulte errónea o varíe la información que hayan proporcionado a Vacanta y a mantener actualizada la información que hayan proporcionado. Vacanta se reserva el derecho a prestar sus servicios o permitir la funcionalidad de los Sitios Web en caso que los Usuarios no proporcionen información integral y veraz, a exclusivo criterio de Vacanta.
                        </v-card-text>
                        <v-card-text>
                          13. Características de los Usuarios. Los Usuarios reconocen que Vacanta no será responsable por la veracidad de la información que obtengan de los Sitios Web o remota, pues reconocen que dicha información es proporcionada a exclusiva responsabilidad de los Reclutadores y los Usuarios y no se encuentra al alcance de Vacanta cerciorarse de la veracidad de dicha información. En ese sentido, los Candidatos liberan de responsabilidad a Vacanta por la incompleta o falsa información que resulte de una oferta laboral publicada a nombre de los Reclutadores; mientras que los Reclutadores liberan de responsabilidad a Vacanta por la incompleta o falsa información que resulte de un Candidato publicada a su nombre. Adicionalmente, los Candidatos liberan de responsabilidad a Vacanta respecto de cualquier requisito de ofertas laborales publicadas a nombre de Reclutadores que pudiera resultar discriminatorio, puesto que reconocen que los requisitos referidos son responsabilidad exclusiva de los Reclutadores.
                        </v-card-text>


                        <v-card-text>
                          14. Titularidad sobre la información.Los Reclutadores se obligan a contar con las autorizaciones correspondientes para proporcionar la información de las ofertas laborales que publiciten y para utilizar el nombre, correos electrónicos, números de teléfonos fijos y celulares, números de contacto de la aplicación de Whatsapp, marcas y demás signos que identifiquen a aquellas personas físicas y morales respecto de las cuales ellos se ostenten como representantes, agentes o empleados. En ese sentido, los Reclutadores reconocen que utilizar información de terceros sin autorización u ostentarse dolosamente como representantes, agentes o empleados de terceros sin serlo puede ser causal de responsabilidades civiles, administrativas y penales, respecto de las cuales asumen su exclusiva responsabilidad y se obligan sacar en paz y a salvo a Vacanta. Asimismo, los Candidatos se obligan a utilizar solamente su información personal, íntegra y veraz, y reconocen que utilizar información de terceros u ostentarse con la identidad de terceros o perfiles falsos puede ser causal de responsabilidades civiles, administrativas y penales, respecto de las cuales asumen su exclusiva responsabilidad y se obligan sacar en paz y a salvo a Vacanta. En ese sentido, los Usuarios reconocen que Vacanta tendrá el derecho de dar aviso inmediato a las autoridades competentes en caso de percatarse de cualquier conducta que pueda incurrir en violación a la normatividad aplicable por parte de los Usuarios. 
                        </v-card-text>
                        <v-card-text>
                          15. Uso de datos personales. Vacanta recaba información personal de los Usuarios y les informa mediante el correspondiente aviso de privacidad, entre otras consideraciones, que dicha información podrá ser transmitida o puesta a disposición de los Usuarios para el fin de facilitar cubrir una oferta laboral. En ese sentido, los Usuarios se obligan a no transmitir a terceros la información personal de Usuarios que reciban o tengan acceso a través de los Sitios Web ni utilizarla para otros fines distintos a los previstos en este instrumento, bajo la pena de incurrir en las violaciones y correspondientes responsabilidades que contemple la normatividad aplicable. 
                        </v-card-text>
                        <v-card-text>
                          16. Uso de marca. Los Reclutadores conceden a Vacanta la autorización para usar su marca en su material publicitario.
                        </v-card-text>
                        <v-card-text>
                          17. Eliminación de información. Los Usuarios reconocen que Vacanta tendrá el derecho de eliminar cualquier información que a su exclusivo criterio no resulte de beneficio para Vacanta, Reclutadores, Candidatos o terceros y también reconocen que Vacanta tendrá el derecho de dar vista a las autoridades correspondientes respecto de cualquier información o conducta que pueda resultar violatoria de la normatividad aplicable.
                        </v-card-text>
                        <v-card-text>
                          18. Terminación anticipada del servicio. Los Usuarios reconocen que Vacanta tendrá el derecho unilateral y a su exclusivo criterio de interrumpir o dar por terminado la prestación de servicio o el uso de los Sitios Web, sin necesidad de aviso previo ni declaración administrativa ni judicial, y que dicho acto no generará ninguna penalidad, sino solamente la obligación de devolver los créditos a favor con que cuente si la interrupción excede el plazo de un mes o en caso de una terminación anticipada.
                        </v-card-text>
                        <v-card-text>
                          19. Autorización de Vacanta. Los Usuarios reconocen que la información que proporcionen, tanto Reclutadores como Candidatos, podrá estar sujeta a revisión de Vacanta y en su caso su autorización para su publicación en los Sitios Web, a exclusivo criterio de Vacanta y sin obligación de justificar su decisión a acreditar alguna causal. En ese sentido, los usuarios aceptan que su información podrá demorar su publicación el tiempo que Vacanta estime oportuno para su análisis.
                        </v-card-text>
                        <v-card-text>
                          20. Vigencia de ofertas de laborales. Los Reclutadores tendrán derecho a que se publique su oferta laboral en los Sitios Web por los periodos que se determinen para las diferentes modalidades en los Sitios Web, durante los cuales candidatos podrán postularse y/o contactarse directamente con los Reclutadores con la intención de cubrir el puesto. Vacanta no se hace responsable de la postulación o no de candidatos o de que algún Candidato se contacte o envié su información para cubrir una oferta laboral, pues los Reclutadores reconocen que queda a la exclusiva libertad de los Candidatos el postularse, contactarse y/o enviar su información a alguna oferta laboral en particular. Una vez transcurrido los plazos correspondientes, las ofertas laborales dejarán de publicarse en los Sitios Web. Los Reclutadores podrán solicitar a Vacanta que deje de publicar una oferta laboral en los Sitios Web antes de que termine el plazo previamente referido, ya sea porque la oferta laboral haya sido cubierta o por cualquier motivo que así convenga a los intereses de los Reclutadores, aunque aceptan que en ningún supuesto tendrán derecho al reembolso de las cantidades pagadas a favor de Vacanta, incluyendo sin limitación los supuestos en que la oferta laboral haya sido cancelada por los Reclutadores o porque hayan logrado cubrir la oferta laboral por medios ajenos a los Sitios Web o los servicios que ofrece Vacanta.
                        </v-card-text>
                        <v-card-text>
                          21. Modalidades de ofertas laborales. Vacanta ofrecerá a los Reclutadores diversas modalidades para publicitar sus ofertas laborales en los Sitios Web, las cuales se regularán por los derechos y obligaciones que así se precise en los Sitios Web, mismos que podrán modificarse a exclusivo criterio de Vacanta, por lo que se sugiere a los Reclutadores informarse de los mismos previo a la solicitud de cada servicio.
                        </v-card-text>
                        <v-card-text>
                          22. Modalidad solicitud de Candidatos. Vacanta también ofrece a los Reclutadores, en caso de que sea seleccionando la opción de este servicio, el servicio consistente en proporcionar la información de número limitado de Candidatos que a criterio exclusivo de Vacanta cumplan total o parcialmente con las características que requieran los Reclutadores para una oferta de trabajo en particular. Bajo esta modalidad, los Reclutadores deberán proporcionar toda la información de la oferta laboral que solicite Vacanta, misma que será procesada internamente por Vacanta en su base de datos de Candidatos y Vacanta se obliga a proporcionar a los Reclutadores dentro del plazo no superior a 24 horas la información del perfil y contacto del número de Candidatos al que se haya comprometido a través de los Sitios Web que a criterio exclusivo de Vacanta cumplan parcial o totalmente con las características de la oferta laboral en cuestión. En caso que Vacanta no identifique dicho número de Candidatos potencialmente idóneos para cubrir la oferta laboral, lo hará del conocimiento de los Reclutadores previo a requerirles el pago de la contraprestación correspondiente. Vacanta hará del conocimiento de los Reclutadores la información de los candidatos mediante publicación en los Sitios Web, lo cual incluirá su perfil y datos de contacto. Dicha información se pondrá a disposición de los Reclutadores por el plazo de al menos 48 horas y después se eliminará. Si los Reclutadores desean tener acceso nuevamente a la información con posterioridad a ese plazo, deberán solicitar un nuevo servicio a Vacanta a través de los Sitios Web. Los Reclutadores reconocen que no será responsabilidad de Vacanta contactar a los Candidatos y que tampoco será responsabilidad de Vacanta si los Candidatos no aceptan iniciar un proceso de reclutamiento con los Reclutadores para cubrir la oferta laboral.
                        </v-card-text>
                        <v-card-text>
                          23. Modalidades de servicios a Candidatos. Vacanta no cobrará contraprestación alguna por facilitar a los Candidatos que se contacten con los Reclutadores para cubrir una oferta laboral o la creación de un usuario y contraseña en los Sitios Web.  Los Candidatos reconocen que no será responsabilidad de Vacanta el efectivamente ser contactados por los Reclutadores, iniciar un proceso de reclutamiento o incluso ser seleccionado para cubrir una oferta laboral, puesto que reconoce que quedará a exclusivo criterio de los Reclutadores el identificar los perfiles que estimen más convenientes a sus intereses.
                        </v-card-text>
                        <v-card-text>
                          24. Precio. La contraprestación por los servicios ofrecidos por Vacanta a través de los Sitios Web, se hará del conocimiento de los Usuarios a través de los referidos Sitios Web, antes de que acepten la prestación del servicio correspondiente. El monto de la contraprestación podrá variar a exclusivo criterio de Vacanta, por lo que se sugiere a los Usuarios que se informen del mismo antes de la solicitud de cada servicio.
                        </v-card-text>
                        <v-card-text>
                          25. Métodos de pago. Vacanta ofrecerá a los Usuarios diversos métodos de pago por sus servicios, entre ellos (i) pago mediante tarjeta de débito o crédito a través de una plataforma de pagos administrada por un tercero, la cual podrá ser utilizada por los Usuarios a su exclusiva responsabilidad y (ii) pago mediante depósito o transferencia bancaria previo la emisión de una orden de compra en la cuenta bancaria titularidad de Vacanta que para tal efecto se informe oportunamente. En cualquier caso, los Usuarios tendrán derecho a solicitar a Vacanta el comprobante fiscal digital por internet (en lo sucesivo “CFDI”) correspondiente dentro del mes en que hayan realizado el pago respectivo.
                        </v-card-text>
                        <v-card-text>
                          26. Medidas de seguridad. Vacanta tomará las medidas requeridas por la normatividad aplicable para proteger la información y la disponibilidad de uso de la plataforma; sin embargo, no se hará responsable si, aún tomando las medidas previamente referidas, terceros acceden sin autorización y previa violación de las medidas de seguridad a los Sitios Web o su central operativa, interfieren con su disponibilidad de uso o vulneran la protección de la información y los datos personales de los Usuarios. 
                        </v-card-text>
                        <v-card-text>
                          27. Limitación de daños. Los Usuarios reconocen que Vacanta no será responsable por los daños que pudieran sufrir los Usuarios derivado de los servicios que disfruten de Vacanta y/o a través de los Sitios Web, incluyendo sin limitar (i) el que no se satisfagan las expectativas o necesidades de los Reclutadores o los Candidatos con la información que en su caso obtengan de los Sitios Web (ii) el supuesto en se cubra una oferta laboral pero alguna de las partes no se encuentre satisfecha con el comportamiento o características de los Candidatos o los Reclutadores y las empresas que en su caso representen y (iii) daños que puedan causar los Candidatos o los Reclutadores a una de las partes o a terceros ya sea por acción u omisión negligente, incluyendo acciones que se puedan tipificar como conductas delictivas.
                        </v-card-text>
                        <v-card-text>
                          28. Misceláneos. Los Usuarios reconocen que Vacanta tendrá derecho a subcontratar total o parcialmente los servicios objeto de este instrumento jurídico y a ceder total o parcialmente los derechos y obligaciones del presente instrumento jurídico sin necesidad de consentimiento ni informe a los Usuarios. Las partes reconocen que los términos identificados con mayúscula inicial y que hayan sido específicamente definidos en este instrumento jurídico tendrán el mismo significado en singular que en plural y en femenino que en masculino. Para la interpretación del presente instrumento jurídico las partes se refieren a la legislación aplicable en Chihuahua, Chihuahua, México y para el cumplimiento de sus derechos y obligaciones las partes se someten a los órganos jurisdiccionales con residencia en Chihuahua, Chihuahua, México.
                        </v-card-text>
                        <v-card-text>
                          Fecha de actualización más reciente: 06 de Mayo de 2022
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
                </v-dialog>

                <v-dialog
                  v-model="dialogo"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      style="
                    margin-bottom:25px;margin-left:45px;"
                      v-bind="attrs"
                      v-on="on"
                      class="preguntas"
                      >Aviso de Privacidad</a
                    >
                    <br />
                  </template>
                  <v-card>
                  <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialogo = false">
                      <v-icon style="color:black">mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title style="color:black"
                      >Aviso de Privacidad</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                        dark
                        text
                        @click="dialogo = false"
                        style="color:black"
                      >
                        Salir
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-list three-line subheader style="text-align:justify;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-center"
                          style="margin-top:10px;"
                        >
                          <strong
                            >Aviso de privacidad integral para
                            candidatos</strong
                          >
                        </v-list-item-title>
                        <v-card-text>
                         Este aviso de privacidad integral fue redactado en fecha 19 de octubre de 2021 y actualizado en fecha 07 de mayo de 2022, con base en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (“Ley”) publicada en el Diario Oficial de la Federación (“DOF”) en fecha 05 de julio de 2010, el Reglamento de la Ley (“Reglamento”) publicado en el DOF en fecha 21 de diciembre de 2011 y los Lineamientos del Aviso de Privacidad publicados en el DOF el 17 de enero de 2013 (“Lineamientos”).
                        </v-card-text>
                        <v-card-text>
A través del presente aviso de privacidad, el responsable del tratamiento de sus datos personales pretende cumplir con el principio de información previsto en la Ley, al dar a conocer a los titulares de los datos personales la información relativa a la existencia y características principales del tratamiento al que serán sometidos los mismos.                         </v-card-text>
                        <v-card-text>
                          En ese sentido, a continuación se presenta el contenido del presente aviso de privacidad integral, conforme lo dispuesto en el lineamiento vigésimo de los Lineamientos:
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>I. Responsable</v-list-item-title>
                        <v-card-text>
                       El responsable que tratará los datos personales de los titulares será la persona moral denominada Operadora Rochen de México, S.A. de C.V., comercialmente conocida como “Vacanta”, con domicilio ubicado en Calle Hacienda del Carrizal, número 3204, Colonia Las Haciendas, C.P. 31215, Chihuahua, Chihuahua, México. 
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >II. Datos personales</v-list-item-title
                        >

                        <v-card-text style="color:gray;">
                          A continuación se listan los datos personales que se
                          tratarán para la consecución de las finalidades que
                          más adelante se precisan, los cuales en su totalidad
                          son obtenidos directamente del titular:
                        </v-card-text>
                        <v-card-text>
                          1. Nombre completo.<br />
                          2. Fecha de nacimiento.<br />
                          3. Código postal de su domicilio.<br />
                          4. Estado y ciudad donde radica.<br />
                          5. Expectativa salarial con mínimo y máximo. <br />
                          6. Historial profesional.<br />
                          7. Aptitudes y capacidades. <br />
                          8. Historial académico. <br />
                          9. Correo electrónico. <br />
                          10. Número de teléfono celular.<br />
                          11. Fotografía.<br />
                          12. Currículo. <br />
                          13. A aquellos titulares que soliciten comprobante
                          fiscal digital por Internet, les será solicitada su
                          información fiscal.
                        </v-card-text>
                        <v-card-text>
                          El responsable conservará y estará autorizado para
                          tratar los datos personales mientras los titulares
                          estén registrados en el portal de internet que el
                          responsable utilice para la prestación de los
                          servicios y en el cual se hayan registrado los
                          titulares directamente o a través del responsable,
                          salvo que el titular ejerza su derecho a la
                          cancelación de los mismos mediante el procedimiento
                          correspondiente.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >III. Datos personales sensibles</v-list-item-title
                        >
                        <v-card-text>
                          El responsable no recabará ni tratará datos personales
                          sensibles de los titulares.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >IV. Finalidades del tratamiento</v-list-item-title
                        >
                        <v-card-text style="color:gray">
                          El responsable recabará y tratará los datos personales
                          de los titulares con las siguientes finalidades:
                        </v-card-text>
                        <v-card-text>
                          1. Identificar y ofrecerle información sobre posibles
                          oportunidades de trabajo o prestación de servicios que
                          se adecúen a su perfil. <br />
                          2. Procurarle un servicio eficiente y proveer una
                          mejor atención.<br />
                          3. Dar cumplimiento a obligaciones legales.<br />
                          4. Evaluar la calidad del servicio y realizar estudios
                          sobre hábitos de consumo. <br />
                          5. En su caso, para procesar los pagos electrónicos
                          por los servicios que den origen a la relación
                          jurídica entre el titular y el responsable.<br />
                          6. Generar y compartir información agregada que no
                          identifica al titular para fines analíticos y
                          estadísticos, que permite un mejor conocimiento de los
                          usuarios del portal web del responsable y permite
                          ofrecerles un mejor servicio.<br />
                          7. Mercadotecnia, publicidad y prospección comercial
                          de los productos y servicios que ofrezca el
                          responsable y los terceros a los que en su caso se le
                          transfieran los datos personales, en términos del
                          presente aviso de privacidad, la Ley, el Reglamento y
                          los Lineamientos. <br />
                          8. Otra información - Cookies.- Cuando el titular ingresa en el portal web del Responsable  le autoriza almacenar alguna información en su computadora bajo la forma de “Cookie” o archivo similar que puede serle útil de varias formas. Con la mayoría de los exploradores para Internet, el titular puede borrar las Cookies del disco rígido de su computadora, bloquear todas las Cookies o recibir un mensaje de alerta antes de que se almacene una Cookie. El titular debe consultar las instrucciones de su explorador para conocer más sobre estas funciones.
                          <br />
                          Para tal efecto, se precisa que todas las finalidades
                          del tratamiento de los datos personales que se recaban
                          del titular son necesarias para la existencia,
                          mantenimiento y cumplimiento de la relación jurídica
                          entre el titular y el responsable
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >V. Mecanismos para manifestar negativa al
                          tratamiento</v-list-item-title
                        >
                        <v-card-text>
                         Si el titular no está de acuerdo en que el responsable realice el tratamiento de sus datos personales para aquellas finalidades que no son necesarias para la existencia, mantenimiento y cumplimiento de la relación jurídica con el responsable, según lo referido en el numeral anterior, podrá enviar un mensaje en esos términos a la dirección de correo electrónico info@vacanta.mx y el responsable estará obligado a responder su solicitud conforme lo dispuesto en la legislación aplicable.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VVI. Transferencias a terceros</v-list-item-title
                        >
                        <v-card-text style="color:gray">
                          Los datos personales podrán ser transferidos a
                          terceros nacionales o internacionales en los
                          siguientes términos:
                        </v-card-text>
                        <v-card-text>
                          1. Podrán ser transmitidos o puestos a disposición de
                          terceros que se encuentren en búsqueda de candidatos
                          para cubrir vacantes laborales y que posiblemente
                          puedan contactar al titular para iniciar un proceso de
                          reclutamiento y en su caso realizarle un ofrecimiento
                          de empleo o prestación de servicios. En ese sentido,
                          en este momento no se tiene identificado un tercero
                          receptor en específico al que se le transferirían los
                          datos personales, sino que se transferirán a aquellos
                          terceros que busquen perfiles laborales con las
                          características del titular, a criterio del
                          responsable o del titular.<br />
                          2. Podrán ser transferidos a terceros que tengan
                          interés en ofrecerles productos o servicios, es decir,
                          una transferencia con motivos de mercadotecnia,
                          publicidad o prospección comercial. En ese sentido, en
                          este momento no se tiene identificado un tercero
                          receptor en específico, sino que dependerá de aquel
                          tercero interesado en adquirir la información que haya
                          recabado el responsable.<br />
                          3. En su caso, podrán ser transferidos a aquellos
                          terceros que procesen los pagos electrónicos o
                          facturación por los servicios que den originen a la
                          relación jurídica entre el titular y el
                          responsable.<br />
                          4. Podrán ser transferidos a terceros que presten
                          servicio de almacenamiento de información, quienes se
                          obligarán contractualmente a mantener la
                          confidencialidad de los datos y conforme al presente
                          aviso de privacidad. <br />
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VII. Cláusula de aceptación de
                          transferencia</v-list-item-title
                        >
                        <v-card-text>
                          Mediante el otorgamiento del consentimiento del
                          presente aviso de privacidad integral, el titular
                          otorga su aceptación a que el responsable pueda
                          transferir sus datos personales en los términos
                          referidos en el apartado anterior.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VIII. Medios y procedimiento para ejercer los
                          derechos ARCO</v-list-item-title
                        >
                        <v-card-text>
                          El titular de los datos personales tiene derecho al
                          acceso, rectificación, cancelación y oposición
                          (“Derechos ARCO”) al tratamiento que les otorgue el
                          responsable. Para su ejercicio, el titular podrá
                          enviar un mensaje en sus términos a la dirección de
                          correo electrónico info@vacanta.mx.
                          <br />
                          El departamento de datos personales del responsable
                          será el encargado de dar trámite a las solicitudes de
                          los titulares para el ejercicio de los derechos ARCO,
                          en los términos previstos en la legislación aplicable,
                          a quien se puede contactar en la dirección de correo
                          electrónico info@vacanta.mx.
                        </v-card-text>
                        <v-card-text style="color:gray;">
                          El procedimiento para el ejercicio de los Derechos
                          ARCO será el siguiente:
                        </v-card-text>
                        <v-card-text>
                          1. El titular o quien se ostente como su representante
                          deberá escribir y firmar su solicitud de ejercicio de
                          los derechos ARCO de forma clara, completa, en idioma
                          español y con toda la información que permita al
                          responsable y a la persona por él encargada para dar
                          trámite a su solicitud comprender a plenitud cuál es
                          la solicitud del titular, cual es el Derecho ARCO en
                          específico que pretende ejercer y a cuáles de sus
                          datos personales se refiere. Asimismo, el titular
                          deberá proporcionar una dirección de correo
                          electrónico en la cual autorice recibir la respuesta
                          correspondiente por parte del responsable.<br />
                          2. En caso que sea el titular quien directamente
                          presente la solicitud, deberá acompañar a la misma
                          alguna identificación oficial vigente con fotografía
                          que le permita al responsable corroborar que
                          efectivamente se trata del titular de los datos
                          personales y que la firma de la solicitud coincide con
                          la de la identificación.<br />
                          3. En caso que sea un representante o apoderado del
                          titular quien presente la solicitud, deberá acompañar
                          a la misma los documentos que indudablemente lo
                          acrediten con tal carácter, además de su
                          identificación oficial vigente.<br />
                          4. El responsable comunicará al titular, en un plazo
                          máximo de veinte días, contados desde la fecha en que
                          se recibió la solicitud para ejercer alguno o varios
                          de los derechos ARCO, la determinación adoptada, a
                          efecto de que, si resulta procedente, se haga efectiva
                          la misma dentro de los quince días siguientes a la
                          fecha en que se comunicó la respuesta. Estos plazos
                          podrán ampliarse una sola vez por un periodo igual,
                          siempre y cuando así lo justifiquen las circunstancias
                          del caso. <br />
                          5. El responsable dará respuesta al titular a través
                          de un mensaje de correo electrónico a la dirección que
                          para tal efecto haya señalado el titular en su
                          solicitud inicial. <br />
                          6. El titular obtendrá la información o datos
                          personales que en su caso haya solicitado a través del
                          ejercicio del derecho de acceso, mediante documentos
                          electrónicos y mensajes compartidos vía correo
                          electrónico. <br />
                          7. Hasta el momento de la elaboración del presente
                          aviso de privacidad, el responsable no ha desarrollado
                          algún formulario, sistema o método simplificado en
                          específico para facilitar al titular el ejercicio de
                          sus derechos ARCO, con excepción de las comunicaciones
                          que el responsable envíe al titular vía correo
                          electrónico, en las cuales el titular podrá dar click
                          en la opción que para tal efecto se le proporcione en
                          el cuerpo del mensaje enviado por ese medio. <br />
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>IX. Revocación</v-list-item-title>
                        <v-card-text>
                          El titular podrá enviar un mensaje a la dirección de
                          correo electrónico info@vacanta.mx para manifestar la
                          revocación de su consentimiento al tratamiento de sus
                          datos personales, lo cual se resolverá por el
                          responsable conforme corresponda según la Ley, el
                          Reglamento y los Lineamientos, tomando en
                          consideración si es factible o no el mantenimiento y
                          cumplimiento de la relación jurídica con el
                          responsable sin esos datos personales o incluso si es
                          interés del responsable continuar prestando sus
                          servicios en los términos solicitados por el titular.
                          Para lo no previsto en el presente numeral, aplicará
                          lo dispuesto en el numeral VIII del presente
                          documento.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >X. Opciones para limitar el uso o
                          divulgación</v-list-item-title
                        >
                        <v-card-text>
                         En caso que el titular deseé limitar el uso o divulgación de sus datos personales, podrá enviar un mensaje con su intención a la dirección de correo electrónico info@vacanta.mx, lo cual se resolverá por el responsable conforme corresponda según la Ley, el Reglamento y los Lineamientos, tomando en consideración si es factible o no el mantenimiento y cumplimiento de la relación jurídica con el responsable sin esos datos personales o incluso si es interés del responsable continuar prestando sus servicios en los términos solicitados por el titular. Para lo no previsto en el presente numeral, aplicará lo dispuesto en el numeral VIII del presente documento.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >XI. Cambios en el aviso de
                          privacidadn</v-list-item-title
                        >
                        <v-card-text>
                          El contenido del presente aviso de privacidad
                          integral, y por ende el tratamiento de los datos
                          personales de los titulares, podrá ser modificado en
                          el futuro en cualquier momento, para lo cual se
                          publicará su versión actualizada en el portal de
                          internet: www.vacanta.mx y/o www.vacanta.com.mx, sin
                          necesidad de darse aviso en particular al titular por
                          parte del responsable.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >XII. Consentimiento</v-list-item-title
                        >
                        <v-card-text>
                          El consentimiento al presente aviso de privacidad
                          podrá otorgarse por el responsable de forma expresa o
                          tácita. Si el titular utiliza los servicios ofrecidos
                          por el responsable significa que ha leído, entendido y
                          aceptado los términos del presente aviso de
                          privacidad. Este aviso de privacidad se pone a
                          disposición del titular al momento de recabar sus
                          datos personales, el cual lo consiente al momento de
                          dar click en el apartado correspondiente para
                          manifestar su consentimiento, disfrutar de los
                          servicios del responsable o firmar un tanto impreso de
                          este documento.
                          <br />
                          La oposición a la aceptación del presente aviso de
                          privacidad traerá como consecuencia la imposibilidad
                          para prestar el servicio por parte del titular. En
                          caso de no aceptar el presente aviso de privacidad, el
                          titular no debe proporcionar sus datos personales.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list three-line subheader style="text-align:justify;">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-center"
                          style="margin-top:10px;"
                        >
                          <strong
                            >Aviso de privacidad integral para
                            reclutadores</strong
                          >
                        </v-list-item-title>
                        <v-card-text>
                          Este aviso de privacidad integral fue redactado en fecha 19 de octubre de 2021 y actualizado en fecha 07 de mayo de 2022, con base en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (“Ley”) publicada en el Diario Oficial de la Federación (“DOF”) en fecha 05 de julio de 2010, el Reglamento de la Ley (“Reglamento”) publicado en el DOF en fecha 21 de diciembre de 2011 y los Lineamientos del Aviso de Privacidad publicados en el DOF el 17 de enero de 2013 (“Lineamientos”).
                        </v-card-text>
                        <v-card-text>
                          A través del presente aviso de privacidad, el responsable del tratamiento de sus datos personales pretende cumplir con el principio de información previsto en la Ley, al dar a conocer a los titulares de los datos personales la información relativa a la existencia y características principales del tratamiento al que serán sometidos los mismos. 
                        </v-card-text>
                        <v-card-text>
                          En ese sentido, a continuación se presenta el
                          contenido del presente aviso de privacidad integral,
                          conforme lo dispuesto en el lineamiento vigésimo de
                          los Lineamientos:
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>I. Responsable</v-list-item-title>
                        <v-card-text>
                         El responsable que tratará los datos personales de los titulares será la persona moral denominada Operadora Rochen de México, S.A. de C.V., comercialmente conocida como “Vacanta”, con domicilio ubicado en Calle Hacienda del Carrizal, número 3204, Colonia Las Haciendas, C.P. 31215, Chihuahua, Chihuahua, México. 
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >II. Datos personales</v-list-item-title
                        >

                        <v-card-text style="color:gray;">
                          A continuación se listan los datos personales que se
                          tratarán para la consecución de las finalidades que
                          más adelante se precisan, los cuales en su totalidad
                          son obtenidos directamente del titular:
                        </v-card-text>
                        <v-card-text>
                          1. Nombre completo en caso de ser persona física o
                          denominación y razón social en caso de ser una persona
                          moral.<br />
                          2. Nombre comercial.<br />
                          3. Registro Federal de Contribuyentes.<br />
                          4. Código postal, ciudad y estado donde se ubica.<br />
                          5. Giros, sectores, industrias o actividades
                          económicas. <br />
                          6. Nombre completo del reclutador y/o usuario.<br />
                          7. Correo electrónico del reclutador y/o usuario.<br />
                          8.	Número de teléfono celular, número de teléfono físico, número de contacto de la aplicación WhatsApp del reclutador y/o usuario.<br />
                          9.	Dirección de correo electrónico del reclutador y/o usuario.<br />
                          10.	Fotografía del reclutador y/o usuario.<br />
                          11.	Logotipo.<br>
                          12.	Descripción de las vacantes u oportunidades laborales para potenciales empleados o prestadores de servicios, en particular:<br>
                          a. Nombre del puesto.<br />
                          b. Rango salarial con mínimo y máximo. <br />
                          c. Descripción de las actividades sujetas al puesto.<br>

                          d. Requisitos de aptitudes, capacidades,
                          conocimientos, formación académica, experiencia
                          profesional previa y rango de edades. <br />
                          e. Ubicación del lugar donde se desarrollarían las
                          actividades. <br />
                          13. A aquellos titulares que soliciten comprobante
                          fiscal digital por Internet, les será solicitada su
                          información fiscal. <br />
                        </v-card-text>
                        <v-card-text>
                          El responsable conservará y estará autorizado para tratar los datos personales mientras los titulares estén registrados en el portal de internet que el responsable utilice para la prestación de los servicios y en el cual se hayan registrado los titulares directamente o a través del responsable, salvo que el titular ejerza su derecho a la cancelación de los mismos mediante el procedimiento correspondiente. 
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >III. Datos personales sensibles</v-list-item-title
                        >
                        <v-card-text>
                          El responsable no recabará ni tratará datos personales
                          sensibles de los titulares.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >IV. Finalidades del tratamiento</v-list-item-title
                        >
                        <v-card-text style="color:gray">
                          El responsable recabará y tratará los datos personales
                          de los titulares con las siguientes finalidades:
                        </v-card-text>
                        <v-card-text>
                          1. Identificar y ofrecerle información sobre posibles
                          oportunidades de trabajo o prestación de servicios que
                          se adecúen a su perfil. <br />
                          2. Procurarle un servicio eficiente y proveer una
                          mejor atención.<br />
                          3. Dar cumplimiento a obligaciones legales.<br />
                          4. Evaluar la calidad del servicio y realizar estudios
                          sobre hábitos de consumo. <br />
                          5. En su caso, para procesar los pagos electrónicos
                          por los servicios que den origen a la relación
                          jurídica entre el titular y el responsable.<br />
                          6. Generar y compartir información agregada que no
                          identifica al titular para fines analíticos y
                          estadísticos, que permite un mejor conocimiento de los
                          usuarios del portal web del responsable y permite
                          ofrecerles un mejor servicio.<br />
                          7. Mercadotecnia, publicidad y prospección comercial
                          de los productos y servicios que ofrezca el
                          responsable y los terceros a los que en su caso se le
                          transfieran los datos personales, en términos del
                          presente aviso de privacidad, la Ley, el Reglamento y
                          los Lineamientos. <br />
                          <br />
                          Para tal efecto, se precisa que todas las finalidades
                          del tratamiento de los datos personales que se recaban
                          del titular son necesarias para la existencia,
                          mantenimiento y cumplimiento de la relación jurídica
                          entre el titular y el responsable
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >V. Mecanismos para manifestar negativa al
                          tratamiento</v-list-item-title
                        >
                        <v-card-text>
                          Si el titular no está de acuerdo en que el responsable
                          realice el tratamiento de sus datos personales para
                          aquellas finalidades que no son necesarias para la
                          existencia, mantenimiento y cumplimiento de la
                          relación jurídica con el responsable, según lo
                          referido en el numeral anterior, podrá enviar un
                          mensaje en esos términos a la dirección de correo
                          electrónico info@vacanta.mx y el responsable estará
                          obligado a responder su solicitud conforme lo
                          dispuesto en la legislación aplicable.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VVI. Transferencias a terceros</v-list-item-title
                        >
                        <v-card-text style="color:gray">
                          Los datos personales podrán ser transferidos a
                          terceros nacionales o internacionales en los
                          siguientes términos:
                        </v-card-text>
                        <v-card-text>
                          1.	Podrán ser puestos a disposición del público en general mediante publicación en el portal de internet del responsable y transmitidos o puestos a disposición de terceros que se encuentren en búsqueda de oportunidades laborales o de prestación de servicios independientes y que posiblemente puedan contactar al titular con la intención de iniciar un proceso de reclutamiento y posiblemente aceptar un ofrecimiento de empleo o de prestación de servicios del titular. En ese sentido, en este momento no se tiene identificado un tercero receptor en específico al que se le transferirían los datos personales, sino que se transferirán a aquellos terceros que busquen empleos o prestar servicios con las características que ofrezca el titular, a criterio del responsable o los terceros receptores.<br />
                          2.	Podrán ser transferidos a terceros que tengan interés en ofrecerles productos o servicios, es decir, una transferencia con motivos de mercadotecnia, publicidad o prospección comercial. En ese sentido, en este momento no se tiene identificado un tercero receptor en específico, sino que dependerá de aquel tercero interesado en adquirir la información que haya recabado el responsable.<br />
                          3.	En su caso, podrán ser transferidos a aquellos terceros que procesen los pagos electrónicos o facturación por los servicios que den originen a la relación jurídica entre el titular y el responsable.<br />
                          4. 4.	Podrán ser transferidos a terceros que presten servicio de almacenamiento de información, quienes se obligarán contractualmente a mantener la confidencialidad de los datos y conforme al presente aviso de privacidad.  <br />
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VII. Cláusula de aceptación de
                          transferencia</v-list-item-title
                        >
                        <v-card-text>
                          Mediante el otorgamiento del consentimiento del presente aviso de privacidad integral, el titular otorga su aceptación a que el responsable pueda transferir sus datos personales en los términos referidos en el apartado anterior. 
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >VIII. Medios y procedimiento para ejercer los
                          derechos ARCO</v-list-item-title
                        >
                        <v-card-text>
                          El titular de los datos personales tiene derecho al
                          acceso, rectificación, cancelación y oposición
                          (“Derechos ARCO”) al tratamiento que les otorgue el
                          responsable. Para su ejercicio, el titular podrá
                          enviar un mensaje en sus términos a la dirección de
                          correo electrónico info@vacanta.mx.
                          <br />
                          El departamento de datos personales del responsable
                          será el encargado de dar trámite a las solicitudes de
                          los titulares para el ejercicio de los derechos ARCO,
                          en los términos previstos en la legislación aplicable,
                          a quien se puede contactar en la dirección de correo
                          electrónico info@vacanta.mx.
                        </v-card-text>
                        <v-card-text style="color:gray;">
                          El procedimiento para el ejercicio de los Derechos
                          ARCO será el siguiente:
                        </v-card-text>
                        <v-card-text>
                          1. El titular o quien se ostente como su representante
                          deberá escribir y firmar su solicitud de ejercicio de
                          los derechos ARCO de forma clara, completa, en idioma
                          español y con toda la información que permita al
                          responsable y a la persona por él encargada para dar
                          trámite a su solicitud comprender a plenitud cuál es
                          la solicitud del titular, cual es el Derecho ARCO en
                          específico que pretende ejercer y a cuáles de sus
                          datos personales se refiere. Asimismo, el titular
                          deberá proporcionar una dirección de correo
                          electrónico en la cual autorice recibir la respuesta
                          correspondiente por parte del responsable.<br />
                          2. En caso que sea el titular quien directamente
                          presente la solicitud, deberá acompañar a la misma
                          alguna identificación oficial vigente con fotografía
                          que le permita al responsable corroborar que
                          efectivamente se trata del titular de los datos
                          personales y que la firma de la solicitud coincide con
                          la de la identificación.<br />
                          3. En caso que sea un representante o apoderado del
                          titular quien presente la solicitud, deberá acompañar
                          a la misma los documentos que indudablemente lo
                          acrediten con tal carácter, además de su
                          identificación oficial vigente.<br />
                          4. El responsable comunicará al titular, en un plazo
                          máximo de veinte días, contados desde la fecha en que
                          se recibió la solicitud para ejercer alguno o varios
                          de los derechos ARCO, la determinación adoptada, a
                          efecto de que, si resulta procedente, se haga efectiva
                          la misma dentro de los quince días siguientes a la
                          fecha en que se comunicó la respuesta. Estos plazos
                          podrán ampliarse una sola vez por un periodo igual,
                          siempre y cuando así lo justifiquen las circunstancias
                          del caso. <br />
                          5. El responsable dará respuesta al titular a través
                          de un mensaje de correo electrónico a la dirección que
                          para tal efecto haya señalado el titular en su
                          solicitud inicial. <br />
                          6. El titular obtendrá la información o datos
                          personales que en su caso haya solicitado a través del
                          ejercicio del derecho de acceso, mediante documentos
                          electrónicos y mensajes compartidos vía correo
                          electrónico. <br />
                          7. Hasta el momento de la elaboración del presente
                          aviso de privacidad, el responsable no ha desarrollado
                          algún formulario, sistema o método simplificado en
                          específico para facilitar al titular el ejercicio de
                          sus derechos ARCO, con excepción de las comunicaciones
                          que el responsable envíe al titular vía correo
                          electrónico, en las cuales el titular podrá dar click
                          en la opción que para tal efecto se le proporcione en
                          el cuerpo del mensaje enviado por ese medio. <br />
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>IX. Revocación</v-list-item-title>
                        <v-card-text>
                          El titular podrá enviar un mensaje a la dirección de
                          correo electrónico info@vacanta.mx para manifestar la
                          revocación de su consentimiento al tratamiento de sus
                          datos personales, lo cual se resolverá por el
                          responsable conforme corresponda según la Ley, el
                          Reglamento y los Lineamientos, tomando en
                          consideración si es factible o no el mantenimiento y
                          cumplimiento de la relación jurídica con el
                          responsable sin esos datos personales o incluso si es
                          interés del responsable continuar prestando sus
                          servicios en los términos solicitados por el titular.
                          Para lo no previsto en el presente numeral, aplicará
                          lo dispuesto en el numeral VIII del presente
                          documento.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >X. Opciones para limitar el uso o
                          divulgación</v-list-item-title
                        >
                        <v-card-text>
                          En caso que el titular deseé limitar el uso o
                          divulgación de sus datos personales, podrá enviar un
                          mensaje con su intención a la dirección de correo
                          electrónico info@vacanta.mx, lo cual se resolverá por
                          el responsable conforme corresponda según la Ley, el
                          Reglamento y los Lineamientos, tomando en
                          consideración si es factible o no el mantenimiento y
                          cumplimiento de la relación jurídica con el
                          responsable sin esos datos personales o incluso si es
                          interés del responsable continuar prestando sus
                          servicios en los términos solicitados por el titular.
                          Para lo no previsto en el presente numeral, aplicará
                          lo dispuesto en el numeral VIII del presente
                          documento.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >XI. Cambios en el aviso de
                          privacidad</v-list-item-title
                        >
                        <v-card-text>
                          El contenido del presente aviso de privacidad
                          integral, y por ende el tratamiento de los datos
                          personales de los titulares, podrá ser modificado en
                          el futuro en cualquier momento, para lo cual se
                          publicará su versión actualizada en el portal de
                          internet: www.vacanta.mx y/o www.vacanta.com.mx, sin
                          necesidad de darse aviso en particular al titular por
                          parte del responsable.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >XII. Consentimiento</v-list-item-title
                        >
                        <v-card-text>
                          El consentimiento al presente aviso de privacidad
                          podrá otorgarse por el responsable de forma expresa o
                          tácita. Si el titular utiliza los servicios ofrecidos
                          por el responsable significa que ha leído, entendido y
                          aceptado los términos del presente aviso de
                          privacidad. Este aviso de privacidad se pone a
                          disposición del titular al momento de recabar sus
                          datos personales, el cual lo consiente al momento de
                          dar click en el apartado correspondiente para
                          manifestar su consentimiento, disfrutar de los
                          servicios del responsable o firmar un tanto impreso de
                          este documento.
                          <br />
                          La oposición a la aceptación del presente aviso de
                          privacidad traerá como consecuencia la imposibilidad
                          para prestar el servicio por parte del titular. En
                          caso de no aceptar el presente aviso de privacidad, el
                          titular no debe proporcionar sus datos personales.
                        </v-card-text>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
                </v-dialog>
              </v-row>
              <v-checkbox
                v-model="checkbox"
                :rules="[
                  (v) =>
                    !!v ||
                    'Tienes que seleccionar esta opción para registrarte',
                ]"
                label="He leído y acepto los términos y condiciones y el aviso de privacidad"
                required
              >
              </v-checkbox>
              <div></div>
              <p>¿Ya estas registrado?</p>
              <router-link :to="{ name: 'loginEmpresas' }">
                <a href="">Ingresa Ahora</a>
              </router-link>
              <v-flex class="red--text" v-if="errorM">
                {{ errorM }}
              </v-flex>
            </v-card-text>
          </v-form>
          <div class="text-center">
            <v-card-actions class="px-3 pb-3">
              <v-flex text-cs-right>
                <v-btn
                  :disabled="!valid"
                  btn
                  @click="registrar()"
                  color="primary"
                  style="color:black;"
                  >Registrar</v-btn
                >
              </v-flex>
            </v-card-actions>
          </div>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      token:
        "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",

      valid: true,
      estados: [], // <-- La lista de edo
      evento: "",
      ciudades: [], // <-- La lista de cd
      mievent: null,
      value: String,
      mask: "AAAN#####NNNN",
      items: ["Busco trabajo", "Busco personal"],
      correo: "",
      errors: [],
      rfc: "",
      checkbox: false,
      razon: "",
      password: "",
      dialogo: false,
      dialogoTer: false,
      nombre: "",
      apellido: "",
      errorM: null,

      rulesEmail: [
        (v) => !!v || "E-mail es necesario",
        (v) =>
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(v) ||
          "E-mail no valido",
      ],
      nombrecontacto: [(v) => v.length < 30 || "Maximo 30 Caracteres"],
      nombrempresa: [(v) => v.length < 81 || "Maximo 80 Caracteres"],
      rfcRules: [(v) => v.length < 13 || "Maximo 13 Caracteres"],
      passwordRules: [(v) => v.length > 5 || "Minimo 6 Caracteres"],
    };
  },
  computed: {
    ...mapState(["elestado"]),
  },
  methods: {
    ...mapMutations(["setState", "setCiudad"]),
    uppercase() {
      this.rfc = this.rfc.toUpperCase();
    },
    registrar() {
      if (this.$refs.form.validate()) {
        if (
          this.nombre &&
          this.password &&
          this.correo &&
          this.rfc &&
          this.razon &&
          this.evento &&
          this.mievent
        ) {
          axios
            .post("/empresas/registro", {
              correo: this.correo,
              nombre: this.nombre,
              apellido: "",
              password: this.password,
              rfc: this.rfc,
              razon: this.razon,
              edo: this.evento,
              cd: this.mievent,
            },  { headers: {
      Authorization: 'Bearer ' + this.token}
   })
            .then((response) => {
              console.log(response.data);
              Swal.fire({
                title: "¡Buen trabajo!",
                text:
                  "¡Te registraste exitosamente! Seras redirigido a el Login'",
                showCancelButton: false,
                confirmButtonColor: "#2afed6",
                cancelButtonColor: "#2afed6",
              });
            })
            .then((data) => {
              this.$router.push({ name: "loginEmpresas" });
            })
            .catch((error) => {
              console.log(error);
              this.errorM = null;
              if (error.response.status == 401) {
                this.errorM = "Error ";
              } else if (error.response.status == 400) {
                this.errorM = "La empresa ya existe";
              } else {
                this.errorM = "Ocurrio un error con el servidor";
              }
            });
        }
      }
      this.errors = [];
      if (!this.nombre) this.errors.push("Nombre es requerido.");
      if (!this.correo) this.errors.push("Correo es requerido.");
      if (!this.password) this.errors.push("Password es requerido.");
      if (!this.rfc) this.errors.push("Rfc es requerido.");
      if (!this.razon) this.errors.push("Razon Social es requerido.");
      if (!this.evento) this.errors.push("Estado es requerido.");
      if (!this.mievent) this.errors.push("Ciudad es requerido.");
    },
  },
  mounted() {
    axios
      .get("/catalogos/estados", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.estados = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    elestado: function(value) {
      axios
        .get("/catalogos/ciudades/" + this.elestado, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
        .then((response) => {
          this.ciudades = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.v-application a {
  color: black !important ;
}
.rfc input {
  text-transform: uppercase;
}
</style>
