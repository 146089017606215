<template>
 <v-container>
   <v-alert v-if="this.vacantes==''"
  type="warning"
> Aun no te has postulado a niguna vacante.</v-alert>
   
   <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card
          class="tarjeta"
          style="background-color:#ddd"
          v-for="vacante in vacantes"
          :key="vacante.folio"
        >
          <v-container fluid style="background-color:black;height:140px; ">
            <v-row>
              <v-col cols="6" class="text-center">
                <p class="vacantePosicion" style="color:white; text-align:center;">
                {{ vacante.posicion }}
                </p>

                <h3 style="color:#FFC000; " class="vacanteEmpresa">
                  <v-icon>mdi-office-building</v-icon> {{ vacante.razonsocial  }}
                </h3>
              </v-col>
              <v-col cols="6">
                <p
                class="vacanteRango"
                  style="background-color:#2afed6; padding:10px;text-align:center; border-radius:12px;"
                >
                  <strong>
                    Rango de Sueldo <br />
                    {{
                      vacante.sueldo_raninf.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    }}

                    -
                    {{
                      vacante.sueldo_ransup.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    }}
                  </strong>
                </p>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row class="text-center" id="pc">
              <v-col cols="3">
                <h4 style="background-color:black; font-size:16;color:white; border-radius:6px; width:fit-content;padding:5px;">
                 Jornada: {{ vacante.tipo_jornada }}
                </h4>
              </v-col>
              <v-col cols="3" style="margin-left:-20px;">
                <h4 style="background-color:black; font-size:16;color:white; border-radius:6px; width:fit-content;padding:5px;">
                 Modalidad: {{ vacante.tipo_area }}
                </h4>
              </v-col>
              <v-col cols="3" style="margin-left:80px;">
                <h4 style="background-color:black; font-size:16;color:white; border-radius:6px; width:fit-content;padding:5px;margin-left:-90px;">
                 Contratación: {{ vacante.tipo_contratacion}}
                </h4>
              </v-col>
            </v-row>
             <v-row class="text-center" id="cel">
              <v-col cols="6">
                <h4 style="background-color:black; font-size:16;color:white; border-radius:6px; width:fit-content;padding:5px;">
                 Jornada: {{ vacante.tipo_jornada }}
                </h4>
              </v-col>
              <v-col cols="12" >
                <h4 style="background-color:black; font-size:16;color:white; border-radius:6px; width:fit-content;padding:5px;">
                 Modalidad: {{ vacante.tipo_area }}
                </h4>
              </v-col>
              <v-col cols="12" >
                <div class="text-center">
                                  <h4 style="background-color:black; font-size:16;color:white; border-radius:6px; width:fit-content;padding:5px;">
                 Contratación: {{ vacante.tipo_contratacion}}
                </h4>

                  
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <br>
                <h2>
                  Descripción de la Vacante
                </h2>
                <br>
                                                <v-textarea style="font-size:16;color:black; border-radius:6px;padding:5px;" outlined v-model="vacante.descripcion" name="" id=""  rows="7"></v-textarea>   

               
              </v-col>
              <v-col cols="4">
                <div class="text-center">

           
                <p><strong>Nivel Educativo Mínimo</strong></p>
                <p style="background-color:#FFC000; font-size:16;color:black; border-radius:6px; padding:5px;"><strong>{{vacante.nivel_educativo}}</strong></p>
                <div v-if="vacante.desc_corta">

                
                                <p><strong>Carreras</strong></p>
                                <strong>
                                  <div id="pc">
                                                    <v-textarea outlined style="background-color:#FFC000;height:67px; font-size:16;color:black; border-radius:6px; text-align:center;"  v-model="vacante.desc_corta" name="" rows="2"></v-textarea>   

                                  </div>
                                  <br>
               <textarea id="cel" style="background-color:#FFC000; font-size:16;color:black; border-radius:6px;padding:5px; text-align:center;"   wrap="soft" v-model="vacante.desc_corta" name=""  cols="8" rows="1"></textarea>   

                </strong>             </div>
                <p><strong>Años de Experiencia</strong></p>
                <p style="background-color:#FFC000; font-size:16;color:black; border-radius:6px;padding:5px;"><strong>{{vacante.experiencia}}</strong></p>
                                <p><strong>Rango de edad</strong></p>
                <p style="background-color:#FFC000; font-size:16;color:black; border-radius:6px ;padding:5px;"><strong>{{vacante.edad_inf}}-{{vacante.edad_sup}}</strong></p>
     </div>
              </v-col>
              
            </v-row>
         

            <v-row>
            
<v-row>
                <v-col md="4" class="text-center">
                  <br>
                   <p>
                     <strong>
                     Lugar:</strong></p>
                     <p style="background-color:#2afed6; border-radius:6px; ">
                       <strong>
                     {{ vacante.ciudad }} ,
                    {{ vacante.estado }}
                    </strong>
                  </p>
                 
                </v-col>
                <v-col md="4" xs="6" class="text-center">
                  <br>
                   <p><strong>
                    Fecha de publicación</strong></p>
                    <p style="background-color:#2afed6; border-radius:6px;"><strong>
                    {{ vacante.fechacreacion }}
                  </strong>
                 </p>
                </v-col>
                <v-col md="4" xs="12" class="text-center">
                  <br>
                   <p><strong>
                    Área: </strong></p>
                    <p  style="background-color:#2afed6; border-radius:6px;"><strong>
                    {{
                      vacante.sectordesc
                    }}</strong>
                  </p>

                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

  
 </v-container>
</template>

<script>
const hoy = moment();
 import { mapState } from 'vuex'
import moment from 'moment'
import axios from 'axios';
moment.locale("es-mx");

export default {
    name: "MisPostulaciones",
    data(){
      return {
        moment: moment,
        vacantes: '',
        fecha: null,

      }
    },
       computed:{
      ...mapState (['VacanteFolio']),
     logueado() {
      return this.$store.state.token;
    }
       },
            mounted(){
               this.$store.dispatch("autoLogin")
      axios.get("/candidatos/vacantescandidato/" + this.$store.state.id.id, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   } )
      .then(response => {
          this.vacantes= [];
           this.vacantes = response.data
        })
        .catch(error=>{
            console.log(error);
        })
    }
}
</script>

<style >
.botonAviso2{
  background-color:black; color:white; border-radius:20px; font-size:15px; padding:10px; margin-left:0px;
  margin-top: 40px !important; 
}
.tarjeta{
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  /* Este es el celular */
  #pc{
    display:none;
  }
.vacantePosicion{
     font-size:14px !important;
  }
  .vacanteEmpresa{
    font-size: 14px;
  }
  .vacanteRango{
    font-size: 13px;;
  }
  
}


@media only screen and (max-width: 430px) and (max-height: 930px){
  #pc{
    display: none;
  }
}
@media only screen and (min-width: 868px) {
  /* Esta es la pc */
  .vacantePosicion{
     font-size:30px !important;
  }
  #cel {
    display: none;
  }
  .vacanteRango{
    font-size: 17px;
  }
}
</style>