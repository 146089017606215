<template>
  <v-container fluid>
    <v-alert v-if="this.respuesta" class="primary" style="color:black;">
      {{ this.respuesta.msg }}
    </v-alert>
    <v-alert v-if="this.mirespuesta" class="primary" style="color:black;">
      Tu candidato se a seleccionado correctamente
    </v-alert>
    <v-row>
      <v-flex md12 xs12>
        <v-col>
        <v-card style="padding:18px;">
          <v-row>
            <v-col cols="6"> <strong>Fecha: {{moment(fecha).format('LL')}}</strong></v-col>
            <v-col cols="6">
               <v-dialog v-model="dialogos" persistent max-width="570">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            style="color:black;"
                          >
                            Desactivar Vacante
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h6">
                             ¿Estas seguro que deseas desactivar la vacante?
                          </v-card-title>
                          <v-card-text
                            >Al desactivar la vacante dejara de aparecer en el listado de
vacantes.
                                      </v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            
                            <div class="text-center">
                              <v-btn
                              color="primary darken-1"
                              text
                              @click="dialogos = false"
                            >
                              Cancelar
                            </v-btn>
                            <v-dialog v-model="dialogonuevo" persistent max-width="570">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            style="color:black;"
                          >
                            Aceptar
                          </v-btn>
                        </template>
                        <v-card>
                          <v-alert
                icon="mdi-alert"
                color="primary"
                v-if="errors.length"
              >
                <b>Por favor selecciona una opción:</b>
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
                          
                          <v-card-title class="text-h6">
                            La vacante ha sido desactivada, por favor selecciona la
opción por la cual fue desactivada:

                          </v-card-title>
                          <v-card-text
                            >

                            <v-radio-group
                      v-model="motivo"
                      
                     style="margin-left:10px"
                      @change="seleccionaMotivo"
                      type=""
                    >
                      <v-radio label="La vacante ya fue cubierta"></v-radio>
                      <v-radio label="Otros motivos."></v-radio>
                    </v-radio-group>
                                      </v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            
                            <div class="text-center">
                              
                            <v-btn
                              color="primary darken-1"
                              text
                              @click="prueba()"
                            >
                              Aceptar
                            </v-btn>

                            </div>
                            
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                            </div>
                            
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
              <!-- <v-btn id="pc" @click="prueba()" color="primary" style="color:black">
                Desactivar Vacante<v-icon>mdi-cancel</v-icon></v-btn>
               <v-btn small id="cel" @click="prueba()" color="primary" style="color:black; margin-left:-25px;">
                Desactivar Vacante<v-icon>mdi-cancel</v-icon></v-btn> -->
              
              </v-col
            >
            <v-col cols="12">
              <h2>Información del puesto</h2>
            </v-col>
            <v-col cols="6">
              <h4>Posición</h4>
              <p>{{ posicion }}</p>

              <h4>Descripción del puesto</h4>
              <textarea disabled wrap="soft"  v-model="descripcion" name="" id="" cols="60" rows="4"></textarea>
              
            </v-col>

            <v-col cols="6">
              <h4>Experiencia en el Puesto</h4>
              <p>{{experiencia}} años</p>

              <h4>Horario</h4>
              <textarea disabled wrap="soft"  v-model="descripcionCorta" name="" id="" cols="60" rows="4"></textarea>
              
            </v-col>

            <v-col cols="12">
              <v-divider> </v-divider>
              <br />
              <h2>Prestaciones y tipo de contratación</h2>
            </v-col>

            <v-flex md4 xs6>

           
            <v-col >
              <h4>Sueldo (rango inf)</h4>
              <p>
                {{
                  sueldo_inf.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
              </p>

              <h4>Colonia</h4>
              <p>{{ area }}</p>
            </v-col>
            </v-flex>
            <v-flex md4 xs6>

           
            <v-col >
              <h4>Sueldo (rango sup)</h4>
              <p>
                {{
                  sueldo_sup.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
              </p>

              <h4>Contratación</h4>
              <p>{{ contratacion }}</p>
            </v-col>
             </v-flex>
            <v-col cols="4">
              <h4>Jornada</h4>
              <p>{{ jornada }}</p>
            </v-col>
            <v-col cols="12">
              <v-divider> </v-divider>
              <br />
            </v-col>
            <v-col cols="4">
              <h4>Localidad</h4>
              <p>{{ estado }}, {{ ciudad }}</p>
            </v-col>
            <v-col cols="4">
              <h4>Rango de edad</h4>
              <p>Desde: {{ edadmenor }} años - Hasta: {{ edadmayor }} años</p>
            </v-col>
            <v-col cols="4">
              <h4>Años de experiencia</h4>
              <p>{{ experiencia }} años.</p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      </v-flex>
      
    </v-row>
  </v-container>
</template>

<script>
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
var moment = require("moment");
moment.locale("es-mx");
import perfil from "../Perfil.vue";
import axios from "axios";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
            errors: [],
         motivo: null,
      confirmaMotivo: null,
      dialogonuevo: false,
      moment: moment,
      posicion: "",
      descripcion: "",
      laespe: "",
      descripcionCorta: "",
      subespe: "",
      fecha: null,
      sueldo_inf: "",
      dialogo: false,
       dialogos: false,
      dialog: false,
      sueldo_sup: "",
      jornada: "",
      area: "",
      contratacion: "",
      fechaContrato: null,
      estado: "",
      ciudad: "",
      experiencia: "",
      posts: [],
      misespecialidades: [],
      subespes: [],
      nombreSector: "",
      nombreGiro: "",
      candidato: [],
      idiomas: [],
      educacion: [],
      token: "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c",
      trabajos: [],
      especialidades: [],
      cursos: [],
      habilidades: [],
      respuesta: null,
      miestatus: null,
      respuesta: null,
      edadmenor: null,
      edadmayor: null,
      cancelada: null,
      micandidato: null,
      cubierta: null,
      mirespuesta: null,
    };
  },
  computed: {
    ...mapState(["VacanteFolio"]),
  },
  methods: {
     seleccionaMotivo() {
      if (this.motivo == 0) {
        this.confirmaMotivo = 1;
      }
      if (this.motivo == 1) {
        this.confirmaMotivo = 2;
      }
      if (this.motivo == 2) {
        this.confirmaMotivo = 3;
      }
    },
    probando() {
      dialogo = true;
    },
    seleccionaCandidato() {
      axios
        .post(
          "/vacantes/cubrevaacante",
          {
            candidato: this.micandidato,
            vacante: this.$store.state.VacanteFolio,
          }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   }
        )
        .then((response) => {
          console.log(response.data);
          this.dialog = false;
          this.mirespuesta = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    prueba() {
       this.errors = [];

      if (
        this.confirmaMotivo
        
      ) {
              console.log("motivo: ", this.confirmaMotivo, "vacante: ", this.$store.state.VacanteFolio)
      axios
        .post(
          "/vacantes/motivocancelavacanteOperativa",
          {
            motivo: this.confirmaMotivo,
            vacante: this.$store.state.VacanteFolio,
          }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   }
        )
        .then((response) => {
          console.log(response.data);
          this.dialogonuevo = false;
          this.dialogos= false;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .post(
          "/vacantes/cancelavacanteOperativa",
          { folio: this.$store.state.VacanteFolio }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   }
        )
        .then((response) => {
          console.log(response.data);
          this.respuesta = response.data;
          this.dialogos= false;
                      this.$router.push({ name: "PerfilEmpresas" });

        })
        .catch((error) => {
          console.log(error);
        });

      }

      if (!this.confirmaMotivo) this.errors.push("Motivo por el cual desactivas la vacante:");
    },
    infoVacante(post) {
      this.micandidato = post.folio;
      console.log(post.folio)
      axios
        .get("/candidatos/" + post.folio , { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
        .then((response) => {
          this.candidato = response.data;
          this.idiomas = response.data.idiomas;
          this.educacion = response.data.educacion;
          this.trabajos = response.data.exp_profesional;
          this.misespecialidades = response.data.especialidades;
          this.cursos = response.data.cursos;
          this.habilidades = response.data.habilidades;
      
        })
        .catch((error) => {
          console.log(error);
        });
         axios
        .post(
          "/vacantes/avisovista",
          { candidato: this.micandidato , vacante:this.$store.state.VacanteFolio}, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   }
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
       
    },
  },
  beforeCreate() {
    axios
      .post("/vacantes/vacantesOperativoempresa/", {
        id: this.$store.state.VacanteFolio,
        empresa: this.$store.state.id.empresa,
        buscar: "%VACIA%",
        activa: true,
        desde: "2021-06-01",
        hasta: "2022-12-30",
        paginacion: 3,
        pagina: 1
      }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
      .then((response) => {
        this.posicion = response.data[0].posicion;
        this.descripcion = response.data[0].descripcion;
        this.laespe = response.data[0].sector;
        this.subespe = response.data[0].giro;
        this.fecha = response.data[0].fechacreacion;
        this.descripcionCorta = response.data[0].desc_corta;
        this.sueldo_inf = response.data[0].sueldo_raninf;
        this.sueldo_sup = response.data[0].sueldo_ransup;
        this.jornada = response.data[0].tipo_jornada;
        this.sueldo_sup = response.data[0].sueldo_ransup;
        this.jornada = response.data[0].tipo_jornada;
        this.area = response.data[0].desc_ubicacion;
        this.contratacion = response.data[0].tipo_contratacion;
        this.fechaContrato = response.data[0].fechacreacion;
        this.estado = response.data[0].estado;
        this.ciudad = response.data[0].ciudad;
        this.experiencia = response.data[0].experiencia;
        this.edadmenor = response.data[0].edad_inf;
        this.edadmayor = response.data[0].edad_sup;
        this.cancelada = response.data[0].cancelada;
        this.cubierta = response.data[0].cubierta;
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error);
      });

   
  },
  components: {
    perfil,
  },
  mounted(){
     axios
      .get(
        "/vacantes/candidatosxvacante/" +
          this.$store.state.VacanteFolio
      , { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
      .then((response) => {
        this.posts = response.data;
      console.log(response.data)

      })
      .catch((error) => {
        console.log(error);
      });
     this.$store.dispatch("autoLogin");
    

    axios
      .get(
        "/catalogos/subespe/" +
          this.laespe
      , { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.subespes = response.data;
        const g = this.subespes.find((elemento) => {
          return elemento.folio === this.subespe;
        });
        this.nombreGiro = g.sub;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  beforeUpdate() {
    
  },
};
</script>

<style>
.miimagen {
  width: 220px;
  border-radius: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.tarjeta-trabajos {
  padding: 25px;
  border-radius: 15px;
  background-color: darkgray !important;
}
.tarjeta-postulaciones {
  padding: 25px;
  border-radius: 15px;
  background-color: darkgray !important;
}
</style>
