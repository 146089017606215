<template>
  <v-container fluid>
<!--     <div v-if="!this.foto">
    
          <v-alert   color="secondary" style="color:black;">
      Tu perfil no muestra Fotografía  </v-alert
    >
      
    </div>
     <div v-if="!this.curriculum">
    
          <v-alert   color="secondary" style="color:black;">
      Tu perfil no tiene un Curriculum  </v-alert
    >
      
    </div> -->

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-toolbar dark prominent  class="hola">
            <v-container>
              <v-row>
                
                <v-col>
                  <div class="text-center">
                                      <h2 class="nombre text-center">{{ this.nombre }} {{ this.apellido }}</h2>
                  <h3 class="titulo">{{ this.puesto }}</h3>


                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-spacer></v-spacer>
            <div class="espaciador"></div>
          </v-toolbar>
          <v-container fluid >
            <v-row id="pc" class="gris">
              <v-col cols="2">
                <div v-if="this.nacimiento">
                  <p style="font-size:20px;" >
                    
                    Edad:
                    <strong>
                    
                    {{ moment().diff(this.nacimiento, "years", false) }} años
                    </strong>
                  </p>
                </div>

               

              </v-col>
              <v-col cols="4">
                

                <p style="font-size:20px;" >
                  
                  Vive en:
                  <strong>
                    {{ this.estado }} ,
                  {{ this.ciudad }}
                   </strong>
                </p>
                

              </v-col>
              <v-col cols="3">
                
                  
                 <p style="font-size:20px;" >
                  
                  Email:
                  <strong>
                    {{ this.correo }}
                  </strong>
                </p>

              </v-col>
              <v-col cols="3" >
               
                
                  <div v-show="this.celular" class="celularphone" style="font-size:20px;">
                                     Tel:
                                   
                <input 
                  style="margin-left:5px;font-size:20px;font-weight:bold;"
                  v-mask="mask"
                  v-model="this.celular"
                 
                />
                  </div>
                  <div class="cel" >
                                     
                <input
                  style="margin-left:5px;font-size:20px;font-weight:bold;"
                  v-model="this.celular"
                 
                />
                  </div>
                
                
                
                <!-- // Aqui esta el error plebe -->
              </v-col>
            </v-row>
             <v-row id="cel" class="gris">
              <v-col cols="12">
                <div v-if="this.nacimiento">
                  <p style="font-size:20px;" >
                    
                    Edad:
                    <strong>
                    
                    {{ moment().diff(this.nacimiento, "years", false) }} años
                    </strong>
                  </p>
                </div>

               

              </v-col>
              <v-col cols="12">
                

                <p style="font-size:20px;" >
                  
                  Vive en:
                  <strong>
                    {{ this.estado }} ,
                  {{ this.ciudad }}
                   </strong>
                </p>
                

              </v-col>
              <v-col cols="12">
                
                  
                 <p style="font-size:20px;" >
                  
                  Email:
                  <strong>
                    {{ this.correo }}
                  </strong>
                </p>

              </v-col>
              <v-col cols="12" >
               
                
                  <div v-show="this.celular" class="celularphone" style="font-size:20px;">
                                     Tel:
                                   
                <input 
                  style="margin-left:5px;font-size:20px;font-weight:bold;"
                  v-mask="mask"
                  v-model="this.celular"
                 
                />
                  </div>
                  <div class="pcel" >
                                 Tel:    
                <input
                  style="margin-left:5px;font-size:20px;font-weight:bold;"
                  v-model="this.celular"
                 
                />
                  </div>
                
                
                
                <!-- // Aqui esta el error plebe -->
              </v-col>
            </v-row>
          </v-container>

          <br />
        <v-row>
          <v-col>
            <v-card class="mb-12" color="#e0e0e0">
          <div class="" style="padding:20px;">
            <h1 class="text-center">Áreas</h1>
            <br />
            <h4 class="text-center">
              Agrega el área y la subárea en donde mas tengas experiencia.<br />
              Puedes agregar como máximo 5 <br>
              Recibirás un correo informativo cuando se publique una vacante de las áreas que tengas agregadas
            </h4>
            <br />
            <v-row style="margin-bottom:18px;">
              <v-col cols="6">
                <v-autocomplete
                  outlined
                  return-object
                  v-model="event"
                  :click="setEspecialidad(event)"
                  clearable
                  :items="misespecialidades"
                  label="Área"
                  color="primary"
                  item-text="especialidad"
                  item-value="value"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                  outlined
                  return-object
                  v-model="miivent"
                  :click="setsubEspecialidad(miivent)"
                  label="Subárea"
                  clearable
                  :items="subespecialidades"
                  item-text="sub"
                  item-value="value"
                  name="nombre"
                  id="nombre"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <div class="text-center">
                  <v-btn
                    v-on:click="agregarEspecialidad()"
                    color="primary"
                    style="color:black;"
                  >
                    Agregar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row style="margin-top:18px">
              <v-col cols="12" style="margin-top:20px;">
                <p style="text-align:center;">
                  <strong>Áreas y Subáreas Agregadas</strong>
                </p>
                <div class="text-center">
                  <table class="text-center">
                    <tbody>
                      <tr
                        v-for="(especialidad, index) of especialidades"
                        :key="especialidad.folio"
                        style="padding:5px;"
                      >
                        <v-col cols="6"> </v-col>
                        <td style="padding:5px;">
                          <v-text-field
                            v-model="especialidad.especialidad"
                            outlined
                            disabled
                            label="Área"
                            required
                          ></v-text-field>
                        </td>
                        <td style="padding:5px;">
                          <v-text-field
                            v-model="especialidad.sub"
                            outlined
                            label="Subárea "
                            required
                            disabled
                          ></v-text-field>
                        </td>

                        <v-btn
                          color="primary"
                          v-on:click="borrarEspecialidad(index)"
                          style="margin-top:13px;color:black;"
                        >
                          Borrar</v-btn
                        >
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <div class="text-center" v-if="this.especialidades.length < 6">
          
          <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary" 
          dark
          v-bind="attrs"
          v-on="on"
          @click="agregarmisespecialidades()"
        >
          Guardar
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2 text-center">
          Guardado con éxito
        </v-card-title>

        <v-card-text>
          Tus áreas y subáreas han sido guardadas exitosamente, mantenlas actualizadas.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-container>
            <div class="text-center">
                     <v-btn
            color="primary"
            style="color:black;"
            @click="dialog = false"
          >
            Aceptar
          </v-btn>
            </div>
          </v-container>
   
        </v-card-actions>
      </v-card>
    </v-dialog>

         
        </div>
        <div class="text-center" v-else>
          <v-btn color="primary" style="color:black;">
            Has seleccionado mas de 5 especialidades
          </v-btn>
          
        </div>
          </v-col>
        </v-row>
         <!--  <v-row>
            <v-col>
              <div class="text-center" v-if="this.curriculum">
                <a
                  target="_blank"
                  style="color:#FFC000;"
                  :href="this.curriculum"
                  >Revisa tu CV</a
                >
                <br />
                <br />
                <v-btn
                  class="text-center"
                  color="secondary"
                  style="margin-left:20px;color:black"
                  @click="eliminarCv()"
                >
                  Eliminar CV <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </div>
              <div class="text-center" v-else>
                <p>
                  Sube tu Curriculum
                </p>
              </div>

              <br />
              <br />

              <div class="text-center">
                <v-dialog width="500" color="secondary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      style="color:black;"
                    >
                      Adjunta tu CV
                      <v-icon style="margin-left:10px">mdi-attachment</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-alert type="primary" v-if="avisoVitae">
                      {{ this.avisoVitae }}
                    </v-alert>
                    <v-alert type="warning" v-if="errorVitae">
                      {{ this.errorVitae }}
                    </v-alert>
                    <div style="padding:10px;">
                      <v-card-title class="text-h5 grey lighten-2">
                        Adjuntar Cv
                      </v-card-title>
                      <br />
                      <p style="margin-bottom:10px;">
                        El tamaño de el curriculum debe ser menor de 1 mb y
                        tener formato PDF.
                      </p>
                      <br />
                      <v-file-input
                        placeholder="Escoge tu Curriculum"
                        label="Curriculum Vitae"
                        v-model="file"
                        outlined
                      ></v-file-input>
                    </div>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        x-large
                        @click="subirVitae()"
                        class="text-center"
                      >
                        Adjuntar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <br />
              <br />
              <div class="text-center">
                <router-link style="text-decoration: none;" :to="{ name: 'datospersonales' }">
                  <v-btn color="secondary" style="color:black; "
                    >Editar Datos
                    <v-icon style="margin-left:15px"
                      >mdi-pencil-outline</v-icon
                    ></v-btn
                  >
                </router-link>
                <br />
              </div>
            </v-col>
            <v-col>
              <v-card class="tarjeta-trabajos">
                <h2>Mis Trabajos</h2>
                <br />
                <div v-for="value in trabajos" :key="value.empresa">
                  <v-row>
                    <v-col cols="6">
                      <h4>
                        {{ value.empresa.toUpperCase() }} -
                        {{ value.puesto.toUpperCase() }}
                      </h4>
                    </v-col>
                    <v-col cols="6">
                      <v-chip
                        v-if="value.actual"
                        color="primary"
                        style="color:black;"
                      >
                        Trabajo Actual</v-chip
                      >
                    </v-col>
                    <v-col cols="12"> 
                    
                <textarea style="font-size:13px ;color:black;padding:5px;margin-bottom:10px;"   disabled wrap="soft" v-model="value.descripcion" name="" id="" cols="70" rows="2"></textarea>   

                      <br>
                       </v-col>
                  </v-row>

                  <h5>Fecha: {{ value.inicio }} - {{ value.final }}</h5>
                  

                  <br />
                </div>
              </v-card>
              <br />
              <v-card class="tarjeta-trabajos">
                <h2>Mis Cursos</h2>
                <br />
                <div v-for="value in cursos" :key="value.curso">
                  <h4>{{ value.curso.toUpperCase() }}</h4>
                  <br />
                  <p>{{ value.descripcion }}</p>
                  <br />
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="tarjeta-postulaciones">
                <h2 class="text-center">Estudios</h2>
                <br />
                <div v-for="value in educacion" :key="value.nivel">
                  <h4>
                   <strong style="color:#2afed6;">Nivel Educativo: </strong>
                   {{value.nivel}}<br>
                    <strong style="color:#2afed6;">Titulo: </strong>{{ value.titulo }} <br><strong style="color:#2afed6;">Institución: </strong>
                    {{ value.institucion }}  
                    <br />
                   <strong style="color:#2afed6;"> Fecha:</strong> {{ value.ano_inicio }} - {{ value.ano_final }}
                   </h4>
                   <br>
                   <h5 class="text-center"  style="background-color:#2afed6; width:auto; border-radius:5px; display: block;
margin-left: auto;
margin-right: auto;" v-if="value.actual">Estudio Actual</h5>
                  <br />
                </div>
                <h2 class="text-center">Áreas de Experiencia</h2>
                <br />
                <div v-for="value in especialidades" :key="value.sub">
                  <h4>
                    <strong style="color:#2afed6;">
                    Área:</strong>
                      {{ value.especialidad }}
                    <br>
                    
                    <strong style="color:#2afed6">
                     Subárea:</strong>
                    {{ value.sub }}
                  </h4>
                  <br />
                </div>
                <br />

                <h2 class="text-center">Habilidades</h2>
                <br />
                <div v-for="value in habilidades" :key="value.folio">
                  <h4>{{ value.habilidad }}</h4>
                  <br />
                </div>
                <h2 class="text-center">
                  Idiomas:
                </h2>
                <div v-for="value in idiomas" :key="value.idioma">
                  <br />
                  <h4> <strong style="color:#2afed6;"> {{ value.idioma }}</strong> - {{ value.nivel }}</h4>
                </div>
              </v-card>
            </v-col>
          </v-row> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
var moment = require("moment");
moment.locale("es-mx");
import axios from "axios";
import { mapMutations, mapState } from "vuex";

const hoy = moment();

export default {
  name: "Vacante",
  data() {
    return {
      dialog: false,
      ultimoNivel: null,
      mask: "(###) ###-##-##",
      moment: moment,
      fechahora: null,
      vacanteFolio: null,
      usuario: null,
      fecha: null,
      educacion: [],
      idiomas: [],
      trabajos: [],
      cursos: [],
      especialidades: [],
            misespecialidades: [],
      missubespecialidades: [],
      subespecialidades: [],
      miivent: "",
      habilidades: [],
      file: null,
      avisoFoto: "",
      errorM: "",
      avisoVitae: "",
      errorVitae: "",
      foto: "",
      event:"",
      nombre: "",
      apellido: "",
      puesto: "",
      estado: "",
      ciudad: "",
      activo: "",
      salarioMayor: "",
      salarioMenor: "",
      celular: "",
      nacimiento: "",
      correo: "",
      curriculum: null,
      trabajando: null,
    token: "76636bbc7c3721cc360a22b9278f38eed6b9f25cd5885e066a745d5327cfea1bb9e704a211d817b913f3dc9381c81b59850e7961c66cb07fa2574f16d8ef0d7c" 
    };
  },

   watch: {
    nuevaespecialidad: function(value) {

      axios
        .get("/catalogos/subespe/" + this.nuevaespecialidad.folio, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
        .then((response) => {
          this.subespecialidades = response.data;
          console.log("prueba: ",response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },
  mounted(){
 axios
      .get("/catalogos/especialidades", { headers: {
      Authorization: 'Bearer ' + this.token}
   })
      .then((response) => {
        this.misespecialidades = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  beforeMount() {
this.$store.dispatch("autoLogin");


    axios
      .get("/candidatos/"+ this.$store.state.id.id, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
      
      .then((response) => {
        this.usuario = response.data;
        this.educacion = response.data.educacion;
        this.idiomas = response.data.idiomas;
        this.trabajos = response.data.exp_profesional;
        this.cursos = response.data.cursos;
        this.especialidades = response.data.especialidades;
        this.habilidades = response.data.habilidades;
        this.fecha = response.data.nac2;
        this.foto = response.data.foto;
        this.nombre = response.data.nombre;
        this.apellido = response.data.apellido;
        this.puesto = response.data.OP_puesto;
        this.estado = response.data.estado;
        this.ciudad = response.data.ciudad;
        this.activo = response.data.activo;
        this.salarioMayor = response.data.op_salario_hi;
        this.salarioMenor = response.data.OP_salario;
        this.celular = response.data.celular;
        this.nacimiento = response.data.nac2;
        this.correo = response.data.correo;
        this.curriculum = response.data.curriculum;

        this.trabajando = response.data.laboral[0].status
      })
      .catch((error) => {
        console.log(error);
      });
    
  },

    computed: {
    ...mapState([
      "fechaNac",
    "id",
    "elestado",
    "nuevaespecialidad",
      "nuevasubespecialidad",]),
      logueado() {
      return this.$store.state.token;
    },
  },
  methods: {
        agregarmisespecialidades() {
      let especialidad = this.especialidades;
      console.log(especialidad);
      axios
        .put("/candidatos/actualizaEspecialidades", {
          folio: this.$store.state.id.id,
           correo:null,
          especialidades: especialidad,
        }, { headers: {
      Authorization: 'Bearer ' + this.token}
   })
        .then((response) => {
          console.log(response.data);
          this.e1 = 7;
        })
        .catch((error) => {
          console.log(error);
        });
    },
        borrarEspecialidad: function(index) {
      this.especialidades.splice(index, 1);
    },
      agregarEspecialidad() {
         if (this.miivent  ) {
          if(this.event){
      this.especialidades.push({
        f_especialidad: this.event.folio,
        f_sub: this.miivent.folio,
        especialidad: this.event.especialidad,
        sub: this.miivent.sub,
      });
      this.event = "";
      this.miivent = "";
       this.especialidades = [
          ...this.especialidades
            .reduce((map, obj) => map.set(obj.sub, obj), new Map())
            .values(),
        ];
         }}
    },
    eliminaFoto() {
      Swal.fire({
        title: "¿Estas seguro de borrarlo?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FFC000",
        cancelButtonColor: "#FFC000",
        confirmButtonText: "¡Si, Eliminar!",
        cancelButtonText: "¡No, cancelar!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/candidatos/quitafoto", {
              id: this.$store.state.id.id,
            }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
            .then((response) => {
              console.log(response.data);
              this.$router.go(this.$router.currentRoute)
            })

            .catch((error) => {
              console.log(error);
            });
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your imaginary file is safe :)",
            "error"
          );
        }
      });
    },

    ...mapMutations(["setEdad","setEspecialidad",
      "setsubEspecialidad"]),
    subirFoto() {
      const fd = new FormData();
      fd.append("image", this.foto, this.foto.name);
      console.log(fd);
      axios
        .put("/candidatos/actualizafoto/" + this.$store.state.id.id, fd , { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
        .then((response) => {
          console.log(response.data);
        })
        .then((data) => {
          this.avisoFoto =
            "La foto se ha actualizado con exito al ingresar de nuevo podras verla";
          this.$router.go(this.$router.currentRoute);
        })
        .catch((error) => {
          this.errorM =
            "La foto que tratas de subir es muy pesada o no cuenta con extension: jpg, png o jpeg";
          console.log(error);
        });
    },
    eliminarCv() {
      Swal.fire({
        title: "¿Estas seguro de borrarlo?",
        text: "¡No podras revertir esta acción!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FFC000",
        cancelButtonColor: "#FFC000",
        confirmButtonText: "¡Si, Eliminar!",
        cancelButtonText: "¡No, cancelar!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/candidatos/quitacv", {
              id: this.$store.state.id.id,
            }, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
            .then((response) => {
              console.log(response.data);
              this.$router.go(this.$router.currentRoute);
            })

            .catch((error) => {
              console.log(error);
            });
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your imaginary file is safe :)",
            "error"
          );
        }
      });
    },
    subirVitae() {
      const cv = new FormData();
      cv.append("vitae", this.file, this.file.name);
      console.log(this.file);
      console.log(this.$store.state.id.id);
      axios
        .put("/candidatos/actualizavitae/" + this.$store.state.id.id, cv, { headers: {
      Authorization: 'Bearer ' + this.$store.state.token}
   })
        .then((response) => {
          console.log(response.data);
        })
        .then((data) => {
          this.avisoVitae =
            "El CV se ha actualizado con exito al ingresar de nuevo podras verlo";
          this.$router.go(this.$router.currentRoute);
        })
        .catch((error) => {
          this.errorVitae = "Error";
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.tarjeta-postulaciones {
  padding: 25px;
  border-radius: 15px;
  background-color: darkgrey !important;
}
.tarjeta-trabajos {
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey !important;
}
.tarjeta-trabajos {
  padding: 25px;
  width: 600px;
  border-radius: 15px;
  background-color: darkgrey !important;
}
.hola {
  border-radius: 25px;
}
.nombre {
  color: white;
  margin-left: 30px;
  margin-top: 20px;
  font-size: 30px;
}
#espacio {
  display: none;
}
.miimagen {
  border-radius: 40px;
}

.gris {
  background-color: #2afed6;
  height: 100px;
}
.estado {
  margin-left: 390px;
}
.percepcion {
  margin-top: -5px;
  margin-left: 395px;
}
.trabajando {
  margin-top: -5px;
  margin-left: 395px;
}
.idiomas {
  margin-top: -25px;
  margin-left: 395px;
}
@media only screen and (max-width: 768px) {
  .imagen {
    border: 4px solid white;
    width: 100px;
    height: 100px;
    margin-top: 20px;
    border-radius: 40px;
    display: flex;
    margin-left: -0px;
    background-color: #ddd;
  }
  .nombre {
    color: white;
    margin-left: -30px;
    font-size: 24px;
    text-align: center;
    margin-top: 0px;
  }
  .titulo {
    margin-top: 10px;
    margin-left: -39px;
    text-align: center;
    font-size: 15px;
  }
  .estado {
    margin-left: 50px;
    margin-bottom: 10px;
  }

  .espacio {
    display: initial;
  }
  .percepcion {
    margin-left: 50px;
  }
  .trabajando {
    margin-left: 50px;
    margin-top: 0px;
  }
  .idiomas {
    margin-left: 50px;
    margin-top: -30px;
  }
  .gris {
    background-color: #2afed6;
    height: 310px;  }
 .celularphone{
    display: none;
  }
 

    
}
@media only screen and (min-width: 800px) {
  /* todo lo de pc */
  .hola{
    height:220px;
  }
 .cel{
    display: none;
  }
  #botonFoto {
    margin-left: -240px;
    margin-top: 20px;
  }
  #phone {
    margin-left: 396px;
  }
  #cashi {
    margin-top: 0px;
  }
  #edad {
    margin-left: 340px;
    margin-top: 0px;
  }
  .imagen {
    border: 4px solid white;
    width: 200px;
    height: 200px;
    margin-top: 10px;
    border-radius: 40px;
    display: flex;
    margin-left: 60px;
    background-color: #ddd;
  }
}

@media (min-width: 700px) and (orientation: landscape) {
}
@media (min-width: 700px) and (orientation: portrait) {
}

@media only screen and (max-width: 768px) {
  /* todo lo de cel */
  .hola{
    height:120px;
  }
  #phone {
    margin-left: 48px;
    margin-top: -5px;
  }
  .celularphone{
    display: none;
  }
  #eliminarFoto{
    display: none;
  }
  #botonFoto{
    margin-top:-60px;
    margin-left:-15px;
  }
  
}
</style>
