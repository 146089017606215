import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Perfil from '../components/Perfil.vue'
import Login from '../components/Login.vue'
import Profesionistas from '../components/Profesionistas.vue'
import DatosPersonales from '../components/DatosPersonales.vue'
import Contacto from '../components/Contacto.vue'
import FacturaEnviada from '../components/FacturaEnviada.vue'
import Vacante from '../components/Vacante.vue'
import VacanteOperativa from '../components/VacanteOperativa.vue'
import Registro from '../components/Registro.vue'
import Olvide from '../components/Olvide.vue'
import OpcionPostulacion from '../components/OpcionPostulacion.vue'
import RecuperacionDeContrasena from '../components/RecuperacionDeContrasena.vue'
import MisPostulaciones from '../components/MisPostulaciones.vue'
import LoginEmpresas from '../components/empresas/LoginEmpresas.vue'
import EmpresasRegistro from '../components/empresas/EmpresasRegistro.vue'
import PerfilEmpresas from '../components/empresas/PerfilEmpresas.vue'
import EmpresasPerfilUsuario from '../components/empresas/EmpresasPerfilUsuario.vue'
import EmpresasVacantes from '../components/empresas/EmpresasVacantes.vue'
import NuevaVacante from '../components/empresas/NuevaVacante.vue'
import verVacante from '../components/empresas/verVacante.vue'
import verPerfilUsuario from '../components/empresas/verPerfilUsuario.vue'
import EmpresasListas from '../components/empresas/EmpresasListas.vue'
import NuevaLista from '../components/empresas/NuevaLista.vue'
import OlvideEmpresas from '../components/empresas/OlvideEmpresas.vue'
import RecuperaContrasena from '../components/empresas/RecuperaContrasena.vue'
import compraExitosa from '../components/empresas/compraExitosa.vue'
import ComprarCreditos from '../components/empresas/ComprarCreditos.vue'
import RegistroDatosPersonales from '../components/RegistroDatosPersonales.vue'
import Compra from '../components/Compra.vue'
import Proximamente from '../components/Proximamente.vue'
import VacantesOperativas from '../components/empresas/VacantesOperativas.vue'
import NuevaVacanteOperativa from '../components/empresas/NuevaVacanteOperativa.vue'
import Operativas from '../components/Operativas.vue'
import verVacanteOperativo from '../components/empresas/verVacanteOperativo.vue'
import Administrativas from '../components/Administrativas.vue'
import Servicios from '../components/Servicios.vue'
import RegistroServicios from '../components/RegistroServicios.vue'
import LoginServicios from '../components/LoginServicios.vue'
import PerfilEmpresaServicios from '../components/empresas/PerfilEmpresaServicios.vue'
import MisServicios from '../components/empresas/MisServicios.vue'
import NuevoServicio from '../components/empresas/NuevoServicio.vue'
import Facturacion from '../components/empresas/Facturacion.vue'
import ReciboPago from '../components/ReciboPago.vue'
import ReciboEnviado from '../components/ReciboEnviado.vue'
import NuevaVacanteOperativaGratuita from '../components/empresas/NuevaVacanteOperativaGratuita.vue'
import TipoVacante from '../components/TipoVacante.vue'
import NuevaVacanteGratuita from '../components/empresas/NuevaVacanteGratuita.vue'
import PaginaNoEncontrada from '../components/PaginaNoEncontrada.vue'


Vue.use(VueRouter)


const routes = [
 
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Facturacion',
    name: 'Facturacion',
    component: Facturacion,
  },
  {
    path: '/NuevaVacanteGratuita',
    name: 'NuevaVacanteGratuita',
    component: NuevaVacanteGratuita,
  },
  {
    path: '/NuevaVacanteOperativaGratuita',
    name: 'NuevaVacanteOperativaGratuita',
    component: NuevaVacanteOperativaGratuita,
  },

  {
    path: '/ReciboPago',
    name: 'ReciboPago',
    component: ReciboPago,
  },
  {
    path: '/ReciboEnviado',
    name: 'ReciboEnviado',
    component: ReciboEnviado,
  },
  {
    path: '/NuevoServicio',
    name: 'NuevoServicio',
    component: NuevoServicio,
  },
  {
    path: '/MisServicios',
    name: 'MisServicios',
    component: MisServicios,
  },
  {
    path: '/PerfilEmpresaServicios',
    name: 'PerfilEmpresaServicios',
    component: PerfilEmpresaServicios,
  },
  {
    path: '/Proximamente',
    name: 'Proximamente',
    component: Proximamente,
  },
  {
    path: '/RegistroServicios',
    name: 'RegistroServicios',
    component: RegistroServicios,
  },
  {
    path: '/LoginServicios',
    name: 'LoginServicios',
    component: LoginServicios,
  },
  {
    path: '/Administrativas',
    name: 'Administrativas',
    component: Administrativas,
  },
  {
    path: '/Servicios',
    name: 'Servicios',
    component: Servicios,
  },
  {
    path: '/Registro',
    name: 'Registro',
    component: Registro,
  },
  {
    path: '/verVacanteOperativo',
    name: 'verVacanteOperativo',
    component: verVacanteOperativo,
  },
  {
    path: '/Operativas',
    name: 'Operativas',
    component: Operativas,
  },
  {
    path: '/EmpresasListas',
    name: 'EmpresasListas',
    component: EmpresasListas,
  },
  {
    path: '/NuevaVacanteOperativa',
    name: 'NuevaVacanteOperativa',
    component: NuevaVacanteOperativa,
  },
  {
    path: '/RegistroDatosPersonales',
    name: 'RegistroDatosPersonales',
    component: RegistroDatosPersonales,
  },
  {
    path: '/ComprarCreditos',
    name: 'ComprarCreditos',
    component: ComprarCreditos,
  },
  {
    path: '/compraExitosa',
    name: 'compraExitosa',
    component: compraExitosa,
  },
  {
    path: '/NuevaLista',
    name: 'NuevaLista',
    component: NuevaLista,
  },
  {
    path: '/TipoVacante',
    name: 'TipoVacante',
    component:TipoVacante,
  },
  
  {
    path: '/olvide',
    name: 'olvide',
    component: Olvide
  },

  {
    path: '/RecuperaContrasena',
    name: 'RecuperaContrasena',
    component: RecuperaContrasena
  },
  {
    path: '/OlvideEmpresas',
    name: 'OlvideEmpresas',
    component: OlvideEmpresas
  },
  {
    path: '/vacante/:folio',
    name: 'vacante',
    component: Vacante
  },
  {
    path: '/VacanteOperativa/:folio',
    name: 'VacanteOperativa',
    component: VacanteOperativa
  },
  {
    path: '/verVacante',
    name: 'verVacante',
    component: verVacante
  },
  {
    path: '/verPerfilUsuario/',
    name: 'verPerfilUsuario',
    component: verPerfilUsuario
  },
  {
    path: '/MisPostulaciones',
    name: 'MisPostulaciones',
    component: MisPostulaciones
  },
  {
    path: '/NuevaVacante',
    name: 'NuevaVacante',
    component: NuevaVacante
  },
  {
    path: '/OpcionPostulacion',
    name: 'OpcionPostulacion',
    component: OpcionPostulacion
  },
  
  {
    path: '/datospersonales',
    name: 'datospersonales',
    component: DatosPersonales
  },
  {
    path: '/RecuperacionDeContrasena',
    name: 'RecuperacionDeContrasena',
    component: RecuperacionDeContrasena
  },
  {
    path: '/registro',
    name: 'registro',
    component: Registro
  },
  {
    path: '/Compra',
    name: 'Compra',
    component: Compra
  },
  {
    path: '/EmpresasRegistro',
    name: 'EmpresasRegistro',
    component: EmpresasRegistro
  },
  {
    path: '/FacturaEnviada',
    name: 'FacturaEnviada',
    component: FacturaEnviada
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: Contacto
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: Perfil
  },
  {
    path: '/Profesionistas',
    name: 'Profesionistas',
    component: Profesionistas
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/loginEmpresas',
    name: 'loginEmpresas',
    component: LoginEmpresas
  },
  {
    path: '/PerfilEmpresas',
    name: 'PerfilEmpresas',
    component: PerfilEmpresas
  },
  {
    path: '/EmpresasPerfilUsuario',
    name: 'EmpresasPerfilUsuario',
    component:  EmpresasPerfilUsuario
  }
  ,
  {
    path: '/EmpresasVacantes',
    name: 'EmpresasVacantes',
    component:  EmpresasVacantes
  },
  {
    path: '/VacantesOperativas',
    name: 'VacantesOperativas',
    component:  VacantesOperativas
  }


  
  
]

const router = new VueRouter({
  mode: "history",
  history: true,
  hashbang: true,
  
  base: process.env.BASE_URL,
  routes
})

export default router
